import { Injectable } from '@angular/core';

import { catchError, concatMap, distinctUntilChanged, filter, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';

import * as ChartActions from '../chart/chart.actions';
import { DashboardActions } from './dashboard.actions';
import * as ChartAvailabilityActions from '@store/container-chart-availability/chart-availability.actions';
import { ApiQueryBuilder } from '@services/api-query-builder';
import { ApiCallService } from '@services/api-call.service';
import { FilterService } from '@services/filter.service';
import { ContainerStatusListService } from '@services/container-status-list.service';

import { IPartFilterAndOrderState } from '@models/dashboard/filters.model';
import { getPartFiltersAndOrders } from '@store/dashboard/dashboard.selectors';


@Injectable()
export class DashboardEffects {

  constructor(
    // eslint-disable-next-line @typescript-eslint/ban-types
    private store: Store<{}>,
    private actions$: Actions,
    private api: ApiCallService,
    private filterService: FilterService,
    private containerStatusListService: ContainerStatusListService,
  ) {}

  public getContainerStatusListResults = createEffect(() => this.actions$.pipe(
    ofType(DashboardActions.getContainerStatusListResults),
    mergeMap(action => {
      const requestObject = this.containerStatusListService.getRequestObjectToSend(action);

      return this.api.constructApiCall(
        new ApiQueryBuilder()
        .addName('containerStatusList')
        .addBody(requestObject)
        .build(),
      ).pipe(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        map((data: any) => {
          const newAction = JSON.parse(JSON.stringify(action)) // sorry for that, i didn't mean to
          newAction.payload.customFieldApplied = requestObject.customFieldsRequest.filterOnCustomFieldsData.length ?
            ContainerStatusListService.isFilteredFor(requestObject.customFieldsRequest.filterOnCustomFieldsData, 'ownerId', 'key') ||
            ContainerStatusListService.isFilteredFor(requestObject.customFieldsRequest.filterOnCustomFieldsData, 'priority', 'key') :
            false;

          const parsedContainers = this.containerStatusListService.getParsedContainerStatusListResponse(data, newAction);

          return DashboardActions.getContainerStatusListResultsSuccess({
            payload: parsedContainers,
            parent: newAction.parent,
            action: newAction,
          });
        }),
        catchError(() => {
          return of(DashboardActions.getContainerStatusListResultsFailure({
            payload: null,
            parent: action.parent,
          }));
        }),
      );
    }),
  ));


  public updateContainerStatusListAndChart = createEffect(() => this.actions$.pipe(
    ofType(DashboardActions.updateListAndChart),
    concatMap(action =>
      of(action).pipe(
        withLatestFrom(
          this.store.pipe(
            select(getPartFiltersAndOrders, { parent: action.parent }),
            filter((filters: IPartFilterAndOrderState) =>
              !!(filters.orders && filters.orders.length || filters.partFilters && filters.partFilters.length),
            ),
            distinctUntilChanged((prev: IPartFilterAndOrderState, value: IPartFilterAndOrderState) =>
              JSON.stringify(prev) === JSON.stringify(value),
            ),
          ),
        ),
      ),
    ),
    map(([action, filters]) => {
      const claimPayload = {
        paging: {
          orders: filters.orders,
          filters: this.filterService.composeFilters(filters.partFilters),
          page: 0,
          showMore: false,
        },
      };

      this.store.dispatch(DashboardActions.getContainerStatusListResults({
        payload: claimPayload,
        parent: action.parent || 'container',
      }));

      const payload = { filters: this.filterService.composeFilters(filters.partFilters) };
      this.store.dispatch(new ChartActions.GetContainerStatusListChartResults(payload));

      // Update available containers
      this.store.dispatch(new ChartAvailabilityActions.GetContainerChartAvailability());
      return action;
    }),
  ), { dispatch: false });
}

