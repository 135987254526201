import { Injectable } from '@angular/core';
import { add, startOfDay, format } from 'date-fns';
import { IOptionItem } from '@app/shared/models/dashboard/tree-option-filter.model';

const PORT_OF_LA_KEY = 2704;
const PORT_OF_LB_KEY = 2709;

const PORT_NAMES = {
  [PORT_OF_LA_KEY]: 'Port of Los Angeles',
  [PORT_OF_LB_KEY]: 'Port of Long Beach',
};

export interface EmptiesHistoricalFetchResponse {
  queryDate: string;
  message: string;
  errorResponse: unknown[];
  successResponse: { [key: string]: string };
}

export interface EmptiesHistoricalTerminalFilterSetResponse {
  lookuphelp: {};
  response: {
    hits: {
      total: {
        value: number;
        relation: string;
      };
    };
    aggregations: {
      byPort: {
        doc_count_error_upper_bound: number;
        sum_other_doc_count: number;
        buckets: {
          doc_count: number;
          key: string;
          byTerminal: {
            doc_count_error_upper_bound: number;
            sum_other_doc_count: number;
            buckets: {
              key_as_string: string;
              doc_count: number;
              key: string;
            }[];
          };
        }[];
      };
    };
  };
  responsehelp: {
    byTerminal: {
      [code: string]: string;
    };
  };
}

export interface EmptiesHistoricalData {
  queryDate: string;
  terminal: string;
  imageData: string;
}

export interface EmptiesHistoricalFetchPayload {
  terminals: string[];
  queryDate: string;
}

export interface EmptiesHistoricalFetchParams {
  terminals: string[];
  date: Date;
  time: { hours: number; minutes: number };
}

@Injectable({
  providedIn: 'root',
})
export class EmptiesHistoricalService {
  public parsePayload(
    params: EmptiesHistoricalFetchParams,
  ): EmptiesHistoricalFetchPayload {
    if (
      !params.date ||
      !params.time ||
      params.time.hours == null ||
      params.time.minutes == null
    ) {
      return null;
    }
    const queryDate = format(add(startOfDay(params.date), params.time), 'yyyy-MM-dd\'T\'HH:mm:ss');
    return {
      terminals: params.terminals,
      queryDate,
    };
  }

  public parseResponse(
    response: EmptiesHistoricalFetchResponse,
  ): EmptiesHistoricalData[] {
    const { queryDate, successResponse } = response;
    return Object.entries(successResponse).map(([terminal, imageData]) => ({
      queryDate,
      terminal,
      imageData,
    }));
  }

  public createTerminalFilterSetPayload() {
    return {
      searchParameters: [
        {
          key: 'byPort',
          children: [
            {
              key: 'byTerminal',
              children: [],
            },
          ],
        },
      ],
    };
  }

  public parseTerminalFilterSetResponse(
    response: EmptiesHistoricalTerminalFilterSetResponse,
  ): IOptionItem[] {
    return response.response.aggregations.byPort.buckets.map((port) => {
      return {
        title: PORT_NAMES[port.key],
        value: port.key,
        children: port.byTerminal.buckets.map((terminal) => ({
          title: terminal.key_as_string,
          value: terminal.key,
          children: [],
          expandable: false,
          disabled: false,
        })).sort((a, b) => a.title > b.title ? 1 : -1),
        expandable: false,
        disabled: false,
      };
    });
  }
}
