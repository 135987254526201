import { StatusName } from '@app/shared/components/status-indicator/status-indicator.constants';
import { PriorityFlagsEnum } from './container-status.model';
import { IOptionItem } from './tree-option-filter.model';
import { CONTAINER_LENGTH, FLAG, UNLOAD_EVENT } from '@models/dashboard/filters.model';

export const INTERMODAL_LIST_PAGE_SIZE = 5;

export enum IntermodalVesselStatusEnum {
  INBOUND_TO_PORT = 'Inbound to Port',
  REACHED_PORT = 'Reached Port',
}

export const intermodalVesselStatusMapToProcess = {
  [IntermodalVesselStatusEnum.INBOUND_TO_PORT]: StatusName.INBOUND,
  [IntermodalVesselStatusEnum.REACHED_PORT]: StatusName.DOCKED,
};

export enum IntermodalContainerStatusEnum {
  INTERCHANGE_DELIVERED = 'Interchange Delivered',
  DISCHARGED_FROM_RAIL = 'Discharged from Rail',
  LOADED_ON_TO_VESSEL = 'Loaded on to Vessel',
  LOADED_ON_RAIL = 'Loaded on Rail',
}

export interface IntermodalItem {
  id: string;
  containerCount: number;
  destinationCity: string;
  destinationCountryAbbreviation: string;
  destinationStateAbbreviation: string;
  destinationTerminalCode?: string;
  dropDownIntermodalName: string;
  estimatedTimeOfArrival: Date;
  interchangeDelivered?: Date;
  originCity: string;
  originCountryAbbreviation: string;
  originStateAbbreviation: string;
  portCode: string;
  portName: string;
  railRoad: string;
  trainId: string;
  trainTripId: string;
  vesselStatus: IntermodalVesselStatusEnum;
}

export interface IntermodalContainersListItem {
  checkDigit: string;
  containerLength: number;
  containerNumber: string;
  containerStatus: IntermodalContainerStatusEnum;
  containerTripId: string;
  created_on: number;
  custom: {
    claimed_on: Date;
    customField1?: string;
    notes?: string;
    orgId: string;
    ownerName: string,
    ownerId: string;
    priority?: PriorityFlagsEnum;
  }[];
  derivedContainerLength: string;
  destinationCity: string;
  destinationSPLC: string;
  destinationStateAbbreviation: string;
  destinationTerminalCode: string;
  equipmentAARType: string;
  equipmentId: string;
  estimatedTimeOfArrival: Date;
  eventTimeStamp: string;
  flag: PriorityFlagsEnum;
  flatcarEquipmentId: string;
  interchangeDelivered?: Date;
  loadEmptyCode: string;
  originCity: string;
  originCountryAbbreviation: string;
  originSPLC: string;
  originStateAbbreviation: string;
  portCode: string;
  portName: string;
  railRoad: string;
  trainId: string;
  trainTripId: string;
  vesselCode?: string;
  vesselName?: string;
  waybillDate: Date;
  waybillNumber: string;
}

export type UpdateFlagPayload = Pick<IntermodalCustomFields, 'containerNumber' | 'tripId' | 'priority'>;

export interface IntermodalCustomFields {
  containerNumber: string;
  tripId: string;
  firstCustomField?: string;
  secondCustomField?: string;
  notes?: string;
  owner?: string;
  ownerId?: string;
  priority?: PriorityFlagsEnum;
}

export enum FILTER_KEYS_ENUM {
  MY_CONTAINERS = 'custom.ownerId',
  TERMINAL = 'destinationTerminalCode',
  ESTIMATED_TIME_OF_ARRIVAL = 'estimatedTimeOfArrival',
  INTERCHANGE_DELIVERED = 'interchangeDelivered',
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const FilterSet: IOptionItem[] = [
  {
    title: 'My containers',
    value: 'myContainers',
    children: [
      {
        title: 'My containers',
        value: FILTER_KEYS_ENUM.MY_CONTAINERS,
        children: [],
        expandable: false,
        disabled: false,
      },
    ],
    expandable: false,
    disabled: false,
  },
  {
    title: 'Terminal',
    value: 'terminal',
    children: [],
    expandable: false,
    disabled: false,
  },
  {
    title: 'Status',
    value: 'status',
    children: [
      {
        title: IntermodalVesselStatusEnum.INBOUND_TO_PORT,
        value: IntermodalVesselStatusEnum.INBOUND_TO_PORT,
        children: [],
        expandable: false,
        disabled: false,
      },
      {
        title: IntermodalVesselStatusEnum.REACHED_PORT,
        value: IntermodalVesselStatusEnum.REACHED_PORT,
        children: [],
        expandable: false,
        disabled: false,
      },
    ],
    expandable: true,
    disabled: false,
  },
];

// eslint-disable-next-line @typescript-eslint/naming-convention
export const FilterSetForContainerList: IOptionItem[] = [
  {
    title: 'Priority',
    value: FLAG,
    children: [
      {
        title: 'Low',
        value: 'LOW',
        children: [],
        expandable: false,
        disabled: false,
      },
      {
        title: 'Medium',
        value: 'MEDIUM',
        children: [],
        expandable: false,
        disabled: false,
      },
      {
        title: 'High',
        value: 'HIGH',
        children: [],
        expandable: false,
      },
    ],
    expandable: false,
    disabled: false,
  },
  {
    title: 'Status',
    value: UNLOAD_EVENT,
    children: [
      {
        title: 'Interchange Delivered',
        value: 'Interchange Delivered',
        children: [],
        expandable: false,
        disabled: false,
      },
      {
        title: 'Discharged from Rail',
        value: 'Discharged from Rail',
        children: [],
        expandable: false,
        disabled: false,
      },
      {
        title: 'Loaded on to Vessel',
        value: 'Loaded on to Vessel',
        children: [],
        expandable: false,
        disabled: false,
      },
      {
        title: 'Loaded on Rail',
        value: 'Loaded on Rail',
        children: [],
        expandable: false,
        disabled: false,
      },
    ],
    expandable: false,
    disabled: false,
  },
  {
    title: 'Container Size',
    value: CONTAINER_LENGTH,
    children: [
      {
        title: '20',
        value: '20\'',
        children: [],
        expandable: false,
        disabled: false,
      },
      {
        title: '40',
        value: '40\'',
        children: [],
        expandable: false,
        disabled: false,
      },
      {
        title: '45',
        value: '45\'',
        children: [],
        expandable: false,
      },
      {
        title: 'Others',
        value: 'Others',
        children: [],
        expandable: false,
        disabled: false,
      },
    ],
    expandable: false,
    disabled: false,
  },
];

export interface FetchListPayload {
  filters?: string[];
  page?: number;
  userId?: string;
}
