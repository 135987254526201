import { Order } from '@shared/models/dashboard/order.model';
import { isArray } from '../utility/util';

export const DEFAULT_PAGE_SIZE = 50;

const defaultPaging = {
  orders: [
    {
      direction: 'ASC',
      property: 'containerNumber',
      title: 'Number',
    },
  ],
  pageNumber: 0,
  pageSize: DEFAULT_PAGE_SIZE,
};
interface Paging {
  pageNumber: number;
  pageSize: number;
  orders: Order[];
}

export class ApiQueryBuilder {
  private name: string;
  private body: object;
  private paging: Paging;
  private query: string;
  private isFile: boolean;
  private entries: any[] = [];
  private headers: any[] = [];

  public addName(name: string): this {
    this.name = name;
    return this;
  }

  public addBody(body: any): this {
    this.body = isArray(body) ? [...body] : { ...body };
    return this;
  }

  private addCommonPaging(paging: any, pagingData: any = null) {
    this.paging = { ...paging };
    if (pagingData && pagingData.orders) {
      this.paging.orders = pagingData.orders;
    }

    if (pagingData && !pagingData.orders) {
      delete this.paging.orders;
    }

    if (pagingData && pagingData.page) {
      this.paging.pageNumber = pagingData.page;
    }
  }

  public addPaging(pagingData: any = null): this {
    this.addCommonPaging(defaultPaging, pagingData);

    if (pagingData && pagingData.filters) {
      this.paging['filters'] = pagingData.filters;
    }
    return this;
  }

  public setIsFile(value: boolean): this {
    this.isFile = value;
    return this;
  }

  public addQuery(query: string): this {
    this.query = query;
    return this;
  }

  public addPageNumber(number: number): this {
    this.paging.pageNumber = number;
    return this;
  }

  public addPageSize(number: number): this {
    this.paging.pageSize = number;
    return this;
  }

  public addEntry(key: string, value: string): this {
    this.entries.push({ key, value });
    return this;
  }

  public addHeaderEntry(key: string, value: string): this {
    this.headers.push(key, value);
    return this;
  }

  public build() {
    const returnObject = { name: this.name };

    if (this.body) {
      returnObject['body'] = isArray(this.body) ? [...this.body] : { ...this.body };
    }

    if (this.paging) {
      returnObject['paging'] = { ...this.paging };
    }

    if (this.query) {
      returnObject['query'] = this.query;
    }

    if (this.isFile) {
      returnObject['isFile'] = this.isFile;
    }

    if (this.entries.length) {
      returnObject['entries'] = this.entries;
    }

    return returnObject;
  }
}
