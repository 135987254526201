import { Action } from '@ngrx/store';

export const DOWNLOAD_CSV = '[Download] DOWNLOAD_CSV';
export const DOWNLOAD_SUCCESS = '[Download] DOWNLOAD_SUCCESS';
export const DOWNLOAD_ERROR = '[Download] DOWNLOAD_ERROR';

export class DownloadCsv implements Action  {
    public readonly type = DOWNLOAD_CSV;
    constructor (public payload: any, public parent: string = '') {}
}

export class DownloadSuccess implements Action  {
    public readonly type = DOWNLOAD_SUCCESS;
}

export class DownloadError implements Action  {
    public readonly type = DOWNLOAD_ERROR;
}

export type All = DownloadCsv | DownloadSuccess | DownloadError;
