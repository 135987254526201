import { Action } from '@ngrx/store';

export const FETCH_HOLD_CODES = '[HoldCodes] FETCH_HOLD_CODES';
export const FETCH_HOLD_CODES_SUCCESS = '[HoldCodes] FETCH_HOLD_CODES_SUCCESS';
export const FETCH_HOLD_CODES_ERROR = '[HoldCodes] FETCH_HOLD_CODES_ERROR';


export class FetchHoldCodes implements Action {
  public readonly type = FETCH_HOLD_CODES;
  constructor(public payload: any = null) { }
}

export class FetchHoldCodesSuccess implements Action {
  public readonly type = FETCH_HOLD_CODES_SUCCESS;
  constructor(public payload: any = null) { }
}
export class FetchHoldCodesError implements Action {
  public readonly type = FETCH_HOLD_CODES_ERROR;
  constructor(public payload: any = null) { }
}


export type All = FetchHoldCodes | FetchHoldCodesSuccess | FetchHoldCodesError;
