import { Component, EventEmitter, OnInit, Output, ChangeDetectionStrategy } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { passwordMatchValidator } from '@app/core/validators/password-match.validator';
import { select, Store } from '@ngrx/store';

import * as ForgetPasswordActions from '@store/forget-password/forget-password.actions';
import * as fromForgetPasswords from '@store/forget-password/forget-password.reducer';
import { Observable, of } from 'rxjs';

export interface UpdatePassword {
  code: string;
  password: string;
  confirmPassword: string;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-password-change-modal',
  templateUrl: './password-change-modal.component.html',
  styleUrls: ['./password-change-modal.component.css'],
})
export class PasswordChangeModalComponent implements OnInit {
  @Output() public closeForm = new EventEmitter<void>();
  public resetPassword: FormGroup;

  public get code(): AbstractControl {
 return this.resetPassword.get('code'); 
}
  public get password(): AbstractControl {
 return this.resetPassword.get('password'); 
}
  public get confirmPassword(): AbstractControl {
 return this.resetPassword.get('confirmPassword'); 
}

  public updateErrorData$: Observable<any>;
  public updatePasswordSuccess$: Observable<boolean> = of(false);

  public _injectedData: any;

  constructor(
    private fb: FormBuilder,
    // eslint-disable-next-line @typescript-eslint/ban-types
    private store: Store<{}>,
  ) {
    this.resetPassword = this.fb.group({
      code: ['', [Validators.required, Validators.pattern(/^[0-9]{6,}$/)]],
      password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(64)]],
      confirmPassword: ['', [Validators.required, Validators.maxLength(64)]],
    }, { validators: [passwordMatchValidator] });
  }

  public ngOnInit() {
    this.updateErrorData$ = this.store.pipe(select(fromForgetPasswords.getUpdateErrorData));
    this.updatePasswordSuccess$ = this.store.pipe(select(fromForgetPasswords.getSetPasswordStatus));

    this.updatePasswordSuccess$.subscribe((success) => {
      if (success) {
        this.store.dispatch(new ForgetPasswordActions.ClearForgetPasswordState());
        this.closeForm.emit();
      }
    });
  }

  public resetPasswordClick(data: UpdatePassword, valid: boolean): void {
    if (valid) {
      this.store.dispatch(new ForgetPasswordActions.SetPassword({
        email: this._injectedData.email,
        code: data.code,
        password: data.password,
      }));
    }
  }

  public closeModal() {
    this.closeForm.emit();
  }
}
