import { createFeatureSelector, createSelector } from '@ngrx/store';
import { NotificationTypesState } from './notification-types.state';
import * as NotificationTypeActions from './notification-types.actions';

export type Action = NotificationTypeActions.All;

const initialState: NotificationTypesState = {
  alertTypes: [],
  loading: false,
  error: false,
};

const getNotificationTypesState = createFeatureSelector<NotificationTypesState>('notificationTypes');

export const getAlertTypes = createSelector(
  getNotificationTypesState,
    state => state.alertTypes,
);

// eslint-disable-next-line @typescript-eslint/naming-convention
export function NotificationTypesReducer (
  state = initialState,
  action: Action,
): NotificationTypesState {
  switch (action.type) {

    case NotificationTypeActions.FETCH_ALERT_TYPES: {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }

    case NotificationTypeActions.FETCH_ALERT_TYPES_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        alertTypes: [...action.payload],
      };
    }

    case NotificationTypeActions.FETCH_ALERT_TYPES_FAIL: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }

    default:
      return state;
  }
}
