import { Component, EventEmitter, OnDestroy, OnInit, Output, ChangeDetectionStrategy } from '@angular/core';

import { Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';
import { State } from '@store/app.state';

import { UNCLAIM_CONTAINER_MODAL } from '@models/shared/modal.constants';
import { getSelectedList } from '@store/dashboard/dashboard.selectors';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-unclaim-container-modal',
  templateUrl: './unclaim-container-modal.component.html',
  styleUrls: ['./unclaim-container-modal.component.css'],
})
export class UnclaimContainerModalComponent implements OnInit, OnDestroy {
  @Output() public submitData = new EventEmitter<object>();
  @Output() public closeForm = new EventEmitter<void>();

  private subscriptions: Subscription = new Subscription();
  private readonly parent: string = 'container';

  public readonly _injectedData: any;
  public selection: string[];

  constructor(private store: Store<State>) {}

  public ngOnInit(): void {
    this.subscriptions.add(
      this.store
        .pipe(
          select(getSelectedList, { parent: (this._injectedData && this._injectedData.parent) || this.parent }),
          tap((list) => {
            let selection = [];
            if (list.selected.length > 0) {
              selection = selection.concat(list.selected);
            }
            if (list.selectedPendingContainers.length > 0) {
              selection = selection.concat(list.selectedPendingContainers);
            }
            this.selection = selection;
          }),
        )
        .subscribe(),
    );
  }

  public closeModal = (): void => this.closeForm.emit();

  public emitData(): void {
    const payload = {
      modalType: UNCLAIM_CONTAINER_MODAL,
      containers: this.selection,
    };

    this.submitData.emit({ data: payload });
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
