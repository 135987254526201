import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { filter, map, switchMap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import * as RoutingActions from './routing.actions';


@Injectable()
export class RoutingEffects {
  constructor(
    private actions$: Actions,
    private router: Router,
  ) {}

  public logRoutes$ = createEffect(() => this.actions$.pipe(
    ofType<RoutingActions.StartLogging>(RoutingActions.START_LOGGING),
    switchMap(() =>
      this.router.events
        .pipe(
          filter(event => event instanceof NavigationEnd),
          map(({ url }: NavigationEnd) => new RoutingActions.SaveRoute(url)),
        ),
    ),
  ),
  );
}
