export const requestSpecialHandling = {
  'containerDetails': [],
  'serviceName': '',
  'terminalCode': '',
  'notes': '',
};

export const requestContainer = {
  "containerNumber":"",
  "typeCode":"",
  "vesselName":"",
  "containerTripId":"",
  "etaAta": 0,
  "terminalCode":"",
}

export enum RequestType {
  PEEL_PILE = "Peel pile",
}