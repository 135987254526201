import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { ApiQueryBuilder } from '@services/api-query-builder';

import * as LandingPageAction from './landing-page-settings.actions';
import { ApiCallService } from '@services/api-call.service';


@Injectable()
export class LandingPageSettingsEffects {

  constructor(
    private actions$: Actions,
    private api: ApiCallService,
  ) {
  }

  public GetLandingPageSettings$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<LandingPageAction.GetLandingPageSettings>(LandingPageAction.GET_LANDING_PAGE_SETTINGS_DATA),
    mergeMap(action => {
      return this.api.constructApiCall(
        new ApiQueryBuilder()
          .addName('landingPage')
          .addBody(action.payload)
          .build(),
      )
        .pipe(
          map((data) => new LandingPageAction.GetLandingPageSettingsSuccess(data)),
          catchError((error) => of(new LandingPageAction.GetLandingPageSettingsError(error))),
        );
    }),
  ),
  );
}
