import { Action } from '@ngrx/store';

export const TERMS_OF_USE_ACCEPT = '[TermsOfUse] TERMS_OF_USE_ACCEPT';
export const TERMS_OF_USE_DENY = '[TermsOfUse] TERMS_OF_USE_DENY';

export class TermsOfUseAccept implements Action {
  public readonly type = TERMS_OF_USE_ACCEPT;
}

export class TermsOfUseDeny implements Action {
  public readonly type = TERMS_OF_USE_DENY;
}

export type All = TermsOfUseAccept | TermsOfUseDeny;
