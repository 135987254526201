import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CustomFieldsState } from './custom-fields.state';
import * as CustomFieldsActions from './custom-fields.actions';

export type Action = CustomFieldsActions.All;

const initialState: CustomFieldsState = {
  loading: false,
  error: false,
  customFieldsResponse: null,
  errorData: null,
  success: false,
  message: null,
  customFieldsUpdateLoading: false,
};

const updateCustomFieldsState = createFeatureSelector<CustomFieldsState>('customFields');

export const getErrorData = createSelector(
  updateCustomFieldsState,
  state => state.errorData,
);

export const getCustomFieldsResponse = createSelector(
  updateCustomFieldsState,
  state => state.customFieldsResponse,
);

export const getLoadingState = createSelector(
  updateCustomFieldsState,
  state => state.loading,
);

export const getCustomFieldLoadingState = createSelector(
  updateCustomFieldsState,
  state => state.customFieldsUpdateLoading,
);

export const operationSuccess = createSelector(
  updateCustomFieldsState,
  state => state.success,
);

export const operationError = createSelector(
  updateCustomFieldsState,
  state =>
    ({
      error: state.error,
      message: state.message,
    }),
);

// eslint-disable-next-line max-lines-per-function, @typescript-eslint/naming-convention
export function CustomFieldsReducer(state = initialState, action: Action): CustomFieldsState {
  switch (action.type) {
    case CustomFieldsActions.GET_CUSTOM_FIELDS_DATA: {
      return { ...state, loading: true, error: false };
    }

    case CustomFieldsActions.GET_CUSTOM_FIELDS_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        customFieldsResponse: { ...action.payload },
      };
    }

    case CustomFieldsActions.GET_CUSTOM_FIELDS_DATA_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
        errorData: { ...action.payload },
      };
    }

    case CustomFieldsActions.UPDATE_CUSTOM_FIELDS: {
      return {
        ...state,
        customFieldsUpdateLoading: true,
        success: false,
        error: false,
        message: null,
      };
    }

    case CustomFieldsActions.UPDATE_CUSTOM_FIELDS_SUCCESS: {
      return {
        ...state,
        customFieldsUpdateLoading: false,
        success: true,
        error: false,
        message: null,
      };
    }

    case CustomFieldsActions.UPDATE_CUSTOM_FIELDS_ERROR: {
      return {
        ...state,
        customFieldsUpdateLoading: false,
        success: false,
        error: true,
        message: action.payload,
      };
    }

    case CustomFieldsActions.CLEAR: {
      return {
        ...initialState,
      };
    }

    case CustomFieldsActions.UPDATE_PENDING_CONTAINER_CUSTOM_FIELDS: {
      return {
        ...state,
        customFieldsUpdateLoading: true,
        success: false,
        error: false,
        message: null,
      };
    }

    case CustomFieldsActions.UPDATE_PENDING_CONTAINER_CUSTOM_FIELDS_SUCCESS: {
      return {
        ...state,
        customFieldsUpdateLoading: false,
        success: true,
        error: false,
        message: null,
      };
    }

    case CustomFieldsActions.UPDATE_PENDING_CONTAINER_CUSTOM_FIELDS_ERROR: {
      return {
        ...state,
        customFieldsUpdateLoading: false,
        success: false,
        error: true,
        message: action.payload,
      };
    }

    case CustomFieldsActions.UPDATE_FLAG: {
      return {
        ...state,
        customFieldsUpdateLoading: true,
      };
    }

    case CustomFieldsActions.UPDATE_FLAG_SUCCESS: {
      return {
        ...state,
        customFieldsUpdateLoading: false,
      };
    }

    case CustomFieldsActions.UPDATE_FLAG_ERROR: {
      return {
        ...state,
        customFieldsUpdateLoading: false,
      };
    }

    default:
      return state;
  }
}
