import { Injectable } from '@angular/core';
import {
  IntermodalClaimPayload,
  IntermodalClaimResponse,
  IntermodalClaimData,
  IntermodalClaimParams,
} from '@app/shared/models/dashboard/intermodal-claim.model';

@Injectable({ providedIn: 'root' })
export class IntermodalClaimService {
  public parsePayload(
    params: IntermodalClaimParams,
  ): IntermodalClaimPayload {
    return {
      claimData: params.ids,
      claimingType: 'container',
    };
  }

  public parseResponse(
    response: IntermodalClaimResponse,
  ): IntermodalClaimData {
    return {
      ...response,
    };
  }
}
