import { Action } from '@ngrx/store';

export const USER_AUTHENTICATE = '[User] USER_AUTHENTICATE';
export const USER_AUTHENTICATE_SUCCESS = '[User] USER_AUTHENTICATE_SUCCESS';
export const USER_AUTHENTICATE_ERROR = '[User] USER_AUTHENTICATE_ERROR';
export const USER_DEAUTHENTICATE = '[User] USER_DEAUTHENTICATE';
export const SET_TOKEN = '[User] SET_TOKEN';
export const SET_USER = '[User] SET_USER';
export const CLEAR_AUTHENTICATION_ERROR = '[User] CLEAR_AUTHENTICATION_ERROR';
export const SET_SESSION_EXPIRED = '[User] SET_SESSION_EXPIRED';
export const CLEAR_SESSION_EXPIRATION = '[User] CLEAR_SESSION_EXPIRATION';

export class UserAuthenticate implements Action {
  public readonly type = USER_AUTHENTICATE;
  constructor(public payload: any = null) { }
}

export class UserAuthenticateSuccess implements Action {
  public readonly type = USER_AUTHENTICATE_SUCCESS;
  constructor(public payload: any = null) {}
}

export class UserAuthenticateError implements Action {
  public readonly type = USER_AUTHENTICATE_ERROR;
  constructor (public payload: any = null) { }
}

export class ClearAutheticationError implements Action {
  public readonly type = CLEAR_AUTHENTICATION_ERROR;
}

export class UserDeauthenticate implements Action {
  public readonly type = USER_DEAUTHENTICATE;
  constructor (public payload: any = null) { }
}

export class SetToken implements Action {
  public readonly type = SET_TOKEN;
  constructor(public payload: any = null) { }
}

export class SetUser implements Action {
  public readonly type = SET_USER;
  constructor(public payload: any = null) { }
}

export class SetSessionExpired implements Action {
  public readonly type = SET_SESSION_EXPIRED;
}

export class ClearSessionExpired implements Action {
  public readonly type = CLEAR_SESSION_EXPIRATION;
}

export type All = UserAuthenticate
  | UserAuthenticateSuccess
  | UserAuthenticateError
  | UserDeauthenticate
  | SetToken
  | SetUser
  | ClearAutheticationError
  | SetSessionExpired
  | ClearSessionExpired
  ;
