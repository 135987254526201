import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Routes } from '@angular/router';

import { LoaderComponent } from '@shared/components/loader/loader.component';
import { ComponentLoaderComponent } from '@shared/components/component-loader/component-loader.component';

import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { Angulartics2Module } from 'angulartics2';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { LoaderOverlayComponent } from './components/loader-overlay/loader-overlay.component';
import { AddContentComponent } from './components/add-content/add-content.component';
import { CopyrightComponent } from '@shared/components/copyright/copyright.component';
import { DownloadButtonComponent } from './components/download-button/download-button.component';
import { TableSummaryComponent } from './components/table-summary/table-summary.component';
import { TableShowMoreButtonComponent } from './components/table-show-more-button/table-show-more-button.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { BadgeComponent } from './components/badge/badge.component';
import { ApiErrorComponent } from './components/api-error/api-error.component';
import { ErrorModalComponent } from './components/error-modal/error-modal.component';
import { MaterialModule } from './material.module';
import { DateDurationPipe } from './pipes/duration.pipe';
import { RequestHandlingPipe } from './pipes/request-handling.pipe';
import { RequestHeaderPipe } from './pipes/request-header.pipe';
import { BemModule } from './directives/bem.directive';

const COMPONENTS = [
  LoaderComponent,
  LoaderOverlayComponent,
  ComponentLoaderComponent,
  ClickOutsideDirective,
  AddContentComponent,
  CopyrightComponent,
  DownloadButtonComponent,
  TableSummaryComponent,
  TableShowMoreButtonComponent,
  PageHeaderComponent,
  BadgeComponent,
  ApiErrorComponent,
  ErrorModalComponent,
  DateDurationPipe,
  RequestHandlingPipe,
  RequestHeaderPipe,
];

// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
const routes: Routes = [
  {
    path: 'details',
    loadChildren: () => import('../modules/dashboard/dashboard.module').then(m => m.DashboardModule),
  },
];

@NgModule({
  declarations: [
    ...COMPONENTS,
  ],
  exports: [
    ...COMPONENTS,
    BemModule,
    CommonModule,
    ReactiveFormsModule,
    MatTableModule,
    MatButtonModule,
    MatListModule,
    MatStepperModule,
    MatRadioModule,
    MatTooltipModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatTreeModule,
    MatCheckboxModule,
    MatIconModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatInputModule,
    MatSortModule,
    MatTabsModule,
    MatFormFieldModule,
    MatBadgeModule,
    MatChipsModule,
    MatGridListModule,
    MaterialModule,
  ],
  imports: [
    ReactiveFormsModule,
    CommonModule,
    BemModule,
    Angulartics2Module,
    MatButtonModule,
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
  ],
})
export class SharedModule {}
