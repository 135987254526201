import { Component, OnDestroy, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';

import { getSelectedList } from '@app/store/dashboard/dashboard.selectors';
import { RequestContainerService } from './request-container.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-request-container-list',
  templateUrl: './request-container-list.component.html',
  styleUrls: ['./request-container-list.component.css'],
})
export class RequestContainerListComponent implements OnInit, OnDestroy {
  public displayedColumns: string[] = [
    'containerNumber',
    'equipmentCode',
    'vesselName',
    'eta',
    'destinationTerminal',
    ' ',
  ];
  private parent: string = 'container';

  private subscriptions: Subscription = new Subscription();
  public containerCount: number = 0;
  public searchCount: number = 0;
  public searchParam: string = '';
  public searchTerms = new Subject<string>();

  // eslint-disable-next-line @typescript-eslint/ban-types
  constructor(private store: Store<{}>, public requestService: RequestContainerService) {}

  public ngOnInit() {
    this.subscriptions.add(
      this.store
        .pipe(
          select(getSelectedList, { parent: this.parent }),
          tap((selected) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            this.requestService.addContainers(selected.selectedContainerData);
          }),
        )
        .subscribe(),
    );

    this.subscriptions.add(
      this.requestService.tableDataSource$.subscribe((containers) => {
        this.containerCount = containers.length;
      }),
    );

    this.searchTerms.pipe(
      debounceTime(300), // Adjust the delay here (in milliseconds)
      distinctUntilChanged(),
    ).subscribe((term: string) => {
      if (term) {
        this.requestService.fetchContainerData(term);
      } else {
        this.requestService.addSearchContainers([])
      }
    })
  }

  public onSearch = (event: KeyboardEvent, param: string): void => {
    this.searchParam = param.trim()
    this.searchTerms.next(this.searchParam);
  };

  public clearSearch() {
    this.searchParam = '';
    this.searchTerms.next(this.searchParam);
    this.requestService.addSearchContainers([]);
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
