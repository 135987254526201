import { Action } from '@ngrx/store';

export const GET_GATE_HOURS = '[GateHours] GET_GATE_HOURS';
export const GET_GATE_HOURS_SUCCESS = '[GateHours] GET_GATE_HOURS_SUCCESS';
export const GET_GATE_HOURS_ERROR = '[GateHours] GET_GATE_HOURS_ERROR';

export class GateHours implements Action {
  public readonly type = GET_GATE_HOURS;
  constructor(public payload: any = null) { }
}

export class GateHoursSuccess implements Action {
  public readonly type = GET_GATE_HOURS_SUCCESS;
  constructor(public payload: any = null) { }
}

export class GateHoursError implements Action {
  public readonly type = GET_GATE_HOURS_ERROR;
  constructor(public payload: any = null) { }
}

export type All = GateHours | GateHoursSuccess | GateHoursError;
