export class WaitTimeModel {
  constructor (
    public change: number = 0,
    public averageTotal: number = 0,
    public queue: number = 0,
    public terminal: number = 0,
    public chart: any[] = [],
    public hoursDifference: number = 24,
  ) {}
}
