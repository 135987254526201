/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { Inject, Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { Action, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { ApiQueryBuilder } from '@services/api-query-builder';
import { ApiCallService } from '@services/api-call.service';
import { FeatureFlagService } from '@services/feature-flag.service';
import { handleLogin, removeUser } from './user.helpers';

import * as UserActions from './user.actions';
import * as ModalActions from '@store/modal/modal.actions';
import { OKTA_AUTH } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';

@Injectable()
export class UserEffects {
  private readonly features = {
    userPreferences: this.featureFlags.featureEnabled('userPreferences'),
  };

  public AuthenticateUser$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<UserActions.UserAuthenticate>(UserActions.USER_AUTHENTICATE),
    mergeMap(action =>
      this.api.constructApiCall(
        new ApiQueryBuilder()
          .addName('login')
          .addBody({"email" :action.payload})
          .build(),
      )
        .pipe(
          map((response: any) => handleLogin({ ...response, featureFlag: this.features })),
          catchError(error => of(new UserActions.UserAuthenticateError(error))),
        ),
    ),
  ),
  );

  public DeauthenticateUser$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<UserActions.UserDeauthenticate>(UserActions.USER_DEAUTHENTICATE), 
    tap(() => removeUser(this.features)),
  ),
  { dispatch: false },
  );

  public SetSessionExpired$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<UserActions.SetSessionExpired>(UserActions.SET_SESSION_EXPIRED),
    tap(() => {
      this.store.dispatch(new ModalActions.CloseDialog());
      this.store.dispatch(new UserActions.UserDeauthenticate(true));
      this.oktaAuth.tokenManager.clear();
      this.oktaAuth.clearStorage();
      this.oktaAuth.signOut(); 
      localStorage.removeItem('IsLoaded') 
    }),
  ),
  { dispatch: false },
  );


  constructor(
    // eslint-disable-next-line @typescript-eslint/ban-types
    private store: Store<{}>,
    private actions$: Actions,
    private api: ApiCallService,
    private featureFlags: FeatureFlagService,
    @Inject(OKTA_AUTH) private oktaAuth: OktaAuth, 
  ) { }
}
