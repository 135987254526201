import { Injectable } from '@angular/core';
import { resetTimezoneOffset } from '@app/core/utility/helpers';

const DAY_LENGTH = 60 * 60 * 24 * 1000;
const INTERVAL_IN_DAYS = 7;

@Injectable({
  providedIn: 'root',
})
export class GateHoursService {
  public createRequestPayload() {
    const date = new Date();
    const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
    const day = date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`;
    const dateString = `${date.getFullYear()}-${month}-${day}`;
    return {
      fromDate: dateString + 'T00:00:00',
      toDate: new Date(new Date().getTime() + INTERVAL_IN_DAYS * DAY_LENGTH).toISOString().substr(0, 10) + 'T23:59:59',
      sourceFields: [
        'date',
        'terminalName',
        'terminalCode',
        'comments',
        'shiftStatus',
        'shiftNumber',
        'shiftStartTime',
        'terminalCode',
        'portName',
        'shiftEndTime',
        'portCode',
      ],
      noPersonaFilteringRequired: true,
    };
  }

  public parseGateHoursResponse(data: any) {
    const result = {};
    const workObject = {};

    if (data && data.hits && data.hits.hits) {
      data.hits.hits.forEach(hit => {
        const terminalCode = hit._source.terminalCode;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        const date = resetTimezoneOffset(hit._source.date);
        const shiftNumber = (hit._source.shiftNumber).charAt(0);

        workObject[terminalCode] = workObject[terminalCode] || [];
        workObject[terminalCode][date] =
          workObject[terminalCode][date] || { date: new Date(date), shift1: null, shift2: null };
        workObject[terminalCode][date][`shift${shiftNumber}`] = hit._source.shiftStatus;
      });

      Object.keys(workObject).forEach(terminalName => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        result[terminalName] = Object.values(workObject[terminalName]);
      });
    }

    return result;
  }
}
