import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

const DEFAULT_API_ERROR = 'Application temporarily not available, please try again later!';

@Component({
  selector: 'app-api-error',
  templateUrl: './api-error.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApiErrorComponent {
  @Input() public error: string;

  public DEFAULT_ERROR = DEFAULT_API_ERROR;
}
