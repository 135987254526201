import { createFeatureSelector, createSelector } from '@ngrx/store';

import { UserPreferencesState } from './user-preferences.state';
import * as UserPreferencesActions from './user-preferences.actions';


export type Action = UserPreferencesActions.All;

const initialState: UserPreferencesState = {
  settings: null,
  loading: false,
  error: false,
  isSaved: false,
};

const getUserPreferencesState = createFeatureSelector<UserPreferencesState>('userPreferences');

export const isLoading = createSelector(
  getUserPreferencesState,
  state => state.loading,
);

export const getUserPreferences = createSelector(
  getUserPreferencesState,
  state => state.settings,
);

export const hasError = createSelector(
  getUserPreferencesState,
  state => state.error,
);

export const isSaved = createSelector(
  getUserPreferencesState,
  state => state.isSaved,
);

export const isUserPreferencesCallFinished = createSelector(
  getUserPreferencesState,
  (state) => {
    return { success: state.isSaved, error: state.error };
  },
);

// eslint-disable-next-line @typescript-eslint/naming-convention
export function UserPreferencesReducer(state = initialState, action: Action): UserPreferencesState {
  switch (action.type) {
    case UserPreferencesActions.SAVE_USER_PREFERENCES: {
      return {
        ...state,
        loading: true,
        error: false,
        isSaved: false,
      };
    }

    case UserPreferencesActions.SAVE_USER_PREFERENCES_SUCCESS: {
      return {
        ...state,
        settings: { ...action.payload },
        loading: false,
        isSaved: true,
      };
    }

    case UserPreferencesActions.SAVE_USER_PREFERENCES_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
        isSaved: false,
      };
    }

    case UserPreferencesActions.CLEAR: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
}
