import { Component, Input, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { RequestContainerService } from '../request-container.service';
import { RequestContainerRemoveModelComponent } from '../../request-container-remove-model/request-container-remove-model.component';
import { MatDialog } from '@angular/material/dialog';
import { map, Observable } from 'rxjs';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-request-specialhandling-table',
  templateUrl: './request-specialhandling-table.component.html',
  styleUrls: ['./request-specialhandling-table.component.css'],
})
export class RequestSpecialhandlingTableComponent implements OnInit {
  @Input() public dataSource: any[];
  @Input() public forSearch: boolean;
  @Input() public searchTerm: string = '';
  @Input() public cancelRequest: boolean;

  public displayedColumns: string[] = [
    'containerNumber',
    'equipmentCode',
    'vesselName',
    'eta',
    'destinationTerminal',
    ' ',
  ];

  constructor(public requestService: RequestContainerService, public dialog: MatDialog) {}

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  public ngOnInit(): void{
  }

  public removeRow(id: string, container: string) {
    const dialogRef = this.dialog.open(RequestContainerRemoveModelComponent, {
      data: { container },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.requestService.removeRow(id);
      }
    });
  }

  public addContainer(id: string) {
    this.requestService.addSearchContainerToRequestContainers(id);
  }

  public inRequestList(id: string): Observable<boolean> {
    return this.requestService.tableDataSource$.pipe(
      map(containers => containers.some(container => container.containerTripId === id))
    );
  }

}
