import { createReducer, on } from '@ngrx/store';
import {
  initIntermodalClaim,
  intermodalClaim,
  intermodalClaimSuccess,
  intermodalClaimError,
} from './intermodal-claim.actions';
import { IntermodalClaimState } from './intermodal-claim.state';

export const initialState: IntermodalClaimState = {
  loading: false,
  error: '',
  data: {
    successResults: [],
    failureResults: [],
    invalidContainers: [],
    claimConditionsNotSatisfiedInES: null,
    containersNotInES: null,
    message: null,
  },
};

const _intermodalClaimReducer = createReducer(
  initialState,
  on(
    initIntermodalClaim,
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    (_: IntermodalClaimState): IntermodalClaimState => ({
      ...initialState,
    }),
  ),
  on(
    intermodalClaim,
    (state: IntermodalClaimState): IntermodalClaimState => ({
      ...state,
      loading: true,
      data: {
        ...initialState.data,
      },
      error: '',
    }),
  ),
  on(
    intermodalClaimSuccess,
    (state: IntermodalClaimState, { data }): IntermodalClaimState => ({
      ...state,
      loading: false,
      data,
      error: '',
    }),
  ),
  on(
    intermodalClaimError,
    (state: IntermodalClaimState, { error }): IntermodalClaimState => ({
      ...state,
      loading: false,
      data: initialState.data,
      error,
    }),
  ),
);

// eslint-disable-next-line @typescript-eslint/naming-convention
export function IntermodalClaimReducer(state, action) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  return _intermodalClaimReducer(state, action);
}
