import { AbstractControl } from '@angular/forms';

// validator to check null,undefined and empty space
export function forbiddenStringCheck(control: AbstractControl): { [key: string]: boolean } | null {
  // eslint-disable-next-line no-useless-escape
  const alphaNumericRegex = /^[A-Za-z0-9]([\+\-\_\@\#\$]?)/;
  if (((control.value).toLowerCase() === 'undefined') ||
      ((control.value).toLowerCase() === 'null') ||
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ((!isNaN(control.value)) || !(alphaNumericRegex.test(control.value)))) {
        return {invalidInput: true};
  }
  return null;
}
