import { ChangeDetectionStrategy, Component, EventEmitter, Inject, OnDestroy, OnInit, Output} from '@angular/core';
import { Router } from '@angular/router';

import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import {  distinctUntilChanged, map, take } from 'rxjs/operators';
import * as InterestedPartiesActions from '@store/interested-parties/interested-parties.actions';
import { getAllUser, isInterestedPartiesLoading } from '@store/interested-parties/interested-parties.reducer';

import { IContainerStatus } from '@models/dashboard/container-status.model';
import { IParty } from '@models/dashboard/interested-parties.model';
import { ICustomFields } from '@models/shared/user-preferences.model';

import { UserState } from '@app/store/user/user.state';
import { getPartFiltersAndOrders } from '@app/store/dashboard/dashboard.selectors';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RequestContainerService } from '../request-container-list/request-container.service';
import { DashboardActions } from '@app/store/dashboard/dashboard.actions';
import { IOrderProp, IPartFilter, ITypeFilter } from '@app/shared/models/dashboard/filters.model';
import { FilterService } from '@app/core/services/filter.service';
import { ContainerStatusListService } from '@app/core/services/container-status-list.service';
import { ApiCallService } from '@app/core/services/api-call.service';

@Component({
  selector: 'app-request-cancel-modal',
  templateUrl: './request-cancel-modal.component.html',
  styleUrls: ['./request-cancel-modal.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RequestCancelModalComponent implements OnInit, OnDestroy {
  @Output() public closeForm = new EventEmitter<void>();
  @Output() public submitData = new EventEmitter<object>();

  private subscriptions: Subscription = new Subscription();
  private parent: string = '';

  public owners$: Observable<IParty[]>;
  public loading$: Observable<boolean>;
  public customFieldsData$: Observable<ICustomFields>;
  public userDetails$: Observable<object>;
  public selected: string[];
  public changeOccured: boolean = false;
  public displayIndex: number = 1;
  public containersList: IContainerStatus[];
  public currentContainer: IContainerStatus;
  public readonly _injectedData: string;
  public isValidRequest: boolean = false;
  public selectList$: string;
  public requestedCancelList: Observable<any[]>; 
  public notes: string;
  public isAllSelectedValue: boolean = false;
  public orderField: string = 'etaAta';
  public orderDir: string = 'asc';
  public pageNumber: number;
  public pageFilterCache: IPartFilter[];  
  public usedFilters: string[] = ['universal', 'container'];
  public totalCount: number = 0;
  public queryPayload: {
    paging: {
      orders: IOrderProp[];
      filters: ITypeFilter[];
      page: number;
      showMore: boolean;
    };
    containerCount?: number;
    pendingApplied?: boolean;
    customFieldApplied?: boolean;
  };
   
  constructor(
    private router: Router,
    // eslint-disable-next-line @typescript-eslint/ban-types
    private store: Store<{}>,
    private userStore: Store<UserState>,
    private requestContainerService: RequestContainerService,
    private api: ApiCallService,
    private filterService: FilterService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}
 
  public ngOnInit(): void {
    this.selectList$= this.data;
    this.parent = this.router.url.split('/')[1];
    this.requestContainerService.fetchRequestList();

    this.subscriptions.add(
      this.requestContainerService.requestList$.subscribe((requestList) => { 
        requestList?.forEach(filters => {
            if(filters['requestId'] === this.selectList$['props']){
              this.requestedCancelList = filters;
              this.notes = filters['notes']? filters['notes']: '';
            }
        })
      }),
    );
    this.userStore.pipe(select('user'), take(1)).subscribe(
      data => this.userDetails$ = data['user']?.userDetails,
   );
    this.store.dispatch(new InterestedPartiesActions.GetAllUser());
    this.owners$ = this.store.pipe(
      select(getAllUser),
      map((owners) => owners.slice().sort((a, b) => a.fullName.localeCompare(b.fullName))),
    );
    this.loading$ = this.store.pipe(select(isInterestedPartiesLoading));
  
    this.subscriptions.add(
      this.store.pipe(
        select(getPartFiltersAndOrders, { parent: this.parent }),
        distinctUntilChanged((x, y) => {
          const orderChanged = JSON.stringify(x.orders) === JSON.stringify(y.orders);
          const filterPartsChanged = JSON.stringify(x.partFilters) === JSON.stringify(y.partFilters);
          return orderChanged && filterPartsChanged;
        }),
      ).subscribe(({ orders, partFilters }) => {
        if (orders.length) {
          this.orderField = orders[0].property;
          this.orderDir = orders[0].direction.toLowerCase();
        }

        this.pageFilterCache = partFilters;
        const appliedFilters = partFilters?.filter(partFilter =>
          this.usedFilters.includes(partFilter.name),
        );
        const filters: ITypeFilter[] = this.filterService.composeFilters(appliedFilters);

        const isFilteredForPending = ContainerStatusListService.isFilteredFor(filters, 'pending_containers');

        this.queryPayload = {
          paging: { orders, filters, page: 0, showMore: false },
          pendingApplied: isFilteredForPending,
          customFieldApplied: false,   // will be set in dashboard.effect from the request
        };
      }),
    );
  }


  public closeSpecialHandling(): void {
    this.closeForm.emit();
  }

  public fireQueryAction(): void {
    this.queryPayload.paging.showMore = false;
    this.queryPayload.containerCount = this.totalCount;

    this.store.dispatch(DashboardActions.getContainerStatusListResults({
      payload: JSON.parse(JSON.stringify(this.queryPayload)),
      parent: this.parent,
    }));
  }

  public onValueChange(): void{
     this.notes;
  }

  public cancelRequest(): void {
    const payload = {
      requestId: this.requestedCancelList['requestId'],
      containerDetails: this.requestedCancelList['containerDetails'],
      serviceName: this.requestedCancelList['serviceName'],
      terminalCode: this.requestedCancelList['containerDetails'][0].terminalCode,
      notes: this.notes,
    }
    this.requestContainerService.requestCancel(payload);
    this.fireQueryAction();
  }


  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
