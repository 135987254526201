import {
  Directive,
  ElementRef,
  Output,
  HostListener,
  EventEmitter,
} from '@angular/core';

import { Store, select } from '@ngrx/store';
import * as fromUniversalSearch from '@store/universal-search/universal-search.reducer';

@Directive({
  selector: '[appClickOutside]',
})
export class ClickOutsideDirective {
  @Output() public appClickOutside = new EventEmitter();
  private isVisible: boolean;

  // eslint-disable-next-line @typescript-eslint/ban-types
  constructor(private _elementRef: ElementRef, private store: Store<{}>) {
    this.store.pipe(
      select(fromUniversalSearch.getSearchResultDialog),
    ).subscribe(isVisible => this.isVisible = isVisible);
  }

  @HostListener('document:click', ['$event'])
  public onClick(event: MouseEvent): void {
    if (!event.target || !this.isVisible) {
      return;
    }

    const clickedInside = this._elementRef.nativeElement.contains(event.target);
    if (!clickedInside) {
      this.appClickOutside.emit(event);
    }
  }
}
