
<div block="acknowledge-containers">
  <div elem="header">
    <img
      elem="svg-icon"
      src="assets/svgs/warning.svg"
      width="20"
      height="20"
      alt="warning"
    />
    <span>Attention</span>
  </div>

  <div elem="content">
    <span>
      The following containers have not been found within the allotted time.
      These containers will be removed from your container list.
      You are able to copy these containers numbers for your records.
    </span>
  </div>

  <div elem="rejected-containers">
    <textarea id="rejected-containers-list" readonly>{{ rejectedContainers$ | async }}</textarea>
  </div>

  <div elem="contact-support">
    <a (click)="contactSupport()" id="ack-cont-contact-support">
      Contact Support
    </a>
  </div>

  <div elem="buttons">
    <button
      mat-flat-button
      id="acknowledge-containers-button"
      color="accent"
      (click)="acknowledgeRejectedContainers()"
    >
      {{ (loading$ | async) === true ? 'Acknowledging...' : 'Acknowledge' }}
    </button>
  </div>
</div>


