import { Action } from '@ngrx/store';

export const GET_VESSEL_LIST = '[Vessel] GET_VESSEL_LIST';
export const GET_ALL_VESSEL_LIST = '[Vessel] GET_ALL_VESSEL_LIST';
export const SET_VESSEL_LOADING = '[Vessel] SET_VESSEL_LOADING';
export const GET_VESSEL_LIST_SUCCESS = '[Vessel] GET_VESSEL_LIST_SUCCESS';
export const GET_ALL_VESSEL_LIST_SUCCESS = '[Vessel] GET_ALL_VESSEL_LIST_SUCCESS';
export const GET_VESSEL_LIST_ERROR = '[Vessel] GET_VESSEL_LIST_ERROR';
export const SET_VESSEL_FILTER = '[Vessel] SET_VESSEL_FILTER';

export class GetVesselList implements Action {
  public readonly type = GET_VESSEL_LIST;
  constructor(public payload: any = null) { }
}


export class SetVesselLoading implements Action {
  public readonly type = SET_VESSEL_LOADING;
  constructor(public payload: boolean) { }
}

export class GetAllVesselList implements Action {
  public readonly type = GET_ALL_VESSEL_LIST;
}

export class GetVesselListSuccess implements Action {
  public readonly type = GET_VESSEL_LIST_SUCCESS;
  constructor(public payload: any = null) { }
}

export class GetAllVesselListSuccess implements Action {
  public readonly type = GET_ALL_VESSEL_LIST_SUCCESS;
  constructor(public payload: any = null) { }
}

export class GetVesselListError implements Action {
  public readonly type = GET_VESSEL_LIST_ERROR;
  constructor(public payload: any = null) { }
}

export class SetVesselFilter implements Action {
  public readonly type = SET_VESSEL_FILTER;
  constructor(public payload: string[] = null) { }
}


export type All =
  | GetVesselList
  | GetAllVesselList
  | GetVesselListSuccess
  | GetAllVesselListSuccess
  | GetVesselListError
  | SetVesselFilter
  | SetVesselLoading;
