import { Injectable } from '@angular/core';

import * as vesselConstants from './vessel.constants';
import { IOptionItem } from '@app/shared/models/dashboard/tree-option-filter.model';
import { monthNames } from '@models/shared/landing-page.constants';
import { TimezoneService } from '@app/core/services/timezone.service';
import { UserState } from '@app/store/user/user.state';
import { Store, select } from '@ngrx/store';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class VesselService {
  private listToDisplay = 0;
  private filterList = [];

  constructor(private timezoneService: TimezoneService,private userStore:Store<UserState>) {}

  public setSelected = (nodes: IOptionItem[], selection: string[]) =>
    nodes.map((node: IOptionItem) => {
      if (node.children.length) {
        node.children = this.setSelected(node.children, selection);
      } else {
        node.checked = selection.includes(node.value as string);
      }
      return node;
    })

  public getFilterMap = (nodes: IOptionItem[], callback: (node: any) => any[] = (node: any) => [node.value, node.title]): any[] =>
    nodes.reduce((result, node) => {
      result.push(callback(node));
      return node.children.length ? result.concat(this.getFilterMap(node.children, callback)) : result;
    }, [])

  public getVesselRequestParamsToSend(action) {
    const vesselRequestFilterToSend = JSON.parse(JSON.stringify(vesselConstants.requestParamsToSend));
    let statusValues = action.payload.statusValues;
    this.filterList = statusValues;

    if (statusValues !== undefined && statusValues.length) {
      const vesselFilter = JSON.parse(JSON.stringify(vesselConstants.vesselFilter));

      if (statusValues.includes('unloading')) {
        statusValues = statusValues.filter(status => status !== 'unloading');
        if (!statusValues.includes('docked')) {
          statusValues.push('docked');
        }
      }
      vesselFilter.filterValues = statusValues;
      vesselRequestFilterToSend.searchRequests[0].searchParameters[0].filters.push(vesselFilter);
    }
    vesselRequestFilterToSend.searchRequests[0].searchParameters[0].fetchOnlyMyContainers = action.payload.vesselMyContainer || false;
    if (action.payload && action.payload.vesselMyContainer) {
      vesselRequestFilterToSend.searchRequests[0].searchParameters[0].customFieldsFilter = this.withMyContainerPayload();
    }
    return vesselRequestFilterToSend;
  }

  // eslint-disable-next-line max-lines-per-function
  public getParsedVesselResponse(action, data) {
    if (data.length) {
      const pageSize = action.payload.paging.pageSize;
      const page = action.payload.paging.page;
      const filteredResponseData = this.filterDockedWorkingVesselData(
        data[0]['response'].aggregations.filter.byVesselTripId.buckets);
      this.listToDisplay = (page + 1) * pageSize;
      let result = filteredResponseData.map(response => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        const additionalData = JSON.parse(response.fields.vesselAdditionaldata[0]);
        let timeStamp: Date | number = 0;
        let cargoTimeStamp: Date | number = 0;
        let receivingNotes: string | number = 0;
        let cutOffNotes: string | number = 0;
        if (additionalData) {
          timeStamp = additionalData.startReceiving
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            ? this.timezoneService.getDateInRequiredTimeZone(additionalData.startReceiving)
            : 0;
          cargoTimeStamp = additionalData.cargoCutOff
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            ? this.timezoneService.getDateInRequiredTimeZone(additionalData.cargoCutOff)
            : 0
          receivingNotes = additionalData.receivingNotes
          ? additionalData.receivingNotes
          : ''
          cutOffNotes = additionalData.cutOffNotes
            ? additionalData.cutOffNotes
            : ''
        }
        return {
          events: response.byStatus.totalBuckets.map((obj) => obj.key),
          vesselCode: response.data.vesselCode,
          ssco: response.data.vesselOwnerSCAC,
          terminal: response.data.destinationTerminalName,
          containerCount: this.getContainerCount(response.byStatus.buckets, vesselConstants.standardStatus),
          ata: response.data.ataTimeStamp > 0 ? response.data.ataTimeStamp : response.data.ata,
          atd: response.data.atdTimeStamp > 0 ? response.data.atdTimeStamp : response.data.atd,
          eta: response.data.etaTimeStamp > 0 ? response.data.etaTimeStamp : response.data.eta,
          etd: response.data.etdTimeStamp > 0 ? response.data.etdTimeStamp : response.data.etd,
          status: response.data.vesselStatus,
          vesselName: response.data.vesselName,
          voyageNumber: response.data.voyageNumber,
          portOrigin: response.fields.portNames[0].portOriginName,
          portDestination: response.fields.portNames[0].portDestinationName,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          etaTimeStamp: this.timezoneService.getDateInRequiredTimeZone(response.data.etaTimeStamp),
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          etdTimeStamp: this.timezoneService.getDateInRequiredTimeZone(response.data.etdTimeStamp),
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          ataTimeStamp: this.timezoneService.getDateInRequiredTimeZone(response.data.ataTimeStamp),
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          atdTimeStamp: this.timezoneService.getDateInRequiredTimeZone(response.data.atdTimeStamp),
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          statusName: this.getVesselStatus(response.data.vesselStatus, response.byStatus.totalBuckets),
          portDestinationName: response.fields.portNames[0].portDestinationName,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          vesselAveragedata: JSON.parse(response.fields.vesselAveragedata[0]),
          claimedContainerCount: this.getContainerCount(response.byStatus.buckets, vesselConstants.claimedStatus),
          etaDifference: this.getDateDiff(response.data.eta),
          ataDifference: this.getDateDiff(response.data.ata),
          atdDifference: this.getDateDiff(response.data.atd),
          terminalCode: response.data.destinationTerminalCode,
          newUnloadNumerator: this.getLoadUnloadCountValue(response.byStatus.buckets, 'unload', 'N'),
          newUnloadDenominator: this.getLoadUnloadCountValue(response.byStatus.buckets, 'unload', 'D'),
          newLoadNumerator: this.getLoadUnloadCountValue(response.byStatus.buckets, 'load', 'N'),
          newLoadDenominator: this.getLoadUnloadCountValue(response.byStatus.buckets, 'load', 'D'),
          // eslint-disable-next-line no-prototype-builtins
          newTotalUnloadDenominator: (response.byStatus).hasOwnProperty('totalBuckets')
            ? this.getLoadUnloadCountValue(response.byStatus.totalBuckets, 'unload', 'D') : 0,
          // eslint-disable-next-line no-prototype-builtins
          newTotalLoadDenominator: (response.byStatus).hasOwnProperty('totalBuckets')
            ? this.getLoadUnloadCountValue(response.byStatus.totalBuckets, 'load', 'D') : 0,
          tripId: response.data.tripId,
          startReceivingTimeStamp: timeStamp,
          cargoCutOffTimeStamp: cargoTimeStamp,
          startReceivigNotes: receivingNotes,
          cutOffNotes: cutOffNotes,
          dropDownVesselName: response.data.vesselName + 
          `- ${this.timezoneService.getDateInRequiredTimeZone(response.data.etaTimeStamp).getDate()} ` +
          `${monthNames[this.timezoneService.getDateInRequiredTimeZone(response.data.etaTimeStamp).getMonth()].substr(0, 3)}`,
        };
      }).sort((a, b) => a.vesselName > b.vesselName ? 1 : -1);
      if (this.filterList !== undefined && (this.filterList.includes('docked') && !this.filterList.includes('unloading'))) {
        result = result.filter(parsedResult => {
          return parsedResult.statusName !== 'Unloading' && parsedResult.statusName !== 'Working';
        });
      }
      if (this.filterList !== undefined && (!this.filterList.includes('docked') && this.filterList.includes('unloading'))) {
        result = result.filter(parsedResult => {
          return parsedResult.statusName !== 'Docked';
        });
      }

      return {
        content: result.slice((page * pageSize), this.listToDisplay),
        totalPages: Math.ceil(result.length / pageSize),
        totalElements: result.length || 0,
        size: pageSize,
        number: page,
      };
    } else {
      return {
        content: [],
        totalPages: 0,
        totalElements: 0,
        size: 0,
        number: 0,
      };
    }
  }

  private filterDockedWorkingVesselData(responseToFilter) {
    let responseToReturn = responseToFilter;
    if (this.filterList.length === 1) {
      if (this.filterList.includes('unloading')) {
        responseToReturn = responseToFilter.filter(vessel => {
          const workingStatusVessel = vessel.byStatus.totalBuckets.filter(uvContainers =>
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            (vesselConstants.unloadingStatus).includes(uvContainers.key)
            && uvContainers.doc_count > 0);
          if (workingStatusVessel.length > 0) {
            return vessel;
          }
        });
      } else if (this.filterList.includes('docked')) {
        responseToReturn = responseToFilter.filter(vessel => {
          let hasWorkingStatus = false;
          vessel.byStatus.totalBuckets.map(working => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            if ((vesselConstants.unloadingStatus).includes(working.key)) {
              hasWorkingStatus = true;
            }
          });
          if (!hasWorkingStatus) {
            return vessel;
          }
        });
      }
    }
    return responseToReturn;
  }

  private getVesselStatus(vesselStatus, totalBuckets = []) {
    if (vesselStatus === 'docked') {
      let isUnloading = false;
      totalBuckets.forEach(unloading => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        if ((vesselConstants.unloadingStatus).includes(unloading.key)) {
          isUnloading = true;
        }
      });
      if (isUnloading) {
        vesselStatus = 'unloading';
      }
    }
    return this.getStatusName(vesselStatus);
  }

  private getLoadUnloadCountValue(response, loadUnload, numeratorDenominator) {
    if (loadUnload === 'unload') {
      if (numeratorDenominator === 'N') {
        return this.getContainerCount(response, vesselConstants.unloadNumerator);
      } else if (numeratorDenominator === 'D') {
        return this.getContainerCount(response, vesselConstants.unloadDenominator);
      }
    } else if (loadUnload === 'load') {
      if (numeratorDenominator === 'N') {
        return this.getContainerCount(response, vesselConstants.loadNumerator);
      } else if (numeratorDenominator === 'D') {
        return this.getContainerCount(response, vesselConstants.loadDenominator);
      }
    }
  }

  private getContainerCount(response, statusList) {
    return response
      .filter(status => statusList.includes(status.key))
      .reduce((total, count) => total + count.doc_count, 0);
  }

  private getStatusName(status) {
    return ({
      'NA': 'Inbound',
      'outbound': 'Outbound',
      'docked': 'Docked',
      'unloading': 'Working',
    })[status] || '';
  }

  private getDateDiff(incomingDate) {
    const today = new Date();
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const vesselRelatedDate = new Date(incomingDate);

    return Math.floor((Date.UTC(vesselRelatedDate.getFullYear(), vesselRelatedDate.getMonth(), vesselRelatedDate.getDate())
        - Date.UTC(today.getFullYear(), today.getMonth(), today.getDate())) / (1000 * 60 * 60 * 24));
  }

  private withMyContainerPayload = (): any => {
    const filterData = {
      filterOnCustomFields: true,
      filterOnCustomFieldsData: [
        {
          filterKey: 'ownerId',
          filterValues: [this.getUserID()],
          comparison: 'EQUAL',
        },
      ],
    };
  
    return filterData;
  };

  private getUserID(): string {
    let userAttributes;
    this.userStore.pipe(select('user'), take(1)).subscribe(
      data =>  userAttributes = data['user'].userDetails,
   );
    return userAttributes.idUser;
  }
}
