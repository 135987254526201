import { Injectable } from '@angular/core';

import { FeatureFlagService } from '@services/feature-flag.service';
import * as containerConstants from './container.constants';
import { TimezoneService } from '@app/core/services/timezone.service';

export const CONTAINER_LIST_SIZE = 50;
@Injectable({
  providedIn: 'root',
})

export class SearchService {
  public readonly features = {
    newLandingSearch: this.featureFlags.featureEnabled('newLandingSearch'),
  };

  constructor(private featureFlags: FeatureFlagService,
              private timezoneService: TimezoneService) {}

  public getEndpoint() {
    return this.features.newLandingSearch
      ? 'newLandingSearch'
      : 'search';
  }

  public addRequestQuery(request) {
    return this.features.newLandingSearch
      ? `?container=${request}`
      : request;
  }

  public parseSearchResponse(response) {
    if (this.features.newLandingSearch) {
      const containerBOLBucket = response.aggregations['sterms#byContainerNumber'].buckets;
      if (containerBOLBucket.length > 0) {
        return containerBOLBucket[0]['top_hits#top_tag_hits'].hits.hits.map(details => {
          return {
            containerNumber: details._source.containerNumber,
            shipmentStatusCode: details._source.shipmentStatusCd,
            vesselName: details._source.vesselName,
            terminalName: details._source.destinationTerminalCode,
            typeCode: details._source.equipmentCategory,
            containerType: details._source.containerType,
            eta: details._source.etaTimeStamp > 0
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
              ? this.timezoneService.getDateInRequiredTimeZone(details._source.etaTimeStamp)
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
              : this.timezoneService.getDateInRequiredTimeZone(details._source.eta),
            timeline: {
              origin: details.fields.portNames[0].portOriginName,
              vessel: details._source.vesselName,
              shippingLine: details._source.shippingLineName,
              destinationPort: details.fields.portNames[0].portDestinationName,
              ata: details._source.ataTimeStamp > 0
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
              ? this.timezoneService.getDateInRequiredTimeZone(details._source.ataTimeStamp)
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
              : this.timezoneService.getDateInRequiredTimeZone(details._source.ata),
              eta: details._source.etaTimeStamp > 0
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
              ? this.timezoneService.getDateInRequiredTimeZone(details._source.etaTimeStamp)
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
              : this.timezoneService.getDateInRequiredTimeZone(details._source.eta),
              yardLocation: (!!details.fields.yardLocation && details.fields.yardLocation.length)
                ? details.fields.yardLocation[0]
                : '',
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
              dischargedTimestamp: this.timezoneService.getDateInRequiredTimeZone(details._source.dischargeDateTime),
              truck: details._source.destinationMode,
              unloadEvent: (!!details.fields.containerStatus && details.fields.containerStatus.length)
                ? details.fields.containerStatus[0]
                : '',
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
              outgateTime: this.timezoneService.getDateInRequiredTimeZone(details._source.outgateDateTime),
           },
          };
        });
      }
      return [];
    }
    return response;
  }

  public parseErrorResponse(error) {
    if (this.features.newLandingSearch) {
      return {
        error: {
          constraintViolations: [
            {
              message: null,
            },
          ],
        },
      };
    }
    return error;
  }

  private getContainerStatusName(shipmentStatusCd, ata) {
    if (shipmentStatusCd === 'NA' && ata) {
      return containerConstants.containerStatusEnum.DOCKED;
    } else if (shipmentStatusCd === 'NA') {
      return containerConstants.containerStatusEnum.ON_VESSEL;
    } else if (shipmentStatusCd === 'UV') {
      return containerConstants.containerStatusEnum.DISCHARGED;
    } else if (shipmentStatusCd === 'OA' || shipmentStatusCd === 'AL') {
      return containerConstants.containerStatusEnum.OUTGATED;
    }
  }
}
