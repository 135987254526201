/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { saveAs } from 'file-saver';

import * as DetailsActions from './details.actions';
import { ApiQueryBuilder } from '@services/api-query-builder';
import { ApiCallService } from '@services/api-call.service';
import { ContainerStatusListService } from '@services/container-status-list.service';
import { fileNameGenerator } from '@core/utility/helpers';

@Injectable()
export class DetailsEffects {

  public GetContainerDetails$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<DetailsActions.GetContainerDetails>(DetailsActions.GET_CONTAINER_DETAILS),
    mergeMap(action => {
      return this.api.constructApiCall(
        new ApiQueryBuilder()
          .addName('containerStatusList')
          .addBody(this.containerStatusListService.getContainerDetailsRequestParamToSend(action))
          .build(),
      ).pipe(
        map(details => new DetailsActions.GetContainerDetailsSuccess(
          this.containerStatusListService.getParsedContainerDetailsResponse(
            details,
            'containerDetails',
            action.payload.containerNumber,
            action.payload.isPendingContainer))),
        catchError(error => of(new DetailsActions.GetContainerDetailsFailure(error))),
      );
    },
    ),
  ),
  );

  public SaveNotes$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<DetailsActions.SaveNotes>(DetailsActions.SAVE_NOTES),
    mergeMap(action =>
      this.api.constructApiCall(
        new ApiQueryBuilder()
          .addName(this.containerStatusListService.getContainerDetailsNotesUpdateEndpoint(action))
          .addBody(this.containerStatusListService.getContainerDetailsNotesUpdateRequestObject(action))
          .build(),
      )
        .pipe(
          map(data => new DetailsActions.SaveNotesSuccess(data)),
          catchError(error => of(new DetailsActions.SaveNotesFailure(error))),
        ),
    ),
  ),
  );

  public SaveContainerPdf$ = createEffect(() => this.actions$.pipe(
    ofType<DetailsActions.SaveContainerPdf>(DetailsActions.SAVE_CONTAINER_PDF),
    mergeMap(action => {
      return this.api.constructApiCall(
        new ApiQueryBuilder()
          .addName('newPDFDownload')
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions, no-useless-escape
          .addQuery(`\/${action.payload.containerNumber}`)
          .addEntry('responseType', 'blob')
          .addEntry('observe', 'response')
          .addBody(this.containerStatusListService.getContainerDetailsPDFDownloadRequestObject(action))
          .build(),
      ).pipe(
        map((data: any) => {
          const fname = fileNameGenerator(action.payload.containerNumber, 'pdf');
          saveAs(data.body, fname);
          return new DetailsActions.SaveContainerPdfSuccess();
        }),
        catchError(error => of(new DetailsActions.SaveContainerPdfFailure(error))),
      );
    }),
  ),
  );

  constructor(
    private actions$: Actions,
    private api: ApiCallService,
    private containerStatusListService: ContainerStatusListService,
  ) { }
}
