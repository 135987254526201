import { IContainerStatus } from '@models/dashboard/container-status.model';

export type EditedCustomFields = Pick<IContainerStatus, 'containerNumber' | 'customFields'>
  & { noteText: string }
  & { isPendingContainer: boolean }
  & { containerTripId: any }
  & { priority: any };
export const NOTE: string = 'note';
export const OWNER: string = 'owner';
export const BODY_FIELD: string = 'body';
export const CUSTOM_FIELDS: string = 'customFields';
export const FIRST_CUSTOM_FIELD: string = 'customField1';
export const SECOND_CUSTOM_FIELD: string = 'customField2';
export const IS_PENDING_CONTAINER: string = 'isPendingContainer';
