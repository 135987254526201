import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IntermodalClaimState } from './intermodal-claim.state';

const getIntermodalClaimState = createFeatureSelector<IntermodalClaimState>(
  'intermodalClaim',
);

export const getData = createSelector(
  getIntermodalClaimState,
  (state) => state.data,
);

export const getLoading = createSelector(
  getIntermodalClaimState,
  (state) => state.loading,
);

export const getError = createSelector(
  getIntermodalClaimState,
  (state) => state.error,
);
