import { createFeatureSelector, createSelector } from '@ngrx/store';

import { WaitTimeState } from './wait-time.state';
import * as WaitTimeAction from './wait-time.actions';
import { WaitTimeModel } from '@app/modules/dashboard/models/wait-time.model';

export type Action = WaitTimeAction.All;

const initialState: WaitTimeState = {
  loading: false,
  error: false,
  data: [],
  errorData: null,
};

const getWaitTimeState = createFeatureSelector<WaitTimeState>('waitTime');

export const getErrorData = createSelector(
  getWaitTimeState,
  state => (state.error ? state.errorData : null),
);

export const getWaitTimeResponse = createSelector(
  getWaitTimeState,
  state => state.data,
);

export const getWaitTimeResponseForTerminal = (terminalCode: string) => {
  return createSelector(
    getWaitTimeState,
    state => state.data[terminalCode] || new WaitTimeModel(),
  );
};

export const getLoadingState = createSelector(
  getWaitTimeState,
  state => state.loading,
);

// eslint-disable-next-line @typescript-eslint/naming-convention
export function WaitTimeReducer(state = initialState, action: Action): WaitTimeState {
  switch (action.type) {
    case WaitTimeAction.GET_WAIT_TIME: {
      return { ...state, loading: true, error: false };
    }

    case WaitTimeAction.GET_WAIT_TIME_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload,
      };
    }

    case WaitTimeAction.GET_WAIT_TIME_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
        errorData: { ...action.payload },
      };
    }

    default:
      return state;
  }
}
