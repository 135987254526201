<div block="holds-modal" class="spinner-overlay-parent">
  <app-loader-overlay [loading]="loading$ | async"></app-loader-overlay>
  <div elem="content-wrapper">
    <div elem="title">Container Number: {{ _injectedData?.containerNumber }}</div>
    <div elem="block" *ngIf="!(error$ | async) && _injectedData?.customsHoldFlag === 'Y'">
      <div elem="block-title">Custom Hold(s)</div>
      <ul elem="list">
        <li *ngFor="let holdCode of (holdCodesData$ | async)">
          {{ holdCode._source.custCode }} - {{ holdCode._source.holdRelDesc }}
        </li>
        <li *ngIf="(holdCodesData$ | async).length === 0">
          This container has custom holds
        </li>
      </ul>
    </div>
    <div elem="block" *ngIf="!(error$ | async) && _injectedData?.terminalHoldFlag === 'Y'">
      <div elem="block-title">Terminal Hold(s)</div>
      <ul elem="list">
        <li *ngFor="let terminalCode of _injectedData?.terminalHoldCodes">
          {{ terminalCode }}
        </li>
        <li *ngIf="_injectedData?.terminalHoldCodes?.length === 0">
          This container has terminal holds
        </li>
      </ul>
    </div>
    <div elem="block" *ngIf="!(error$ | async) && _injectedData?.otherHoldFlag === 'Y'">
      <div elem="block-title">Other Hold(s)</div>
      <ul elem="list">
        <li *ngFor="let otherCode of _injectedData?.otherHoldCodes">
          {{ otherCode }}
        </li>
        <li *ngIf="_injectedData?.otherHoldCodes?.length === 0">
          This container has other holds
        </li>
      </ul>
    </div>
  </div>
  <div mat-dialog-actions elem="buttons">
    <button elem="button" type="button" color="accent" mat-flat-button (click)="closeModal()">Close</button>
  </div>
</div>
