import { Component, OnInit, EventEmitter, Output, ChangeDetectionStrategy } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  AbstractControl,
} from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { isValidContainerNumber } from '@app/core/utility/helpers';
import { IntermodalClaimState } from '@app/store/intermodal-claim/intermodal-claim.state';
import {
  initIntermodalClaim,
  intermodalClaim,
} from '@app/store/intermodal-claim/intermodal-claim.actions';
import {
  IntermodalClaimParams,
  IntermodalClaimIDList,
  IntermodalClaimData,
} from '@app/shared/models/dashboard/intermodal-claim.model';
import {
  getLoading,
  getData,
} from '@app/store/intermodal-claim/intermodal-claim.selectors';
import { BannerService } from '@app/shared/modules/banner/service/banner.service';
import { BANNER_LEVEL } from '@app/shared/modules/banner/models/banner.models';
import {
  FetchTerminalCodesForFilter as FetchIntermodalTerminalCodesForFilter,
  FetchList as FetchIntermodalList,
  FetchListAll as FetchIntermodalListAll,
} from '@store/intermodal/intermodal.actions';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-intermodal-claim-modal',
  templateUrl: './intermodal-claim-modal.component.html',
  styleUrls: ['./intermodal-claim-modal.component.css'],
})
export class IntermodalClaimModalComponent implements OnInit {
  @Output() public submitData = new EventEmitter<object>();
  @Output() public closeForm = new EventEmitter<void>();

  public data$: Observable<IntermodalClaimData>;
  public loading$: Observable<boolean>;
  public counter$: Observable<number>;
  public responseReceived$: Observable<boolean>;
  public failureReceived$: Observable<boolean>;
  public numOfFailuresInResponse$: Observable<number>;
  public successCount: number;
  public totalCount: number;

  public claimForm: FormGroup;

  public get idList() {
    return this.claimForm.get('idList');
  }

  constructor(
    private store: Store<IntermodalClaimState>,
    private bannerService: BannerService,
  ) {
    this.data$ = this.store.pipe(select(getData));
    this.loading$ = this.store.pipe(select(getLoading));

    this.claimForm = new FormGroup({
      idList: new FormControl('', [
        Validators.required,
        this.containerListStringValidator,
      ]),
    });

    this.counter$ = this.idList.valueChanges.pipe(
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      map((value) => this.convertToIDList(value).length),
    );

    this.failureReceived$ = this.data$.pipe(
      map(({ failureResults }) => !!failureResults.length),
    );

    this.numOfFailuresInResponse$ = this.data$.pipe(
      tap(({ failureResults }) => {
        this.idList.setValue(failureResults.join('\n'), {});
      }),
      map(({ failureResults }) => failureResults.length),
    );

    this.responseReceived$ = this.data$.pipe(
      tap(({ successResults, failureResults }) => {
        this.successCount = successResults.length;
        if (!!successResults.length && !failureResults.length) {
          this.onClose();
        }
      }),
      map(
        ({ successResults, failureResults }) =>
          !!successResults.length || !!failureResults.length,
      ),
    );
  }

  public ngOnInit() {
    this.store.dispatch(initIntermodalClaim());
  }

  public onClose() {
    this.closeForm.emit();
    if (this.successCount) {
      this.bannerService.add({
        text: `You have successfully added ${this.successCount} out of ${this.totalCount} containers!`,
        level: BANNER_LEVEL.SUCCESS,
        autoClose: true,
      });
      this.store.dispatch(new FetchIntermodalTerminalCodesForFilter());
      this.store.dispatch(new FetchIntermodalListAll());
      this.store.dispatch(new FetchIntermodalList({ page: 1 }));
    }
  }

  public onSubmit() {
    const params = this.getParams();
    this.totalCount = params.ids.length;
    this.store.dispatch(intermodalClaim(params));
  }

  private getParams(): IntermodalClaimParams {
    return {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ids: [...new Set(this.convertToIDList(this.idList.value))],
    };
  }

  private convertToIDList = (value: string): IntermodalClaimIDList => {
    if (!value) {
      return [];
    }

    return value
      .split(/[,\n]/)
      .map((item) => item.trim())
      .filter((item) => item);
  }

  private containerListStringValidator = (
    control: AbstractControl,
  ): { [key: string]: any } | null => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const invalidIDs = this.convertToIDList(control.value).filter(
      (id) => !isValidContainerNumber(id),
    );
    if (invalidIDs.length > 0) {
      return {
        invalidIDs,
      };
    }

    return null;
  }
}
