import { createFeatureSelector, createSelector } from '@ngrx/store';

import { TerminalMetricsState } from './terminal-metrics.state';
import * as TerminalMetricsAction from './terminal-metrics.actions';

export type Action = TerminalMetricsAction.All;

const initialState: TerminalMetricsState = {
  loading: false,
  error: false,
  data: {},
  errorData: null,
  terminalList: [],
  filterSet: [],
  filters: [],
};

const getTerminalMetricsState = createFeatureSelector<TerminalMetricsState>('terminalMetrics');

export const getErrorData = createSelector(
  getTerminalMetricsState,
  state => (state.error ? state.errorData : null),
);

export const getTerminalMetricsResponse = createSelector(
  getTerminalMetricsState,
  state => state.data,
);

export const getTerminalMetricsResponseForTerminal = (terminalCode: string) =>
  createSelector(
    getTerminalMetricsState,
    state => state.data[terminalCode] || {},
  );

export const getLoadingState = createSelector(
  getTerminalMetricsState,
  state => state.loading,
);

export const getTerminalList = createSelector(
  getTerminalMetricsState,
  state => state.terminalList,
);

export const getFilterSet = createSelector(
  getTerminalMetricsState,
  state => state.filterSet,
);

export const getFilters = createSelector(
  getTerminalMetricsState,
  state => state.filters,
);

// eslint-disable-next-line @typescript-eslint/naming-convention
export function TerminalMetricsReducer(state = initialState, action: Action): TerminalMetricsState {
  switch (action.type) {
    case TerminalMetricsAction.FETCH_METRICS: {
      return { ...state, loading: true, error: false };
    }

    case TerminalMetricsAction.FETCH_METRICS_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        data: { ...action.payload.data },
        terminalList: [...action.payload.terminalList],
        filterSet: [...action.payload.filterSet],
      };
    }

    case TerminalMetricsAction.FETCH_METRICS_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
        errorData: { ...action.payload },
      };
    }

    case TerminalMetricsAction.UPDATE_FILTERS: {
      return {
        ...state,
        filters: [...action.payload],
      };
    }

    default:
      return state;
  }
}
