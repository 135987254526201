import { Component, Inject, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { FullStoryAnalytics } from '@app/shared/services/fullstory-analytics';

import { Store, select } from '@ngrx/store';
import { delay, withLatestFrom } from 'rxjs/operators';
import { getAuthStatus } from '@store/user/user.reducer';
import { getRouteHistory } from '@store/routing/routing.reducer';
import * as UserActions from '@store/user/user.actions';
import * as RoutingActions from '@store/routing/routing.actions';

import { environment } from '@env/environment';
import { ApiCallService } from './core/services/api-call.service';
import { OKTA_AUTH } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';
import { MatDialog } from '@angular/material/dialog';
import { ErrorModalComponent } from './shared/components/error-modal/error-modal.component';
import { Angulartics2GoogleAnalytics } from 'angulartics2';

declare let ga: any;

const ALLOWED_DIRECT_URLS = [
  '/login/callback',
  '/container/overview',
  '/register',
  '/forgot-password',
];

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {

  constructor(
    private router: Router,
    private store: Store<{}>,
    private analyitcs: Angulartics2GoogleAnalytics,
    private fullstory: FullStoryAnalytics,
    private location: Location,
    private api: ApiCallService,
    public dialog: MatDialog,
    @Inject(OKTA_AUTH) private oktaAuth: OktaAuth,
  ) {}

  public ngOnInit(): void {

    const errorData = JSON.parse(localStorage.getItem('errorData'));
    if(errorData){
    this.router.navigate(['/']);
     const dialogReference =  this.dialog.open(ErrorModalComponent, {
       width: '474px',
       height: 'auto',
       data: { errorData },
     })
     dialogReference.afterClosed().subscribe(() => {
       dialogReference.close();
       localStorage.removeItem('errorData')
     });
   }
    this.setupAnalytics();
    this.store.dispatch(new RoutingActions.StartLogging());

    const routeHistory$ = this.store.pipe(select(getRouteHistory));
    this.store.pipe(
      select(getAuthStatus, UserActions.UserAuthenticate),
      withLatestFrom(routeHistory$),
      delay(0),
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ).subscribe(([isAuthenticated, routeHistory]) => {
       {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        const token = JSON.parse(sessionStorage['okta-token-storage']).accessToken?.accessToken;
        if (token) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          localStorage.setItem('token', token);
          this.store.dispatch(new UserActions.SetToken({ token }));
        } else if (!token) {
          if (!ALLOWED_DIRECT_URLS.includes(this.location.path()) && this.router.url =="/") {
            this.router.navigate(['/']);
          }
          }
        }
    });
  }

  private setupAnalytics(): void {
    ga('create', environment.gaTrackingId, 'auto');
    ga('send', 'pageview');
    ga('send', {
      hitType: 'event',
      eventCategory: 'version',
      eventAction: 'load',
      eventLabel: environment.version,
    });
    this.analyitcs.startTracking();
    this.fullstory.initialize();
  }
}
