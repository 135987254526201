import { Action } from '@ngrx/store';

export const GET_CHASSIS_COUNT = '[ChassisCount] GET_CHASSIS_COUNT';
export const GET_CHASSIS_COUNT_SUCCESS = '[ChassisCount] GET_CHASSIS_COUNT_SUCCESS';
export const GET_CHASSIS_COUNT_ERROR = '[ChassisCount] GET_CHASSIS_COUNT_ERROR';

export class ChassisCount implements Action {
  public readonly type = GET_CHASSIS_COUNT;
  constructor(public payload: any = null) { }
}

export class ChassisCountSuccess implements Action {
  public readonly type = GET_CHASSIS_COUNT_SUCCESS;
  constructor(public payload: any = null) { }
}

export class ChassisCountError implements Action {
  public readonly type = GET_CHASSIS_COUNT_ERROR;
  constructor(public payload: any = null) { }
}

export type All = ChassisCount | ChassisCountSuccess | ChassisCountError;
