/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { mergeMap, map, catchError} from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { ApiQueryBuilder } from '@services/api-query-builder';
import { ApiCallService } from '@services/api-call.service';

import * as ContainerFilterAction from '@store/container-filter/container-filter.actions';
import { AVAILABLE } from '@models/dashboard/filters.model';

@Injectable()
export class ContainerFilterEffects {
  constructor(
    private actions$: Actions,
    private api: ApiCallService,
  ) {}

  public ContainerAvailabilityCountSuccess$ = createEffect(() => this.actions$
  .pipe(
    ofType<ContainerFilterAction.ContainerAvailabilityCount>(ContainerFilterAction.CONTAINER_AVAILABILITY_COUNT),
    mergeMap(action => {
      return this.api.constructApiCall(
        new ApiQueryBuilder()
        .addName('containerAvailabilityCount')
        .addBody(action.payload)
        .build(),
      )
      .pipe(
        map((data: any) => {
          const bucketsAvailablityArray = data.response.aggregations.filter.byAvailableForPickup.buckets;
          const availabilityCount = bucketsAvailablityArray.filter((res: any) => {
            return res.key === AVAILABLE;
          });
          return new ContainerFilterAction.ContainerAvailabilityCountSuccess(
            availabilityCount.length ?
              availabilityCount[0].doc_count :
              0,
          );
        }),
        catchError(error => {
          return of(new ContainerFilterAction.ContainerAvailabilityCountError(error));
        }),
      );
    }),
  ),
  );

  public ContainerHoldsCountSuccess$ = createEffect(() => this.actions$
  .pipe(
    ofType<ContainerFilterAction.ContainerHoldsCount>(ContainerFilterAction.CONTAINER_HOLDS_COUNT),
    mergeMap(action => {
      return this.api.constructApiCall(
        new ApiQueryBuilder()
        .addName('containerHoldsCount')
        .addBody(action.payload)
        .build(),
      )
      .pipe(
        map((data: any) => {
         const customHoldCount = data[0].response.aggregations.filter.byCustomsHolds.buckets;
         const terminalHoldCount = data[1].response.aggregations.filter.byTerminalHolds.buckets;
         const otherHoldCount = data[2].response.aggregations.filter.byOtherHolds.buckets;
          const holdsResponse = {
            customs_count: customHoldCount.length ? customHoldCount[0].doc_count : 0,
            terminal_count: terminalHoldCount.length ? terminalHoldCount[0].doc_count : 0,
            others_count: otherHoldCount.length ? otherHoldCount[0].doc_count : 0,
            total_count: 0,
          };

          holdsResponse.total_count = Object.values(holdsResponse).reduce((sumCount, totalCount) =>
            sumCount + totalCount, 0);
          return new ContainerFilterAction.ContainerHoldsCountSuccess(holdsResponse);
        }),
        catchError(error => {
          return of(new ContainerFilterAction.ContainerHoldsCountError(error));
        }),
      );
    }),
  ),
  );
}
