import * as GlobalErrorActions from './global-error.actions';
import { GlobalErrorState } from './global-error.state';

export type Action = GlobalErrorActions.All;

const initialState: GlobalErrorState = {
  error: false,
  errorData: null,
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export function GlobalErrorReducer(state = initialState, action: Action): GlobalErrorState {
  switch (action.type) {
    case GlobalErrorActions.SET_GLOBAL_ERROR: {
      return { ...state, error: true, errorData: action.payload };
    }
    case GlobalErrorActions.CLEAR_GLOBAL_ERROR: {
      return { ...state, error: false, errorData: null };
    }
    default:
      return state;
  }
}
