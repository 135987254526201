<div block="addContainer">
  <div elem="header">
    <h1 mat-dialog-title>Add Containers</h1>
    <div elem="closeIcon" (click)="onCancelClick()">
      <img src="/assets/svgs/close.svg" alt="Close information bar"/>
    </div>
  </div>
  <div elem="error" *ngIf="addContainerNumbers.get('type').value === 'container' && containersError && invalidContainerNumbers > 0">
    <img src="/assets/svgs/failure-white.svg" color="" alt="Possible error" />
    <div elem="errorText">There was an error adding your container(s).</div>
  </div>
  <div elem="error" *ngIf="addContainerNumbers.get('type').value === 'bol' && failedContainerNumbers > 0">
    <img src="/assets/svgs/failure-white.svg" color="" alt="Possible error" />
    <div elem="errorText">There was an issue when adding your BOLs, please try again later.</div>
  </div>
  <p>
    Track specific containers by entering their container number or bill of lading number. For larger lists, it's recommended that you
    upload a CSV file.
  </p>
  <form
    novalidate
    [formGroup]="addContainerNumbers"
    (ngSubmit)="onSendData(addContainerNumbers)"
  >
    <div elem="checkboxes">
      <mat-radio-group formControlName="type">
        <mat-radio-button elem="checkbox" value="container">
          Container No
        </mat-radio-button>
        <mat-radio-button value="bol" *ngIf="features.isBOLClaimEnabled">
          Bill of Lading
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <div elem="textboxes">
      <div elem="flexColumn">
        <div elem="counter" [mod]="{ invalid: invalidContainerNumbers > 0 }">
          <span [class.add-Containers]="invalidContainerNumbers > 0 && addContainerNumbers.get('type').value === 'container'">
            {{ addContainerNumbers.get('type').value === 'container' ? 'Container ' : 'BOL ' }} Count: {{ containerCounter }}
          </span>
        </div>
        <textarea
          cdkFocusInitial
          elem="addContainers"
          formControlName="containerNumbers"
          [mod]="{ error: containersError, invalid: invalidContainerNumbers > 0 }"
          placeholder="{{ addContainerNumbers.get('type').value === 'container' ? 'Add Containers here' : 'Add BOLs here' }}"
          tabindex="-1"
          [ngClass]="{ 'textArea-disabled': ((containersLoading$ | async) && enableSubmit === true && !hasFailedBOLNumbers) || enableAddContainers, 'add-Containers': invalidContainerNumbers > 0 }"
        ></textarea>
        <div elem="errorMessagePadding" *ngIf="invalidContainerNumbers > 0 && addContainerNumbers.get('type').value === 'container'">
          <span elem="errorContainerMessage">
            Format Error: {{ invalidContainerNumbers + '/' + totalContainerNumbers }} will not be added as they have format error,
            kindly edit or delete to continue.
          </span>
        </div>
      </div>
      <div elem="flexColumn">
        <div elem="counter" [mod]="{ error: containersError, invalid: invalidContainerNumbers > 0 }" *ngIf="addContainerNumbers.get('type').value === 'container'">
          <span elem="containerCounterPadding" [class.container-error]="containersError && pendingContainerNumbers > 0">Pending Containers: {{ pendingContainerNumbers || '0' }}</span>
        </div>
        <div elem="counter" [mod]="{ invalid: failedContainerNumbers > 0 }" *ngIf="addContainerNumbers.get('type').value === 'bol'">
          <span elem="containerCounterPadding" [class.bol-error]="failedContainerNumbers > 0">Error Count: {{ failedContainerNumbers || '0' }}</span>
        </div>
        <textarea
          cdkFocusInitial
          elem="failedContainers"
          [class.failedBolNumbers]="addContainerNumbers.get('type').value === 'bol'"
          formControlName="pendingContainerNumbers"
          [mod]="{ error: containersError }"
          placeholder="{{ addContainerNumbers.get('type').value === 'container' ? 'Not Found Container will appear here' : 'Error will appear here' }}"
          tabindex="-1"
          readonly
        ></textarea>
        <div elem="pendingMessage" *ngIf="pendingContainerNumbers > 0 && addContainerNumbers.get('type').value === 'container'">
          <span elem="pendingContainerMessage">
            Not Found: {{ pendingContainerNumbers + '/' + totalContainerNumbers }} would be added as Pending Status
          </span>
        </div>
      </div>
    </div>
    <div elem="fileButtons" [mod]="{ invalid: invalidContainerNumbers > 0 || pendingContainerNumbers > 0 }">
      <div elem="fileUploadContainer">
        <button
          mat-stroked-button
          color="primary"
          type="button"
          (click)="fileUpload.click()"
          elem="selectButton"
          [disabled]="disableChooseFile"
          >Choose file
        </button>
        <input
          type="file"
          accept=".csv"
          #fileUpload
          (click)="handleFileClear($event)"
          (change)="handleFileChange($event.target.files)"
          elem="hiddenInput"
        />
        <span type="text" elem="fileNameLabel">{{fileToUpload ? fileToUpload.name : 'No file selected'}}</span>
      </div>
      <div elem="fileInputField">
        <a
          href="/assets/csv/Bulk_Claim_Template.csv"
          download
          elem="templateDownloadLink"
          >Download CSV Template
        </a>
      </div>
    </div>
    <div mat-dialog-actions elem="buttons">
      <button
        elem="button"
        mat-flat-button
        color="primary"
        (click)="onCancelClick()"
        type="button"
      >Cancel
      </button>
      <ng-container *ngFor="let containersLoading of [containersLoading$ | async]">
        <button *ngIf="enableSubmit === true"
          elem="button"
          mat-flat-button
          color="accent"
          type="submit"
          [class.disabled]="
            containersLoading || submitStatus()
          "
          [disabled]="
            containersLoading || submitStatus()
          "
        >
          {{ containersLoading === true ? "Submitting" : "Submit" }}
        </button>
        <button *ngIf="( pendingContainerNumbers > 0 || ( !pendingContainerNumbers && !!successContainers.length ) ) && enableSubmit === false"
          elem="button"
          mat-flat-button
          color="accent"
          [class.disabled]="containerCounter > 0 || containersLoading"
          [disabled]="containerCounter > 0 || containersLoading"
          type="submit">
          {{ containersLoading === true ? "Continuing" : "Continue" }}
        </button>
      </ng-container>
    </div>
  </form>
</div>
