<div block="unclaim">
  <div elem="header">
    <h2 mat-dialog-title elem="header-text">Remove Containers</h2>
  </div>
  <div elem="content" mat-dialog-content>
    <p elem="subtitle">
      You are about to remove the container from your list. 
      Are you sure you would like to stop tracking these {{ selection?.length }} containers?
    </p>
  </div>
  <div mat-dialog-actions elem="buttons">
    <button elem="button" type="button" color="primary" mat-flat-button (click)="closeModal()">Cancel</button>
    <button elem="button" type="button" color="accent" mat-flat-button (click)="emitData()">Confirm</button>
  </div>
</div>
