import { createFeatureSelector, createSelector } from '@ngrx/store';

import { LandingPageSettingsState } from './landing-page-settings.state';
import { ModalState } from '../modal/modal.state';
import * as LandingPageSettingsAction from './landing-page-settings.actions';

export type Action = LandingPageSettingsAction.All;

const initialState: LandingPageSettingsState = {
  loading: false,
  error: false,
  landingPageSettings: {},
  success: false,
  errorData: null,
  selectedPage: null,
};

const getLandingPageActionState = createFeatureSelector<ModalState>('modal');

export const getErrorData = createSelector(
  getLandingPageActionState,
  state => state.landingPage.error ? state.landingPage.errorData : null,
);

export const getLandingPageSuccess = createSelector(
  getLandingPageActionState,
  state => state.landingPage.success,
);

export const isLandingCallFinished = createSelector(
  getLandingPageActionState,
  (state) => {
    return { success: state.landingPage.success, error: state.landingPage.error };
  },
);

export const getSelectedLandingPage = createSelector(
  getLandingPageActionState,
  state => state.landingPage.selectedPage,
);

export const getLoadingState = createSelector(
  getLandingPageActionState,
  state => state.landingPage.loading,
);

export const getLandingPageReset = createSelector(
  getLandingPageActionState,
  state => state.landingPage.success,
);

// eslint-disable-next-line @typescript-eslint/naming-convention
export function LandingPageSettingsReducer(state = initialState, action: Action): LandingPageSettingsState {
  switch (action.type) {
    case LandingPageSettingsAction.GET_LANDING_PAGE_SETTINGS_DATA: {
      return {
        ...state,
        loading: true,
        success: false,
        error: false,
      };
    }

    case LandingPageSettingsAction.GET_LANDING_PAGE_SETTINGS_DATA_SUCCESS: {
      const selectedPage = action.payload && action.payload.landingPage
        ? { selectedPage: action.payload.landingPage }
        : {};
      const landingPageSettings = action.payload
        ? { landingPageSettings: action.payload }
        : {};
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        ...selectedPage,
        ...landingPageSettings,
      };
    }

    case LandingPageSettingsAction.GET_LANDING_PAGE_SETTINGS_DATA_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
        success: false,
        selectedPage: null,
        errorData: { ...action.payload },
      };
    }

    case LandingPageSettingsAction.GET_LANDING_PAGE_SETTINGS_DATA_RESET: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
}
