export interface NotificationSettingsState {
  settings: any;
  subscribedTypes: any;
  loading: boolean;
  error: boolean;
  alerts: Alert[];
  alertsLoading: boolean;
  alertsError: boolean;
  sendSuccess: boolean;
  sendLoading: boolean;
  typesError: boolean;
  typesLoading: boolean;
}

export enum AlertTypeEnum {
  DWELL_NOTIFICATION = 'DWELL_NOTIFICATION',
  LAST_FREE_DAY_NOTIFICATION = 'LAST_FREE_DAY_NOTIFICATION',
  VESSEL_ARRIVAL = 'VESSEL_ARRIVAL',
  CONTAINER_OUTGATE = 'CONTAINER_OUTGATE',
  CONTAINER_AUTO_UNCLAIM = 'CONTAINER_AUTO_UNCLAIM',
  CONTAINER_DISCHARGE = 'CONTAINER_DISCHARGE',
  CONTAINER_AVAILABLE = 'CONTAINER_AVAILABLE',
  EMPTY_RETURNS = 'EMPTY_RETURNS',
}

export interface Alert {
  active: boolean;
  alertTypeEnum: AlertTypeEnum;
  configuration: string;
  description: string;
  emailOnly: boolean;
  expiryDate: string;
  idAlert: string;
  idAlertType: string;
  idOrganization: string;
  idUserRole: string;
  idUserUser: string;
  includeSms: boolean;
  nextSend: string;
  subscriptionType: string;
}

export enum AlertTypeNotificationDaysCountEnum {
  DWELL_NOTIFICATION = 'dwellDaysCount',
  LAST_FREE_DAY_NOTIFICATION = 'daysBeforeLastFreeDayCount'
}
