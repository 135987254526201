import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OverlayModule } from '@angular/cdk/overlay';
import { MatIconModule } from '@angular/material/icon';

import { defaultToastConfig, TOAST_CONFIG_TOKEN } from './toast-config';
import { ToastComponent } from './toast.component';

@NgModule({
  imports: [
    CommonModule,
    OverlayModule,
    MatIconModule,
  ],
  declarations: [ToastComponent],
})
export class ToastModule {
  public static forRoot(config = defaultToastConfig): ModuleWithProviders<ToastModule> {
    return {
      ngModule: ToastModule,
      providers: [
        {
          provide: TOAST_CONFIG_TOKEN,
          useValue: { ...defaultToastConfig, ...config },
        },
      ],
    };
  }
}
