import { Injectable } from '@angular/core';

const DAY_LENGTH = 60 * 60 * 24 * 1000;
const INTERVAL_IN_DAYS = 7;

@Injectable({
  providedIn: 'root',
})
export class ChassisCountService {
  public createRequestPayload() {
    return {
      fromDate: new Date(new Date().getTime() - INTERVAL_IN_DAYS * DAY_LENGTH).toISOString().substr(0, 10) + 'T00:00:00',
      toDate: new Date(new Date().getTime() + INTERVAL_IN_DAYS * DAY_LENGTH).toISOString().substr(0, 10) + 'T23:59:59',
      sourceFields: [
        'terminalName',
        'chassisAvailability',
        'containers',
        'containerTypes',
      ],
      noPersonaFilteringRequired: true,
    };
  }

  public parseChassisCountResponse(data: any) {
    const result = {};

    if (data && data.hits && data.hits.hits) {
      data.hits.hits.forEach(hit => {
        result[hit._id.replace(/.*?-/, '')] = [{
          20: hit._source.chassisAvailability.current
            ? hit._source.chassisAvailability.current.filter(record => record.size === '20').reduce((acc, curr) => curr.value, 0)
            : 0,
          40: hit._source.chassisAvailability.current
            ? hit._source.chassisAvailability.current.filter(record => record.size === '40/45').reduce((acc, curr) => curr.value, 0)
            : 0,
        }];
      });
    }

    return result;
  }
}
