<div class="containers-list-card">
  <span class="request-type-header">Containers</span>
  <span class="count-containers">{{ containerCount }} containers</span>
  <span class="container-note">Only containers that are at the same terminal, at least 48 hours out from arrival, and not
    part of an existing special request are searchable.</span>
  <div block="container-search">
    <div elem="wrapper">
      <button elem="icon">&nbsp;</button>
      <input #sv name="qu" type="text" elem="input" maxlength="29" autocomplete="off" id="universal-search"
        [(value)]="searchParam" (keyup)="onSearch($event, sv.value)" placeholder="Search for containers" />
        <div elem="cancel-icon" *ngIf="searchParam">
          <img
            src="assets/svgs/close-grey.svg"
            width="20"
            height="20"
            alt="cancel"
            (click)="clearSearch()"
          />
        </div>
    </div>
    <div class="search-list-card">
      <app-request-specialhandling-table [dataSource]="(requestService.searchDataSource$ | async)"
        [forSearch]="true" [searchTerm]="searchTerms | async"></app-request-specialhandling-table>
    </div>
  </div>
  <div class="containers-table-box">
    <div class="has-containers" *ngIf="containerCount >= 1">
      <app-request-specialhandling-table
        [dataSource]="(requestService.tableDataSource$ | async)"></app-request-specialhandling-table>
    </div>
    <div class="no-containers" *ngIf="containerCount < 1">
      <img src="../../../../../../assets/svgs/no-containers.svg" alt="" />
      <span class="no-container-heading">The list is empty</span>
      <span class="no-container-description">Search for the containers<br>to proceed with a request</span>
    </div>
  </div>
</div>