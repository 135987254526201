import { AddContainersState } from './add-containers.state';
import * as AddContainersActions from './add-containers.actions';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export type Action = AddContainersActions.All;

const initialState: AddContainersState = {
  success: false,
  loading: false,
  error: false,
  message: null,
  failedContainers: [],
  successContainers: [],
  invalidContainers: [],
  claimConditionsNotSatisfiedInES: [],
  containersNotInES: [],
  successPendingContainers: [],
  hasSuccessPendingContainers: false,
  hasFailedContainers: false,
  containersClear: false,
  claimingType: 'container',
  cancelModal: false,
};

const getAddContainersState = createFeatureSelector<AddContainersState>('addContainers');

export const addContainersResult = createSelector(
  getAddContainersState,
  state =>
    ({
      success: state.success,
      hasFailedContainers: !!state.failedContainers.length,
      failedContainers: state.failedContainers,
      successContainers: state.successContainers,
      invalidContainers: state.invalidContainers,
      claimConditionsNotSatisfiedInES: state.claimConditionsNotSatisfiedInES,
      containersNotInES: state.containersNotInES,
      successPendingContainers: state.successPendingContainers,
      containersClear: state.containersClear,
      claimingType: state.claimingType,
      cancelModal: state.cancelModal,
    }),
);

export const addPendingContainersResult = createSelector(
  getAddContainersState,
  state =>
    ({
      hasSuccessPendingContainers: state.hasSuccessPendingContainers,
      successPendingContainers: state.successPendingContainers,
    }),
);

export const addBOLNumbersResult = createSelector(
  getAddContainersState,
  state =>
    ({
      success: state.success,
      failedContainers: state.failedContainers,
      successContainers: state.successContainers,
      claimingType: state.claimingType,
    }),
);

export const successOperationFlag = createSelector(
  getAddContainersState,
  state => (state.success || state.hasSuccessPendingContainers || state.containersClear),
);

export const errorOperationFlag = createSelector(
  getAddContainersState,
  state => state.error,
);

export const isAddContainerLoading = createSelector(
  getAddContainersState,
  state => state.loading,
);

export const getErrorMessage = createSelector(
  getAddContainersState,
  state => {
    return {
      error: state.error,
      message: state.message,
    };
  },
);


// eslint-disable-next-line @typescript-eslint/naming-convention
export function AddContainersReducer(state = initialState, action: Action): AddContainersState {
  switch (action.type) {
    case AddContainersActions.CLEAR:
    case AddContainersActions.ADD_CONTAINERS_INIT: {
      return {
        ...initialState,
      };
    }

    case AddContainersActions.UPLOAD_FILE:
    case AddContainersActions.ADD_CONTAINERS: {
      return {
        ...state,
        loading: true,
        success: false,
        message: null,
      };
    }

    case AddContainersActions.ADD_CONTAINERS_RESULT: {
      return {
        ...state,
        loading: false,
        success: true,
        hasFailedContainers: !!action.payload.failed.length,
        failedContainers: action.payload.failed,
        successContainers: action.payload.success,
        invalidContainers: action.payload.invalidContainers,
        claimConditionsNotSatisfiedInES: action.payload.claimConditionsNotSatisfiedInES,
        containersNotInES: action.payload.containersNotInES,
        containersClear: false,
        claimingType: action.payload.claimingType,
        cancelModal: action.payload.cancelModal,
      };
    }

    case AddContainersActions.ADD_CONTAINERS_ERROR: {
      return {
        ...state,
        loading: false,
        success: false,
        error: !!(action.payload && action.payload.message),
        message: action.payload.message,
      };
    }

    case AddContainersActions.ADD_PENDING_CONTAINERS: {
      return {
        ...state,
        loading: true,
        hasSuccessPendingContainers: false,
        invalidContainers: [],
        message: null,
      };
    }

    case AddContainersActions.ADD_PENDING_CONTAINERS_RESULT: {
      return {
        ...state,
        loading: false,
        hasSuccessPendingContainers: true,
        success: true,
        error: false,
        invalidContainers: [],
        successPendingContainers: action.payload.successPendingContainers,
        containersClear: false,
      };
    }

    case AddContainersActions.ADD_PENDING_CONTAINERS_ERROR: {
      return {
        ...state,
        loading: false,
        hasSuccessPendingContainers: false,
        error: true,
      };
    }

    case AddContainersActions.ADD_CONTAINERS_CLEAR: {
      return {
        ...initialState,
        containersClear: true,
      };
    }

    default:
      return state;
  }
}
