import { IUniversalSearchPayload } from '@shared/models/dashboard/universal-search.model';
import { Action } from '@ngrx/store';

export const GET_UNIVERSAL_SEARCH_RESULTS = '[Universal Search] GET_SEARCH_RESULTS';
export const GET_UNIVERSAL_SEARCH_RESULTS_SUBMIT = '[Universal Search] GET_SEARCH_RESULTS_SUBMIT';
export const GET_UNIVERSAL_SEARCH_RESULTS_SUCCESS = '[Universal Search] GET_SEARCH_RESULTS_SUCCESS';
export const GET_UNIVERSAL_SEARCH_RESULTS_ERROR = '[Universal Search] GET_SEARCH_RESULTS_ERROR';
export const SHOW_UNIVERSAL_SEARCH_RESULTS = '[Universal Search] SHOW_SEARCH_RESULTS';
export const HIDE_UNIVERSAL_SEARCH_RESULTS = '[Universal Search] HIDE_SEARCH_RESULTS';
export const DELETE_UNIVERSAL_SEARCH_PARAM = '[Universal Search] DELETE_UNIVERSAL_SEARCH_PARAM';
export const REVERT_SEARCH_PARAM = '[Universal Search] REVERT_SEARCH_PARAM';
export const SET_LOADING = '[Universal Search] SET_LOADING';
export const SET_SEARCH_TEXT = '[Universal Search] SET_SEARCH_TEXT';

export class GetSearchResults implements Action {
  public readonly type = GET_UNIVERSAL_SEARCH_RESULTS;
  constructor(public payload: IUniversalSearchPayload = null, public parent: string = '') { }
}

export class GetSearchResultsSuccess implements Action {
  public readonly type = GET_UNIVERSAL_SEARCH_RESULTS_SUCCESS;
  constructor(public payload: any) { }
}

export class GetSearchResultsError implements Action {
  public readonly type = GET_UNIVERSAL_SEARCH_RESULTS_ERROR;
}

export class GetSearchResultSubmit implements Action {
  public readonly type = GET_UNIVERSAL_SEARCH_RESULTS_SUBMIT;
}

export class DeleteSearchParam implements Action {
  public readonly type = DELETE_UNIVERSAL_SEARCH_PARAM;
}

export class ShowSearchResult implements Action {
  public readonly type = SHOW_UNIVERSAL_SEARCH_RESULTS;
}

export class HideSearchResult implements Action {
  public readonly type = HIDE_UNIVERSAL_SEARCH_RESULTS;
}

export class RevertSearchParam implements Action {
  public readonly type = REVERT_SEARCH_PARAM;
}

export class SetLoading implements Action {
  public readonly type = SET_LOADING;
  constructor(public payload: boolean) {}
}

export class SetSearchText implements Action {
  public readonly type = SET_SEARCH_TEXT;
  constructor(public payload: string) {}
}

export type All =
  | GetSearchResults
  | GetSearchResultsSuccess
  | GetSearchResultsError
  | GetSearchResultSubmit
  | ShowSearchResult
  | HideSearchResult
  | RevertSearchParam
  | DeleteSearchParam
  | SetLoading
  | SetSearchText;
