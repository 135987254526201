import { Action } from '@ngrx/store';

export const GET_CUSTOM_FIELDS_DATA = '[CustomFields] GET_CUSTOM_FIELDS_DATA';
export const GET_CUSTOM_FIELDS_DATA_SUCCESS = '[CustomFields] GET_CUSTOM_FIELDS_DATA_SUCCESS';
export const GET_CUSTOM_FIELDS_DATA_ERROR = '[CustomFields] GET_CUSTOM_FIELDS_DATA_ERROR';
export const UPDATE_CUSTOM_FIELDS = '[CustomFields] UPDATE_CUSTOM_FIELDS';
export const UPDATE_CUSTOM_FIELDS_SUCCESS = '[CustomFields] UPDATE_CUSTOM_FIELDS_SUCCESS';
export const UPDATE_CUSTOM_FIELDS_ERROR = '[CustomFields] UPDATE_CUSTOM_FIELDS_ERROR';
export const CLEAR = '[CustomFields] CLEAR';
export const UPDATE_FLAG = '[CustomFields] UPDATE_FLAG';
export const UPDATE_FLAG_SUCCESS = '[CustomFields] UPDATE_FLAG_SUCCESS';
export const UPDATE_FLAG_ERROR = '[CustomFields] UPDATE_FLAG_ERROR';
export const UPDATE_PENDING_CONTAINER_CUSTOM_FIELDS = '[CustomFields] UPDATE_PENDING_CONTAINER_CUSTOM_FIELDS';
export const UPDATE_PENDING_CONTAINER_CUSTOM_FIELDS_SUCCESS = '[CustomFields] UPDATE_PENDING_CONTAINER_CUSTOM_FIELDS_SUCCESS';
export const UPDATE_PENDING_CONTAINER_CUSTOM_FIELDS_ERROR = '[CustomFields] UPDATE_PENDING_CONTAINER_CUSTOM_FIELDS_SUCCESS';

const DEFAULT_PARENT = 'container';

export class GetCustomFields implements Action {
  public readonly type = GET_CUSTOM_FIELDS_DATA;
  constructor(public payload: any = null) { }
}

export class GetCustomFieldsSuccess implements Action {
  public readonly type = GET_CUSTOM_FIELDS_DATA_SUCCESS;
  constructor(public payload: any = null) { }
}

export class GetCustomFieldsError implements Action {
  public readonly type = GET_CUSTOM_FIELDS_DATA_ERROR;
  constructor(public payload: any = null) { }
}

export class UpdateCustomFields implements Action {
  public readonly type = UPDATE_CUSTOM_FIELDS;
  constructor(
    public payload: any = null,
    public parent: string = DEFAULT_PARENT,
  ) { }
}

export class UpdateCustomFieldsSuccess implements Action {
  public readonly type = UPDATE_CUSTOM_FIELDS_SUCCESS;
  constructor(
    public payload: any = null,
    public parent: string = DEFAULT_PARENT,
  ) { }
}

export class UpdateCustomFieldsError implements Action {
  public readonly type = UPDATE_CUSTOM_FIELDS_ERROR;
  constructor(
    public payload: any = null,
    public parent: string = DEFAULT_PARENT,
  ) { }
}

export class Clear implements Action {
  public readonly type = CLEAR;
}

export class UpdateFlag implements Action {
  public readonly type = UPDATE_FLAG;
  constructor(
    public payload: any = null,
    public parent: string = DEFAULT_PARENT,
  ) { }
}

export class UpdateFlagSuccess implements Action {
  public readonly type = UPDATE_FLAG_SUCCESS;
  constructor(
    public payload: any = null,
    public parent: string = DEFAULT_PARENT,
  ) { }
}

export class UpdateFlagError implements Action {
  public readonly type = UPDATE_FLAG_ERROR;
  constructor(
    public payload: any = null,
    public parent: string = DEFAULT_PARENT,
  ) { }
}

export class UpdatePendingContainerCustomFields implements Action {
  public readonly type = UPDATE_PENDING_CONTAINER_CUSTOM_FIELDS;
  constructor(
    public payload: any = null,
    public parent: string = DEFAULT_PARENT,
  ) { }
}

export class UpdatePendingContainerCustomFieldsSuccess implements Action {
  public readonly type = UPDATE_PENDING_CONTAINER_CUSTOM_FIELDS_SUCCESS;
  constructor(
    public payload: any = null,
    public parent: string = DEFAULT_PARENT,
  ) { }
}

export class UpdatePendingContainerCustomFieldsError implements Action {
  public readonly type = UPDATE_PENDING_CONTAINER_CUSTOM_FIELDS_ERROR;
  constructor(
    public payload: any = null,
    public parent: string = DEFAULT_PARENT,
  ) { }
}

export type All =
  | UpdateCustomFields
  | UpdateCustomFieldsSuccess
  | UpdateCustomFieldsError
  | UpdateFlag
  | Clear
  | UpdateFlagSuccess
  | UpdateFlagError
  | GetCustomFields
  | GetCustomFieldsSuccess
  | GetCustomFieldsError
  | UpdatePendingContainerCustomFields
  | UpdatePendingContainerCustomFieldsSuccess
  | UpdatePendingContainerCustomFieldsError;
