import * as modalActions from './modal.actions';
import { DialogState, ModalState } from './modal.state';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const initalState: DialogState = {
  showModal: false,
  dialogRef: null,
};

const getModalState = createFeatureSelector<ModalState>('modal');

export const getDialogRef = createSelector(
  getModalState,
  state => state.dialog.dialogRef,
);

// eslint-disable-next-line @typescript-eslint/naming-convention
export function ModalReducer(
  state: DialogState = initalState,
  action: modalActions.All,
): DialogState {
  switch (action.type) {
    case modalActions.OPEN_DIALOG:
      return {
        ...state,
        showModal: true,
      };

    case modalActions.CLOSE_DIALOG:
      return {
        ...state,
        showModal: false,
      };

    case modalActions.SET_DIALOG_REF:
      return {
        ...state,
        dialogRef: action.payload,
      };

    case modalActions.CLEAR_DIALOG_REF:
      return {
        ...state,
        dialogRef: null,
      };

    default: return state;
  }
}
