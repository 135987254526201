export enum IUserPreferencesField {
  CONTAINER = 'containerFields',
  TERMINAL = 'terminalFields',
  VESSEL = 'vesselFields',
}

export interface IUserPreferencesItem {
  field: string;
  header: string;
  checked: boolean;
  disabled: boolean;
  disabledSorting?: boolean;
}

export interface ICustomFieldsItem {
  customOne: string;
  customTwo: string;
}

export interface ICustomFields {
  containerFields: ICustomFieldsItem;
  terminalFields: ICustomFieldsItem;
  vesselFields: ICustomFieldsItem;
}

export interface IUserPreferences {
  containerFields: IUserPreferencesItem[];
  terminalFields: IUserPreferencesItem[];
  vesselFields: IUserPreferencesItem[];
}

export const DEFAULT_USER_PREFERENCES: IUserPreferences = {
  containerFields: [
    { field: 'flag', header: 'Priority', checked: true, disabled: false },
    { field: 'holdStatusAsEnum', header: 'Holds', checked: true, disabled: false },
    { field: 'containerNumber', header: 'Container', checked: true, disabled: true },
    { field: 'equipmentCode', header: 'Type', checked: true, disabled: false },
    { field: 'unloadEvent', header: 'Status', checked: true, disabled: false },
    { field: 'bolNumbers', header: 'Bill Of Lading', checked: false, disabled: false },
    { field: 'shippingLine', header: 'Shipping Line', checked: false, disabled: false },
    { field: 'eventdate', header: 'Event Date', checked: false, disabled: false },
    { field: 'vesselName', header: 'Vessel', checked: true, disabled: false },
    { field: 'etaAta', header: 'ETA/ATA', checked: true, disabled: true },
    { field: 'destinationTerminal', header: 'Terminal', checked: true, disabled: false },
    { field: 'lastFreeDay', header: 'Last Free Day', checked: true, disabled: false },
    { field: 'location', header: 'Yard Location', checked: true, disabled: false },
    { field: 'owner.fullName', header: 'Owner', checked: true, disabled: false },
    { field: 'averageBerthTime', header: 'Average Berth Time', checked: false, disabled: false },
    { field: 'averageDischargeTime', header: 'Average Discharge Time', checked: false, disabled: false },
    { field: 'averageLoadTime', header: 'Average Load Time', checked: false, disabled: false },
    { field: 'emptyReturns', header: 'Empty Returns', checked: false, disabled: false },
    { field: 'apptStatus', header: 'APPT Status', checked: false, disabled: false },
    { field: 'apptTime', header: 'APPT Time', checked: false, disabled: false },
    { field: 'customField1', header: 'Custom', checked: true, disabled: false },
    { field: 'customField2', header: 'Custom 2', checked: false, disabled: false },
  ],
  terminalFields: [
    { field: 'flag', header: 'Priority', checked: true, disabled: false },
    { field: 'holdStatusAsEnum', header: 'Holds', checked: true, disabled: false },
    { field: 'containerNumber', header: 'Container', checked: true, disabled: true },
    { field: 'equipmentCode', header: 'Type', checked: true, disabled: false },
    { field: 'unloadEvent', header: 'Status', checked: true, disabled: false },
    { field: 'bolNumbers', header: 'Bill Of Lading', checked: false, disabled: false },
    { field: 'shippingLine', header: 'Shipping Line', checked: false, disabled: false },
    { field: 'eventdate', header: 'Event Date', checked: true, disabled: false },
    { field: 'vesselName', header: 'Vessel', checked: false, disabled: false },
    { field: 'etaAta', header: 'ETA/ATA', checked: true, disabled: true },
    { field: 'lastFreeDay', header: 'Last Free Day', checked: true, disabled: false },
    { field: 'location', header: 'Yard Location', checked: true, disabled: false },
    { field: 'owner.fullName', header: 'Owner', checked: true, disabled: false },
    { field: 'averageBerthTime', header: 'Average Berth Time', checked: false, disabled: false },
    { field: 'averageDischargeTime', header: 'Average Discharge Time', checked: false, disabled: false },
    { field: 'averageLoadTime', header: 'Average Load Time', checked: false, disabled: false },
    { field: 'emptyReturns', header: 'Empty Returns', checked: false, disabled: false },
    { field: 'apptStatus', header: 'APPT Status', checked: false, disabled: false },
    { field: 'apptTime', header: 'APPT Time', checked: false, disabled: false },
    { field: 'customField1', header: 'Custom', checked: true, disabled: false },
    { field: 'customField2', header: 'Custom 2', checked: false, disabled: false },
  ],
  vesselFields: [
    { field: 'flag', header: 'Priority', checked: true, disabled: false },
    { field: 'holdStatusAsEnum', header: 'Holds', checked: true, disabled: false },
    { field: 'containerNumber', header: 'Container', checked: true, disabled: true },
    { field: 'equipmentCode', header: 'Type', checked: true, disabled: false },
    { field: 'unloadEvent', header: 'Status', checked: true, disabled: true },
    { field: 'bolNumbers', header: 'Bill Of Lading', checked: false, disabled: false },
    { field: 'shippingLine', header: 'Shipping Line', checked: true, disabled: false },
    { field: 'eventdate', header: 'Event Date', checked: true, disabled: true },
    { field: 'destinationTerminal', header: 'Terminal', checked: true, disabled: false },
    { field: 'lastFreeDay', header: 'Last Free Day', checked: false, disabled: false },
    { field: 'location', header: 'Yard Location', checked: false, disabled: false },
    { field: 'owner.fullName', header: 'Owner', checked: true, disabled: false },
    { field: 'averageBerthTime', header: 'Average Berth Time', checked: false, disabled: false },
    { field: 'averageDischargeTime', header: 'Average Discharge Time', checked: false, disabled: false },
    { field: 'averageLoadTime', header: 'Average Load Time', checked: false, disabled: false },
    { field: 'emptyReturns', header: 'Empty Returns', checked: false, disabled: false },
    { field: 'apptStatus', header: 'APPT Status', checked: false, disabled: false },
    { field: 'apptTime', header: 'APPT Time', checked: false, disabled: false },
    { field: 'customField1', header: 'Custom', checked: true, disabled: false },
    { field: 'customField2', header: 'Custom 2', checked: false, disabled: false },
  ],
};

export const DEFAULT_CUSTOM_FIELDS_STATE = {
  containerFields: [{ customField1: true }, { customField2: true }],
  terminalFields: [{ customField1: true }, { customField2: true }],
  vesselFields: [{ customField1: true }, { customField2: true }],
};

export const DEFAULT_DISABLED_FIELDS = {
  containerFields: ['containerNumber', 'etaAta'],
  terminalFields: ['containerNumber', 'etaAta'],
  vesselFields: ['containerNumber', 'unloadEvent', 'eventdate'],
};

export interface IUserPreferenceModalMessage {
  from: ModalTypes;
  status: boolean;
}

export enum ModalTypes {
  TABLE_SETTINGS = 0,
  NOTIFICATION = 1,
  LANDING = 2,
  PROFILE = 3,
}
