<div block="page-header" [mod]="{ sticky: !!sticky }">
  <div elem="title" [mod]="{ 'with-controls': !!controlsInTitle }">
    <ng-content select="[title]"></ng-content>
  </div>

  <div elem="subtitle">
    <ng-content select="[subtitle]"></ng-content>
  </div>

  <div elem="controls">
    <ng-content select="[controls]"></ng-content>
  </div>
</div>
