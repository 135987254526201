import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import * as FullStory from '@fullstory/browser';

@Injectable({
  providedIn: 'root',
})
export class FullStoryAnalytics {
  public initialize() {
    FullStory.init({ orgId: environment.fullStoryOrgID });
  }

  public identifyUserSession(userID: string, userEmail: string) {
    FullStory.identify(userID, {
      displayName: userEmail,
    });
  }

  public releaseIdentity() {
    FullStory.anonymize();
  }

  public setUserDetails(data: any) {
    const userDetails = data['userResponse'].userDetails;
    const userName = userDetails.firstName + ' ' + userDetails.lastName;

    FullStory.setUserVars({
      userName: userName,
      userEmail: userDetails.email,
      companyName: userDetails.companyName.toLocaleLowerCase(),
      companySCACcode: userDetails.scacCode,
      organizationType: userDetails.organizationType ? userDetails.organizationType : 'Other',
      otherOrganizationType: userDetails.otherOrganizationType ? userDetails.otherOrganizationType : 'Not Available',
      persona: data['userResponse'].persona,
      apiSubscribed: userDetails.apiSubscribed,
    });
  }

  public triggerEvent(eventName: string, eventProperties: { [key: string]: any }) {
    FullStory.event(eventName, eventProperties);
  }
}
