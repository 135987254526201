import { Action } from '@ngrx/store';

export const POST_FEEDBACK = '[Feedback] POST_FEEDBACK';
export const POST_FEEDBACK_SUCCESS = '[Feedback] POST_FEEDBACK_SUCCESS';
export const POST_FEEDBACK_ERROR = '[Feedback] POST_FEEDBACK_ERROR';
export const DRAWER_OPEN = '[Feedback Drawer] OPEN';
export const DRAWER_CLOSE = '[Feedback Drawer] CLOSE';
export const DRAWER_TOGGLE = '[Feedback Drawer] TOGGLE';
export const SET_ERROR = '[Feedback Drawer] SET_ERROR';
export const SET_REJECTED_CONTAINERS = '[Feedback Drawer] SET_REJECTED_CONTAINERS';
export const FEEDBACK_CONTAINER_REPORT_DRAWER_OPEN = '[Feedback Drawer] FEEDBACK_CONTAINER_REPORT_DRAWER_OPEN';
export const FEEDBACK_CONTAINER_REPORT_DRAWER_CLOSE = '[Feedback Drawer] FEEDBACK_CONTAINER_REPORT_DRAWER_CLOSE';

export class PostFeedback implements Action {
  public readonly type = POST_FEEDBACK;
  constructor(public payload: any = null) {}
}

export class PostFeedbackSuccess implements Action {
  public readonly type = POST_FEEDBACK_SUCCESS;
  constructor(public payload: any = null) { }
}

export class PostFeedbackError implements Action {
  public readonly type = POST_FEEDBACK_ERROR;
  constructor(public payload: any = null) {}
}

export class DrawerOpen implements Action {
  public readonly type = DRAWER_OPEN;
  constructor(public payload: any = null) {}
}

export class DrawerClose implements Action {
  public readonly type = DRAWER_CLOSE;
  constructor(public payload: any = null) {}
}

export class SetError implements Action {
  public readonly type = SET_ERROR;
  constructor(public payload: boolean = false) {}
}

export class FeedbackContainerReportDrawerOpen implements Action {
  public readonly type = FEEDBACK_CONTAINER_REPORT_DRAWER_OPEN;
  constructor(public payload: boolean = false) {}
}

export class FeedbackContainerReportDrawerClose implements Action {
  public readonly type = FEEDBACK_CONTAINER_REPORT_DRAWER_CLOSE;
  constructor(public payload: boolean = false) {}
}

export class SetRejectedContainers implements Action {
  public readonly type = SET_REJECTED_CONTAINERS;
  constructor(public payload: string[] = []) {}
}

export type All =
  | PostFeedback
  | PostFeedbackSuccess
  | PostFeedbackError
  | DrawerOpen
  | SetError
  | DrawerClose
  | FeedbackContainerReportDrawerOpen
  | FeedbackContainerReportDrawerClose
  | SetRejectedContainers;
