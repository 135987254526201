import { AcknowledgeContainersState } from './acknowledge-containers.state';
import * as AcknowledgeContainersActions from './acknowledge-containers.actions';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export type Action = AcknowledgeContainersActions.All;

const initialState: AcknowledgeContainersState = {
  loading: false,
  containers: [],
};

const getAcknowledgeContainersState = createFeatureSelector<AcknowledgeContainersState>('acknowledgeContainers');

export const acknowledgeRejectedContainers = createSelector(
  getAcknowledgeContainersState,
  state => state,
);

export const getRejectedContainers = createSelector(
  getAcknowledgeContainersState,
  state => state.containers,
);

export const getLoadingStatus = createSelector(
  getAcknowledgeContainersState,
  state => state.loading,
);

// eslint-disable-next-line @typescript-eslint/naming-convention
export function AcknowledgeContainersReducer(state = initialState, action: Action): AcknowledgeContainersState {
  switch (action.type) {
    case AcknowledgeContainersActions.ACKNOWLEDGE_REJECTED_CONTAINERS: {
      return { ...state, loading: true };
    }
    case AcknowledgeContainersActions.ACKNOWLEDGE_REJECTED_CONTAINERS_ERROR: {
      return { ...state, loading: false };
    }
    case AcknowledgeContainersActions.ACKNOWLEDGE_REJECTED_CONTAINERS_SUCCESS: {
      return { ...state, loading: false };
    }
    case AcknowledgeContainersActions.SET_REJECTED_CONTAINERS: {
      return { ...state, containers: action.payload };
    }
    default:
      return state;
  }
}
