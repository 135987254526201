import { Injectable } from '@angular/core';
import { of, combineLatest, Observable } from 'rxjs';
import { map, catchError, switchMap, filter, tap } from 'rxjs/operators';
import { Store, select, Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { format, addMonths, subMonths } from 'date-fns';

import { ApiQueryBuilder } from '@services/api-query-builder';
import { ApiCallService } from '@services/api-call.service';
import {
  doPayloadsDiffer,
  constructRequest,
  transformCache,
  isCached,
  extendCategories,
  withCustomTypes,
} from './notification.helpers';

import * as GlobalErrorActions from '@store/global-error/global-error.actions';
import * as NotificationActions from '@store/notification/notification.actions';
import { INotificationType, INotification } from '@models/dashboard/notification.model';
import { getAlertTypes } from '@store/notification/notification.reducer';

const CACHE_LIFESPAN_IN_MS: number = 1000 * 60 * 3;

@Injectable()
export class NotificationsEffect {
  private cache: {} = {};
  private payloadClone: {} = {};


  constructor(
    private store: Store<{}>,
    private actions$: Actions,
    private api: ApiCallService,
  ) { }

  public fetchNotifications$: Observable<Action> =  createEffect(() =>this.actions$.pipe(
    ofType<NotificationActions.FetchNotifications>(NotificationActions.FETCH_NOTIFICATIONS),
    switchMap((action) => {
      const { idAlert, fetchMore, options } = action.payload;
      const today = new Date();
      const payload = {
        idAlert,
        page: 0,
        pageSize: 50,
        fromSequence: 1e8,
        toDate: format(addMonths(today, 3), 'yyyy-MM-dd'),
        fromDate: format(subMonths(today, 3), 'yyyy-MM-dd'),
        ...options,
      };

      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      const request$: Observable<{}> = doPayloadsDiffer({ ...payload }, { ...this.payloadClone[idAlert] })
        ? (
          this.payloadClone[idAlert] = { ...payload, expires: Date.now() + CACHE_LIFESPAN_IN_MS },
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          constructRequest(this.api, payload)
        )
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        : transformCache(this.cache[idAlert]);

      return request$.pipe(
        tap(({ content }: any) => content.length
          ? this.store.dispatch(new NotificationActions.MarkAsRead({ fromSequence: content[0].sequence }))
          : null,
        ),
        map((response: any) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          if (isCached(this.cache, idAlert) && fetchMore) {
            this.cache[idAlert].content = this.cache[idAlert].content.concat(response.content);
            this.cache[idAlert].last = response.last;
            return { ...response, content: this.cache[idAlert].content };
          }
          this.cache[idAlert] = { ...response };
          return response;
        }),
        map(({ content, last }: any) => {
          const data = content.map((c: INotification) => {
            if (c.notificationData && c.notificationData.unClaimData) {
              try {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                c.notificationData.unClaimData = JSON.parse(c.notificationData.unClaimData);
              // eslint-disable-next-line no-empty
              } catch {}
            }

            if (c.notificationData && c.notificationData.lfdContainers) {
              try {
                c.notificationData.lfdContainers = c.notificationData.lfdContainers.split(/,/);
              // eslint-disable-next-line no-empty
              } catch {}
            }

            if (c.notificationData && c.notificationData.lfdContainerTripIds) {
              try {
                c.notificationData.lfdContainerTripIds = c.notificationData.lfdContainerTripIds.split(/,/);
              // eslint-disable-next-line no-empty
              } catch {}
            }
            return c;
          });
          return new NotificationActions.FetchNotificationsSuccess({ data, type: idAlert, last });
        }),
        catchError(error => of(new GlobalErrorActions.SetGlobalError({ errorType: 'network', error }))),
      );
    }),
  ),
  );


  public fetchCategories$: Observable<Action> =  createEffect(() => this.actions$.pipe(
    ofType<NotificationActions.FetchNotificationCategories>(NotificationActions.FETCH_NOTIFICATION_CATEGORIES),
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    switchMap(_ => {
      const categories$ = this.api.constructApiCall(
        new ApiQueryBuilder()
          .addName('notificationCategories')
          .build(),
      );

      const types$ = this.store.pipe(
        select(getAlertTypes),
        filter(types => types && !!types.length),
        map(withCustomTypes),
      );

      return combineLatest([categories$, types$]).pipe(
        map(extendCategories),
        map((categories) => new NotificationActions.FetchNotificationCategoriesSuccess(categories)),
        catchError(error => of(new NotificationActions.FetchNotificationCategoriesFail(error))),
      );
    }),
  ),
  );

  public fetchAlertTypes$: Observable<Action> =  createEffect(() => this.actions$.pipe(
    ofType<NotificationActions.FetchAlertTypes>(NotificationActions.FETCH_ALERT_TYPES),
    switchMap(_ =>
      this.api.constructApiCall(
        new ApiQueryBuilder()
          .addName('alertTypes')
          .build(),
      ).pipe(
        map((data: INotificationType[]) => new NotificationActions.FetchAlertTypesSuccess(data)),
        catchError(error => of(new GlobalErrorActions.SetGlobalError({ errorType: 'network', error }))),
      ),
    ),
  ),
  );

  public archiveNotification$: Observable<object> = createEffect(() => this.actions$.pipe(
    ofType<NotificationActions.ArchiveNotificatons>(NotificationActions.ARCHIVE_NOTIFICATIONS),
    switchMap(({ payload }) =>
      this.api.constructApiCall(
        new ApiQueryBuilder()
          .addName(`archiveNotifications`)
          .addBody(payload.ids)
          .build(),
      ).pipe(
        tap(() => {
          [this.cache, this.payloadClone].forEach((storage) =>
            (delete storage[payload.type], delete storage['ARCHIVED']),
          );
          this.store.dispatch(new NotificationActions.FetchNotificationCategories());
          this.store.dispatch(new NotificationActions.FetchNotifications({ idAlert: payload.type }));
        }),
        catchError(error => of(new GlobalErrorActions.SetGlobalError({ errorType: 'network', error }))),
      ),
    ),
  ),
    { dispatch: false },

  );

  public marAsRead$: Observable<object> = createEffect(() => this.actions$.pipe(
    ofType<NotificationActions.MarkAsRead>(NotificationActions.MARK_AS_READ),
    switchMap(({ payload }) =>
      this.api.constructApiCall(
        new ApiQueryBuilder()
          .addName('markAsRead')
          .addBody({ fromSequence: payload.fromSequence || null })
          .build(),
      ).pipe(
        catchError(error => of(new GlobalErrorActions.SetGlobalError(error))),
      ),
    ),
  ),
  {dispatch: false},
  );
}
