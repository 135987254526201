import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DashboardState } from '@store/dashboard/dashboard.state';

const getDashboardState = createFeatureSelector<DashboardState>('dashboard');
export const getContainerStatusListResults = createSelector(
  getDashboardState,
  (state: DashboardState, props) => state.status[props.parent].results,
);
export const isLoading = createSelector(
  getDashboardState,
  (state: DashboardState, props) =>
    ({
      loading: state.status[props.parent].loading,
      page: state.status[props.parent].page,
    }),
);
export const isFirstPageLoading = createSelector(
  isLoading,
  (loadingState) => loadingState.loading && loadingState.page === 0,
);
export const isListLoading = createSelector(
  isLoading,
  (loadingState) => loadingState.loading,
);
export const isMoreHitsLoading = createSelector(
  isLoading,
  (loadingState) => loadingState.loading && loadingState.page > 0,
);
export const isMoreHits = createSelector(
  getDashboardState,
  (state: DashboardState, props) => state.status[props.parent].moreHits,
);
export const getResultListNumber = createSelector(
  getDashboardState,
  (state: DashboardState, props) => state.status[props.parent].results.length,
);
export const getError = createSelector(
  getDashboardState,
  (state: DashboardState, props) => state.status[props.parent].error,
);
export const getFilterParts = createSelector(
  getDashboardState,
  state => ({ partFilters: state.filterPart.partFilters }),
);
export const getMessageQueue = createSelector(
  getDashboardState,
  state => state.messageQueue,
);
export const getPartFiltersAndOrders = createSelector(
  getDashboardState,
  (state: DashboardState, props) =>
    ({
      partFilters: state ? state.filterPart.partFilters : [],
      orders: state && props && props.parent ? state.status[props.parent].orders : [],
    }),
);
export const getOrders = createSelector(
  getDashboardState,
  (state: DashboardState, props) => state && state.status && state.status[props.parent].orders,
);
export const getSelectedList = createSelector(
  getDashboardState,
  (state: DashboardState, props) => state.status[props.parent].selection,
);
export const getSearch = createSelector(
  getDashboardState,
  (state: DashboardState, props) => state.status[props.parent].search,
);
export const getTotalElements = createSelector(
  getDashboardState,
  (state: DashboardState, props) => state.status[props.parent].totalElements,
);
export const getPendingContainers = createSelector(
  getDashboardState,
  (state: DashboardState, props) => state.status[props.parent].pending,
);
export const getPage = createSelector(
  getDashboardState,
  (state: DashboardState, props) => state.status[props.parent].page,
);
export const getLoading = createSelector(
  getDashboardState,
  (state: DashboardState, props) => state.status[props.parent].loading,
);
export const getScrollToTop = createSelector(
  getDashboardState,
  state => state.scrollToTop,
);
