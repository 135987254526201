import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { ApiQueryBuilder } from '@services/api-query-builder';

import * as FeedbackActions from './feedback.actions';
import { ApiCallService } from '@services/api-call.service';

@Injectable()
export class FeedbackEffects {

  constructor(
    private actions$: Actions,
    private api: ApiCallService,
  ) { }

  public AddFeedback$: Observable<Action> = createEffect(() => this.actions$
  .pipe(
      ofType<FeedbackActions.PostFeedback>(FeedbackActions.POST_FEEDBACK),
      mergeMap(action => {
        return this.api.constructApiCall(
          new ApiQueryBuilder()
            .addName('feedback')
            .addBody(action.payload)
            .build(),
        )
        .pipe(
          map(data => {
            return new FeedbackActions.PostFeedbackSuccess(data);
          }),
          catchError(error => {
            return of(new FeedbackActions.PostFeedbackError(error));
          }),
        );
      }),
    ),
    );
}
