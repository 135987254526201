import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IntermodalState } from './intermodal.state';

const getIntermodalState = createFeatureSelector<IntermodalState>('intermodal');

export const getList = createSelector(
  getIntermodalState,
  state => state.results,
);

export const getListAll = createSelector(
  getIntermodalState,
  state => state.allIntermodal,
);

export const getListError = createSelector(
  getIntermodalState,
  state => state.error,
);

export const isLoading = createSelector(
  getIntermodalState,
  state => state.loading,
);

export const isFirstPageLoading = createSelector(
  getIntermodalState,
  state => state.loading && state.page === 1,
);

export const isMoreHitsLoading = createSelector(
  getIntermodalState,
  state => state.loading && state.page > 1,
);

export const hasMoreHits = createSelector(
  getIntermodalState,
  state => state.results && state.allIntermodal && state.results.length < state.allIntermodal.length,
);

export const getTotalElements = createSelector(
  getIntermodalState,
  state => state.allIntermodal && state.allIntermodal.length || 0,
);

export const getPage = createSelector(
  getIntermodalState,
  state => state.page || 1,
);

export const getSelectedTrainTripId = createSelector(
  getIntermodalState,
  state => state.selectedTrainTripId,
);

export const getContainersListError = createSelector(
  getIntermodalState,
  state => state.containersListError,
);

export const isLoadingContainersList = createSelector(
  getIntermodalState,
  state => state.containersListLoading,
);

export const isLoadingUpdatingCustomFields = createSelector(
  getIntermodalState,
  state => state.updatingCustomFieldsLoading,
);

export const getFilter = createSelector(
  getIntermodalState,
  state => state.filter,
);

export const getContainerListFilter = createSelector(
  getIntermodalState,
  state => state.containerListFilter,
);

export const getTerminalCodes = createSelector(
  getIntermodalState,
  state => state.terminalCodes,
);

export const getDownloadState = createSelector(
  getIntermodalState,
  state => state.loadingDownload,
);
