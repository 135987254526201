import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild, ChangeDetectionStrategy } from '@angular/core';

import { MatTabChangeEvent } from '@angular/material/tabs';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { IUserPreferenceModalMessage } from '@models/shared/user-preferences.model';
import { UserPreferencesNotificationComponent } from '@shared/components/user-preferences-notification/user-preferences-notification.component';
import {
  getLoadingState,
} from '@store/landing-page-settings/landing-page-settings.reducer';
import {
  isAlertSaveInProgress,
} from '@store/notification-settings/notification-settings.reducer';
import {
  isLoading as isUserPrefLoading,
} from '@store/user-preferences/user-preferences.reducer';
import { LandingPageSettingsComponent } from '../landing-page-settings/landing-page-settings.component';
import { TableSettingsModalComponent } from '../table-settings-modal/table-settings-modal.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-user-preferences',
  styleUrls: ['./user-preferences-modal.component.css'],
  templateUrl: './user-preferences-modal.component.html',
})
export class UserPreferencesComponent implements OnInit, OnDestroy {
  @Output() public closeForm = new EventEmitter<void>();
  public userPreferenceTabIndex: number = 0;
  public loading$: Observable<boolean>;

  public tabConfig: any[];
  public isApplyDisabled = true;

  @ViewChild(TableSettingsModalComponent)
  private tableSettingsComponent: TableSettingsModalComponent;
  @ViewChild(UserPreferencesNotificationComponent)
  private userPreferencesNotificationComponent: UserPreferencesNotificationComponent;
  @ViewChild(LandingPageSettingsComponent)
  private landingPageSettingsComponent: LandingPageSettingsComponent;
  private subscriptions: Subscription = new Subscription();

  // eslint-disable-next-line @typescript-eslint/ban-types
  constructor(private store: Store<{}>) {
  }

  public ngOnInit(): void {

    this.tabConfig = [
      this.tableSettingsComponent,
      this.userPreferencesNotificationComponent,
      this.landingPageSettingsComponent,
    ];

    const notificationSettingLoading$ = this.store.pipe(select(isAlertSaveInProgress));
    const tableSettingLoading$ = this.store.pipe(select(getLoadingState));
    const userPrefLoading$ = this.store.pipe(select(isUserPrefLoading));

    this.loading$ = combineLatest([notificationSettingLoading$, tableSettingLoading$, userPrefLoading$]).pipe(
      map((loadingStates) => {
          return loadingStates.reduce((acc, loading) => acc || loading, false);
        },
      ));
  }

  public onCancelClick(): void {
    this.closeForm.emit();
  }

  public initForms = () => {
    this.tableSettingsComponent.initForm();
    this.userPreferencesNotificationComponent.initForm();
    this.landingPageSettingsComponent.initForm();
  };

  public onTabChanged({ index }: MatTabChangeEvent) {
    this.userPreferenceTabIndex = index;
  }

  public submitChanges() {

    if (!this.userPreferencesNotificationComponent.formError) {
      if (this.userPreferencesNotificationComponent) {
        this.userPreferencesNotificationComponent.submitChanges();
      }
      this.landingPageSettingsComponent.submitChanges();
      this.tableSettingsComponent.submitChanges();
    }
  }


  public setApplyButtonStatus(message: IUserPreferenceModalMessage) {
    if (!!this.userPreferencesNotificationComponent && !this.userPreferencesNotificationComponent.formError) {
      this.isApplyDisabled = !message.status;
    } else {
      this.isApplyDisabled = true;
    }
  }

  public ngOnDestroy(): void {
    this.initForms();
    this.subscriptions.unsubscribe();
  }

}
