import { createFeatureSelector, createSelector } from '@ngrx/store';

import { BannerState } from './banner.state';
import * as BannerActions from './banner.actions';

const initialState: BannerState = [];

export type Action = BannerActions.All;

const getBannerState = createFeatureSelector<BannerState>('banner');

export const getBanners = createSelector(
  getBannerState,
  state => state,
);

// eslint-disable-next-line @typescript-eslint/naming-convention
export function BannerReducer(state = initialState, action: Action): BannerState {
  switch (action.type) {
    case BannerActions.ADD_BANNER:
      return state.concat({ ...action.payload, id: Date.now() });
    case BannerActions.REMOVE_BANNER:
      return state.filter(i => i.id !== action.payload);
    default:
      return state;
  }
}
