import { TermsOfUseState } from './terms-of-use.state';

import * as fromTermsOfUseActions from './terms-of-use.actions';
import { createFeatureSelector, createSelector } from '@ngrx/store';

const initialState: TermsOfUseState = {
  accepted: false,
};

export type Action = fromTermsOfUseActions.All;

const getTermsOfUseState = createFeatureSelector<TermsOfUseState>('terms-of-use');

export const termsOfUseAccepted = createSelector(
  getTermsOfUseState,
  state => state.accepted,
);


// eslint-disable-next-line @typescript-eslint/naming-convention
export function TermsOfUseReducer(
  state = initialState,
  action: Action,
): TermsOfUseState {
  switch (action.type) {
    case fromTermsOfUseActions.TERMS_OF_USE_ACCEPT: {
      return { ...state, accepted: true };
    }

    case fromTermsOfUseActions.TERMS_OF_USE_DENY: {
      return { ...state, accepted: false };
    }

    default:
      return state;
  }
}
