<div block="appointment-modal">
  <div elem="header">
    <div elem="dropdown"></div>
    <div elem="title">{{ modalTitle }}</div>
    <div elem="close" (click)="onCloseClick($event)"><img src="/assets/svgs/close.svg" alt="Close modal" width="20" height="20"></div>
  </div>
  <div elem="frame-wrapper">
    <iframe elem="frame" [src]="url" title="External appointment site"></iframe>
  </div>
  <div mat-dialog-actions elem="buttons">
    <button
      elem="button"
      mat-flat-button
      color="primary"
      (click)="onCloseClick($event)"
      type="button"
    >Close</button>
  </div>
</div>
