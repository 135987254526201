import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { ApiQueryBuilder } from '@services/api-query-builder';

import * as ChassisCountAction from './chassis-count.actions';
import { ApiCallService } from '@services/api-call.service';
import { ChassisCountService } from '@app/modules/dashboard/services/chassis-count.service';

@Injectable()
export class ChassisCountEffects {

  constructor(
    private actions$: Actions,
    private api: ApiCallService,
    private chassisCountService: ChassisCountService,
  ) { }

  public GetChassisCount$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<ChassisCountAction.ChassisCount>(ChassisCountAction.GET_CHASSIS_COUNT),
    mergeMap(() => {
      return this.api.constructApiCall(
        new ApiQueryBuilder()
          .addName('chassisCount')
          .addBody(this.chassisCountService.createRequestPayload())
          .build(),
      )
        .pipe(
          map(data => {
            return new ChassisCountAction.ChassisCountSuccess(this.chassisCountService.parseChassisCountResponse(data));
          }),
          catchError(error => {
            return of(new ChassisCountAction.ChassisCountError(error));
          }),
        );
    }),
  ),
  );
}
