import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

import { environment } from '@env/environment';
import { RequestMethods } from '@models/shared/api-call.model';
import { ApiConstants, CognitoConstants } from './api.constants';
import { CognitoParams } from '@models/shared/cognito-params.model';
import { isArray } from '../utility/util';

@Injectable({
  providedIn: 'root',
})
export class ApiCallService {
  constructor(private http: HttpClient) {}

  public constructApiCall(params: any): Observable<Object> {
    const configObject = {};
    const apiConfig = ApiConstants[params.name];
    const apiUrl = `${environment.apiEndpointUrl}${apiConfig.endPoint}${params.query || ''}`;

    if (apiConfig.method === RequestMethods.POST && !params.isFile) {
      configObject['body'] = isArray(params.body) ? [...params.body] : { ...params.body };
    }

    if (apiConfig.method === RequestMethods.DELETE) {
      configObject['body'] = params.body.data;
    }

    if (params.isFile && apiConfig.method === RequestMethods.POST) {
      configObject['body'] = params.body.data;
    }

    if (params.paging) {
      configObject['body']['paging'] = { ...params.paging };
    }

    if (params.entries && !!params.entries.length) {
      params.entries.forEach((entry) => (configObject[entry.key] = entry.value));
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    return this.http.request(apiConfig.method, apiUrl, configObject).pipe(
      catchError((error) => {
        return throwError(error);
      }),
    );
  }

  public cognitoCall({ name, query, body }: CognitoParams): Observable<Object> {
    const apiConfig = CognitoConstants[name];
    const apiUrl = `${environment.apiEndpointUrl}${apiConfig.endPoint}${query || ''}`;
    const configObject = { body };

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    return this.http.request(apiConfig.method, apiUrl, configObject).pipe(catchError((error) => throwError(error)));
  }
}
