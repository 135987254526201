import { Component, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { SESSION_MODAL } from '@models/shared/modal.constants';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-session-timeout-modal',
  templateUrl: './session-timeout-modal.component.html',
  styleUrls: ['./session-timeout-modal.component.css'],
})
export class SessionTimeoutModalComponent {
  @Output() public submitData = new EventEmitter<object>();
  public setSessionValid = (): void => this.submitData.emit({data: {modalType: SESSION_MODAL}});
}
