/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/operators';

import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import * as HoldCodeActions from './hold-codes.actions';
import { ApiCallService } from '@services/api-call.service';
import { ApiQueryBuilder } from '@services/api-query-builder';


@Injectable()
export class HoldCodesEffects {

   constructor(
      private actions$: Actions,
      private api: ApiCallService,
   ) { }


  public  fetchHoldCodes$: Observable<Action> = createEffect(() => this.actions$
      .pipe(
        ofType<HoldCodeActions.FetchHoldCodes>(HoldCodeActions.FETCH_HOLD_CODES),
        mergeMap(action => this.api
           .constructApiCall(
              new ApiQueryBuilder()
                 .addName('customCodes')
                 .addBody(action.payload)
                 .build(),
           )
           .pipe(
              map((data: any) => {
                const holdCodes = data.hits;
                return new HoldCodeActions.FetchHoldCodesSuccess(holdCodes);
              }),
              catchError(() => of(new HoldCodeActions.FetchHoldCodesError())),
           ),
        ),
      ),
      );
}
