import { Injectable } from '@angular/core';
import { TimezoneService } from '@app/core/services/timezone.service';
import { differenceInHours, format } from 'date-fns';
import { createColoredTooltip } from '@core/utility/chart.utility';

@Injectable({
  providedIn: 'root',
})
export class WaitTimeService {
  public toolTipColours: string[] = [];
  private terminals = [
    'APMT',
    'TRPC',
    'ITS',
    'TTI',
    'ETS',
    'FMS',
    'ITS',
    'LBCT',
    'PCT',
    'SSA-A',
    'SSA-C',
    'TRPC',
    'WBCT',
    'YTI',
  ];

  constructor(private timezoneService: TimezoneService) {}

  public parseWaitTimeResponse(data) {
    const result = {};
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const terminals = [...new Set(data.response.aggregations.filter.byTerminalName.buckets.map(waitTimeData => waitTimeData.key))];
    terminals.forEach(terminal => {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-base-to-string
      result[`${terminal}`] = this.parseTerminal(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        data.response.aggregations.filter.byTerminalName.buckets.filter(terminalData => terminalData.key === terminal),
      );
    });
    return result;
  }

  public getRequestBody() {
    return {
      searchParameters: [
        {
          key: 'byTerminalName',
          children: [
            {
              key: 'byStartDate',
              children: [
                {
                  key: 'byAverageTerminalAverage',
                  children: [],
                },
                {
                  key: 'byAverageInQueueAverage',
                  children: [],
                },
                {
                  key: 'byAverageTotalTurnTime',
                  children: [],
                },
                {
                  key: 'bySumGateMoves',
                  children: [],
                },
              ],
              firstSortPreference: {
                orderBy: 'KEY',
                orderByDirection: 'DESC',
              },
              size: 200,
            },
          ],
          filters: [
            {
              filterKey: 'terminalName',
              filterValues: [
                'APMT',
                'WBCT',
                'TRPC',
                'Trapac',
                'YTI',
                'EverportTS',
                'FMS',
                'ITS',
                'PCT',
                'SSA-A',
                'TTI',
                'LBCT',
                'LBCT Pier E',
                'SSA-C',
                'Matson',
                'SSA Pier A',
              ],
            },
          ],
        },
      ],
    };
  }

  private parseTerminal(terminalDataList: any[]) {
    let change = 0;
    let chart = [];
    let todayAverageTerminalTime = null;
    let todayAverageQueueTime = null;
    if (terminalDataList) {
      terminalDataList.forEach((terminalData) => {
        let previousAverageTotal = 0;
        chart = terminalData.byStartDate.buckets.map((waitTime) => {
          const inQueueAverage = waitTime.byAverageInQueueAverage.value;
          const terminalAverage = waitTime.byAverageTerminalAverage.value;
          const averageTotal = inQueueAverage + terminalAverage;
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          change = Math.round(100 - (previousAverageTotal / Math.max(averageTotal, 1)) * 100);
          previousAverageTotal = averageTotal;
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          const timestamp = this.timezoneService.getDateInUTC(waitTime.key);
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          const tooltip = this.createTooltip(timestamp, averageTotal, inQueueAverage, terminalAverage);
          todayAverageTerminalTime += terminalAverage;
          todayAverageQueueTime += inQueueAverage;
          return [timestamp, tooltip, terminalAverage, inQueueAverage];
        });
      });
    }
    const currentBucket = terminalDataList[0].byStartDate.buckets[0];
    const queue =
      (currentBucket && currentBucket.byAverageInQueueAverage && currentBucket.byAverageInQueueAverage.value) || 0;
    const terminal =
      (currentBucket && currentBucket.byAverageTerminalAverage && currentBucket.byAverageTerminalAverage.value) || 0;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const hoursDifference = differenceInHours(new Date(), currentBucket.key);

    return {
      change,
      averageTotal: terminal + queue,
      queue,
      terminal,
      chart,
      hoursDifference,
      todayAverageTerminalTime: Math.floor(todayAverageTerminalTime / chart.length),
      todayAverageQueueTime: Math.floor(todayAverageQueueTime / chart.length),
      totalTodayAverage: Math.floor(todayAverageTerminalTime / chart.length + todayAverageQueueTime / chart.length),
    };
  }

  private createTooltip(timestamp: Date, averageTotal: number, inQueueAverage: number, terminalAverage: number) {
    const title = format(timestamp, 'dd MMM yyyy hh:mm:ss aa');
    const categories = [
      { label: 'Queue Time', value: inQueueAverage, color: this.toolTipColours[0] ? this.toolTipColours[0]: '#EAB765' },
      { label: 'Terminal Time', value: terminalAverage, color: this.toolTipColours[1] ? this.toolTipColours[1]: '#EA9965' },
      { label: 'Total Turn Time', value: averageTotal },
    ];
    return createColoredTooltip({ title, categories });
  }
}
