/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';

import * as InterestedPartiesActions from './interested-parties.actions';

import { IParty } from '@models/dashboard/interested-parties.model';

import { ApiCallService } from '@services/api-call.service';
import { ApiQueryBuilder } from '@services/api-query-builder';
import { InterestedPartiesService } from '@services/interested-parties.service';

@Injectable()
export class InterestedPartiesEffects {
  constructor(
    private actions$: Actions,
    private api: ApiCallService,
    private interestedPartiesService: InterestedPartiesService,
  ) { }

  public GetInterestedParties$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType<InterestedPartiesActions.GetInterestedParties>(
        InterestedPartiesActions.GET_INTERESTED_PARTIES,
      ),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
      mergeMap(_ => {
        return this.api.constructApiCall(
          new ApiQueryBuilder()
            .addName(this.interestedPartiesService.getEndpointName())
            .build(),
        )
          .pipe(
            map((data: IParty[]) => {
              return new InterestedPartiesActions.GetInterestedPartiesSuccess(
                this.interestedPartiesService.getParsedInterestedPartiesResponse(data),
              );
            }),
            catchError(() => {
              return of(new InterestedPartiesActions.GetInterestedPartiesFailure());
            }),
          );
      }),
    ),
    );

  public GetAllParties$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType<InterestedPartiesActions.GetAllUser>(InterestedPartiesActions.GET_ALL_USER),
      // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
      mergeMap(_ => {
        return this.api.constructApiCall(
          new ApiQueryBuilder()
            .addName('getAllUser')
            .build(),
        )
          .pipe(
            map((data: IParty[]) => new InterestedPartiesActions.GetAllUserSuccess(data)),
            catchError(error => of(new InterestedPartiesActions.GetAllUserFailure(error))),
          );
      }),
    ),
    );
}
