import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
  ViewChild,
  ElementRef, ChangeDetectionStrategy,
} from '@angular/core';

import { Store, select } from '@ngrx/store';
import { tap, filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';

import * as UserPreferencesActions from '@store/user-preferences/user-preferences.actions';
import * as fromUserPreferences from '@store/user-preferences/user-preferences.reducer';
import * as CustomFieldsActions from '@store/custom-fields/custom-fields.actions';
import * as fromCustomFields from '@store/custom-fields/custom-fields.reducer';
import { UserPreferencesService } from '@services/user-preferences.service';
import { forbiddenStringCheck } from '@core/validators/forbiddenString.validator';

import {
  IUserPreferences,
  IUserPreferencesField,
  IUserPreferencesItem,
  ICustomFields,
  IUserPreferenceModalMessage,
  ModalTypes,
  DEFAULT_CUSTOM_FIELDS_STATE,
  DEFAULT_DISABLED_FIELDS,
} from '@models/shared/user-preferences.model';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-table-settings-modal',
  templateUrl: './table-settings-modal.component.html',
  styleUrls: ['./table-settings-modal.component.css'],
})
export class TableSettingsModalComponent implements OnInit, OnDestroy {
  @Output() public closeEvent = new EventEmitter<void>();
  @ViewChild('customFieldOne') public customFieldOne: ElementRef;
  @ViewChild('customFieldTwo') public customFieldTwo: ElementRef;
  @Output() public applyButtonStatusEvent = new EventEmitter<IUserPreferenceModalMessage>();

  private subscriptions: Subscription = new Subscription();
  private maxToCheck: number = 12;
  public defaultDisabledFields: any;

  public userPreferences: IUserPreferences;
  public preferencesKeys: Partial<IUserPreferencesItem>[];
  public customFieldsFormGroup: FormGroup;
  public customFieldsStatus: any;
  public customFieldsPayload: ICustomFields;
  public formDirty = false;

  constructor(
    private fb: FormBuilder,
    // eslint-disable-next-line @typescript-eslint/ban-types
    private store: Store<{}>,
    private userPreferencesService: UserPreferencesService,
  ) {
  }

  public ngOnInit(): void {
    this.initForm();

    this.subscriptions.add(
      this.store.pipe(
        select(fromCustomFields.getCustomFieldsResponse),
        filter(data => !!data),
        tap(data => window.localStorage.setItem('orgPreferencesData', JSON.stringify(data))),
      ).subscribe(),
    );

    this.subscriptions.add(
      this.store.pipe(
        select(fromUserPreferences.getUserPreferences),
        filter(data => !!data),
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        tap(data => this.updateData(data)),
      ).subscribe(),
    );
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    this.customFieldsFormGroup.valueChanges.subscribe(_ => {
      this.applyButtonStatusEvent.next({
        from: ModalTypes.TABLE_SETTINGS,
        status: true,
      });
    });
  }

  public initForm = () => {
    const customFieldData = this.userPreferencesService.getCustomFieldData(null);
    this.customFieldsPayload = customFieldData
      ? this.extractSettings(customFieldData)
      : this.customFieldsPayload;

    this.customFieldsStatus = DEFAULT_CUSTOM_FIELDS_STATE;
    this.defaultDisabledFields = DEFAULT_DISABLED_FIELDS;

    this.customFieldsFormGroup = this.fb.group({
      customField1: ['', [forbiddenStringCheck]],
      customField2: ['', [forbiddenStringCheck]],
    }, { updateOn: 'blur' });

    this.userPreferences = this.extractSettings<IUserPreferences>(
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      JSON.parse(localStorage.getItem('userPreferencesData')),
    );

    this.preferencesKeys = Object.keys(this.userPreferences)
      .sort()
      .map(this.getHeader);

    Object.keys(this.userPreferences).forEach(fieldName => {
      this.addDisabledField(fieldName);
      this.disableFields(fieldName);
    });
    this.formDirty = false;
  }

  private getHeader = (field: IUserPreferencesField): Partial<IUserPreferencesItem> =>
    ({
      field,
      header: {
        containerFields: 'Container',
        vesselFields: 'Vessel',
        terminalFields: 'Terminal',
      }[field] || '',
    })

  private updateData(data: IUserPreferences): void {
    window.localStorage.setItem('userPreferencesData', JSON.stringify(data));
    this.userPreferences = this.extractSettings(data);
  }

  public extractSettings = <T>(data: T): T => Object.keys(data || <T>{})
    .filter(field =>
      ['containerFields', 'vesselFields', 'terminalFields'].includes(field),
    )
    .reduce((acc: any, field) => ({ ...acc, [field]: data[field] }), <T>{})


  public onChange(fieldName: IUserPreferencesField, value: string, event: any) {
    this.applyButtonStatusEvent.emit({
      from: ModalTypes.TABLE_SETTINGS,
      status: true,
    });
    this.formDirty = true;
    this.userPreferences[fieldName] = this.userPreferences[fieldName].map(
      item => item.field === value ? { ...item, checked: event.checked } : item);

    this.disableFields(fieldName);
  }

  public submitChanges(): void {
    this.onCustomFieldBlur();
    this.updateUserCustomFields();
    this.updateData(this.userPreferences);
    localStorage.setItem('orgPreferencesData', JSON.stringify(this.customFieldsPayload));
    this.closeModal();
    this.store.dispatch(new UserPreferencesActions.SaveUserPreferences(this.userPreferences));
  }


public updateUserCustomFields(): void {
  if (!this.customFieldsPayload) {
    return;
  }

const fields = ['containerFields', 'vesselFields', 'terminalFields'];
fields.forEach(field => {
  if (this.userPreferences && this.userPreferences[field]) {
    this.userPreferences[field].forEach(userField => {
      const customField = this.customFieldsPayload[field].customOne || this.customFieldsPayload[field].customTwo;
      if (userField.field === "customField1" || userField.field === "customField2") {
        userField.header = customField;
      }
    });
  }
});
}

  public closeModal(): void {
    this.closeEvent.emit();
  }

  public onEditCustomFields(field: string, custom: string, event: Event) {
    this.customFieldsStatus[field][custom] = true;

    if (event.type === 'blur') {
      const customKey = custom === 'customField1' ? 'customOne' : 'customTwo';
      const customField = this.customFieldsFormGroup.get(custom).value.trim();
      const prevCustomField = this.customFieldsPayload[field][customKey].trim();

      Object.keys(this.customFieldsPayload).forEach(key => {
        if (key === field) {
          this.customFieldsPayload[key][customKey] = this.customFieldsFormGroup.get(custom).hasError('invalidInput') ?
            prevCustomField : customField;
        }
      });

      this.customFieldsFormGroup.controls[custom].setValue('');
      this.customFieldsStatus[field][custom] = false;
      this.formDirty = true;
    } else {
      if (custom === 'customField1') {
        setTimeout(() => this.customFieldOne.nativeElement.focus(), 0);
      } else {
        setTimeout(() => this.customFieldTwo.nativeElement.focus(), 0);
      }
    }
  }

  public disableFields(fieldName) {
    const checkedCount = this.userPreferences[fieldName].filter(field => field.checked === true).length;
    if (checkedCount >= this.maxToCheck) {
      this.userPreferences[fieldName] = this.userPreferences[fieldName]
        .map(item => {
          if (!item.checked) {
            item.disabled = true;
          }
          return item;
        });
    } else {
      this.userPreferences[fieldName] = this.userPreferences[fieldName]
        .map(item => {
          if (!(this.defaultDisabledFields[fieldName]).includes(item.field)) {
            item.disabled = false;
          }
          return item;
        });
    }
  }

  public addDisabledField(fieldName) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const isDisabledFieldAdded = Object.keys(this.userPreferences[fieldName][0]).includes('disabled');
    if (!isDisabledFieldAdded) {
      this.userPreferences[fieldName] = this.userPreferences[fieldName]
        .map(item => {
          let isDisabled = false;
          if ((this.defaultDisabledFields[fieldName]).includes(item.field)) {
            isDisabled = true;
            item.checked = true;
          }
          item['disabled'] = isDisabled;
          return item;
        });
    }
  }

  public onCustomFieldBlur(): void {
    this.store.dispatch(new CustomFieldsActions.GetCustomFields(this.customFieldsPayload));
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
