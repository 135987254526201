import { createFeatureSelector, createSelector } from '@ngrx/store';

import { TerminalMetaState } from './terminal-meta.state';
import * as TerminalMetaAction from './terminal-meta.actions';

export type Action = TerminalMetaAction.All;

const initialState: TerminalMetaState = {
  loading: false,
  error: false,
  data: [],
  errorData: null,
};

const getTerminalMetaState = createFeatureSelector<TerminalMetaState>('terminalMeta');

export const getErrorData = createSelector(
  getTerminalMetaState,
  state => (state.error ? state.errorData : null),
);

export const getTerminalMetaResponse = createSelector(
  getTerminalMetaState,
  state => state.data.reduce((a, c) => ({...a, [c.terminalCode]: c }), {}),
);

export const getTerminalMetaResponseForTerminal = (terminalCode: string) =>
  createSelector(
    getTerminalMetaState,
    state => state.data.filter(data => data.terminalCode === terminalCode).reduce((acc, curr) => curr, {}),
  );


export const getLoadingState = createSelector(
  getTerminalMetaState,
  state => state.loading,
);

// eslint-disable-next-line @typescript-eslint/naming-convention
export function TerminalMetaReducer(state = initialState, action: Action): TerminalMetaState {
  switch (action.type) {
    case TerminalMetaAction.GET_TERMINAL_META: {
      return { ...state, loading: true, error: false };
    }

    case TerminalMetaAction.GET_TERMINAL_META_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        data: [ ...action.payload ],
      };
    }

    case TerminalMetaAction.GET_TERMINAL_META_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
        errorData: { ...action.payload },
      };
    }

    default:
      return state;
  }
}
