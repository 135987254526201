const PORT_TYPE_DESTINATION = 'Destination';
const PORT_TYPE_ORIGIN = 'Origin';

export const HOUR_LENGTH = 60 * 60 * 1000;
export const MOVING_TIME = 7 * 24 * HOUR_LENGTH;
export const PLUS_SEVEN_DAYS_LABEL = '7+ days';
export const PACIFIC_TIMEZONE_SHIFT = 7;

export enum Position {
  STARTING = 'starting',
  ON_WAY = 'on-way',
  ARRIVED = 'arrived',
}

export enum StatusName {
  INBOUND = 'inbound',
  DOCKED = 'docked',
  WORKING = 'working',
  OUTBOUND = 'outbound',
}

export const portTypes = {
  [StatusName.INBOUND]: PORT_TYPE_DESTINATION,
  [StatusName.DOCKED]: PORT_TYPE_DESTINATION,
  [StatusName.WORKING]: PORT_TYPE_DESTINATION,
  [StatusName.OUTBOUND]: PORT_TYPE_ORIGIN,
};
