import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import {
  DEFAULT_USER_PREFERENCES,
  ICustomFields,
  IUserPreferences,
  IUserPreferencesField,
  IUserPreferencesItem,
} from '@models/shared/user-preferences.model';

@Injectable({
  providedIn: 'root',
})
export class UserPreferencesService {
  public customFieldPayload: ICustomFields = {
    'containerFields': {
      'customOne': 'Custom',
      'customTwo': 'Custom 2',
    },
    'vesselFields': {
      'customOne': 'Custom',
      'customTwo': 'Custom 2',
    },
    'terminalFields': {
      'customOne': 'Custom',
      'customTwo': 'Custom 2',
    },
  };

  public getCustomFieldData(forPage: string) {
    const customField = JSON.parse(localStorage.getItem('orgPreferencesData')) || this.customFieldPayload;
    Object.keys(this.customFieldPayload).forEach(fields => {
      customField[fields] = customField[fields] || this.customFieldPayload[fields];
    });
    return forPage ? customField[forPage] : customField;
  }

  private getCommonColumns(settings: IUserPreferences, fieldName: IUserPreferencesField): string[] {
    const baseFields: string[] = ['select'];

    const displayedColumns: string[] = baseFields.concat(
      settings[fieldName]
        .filter((field: IUserPreferencesItem) => field.checked)
        .map((column: IUserPreferencesItem) => column.field),
    );


    return [...new Set(displayedColumns)];
  }

  public getSelectedColumns(settings: IUserPreferences, fieldName: IUserPreferencesField) {
    const userData = JSON.parse(localStorage.getItem('userPreferencesData'));
    let preferencesData: IUserPreferences = {
      containerFields: [],
      terminalFields: [],
      vesselFields: [],
    };
    if (settings === null) {
      preferencesData = userData === null ? DEFAULT_USER_PREFERENCES : userData;
    } else {
      preferencesData = settings;
    }

    return this.getCommonColumns(preferencesData, fieldName);
  }

  public getColumns$(fieldName: IUserPreferencesField): Observable<string[]> {
    return of(this.getCommonColumns(DEFAULT_USER_PREFERENCES, fieldName));
  }
}
