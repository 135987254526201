export const isDate = (value: any): value is Date =>
    value instanceof Date && !isNaN(+value);

export const isObject = (x: any): x is Object =>
    x !== null && typeof x === 'object';

export const compose = (...fns: Function[]): Function =>
    (initialVal: object | number | []) =>
        fns.reduceRight(
            (acc, fn) => fn(acc), initialVal);

export const isArray = Array.isArray || (<T>(x: any): x is T[] =>
    x && typeof x.length === 'number');

export const identity = <T>(x: T): T => x;

export const getType = (x: any): string =>
    Object.prototype.toString.call(x);
