/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { saveAs } from 'file-saver';

import { ApiCallService } from '@services/api-call.service';
import { ApiQueryBuilder } from '@services/api-query-builder';
import { fileNameGenerator } from '@core/utility/helpers';

import * as DownloadActions from './download.actions';
import { ContainerStatusListService } from '@services/container-status-list.service';

@Injectable()
export class DownloadEffects {

  constructor(
    private actions$: Actions,
    private api: ApiCallService,
    private containerStatusListService: ContainerStatusListService,
  ) {}

  public Download$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<DownloadActions.DownloadCsv>(DownloadActions.DOWNLOAD_CSV),
    mergeMap(action => {
      const payload = {
        filters: action.payload ? action.payload.filters || [] : [],
        orders: action.payload ? action.payload.orders || [] : [],
        size: action.payload.size || 0,
      };
      payload['selectedPendingContainers'] = action.payload ? action.payload.selectedPendingContainers || [] : [];

      return this.api.constructApiCall(
        new ApiQueryBuilder()
          .addName(this.containerStatusListService.getExcelDownloadEndpoint(action.parent))
          .addBody(this.containerStatusListService.getExcelDownloadRequestObjectToSend(action.parent, payload))
          .setIsFile(false)
          .addEntry('responseType', 'blob')
          .addEntry('observe', 'response')
          .build(),
      ).pipe(
        map((data: any) => {
          const fname = fileNameGenerator('containers', 'xlsx');
          saveAs(data.body, fname);
          return new DownloadActions.DownloadSuccess();
        }),
        catchError(() => {
          return of(new DownloadActions.DownloadError());
        }),
      );
    }),
  ),
  );
}
