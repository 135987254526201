import { Action } from '@ngrx/store';


export const SAVE_USER_PREFERENCES = '[User Preferences] SAVE_USER_PREFERENCES';
export const SAVE_USER_PREFERENCES_SUCCESS = '[User Preferences] SAVE_USER_PREFERENCES_SUCCESS';
export const SAVE_USER_PREFERENCES_ERROR = '[User Preferences] SAVE_USER_PREFERENCES_ERROR';
export const CLEAR = '[User Preferences] CLEAR';

export class SaveUserPreferences implements Action {
  public readonly type = SAVE_USER_PREFERENCES;
  constructor(public payload?: any) { }
}

export class SaveUserPreferencesSuccess implements Action {
  public readonly type = SAVE_USER_PREFERENCES_SUCCESS;
  constructor(public payload?: any) { }
}

export class SaveUserPreferencesError implements Action {
  public readonly type = SAVE_USER_PREFERENCES_ERROR;
  constructor(public payload?: any ) { }
}

export class Clear implements Action {
  public readonly type = CLEAR;
}

export type All =
  | Clear
  | SaveUserPreferences
  | SaveUserPreferencesSuccess
  | SaveUserPreferencesError;
