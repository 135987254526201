import { DownloadState } from './download.state';

import * as fromDownloadActions from './download.actions';
import { createFeatureSelector, createSelector } from '@ngrx/store';

const initialState: DownloadState = {
  loading: false,
  error: false,
};

export type Action = fromDownloadActions.All;

const downloadState = createFeatureSelector<DownloadState>('download');

export const getDownloadState = createSelector(
  downloadState,
  state => state.loading,
);

// eslint-disable-next-line @typescript-eslint/naming-convention
export function DownloadReducer(state = initialState, action: Action): DownloadState {
  switch (action.type) {
    case fromDownloadActions.DOWNLOAD_CSV: {
      return {
        ...state,
        loading: true,
      };
    }

    case fromDownloadActions.DOWNLOAD_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }

    case fromDownloadActions.DOWNLOAD_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
      };
    }

    default:
      return state;
  }
}
