import { Action } from '@ngrx/store';

export const FORGET_PASSWORD = '[ForgetPassword] FORGET_PASSWORD';
export const FORGET_PASSWORD_SUCCESS = '[ForgetPassword] FORGET_PASSWORD_SUCCESS';
export const FORGET_PASSWORD_ERROR = '[ForgetPassword] FORGET_PASSWORD_ERROR';

export const SET_PASSWORD = '[ForgetPassword] SET_PASSWORD';
export const SET_PASSWORD_SUCCESS = '[ForgetPassword] SET_PASSWORD_SUCCESS';
export const SET_PASSWORD_ERROR = '[ForgetPassword] SET_PASSWORD_ERROR';

export const CLEAR_FORGET_PASSWORD_STATE = '[ForgetPassword] CLEAR_FORGET_PASSWORD_STATE';

export class ForgetPassword implements Action {
  public readonly type = FORGET_PASSWORD;
  constructor(public payload: any = null) { }
}

export class ForgetPasswordSuccess implements Action {
  public readonly type = FORGET_PASSWORD_SUCCESS;
  constructor(public payload: any = null) { }
}

export class ForgetPasswordError implements Action {
  public readonly type = FORGET_PASSWORD_ERROR;
  constructor (public payload: any) { }
}

export class SetPassword implements Action {
  public readonly type = SET_PASSWORD;
  constructor(public payload: any = null) { }
}

export class SetPasswordSuccess implements Action {
  public readonly type = SET_PASSWORD_SUCCESS;
  constructor(public payload: any = null) { }
}

export class SetPasswordError implements Action {
  public readonly type = SET_PASSWORD_ERROR;
  constructor (public payload: any) { }
}

export class ClearForgetPasswordState implements Action {
  public readonly type = CLEAR_FORGET_PASSWORD_STATE;
  constructor (public payload?: any) { }
}

export type All =
  | ForgetPassword
  | ForgetPasswordSuccess
  | ForgetPasswordError
  | SetPassword
  | SetPasswordSuccess
  | SetPasswordError
  | ClearForgetPasswordState;
