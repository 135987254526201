import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { mergeMap, map, catchError} from 'rxjs/operators';
import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { ApiQueryBuilder } from '@services/api-query-builder';

import * as WaitTimeAction from './wait-time.actions';
import { ApiCallService } from '@services/api-call.service';
import { WaitTimeService } from '@app/modules/dashboard/services/wait-time.service';

@Injectable()
export class WaitTimeEffects {

  constructor(
    private actions$: Actions,
    private api: ApiCallService,
    private waitTimeService: WaitTimeService,
  ) { }

  public GetWaitTime$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType<WaitTimeAction.WaitTime>(WaitTimeAction.GET_WAIT_TIME),
      mergeMap(() => {
        return this.api.constructApiCall(
          new ApiQueryBuilder()
            .addName('terminalWaitTime')
            .addBody(this.waitTimeService.getRequestBody())
            .build(),
        )
        .pipe(
          map(data => {
            return new WaitTimeAction.WaitTimeSuccess(this.waitTimeService.parseWaitTimeResponse(data));
          }),
          catchError(error => {
            return of(new WaitTimeAction.WaitTimeError(error));
          }),
        );
      }),
    ),
    );
}
