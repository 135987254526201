import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { mergeMap, map, catchError} from 'rxjs/operators';
import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { ApiQueryBuilder } from '@services/api-query-builder';

import * as GateHoursAction from './gate-hours.actions';
import { ApiCallService } from '@services/api-call.service';
import { GateHoursService } from '@app/modules/dashboard/services/gate-hours.service';

@Injectable()
export class GateHoursEffects {

  constructor(
    private actions$: Actions,
    private api: ApiCallService,
    private gateHoursService: GateHoursService,
  ) { }

  public GetGateHours$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType<GateHoursAction.GateHours>(GateHoursAction.GET_GATE_HOURS),
      mergeMap(() => {
        return this.api.constructApiCall(
          new ApiQueryBuilder()
            .addName('gateHours')
            .addBody(this.gateHoursService.createRequestPayload())
            .build(),
        )
        .pipe(
          map(data => {
            return new GateHoursAction.GateHoursSuccess(this.gateHoursService.parseGateHoursResponse(data));
          }),
          catchError(error => {
            return of(new GateHoursAction.GateHoursError(error));
          }),
        );
      }),
    ),
    );
}
