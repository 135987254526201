import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { OktaAuthGuard, OktaCallbackComponent } from '@okta/okta-angular';
import { LoggedInGuard } from './core/guards/loggedIn.guard';

export const ROUTES: Routes = [
  {
    path: '',
    loadChildren: () => import('./modules/landing/landing.module').then(m => m.LandingModule),
    pathMatch: 'full',
  },
  {
    path: 'login/callback',
    component: OktaCallbackComponent,
  },
  {
    path: '',
    loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [OktaAuthGuard, LoggedInGuard],
  },
/*   {
    path: 'container/overview',
    loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule),
  }, */
  {
    path: 'register',
    loadChildren: () => import('./modules/landing/pages/register/register.module').then(m => m.RegisterModule),
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./modules/landing/pages/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule),
  },
];


@NgModule({
  imports: [
    RouterModule.forRoot(
      ROUTES,
      {
        // enableTracing: true, // <-- debugging purposes only
        preloadingStrategy: PreloadAllModules,
        scrollPositionRestoration: 'enabled',
      }),
  ],
  exports: [
    RouterModule,
  ],
})
export class AppRoutingModule { }
