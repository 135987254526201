import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { Action, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { ApiQueryBuilder } from '@services/api-query-builder';
import * as SearchActions from './search.actions';
import { ApiCallService } from '@services/api-call.service';
import { FeatureFlagService } from '@app/core/services/feature-flag.service';
import { Angulartics2 } from 'angulartics2';
import * as DialogActions from '@store/modal/modal.actions';
import { State } from '@store/search/search.state';
import { SearchService } from '@services/search.service';

@Injectable()
export class SearchEffects {
  public countVal: string;
  public incrementVal: any;
  public features = {
    countReachedModal: this.featureFlags.featureEnabled('clickCountModal'),
  };
  constructor(
    private actions$: Actions,
    private api: ApiCallService,
    private analytics: Angulartics2,
    private store: Store<State>,
    private featureFlags: FeatureFlagService,
    private searchService: SearchService,
  ) { }

  public GetSearchResults$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType<SearchActions.GetSearchResults>(SearchActions.GET_SEARCH_RESULTS),
      mergeMap(action => {
        return this.api.constructApiCall(
          new ApiQueryBuilder()
            .addName(this.searchService.getEndpoint())
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            .addQuery(this.searchService.addRequestQuery(action.payload.containerOrBolNumber)),
        )
        .pipe(
          map((data: any[]) => {
            data = this.searchService.parseSearchResponse(data);
            if (data.length) {
              if (this.features.countReachedModal) {
                this.incrementVal = 0;
                this.countVal = localStorage.getItem('countItem');
                if (this.countVal == null || this.countVal === '0') {
                  localStorage.setItem('countItem', '1');
                } else {
                  this.incrementVal = Number(localStorage.getItem('countItem')) + 1;
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                  localStorage.setItem('countItem', this.incrementVal);
                }
                if (this.incrementVal <= 5) {
                  return new SearchActions.GetSearchResultsSuccess(data);
                } else {
                  localStorage.setItem('countItem', '0');
                  this.analytics.eventTrack.next({
                    action: 'dialog-open',
                    properties: {
                      category: 'prompt-popup',
                    },
                  });
                  this.store.dispatch(
                    new DialogActions.OpenDialog({
                      dialogType: 'PromptModalComponent',
                    }),
                  );
                  return new SearchActions.GetSearchResultsSuccess(data);
                }
              } else {
                return new SearchActions.GetSearchResultsSuccess(data);
              }
            } else {
              return new SearchActions.GetSearchResultsError('No result');
            }
          }),
          catchError(error => {
            return of(new SearchActions.GetSearchResultsError(error.error.constraintViolations[0].message || 'Server error.'));
          }),
        );
      }),
    ),
    );
}
