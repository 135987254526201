import { compose } from '@core/utility/FP.utility';
import { UserAuthenticateSuccess } from './user.actions';

import { DEFAULT_USER_PREFERENCES, IUserPreferences } from '@models/shared/user-preferences.model';
import { menuNamesEnum, menuOptions } from '@models/shared/landing-page.constants';

declare let ga: any;
type Preferences = IUserPreferences & { id: string|null, idUser: string|null, landingPage: string|null };

const doFieldsDiffer = (fieldA: string[], fieldB: string[]): boolean => JSON.stringify(fieldA) !== JSON.stringify(fieldB);

function storeUser(data: any): UserAuthenticateSuccess {

  
  if (data.featureFlag.userPreferences) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    let preferences: Preferences = Object.values(data.userResponse.pottDetails.userPreferencesObject || {}).length
      ? { ...data.userResponse.pottDetails.userPreferencesObject }
      : null;

    const menusArray: string[] = [];

    menuOptions[0].children.forEach(mainMenu => {
      mainMenu.children.forEach(childMenu => {
        if (childMenu.isAllowed) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
          const title = childMenu.title === menuNamesEnum.OVERVIEW
            ? mainMenu.title
            : childMenu.title;
          menusArray.push(title);
        }
      });
    });

    const landingPage: string = preferences && preferences.landingPage || menusArray[0];
    const customFieldsData = data.userResponse.pottDetails.orgPreferences;

    if (preferences) {
      // each field value is unique, so there's no need to compare headers
      const apiContainerFields = preferences.containerFields && preferences.containerFields.length
        ? preferences.containerFields.map(item => item.field).sort() : [];
      const apiTerminalFields = preferences.terminalFields && preferences.terminalFields.length
        ? preferences.terminalFields.map(item => item.field).sort() : [];
      const apiVesselFields = preferences.vesselFields && preferences.vesselFields.length
        ? preferences.vesselFields.map(item => item.field).sort() : [];
      const defaultContainerFields = DEFAULT_USER_PREFERENCES.containerFields.map(item => item.field).sort();
      const defaultTerminalFields = DEFAULT_USER_PREFERENCES.terminalFields.map(item => item.field).sort();
      const defaultVesselFields = DEFAULT_USER_PREFERENCES.vesselFields.map(item => item.field).sort();

      preferences = {
        ...preferences,
        containerFields: doFieldsDiffer(apiContainerFields, defaultContainerFields)
          ? [...DEFAULT_USER_PREFERENCES.containerFields]
          : [...preferences.containerFields],
        terminalFields: doFieldsDiffer(apiTerminalFields, defaultTerminalFields)
          ? [...DEFAULT_USER_PREFERENCES.terminalFields]
          : [...preferences.terminalFields],
        vesselFields: doFieldsDiffer(apiVesselFields, defaultVesselFields)
          ? [...DEFAULT_USER_PREFERENCES.vesselFields]
          : [...preferences.vesselFields],
      };
    } else {
      preferences = { ...DEFAULT_USER_PREFERENCES, id: null, idUser: null, landingPage: null };
    }

    localStorage.setItem('userPreferencesData', JSON.stringify(preferences));
    localStorage.setItem('landingPage', landingPage);
    localStorage.setItem('orgPreferencesData', JSON.stringify(customFieldsData));

    // eslint-disable-next-line no-prototype-builtins
    if ((data.userResponse.hasOwnProperty('pottDetails')) &&
          // eslint-disable-next-line no-prototype-builtins
          (data.userResponse.pottDetails.hasOwnProperty('rejectedContainers'))) {
      localStorage.setItem('rejectedContainers', JSON.stringify(data.userResponse.pottDetails.rejectedContainers));
    } else {
      localStorage.setItem('rejectedContainers', JSON.stringify([]));
    }
  }

  return new UserAuthenticateSuccess(data);
}

function setAnaliticsUserId(data: any): any {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const userId = JSON.parse(sessionStorage['okta-token-storage']).idToken?.claims;
  ga('set', 'userId', userId);
  ga('send', 'pageview');

  return data;
}

function validatePersona(data: any): any {
  const allowedPersonas: string[] = ['Port_Super', 'BCO', 'Trucker'];

  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  return allowedPersonas.includes(data.userResponse.persona)
    ? data
    : throwError();
}

function throwError() {
  throw { error: { errorMessage: 'You are not authorized to access Track & Trace.' } };
}

export function removeUser(features: { userPreferences: boolean }): void {
  sessionStorage.removeItem('token');
  sessionStorage.removeItem('user');
  localStorage.removeItem('token');
  localStorage.removeItem('user');

  if (features.userPreferences) {
    localStorage.removeItem('userPreferencesData');
    localStorage.removeItem('landingPage');
  }
}

export const handleLogin = compose(
  storeUser,
  setAnaliticsUserId,
  validatePersona,
);
