import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { MAT_RIPPLE_GLOBAL_OPTIONS } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatMenuModule } from '@angular/material/menu';
import { environment } from '@env/environment';
import {
  OKTA_CONFIG,
  OktaAuthModule,
  OktaConfig,
  OktaAuthGuard,
} from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { Angulartics2Module } from 'angulartics2';
import { BemModule } from '@app/shared/directives/bem.directive'

import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from '@shared/shared.module';
import { ModalModule } from '@shared/modal.module';
import { ToastModule } from '@shared/components/toast/toast.module';

import { UserReducer } from '@store/user/user.reducer';
import { UniversalSearchReducer } from '@store/universal-search/universal-search.reducer';
import { GlobalErrorReducer } from '@store/global-error/global-error.reducer';
import { DownloadReducer } from '@store/download/download.reducer';
import { TermsOfUseReducer } from '@store/terms-of-use/terms-of-use.reducer';
import { RoutingReducer } from '@store/routing/routing.reducer';
import { VesselReducer } from '@store/vessel/vessel.reducer';

import { logout } from '@store/app.metareducer';

import { CustomFieldsEffect } from '@store/custom-fields/custom-fields.effects';
import { SearchEffects } from '@store/search/search.effects';
import { UserEffects } from '@store/user/user.effects';
import { DashboardEffects } from '@store/dashboard/dashboard.effects';
import { DetailsEffects } from '@store/details/details.effects';
import { RegisterEffects } from '@store/register/register.effects';
import { ModalEffects } from '@store/modal/modal.effects';
import { ChartEffects } from '@store/chart/chart.effects';
import { LandingPageSettingsEffects } from '@store/landing-page-settings/landing-page-settings.effects';
import { UniversalSearchEffects } from '@store/universal-search/universal-search.effects';
import { AddContainersEffect } from '@store/add-containers/add-containers.effects';
import { DownloadEffects } from '@store/download/download.effects';
import { FeedbackEffects } from '@store/feedback/feedback.effects';
import { NotificationsEffect } from '@store/notification/notification.effects';
import { NotificationSettingsEffect } from '@store/notification-settings/notification-settings.effects';
import { InterestedPartiesEffects } from '@store/interested-parties/interested-parties.effects';
import { ForgetPasswordEffects } from '@store/forget-password/forget-password.effects';
import { RoutingEffects } from '@store/routing/routing.effects';
import { HoldCodesEffects } from '@store/hold-codes/hold-codes.effects';
import { VesselEffects } from '@store/vessel/vessel.effects';
import { UserPreferencesEffects } from '@store/user-preferences/user-preferences.effects';
import { EmptiesReturnEffects } from '@store/empties-return/empties-return.effects';
import { ChartAvailabilityEffects } from '@store/container-chart-availability/chart-availability.effects';
import { ContainerFilterEffects } from '@store/container-filter/container-filter.effects';
import { EmptiesHistoricalEffects } from '@store/empties-historical/empties-historical.effects';
import { RequestTokenInterceptor } from '@core/interceptors/request-token.interceptor';

import { ModalContainerComponent } from '@shared/components/modal-container/modal-container.component';
import { AppComponent } from './app.component';
import { UnclaimEffect } from '@store/unclaim/unclaim.effects';
import { UnclaimReducer } from '@store/unclaim/unclaim.reducer';
import { CustomFieldsReducer } from '@store/custom-fields/custom-fields.reducer';
import { AddContainersReducer } from '@store/add-containers/add-containers.reducer';
import { UserPreferencesReducer } from '@store/user-preferences/user-preferences.reducer';
import { NotificationSettingsReducer } from '@store/notification-settings/notification-settings.reducer';
import { ContainerFilterReducer } from '@store/container-filter/container-filter.reducer';

import { AcknowledgeContainersReducer } from '@store/acknowledge-containers/acknowledge-containers.reducer';
import { AcknowledgeContainersEffect } from '@store/acknowledge-containers/acknowledge-containers.effects';
import { NotificationTypesReducer } from '@store/notification-types/notification-types.reducer';
import { NotificationTypesEffect } from '@store/notification-types/notification-types.effects';
import { WaitTimeReducer } from '@store/wait-time/wait-time.reducer';
import { WaitTimeEffects } from '@store/wait-time/wait-time.effects';
import { TerminalMetricsReducer } from '@store/terminal-metrics/terminal-metrics.reducer';
import { TerminalMetricsEffects } from '@store/terminal-metrics/terminal-metrics.effects';
import { GateHoursReducer } from '@store/gate-hours/gate-hours.reducer';
import { GateHoursEffects } from '@store/gate-hours/gate-hours.effects';
import { ChassisCountReducer } from '@store/chassis-count/chassis-count.reducer';
import { ChassisCountEffects } from '@store/chassis-count/chassis-count.effects';
import { EmptiesReturnReducer } from '@store/empties-return/empties-return.reducer';
import { TerminalMetaReducer } from '@store/terminal-meta/terminal-meta.reducer';
import { TerminalMetaEffects } from '@store/terminal-meta/terminal-meta.effects';
import { EmptiesHistoricalReducer } from '@store/empties-historical/empties-historical.reducer';
import { IntermodalEffects } from '@store/intermodal/intermodal.effects';
import { IntermodalReducer } from '@store/intermodal/intermodal.reducer';
import { BannerReducer } from './store/banner/banner.reducer';
import { IntermodalClaimReducer } from '@store/intermodal-claim/intermodal-claim.reducer';
import { IntermodalClaimEffects } from '@store/intermodal-claim/intermodal-claim.effects';
import { MaterialModule } from './shared/material.module';
import { LoggedInGuard } from './core/guards/loggedIn.guard';
import { FlexLayoutModule } from '@angular/flex-layout';

BemModule.config({
  separators: ['-', '_', ''],
  modCase: 'kebab',
  ignoreValues: false,
});


const META_REDUCERS = [
  logout,
];
const oktaAuth = new OktaAuth(environment.oidc);
const moduleConfig: OktaConfig = { oktaAuth };

const REDUCERS = {
  'user': UserReducer,
  'error': GlobalErrorReducer,
  'universal-search': UniversalSearchReducer,
  'download': DownloadReducer,
  'terms-of-use': TermsOfUseReducer,
  'routing': RoutingReducer,
  'vessel': VesselReducer,
  'unclaim': UnclaimReducer,
  'customFields': CustomFieldsReducer,
  'addContainers': AddContainersReducer,
  'userPreferences': UserPreferencesReducer,
  'notificationSettings': NotificationSettingsReducer,
  'notificationTypes': NotificationTypesReducer,
  'acknowledgeContainers': AcknowledgeContainersReducer,
  'containerFilter': ContainerFilterReducer,
  'waitTime': WaitTimeReducer,
  'terminalMetrics': TerminalMetricsReducer,
  'gateHours': GateHoursReducer,
  'chassisCount': ChassisCountReducer,
  'empties-return': EmptiesReturnReducer,
  'terminalMeta': TerminalMetaReducer,
  'emptiesHistorical': EmptiesHistoricalReducer,
  'intermodal': IntermodalReducer,
  'intermodalClaim': IntermodalClaimReducer,
  'banner': BannerReducer,
};

const EFFECTS = [
  SearchEffects,
  UserEffects,
  ForgetPasswordEffects,
  DashboardEffects,
  DetailsEffects,
  RegisterEffects,
  ModalEffects,
  ChartEffects,
  UniversalSearchEffects,
  AddContainersEffect,
  UnclaimEffect,
  CustomFieldsEffect,
  DownloadEffects,
  FeedbackEffects,
  NotificationsEffect,
  NotificationSettingsEffect,
  NotificationTypesEffect,
  InterestedPartiesEffects,
  RoutingEffects,
  HoldCodesEffects,
  VesselEffects,
  UserPreferencesEffects,
  ChartAvailabilityEffects,
  LandingPageSettingsEffects,
  AcknowledgeContainersEffect,
  ContainerFilterEffects,
  WaitTimeEffects,
  TerminalMetricsEffects,
  GateHoursEffects,
  ChassisCountEffects,
  EmptiesReturnEffects,
  TerminalMetaEffects,
  EmptiesHistoricalEffects,
  IntermodalEffects,
  IntermodalClaimEffects,
];

@NgModule({ declarations: [
        AppComponent,
        ModalContainerComponent,
    ],
    bootstrap: [
        AppComponent,
    ],
    exports: [
        BemModule,
    ], imports: [BemModule,
        BrowserModule,
        StoreModule.forRoot(REDUCERS, { metaReducers: META_REDUCERS }),
        EffectsModule.forRoot(EFFECTS),
        StoreDevtoolsModule.instrument({
            name: 'POTT Dev tools',
            maxAge: 200,
            logOnly: environment.production,
        }),
        AppRoutingModule,
        SharedModule,
        BrowserAnimationsModule,
        MatDialogModule,
        MaterialModule,
        MatProgressSpinnerModule,
        OktaAuthModule.forRoot(moduleConfig),
        ModalModule,
        ToastModule.forRoot(),
        Angulartics2Module.forRoot(),
        FlexLayoutModule,
        MatMenuModule], providers: [
        { provide: HTTP_INTERCEPTORS, useClass: RequestTokenInterceptor, multi: true },
        { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: { disabled: true } },
        { provide: OKTA_CONFIG, useValue: { oktaAuth } },
        OktaAuthGuard, LoggedInGuard,
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule { }
