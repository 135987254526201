import { IContainerStatus } from '@models/dashboard/container-status.model';
import { ActionCreator, ActionType, on, ReducerTypes } from '@ngrx/store';
import { produce, enableMapSet } from 'immer';

export const getAvailabilityText = ({ isAvailableForPickup = null, data = null }): string => {
  let availability = '';

  if (isAvailableForPickup !== null && isAvailableForPickup !== undefined) {
    // For outgated containers skip availability and holds
    if (data !== null && data !== undefined && data.outgatedTime != null && data.outgatedTime !== undefined && data.outgatedTime > 0) {
      return availability;
    } else if (isAvailableForPickup) {
      availability = 'Available';
      if (data && data.holds !== 'NONE') {
        availability += ' with holds';
      }
    } else {
      availability = 'Not available';
    }
  }

  return availability;
};

export const getHolds = ({ holds }): string => {
  if (holds === 'NONE') {
    return '-';
  } else {
    return holds;
  }
};

export const doContainerListsMatch = (x: IContainerStatus[], y: IContainerStatus[]): boolean => {
  if (!x.length || !y.length) {
    return false;
  }

  if ((x && x.length) !== (y && y.length)) {
    return false;
  }

  const length = x.length - 1;

  if (
    x[0].containerNumber !== y[0].containerNumber &&
    x[Math.ceil(length / 2)].containerNumber !== y[Math.ceil(length / 2)].containerNumber &&
    x[length].containerNumber !== y[length].containerNumber
  ) {
    return false;
  }

  return true;
};

export function fileNameGenerator(filePrefix: string, extension: string): string {
  const date: Date = new Date();
  const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
  const day = date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`;
  const hours = date.getHours() < 10 ? `0${date.getHours()}` : `${date.getHours()}`;
  const mins = date.getMinutes() < 10 ? `0${date.getMinutes()}` : `${date.getMinutes()}`;
  return `${filePrefix}_${date.getFullYear()}-${month}-${day}_${hours}-${mins}.${extension}`;
}

export function resetTimezoneOffset(date: number) {
  return new Date(new Date(date).getTime() + new Date(date).getTimezoneOffset() * 60 * 1000);
}

export function isValidContainerNumber(containerNumber: string = ''): boolean {
  const regex = new RegExp('^[A-Z]{3}U[0-9]{6,7}$');
  return !!(regex.exec(containerNumber));
}

export const arrayMatch = (arr1: string[], arr2: string[]): boolean => arr1.reduce((result: boolean, value: string) => arr2.includes(value) && result, true);

enableMapSet();
 
export function produceOn<State, Creators extends ActionCreator>(
  creators: Creators,
  reducer: (state: State, action: ActionType<Creators>) => void,
): ReducerTypes<State, Creators[]> {
  return on(
    creators,
    (state, action) => produce(state, (draft) => reducer(draft as State, action)) as State,
  ) as unknown as ReducerTypes<State, Creators[]>;
}
 
