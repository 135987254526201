import { Action } from '@ngrx/store';

export const FETCH_METRICS = '[TerminalMetrics] FETCH_METRICS';
export const FETCH_METRICS_SUCCESS = '[TerminalMetrics] FETCH_METRICS_SUCCESS';
export const FETCH_METRICS_ERROR = '[TerminalMetrics] FETCH_METRICS_ERROR';

export const UPDATE_FILTERS = '[TerminalMetrics] UPDATE_FILTERS';

export class FetchMetrics implements Action {
  public readonly type = FETCH_METRICS;
  constructor(public payload: any = null) { }
}

export class FetchMetricsSuccess implements Action {
  public readonly type = FETCH_METRICS_SUCCESS;
  constructor(public payload: any = null) { }
}

export class FetchMetricsError implements Action {
  public readonly type = FETCH_METRICS_ERROR;
  constructor(public payload: any = null) { }
}

export class UpdateFilters implements Action {
  public readonly type = UPDATE_FILTERS;
  constructor(public payload: string[] = null) { }
}

export type All = FetchMetrics | FetchMetricsSuccess | FetchMetricsError | UpdateFilters;
