import { Component, EventEmitter, OnDestroy, OnInit, Output, ChangeDetectionStrategy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { MatCheckbox } from '@angular/material/checkbox';
import { Subscription } from 'rxjs';

import * as notificationSettings from '@store/notification-settings/notification-settings.reducer';
import * as notificationTypes from '@store/notification-types/notification-types.reducer';
import * as notificationSettingsActions from '@store/notification-settings/notification-settings.actions';
import * as notificationTypesActions from '@store/notification-types/notification-types.actions';
import {
  Alert,
  AlertTypeEnum,
  AlertTypeNotificationDaysCountEnum,
} from '@store/notification-settings/notification-settings.state';
import { IUserPreferenceModalMessage, ModalTypes } from '@app/shared/models/shared/user-preferences.model';

function isLastFreeDay(type: string): boolean {
  return type === AlertTypeEnum.LAST_FREE_DAY_NOTIFICATION.toString();
}

function isDwell(type: string): boolean {
  return type === AlertTypeEnum.DWELL_NOTIFICATION.toString();
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-user-preferences-notification',
  templateUrl: './user-preferences-notification.component.html',
  styleUrls: ['./user-preferences-notification.component.css'],
})
export class UserPreferencesNotificationComponent implements OnInit, OnDestroy {
  @Output() public closeEvent = new EventEmitter<void>();
  private subscriptions: Subscription = new Subscription();
  @Output() public applyButtonStatusEvent = new EventEmitter<IUserPreferenceModalMessage>();

  public notificationIsValid: boolean = true;
  public dwellAlertTypeErrorMsg: boolean;
  public lastFreeDayAlertTypeErrorMsg: boolean;
  public lastFreeDayInAppSelected: boolean;
  public dwellInAppSelected: boolean;
  public notificationSettingsForm: FormGroup;
  public types = Object.keys(AlertTypeEnum);
  public typeIds = {};
  public formDirty = false;
  public formError = false;

  // eslint-disable-next-line @typescript-eslint/ban-types
  constructor(private fb: FormBuilder, private store: Store<{}>) {
  }

  public ngOnInit(): void {
    this.initForm();

    this.subscriptions.add(
      this.store.pipe(select(notificationSettings.getAlerts))
        .subscribe(alerts => alerts.forEach(alert => this.fillFormGroup(alert))));

    this.subscriptions.add(
      this.store.pipe(select(notificationTypes.getAlertTypes))
        .subscribe(alertTypes => {
          this.typeIds = {};
          alertTypes.forEach(alertType => this.typeIds[alertType.name] = alertType.idAlertType);
        }));

    this.store.dispatch(new notificationSettingsActions.FetchAlerts());
    this.store.dispatch(new notificationTypesActions.FetchAlertTypes());

    this.notificationSettingsForm.statusChanges.subscribe((status: string) => {
      this.formError = this.hasFormError();
      this.applyButtonStatusEvent.emit({
        from: ModalTypes.NOTIFICATION,
        status: status === 'VALID' && this.formDirty,
      });
    });
  }

  public initForm(): void {
    const buildObj = {};

    Object.keys(AlertTypeEnum).forEach(type => {
      const configFormBuildObj = {
        subscribe: [null],
        email: [null],
      };

      if (isDwell(type)) {
        configFormBuildObj['dwellDaysCount'] = [null];
      } else if (isLastFreeDay(type)) {
        configFormBuildObj['daysBeforeLastFreeDayCount'] = [null];
      }

      buildObj[type] = this.fb.group(configFormBuildObj);
    });

    this.notificationSettingsForm = this.fb.group(buildObj);

    this.notificationSettingsForm.addControl('ownerBased', new FormControl());

    this.subscriptions.add(
      this.notificationSettingsForm.valueChanges.subscribe(() => {
        const dwellNotification = this.notificationSettingsForm.get('DWELL_NOTIFICATION');
        const lastFreeDayNotification = this.notificationSettingsForm.get('LAST_FREE_DAY_NOTIFICATION');

        this.notificationIsValid = this.notificationSettingsForm.valid;
        this.dwellInAppSelected = dwellNotification.get('subscribe').value;
        this.lastFreeDayInAppSelected = lastFreeDayNotification.get('subscribe').value;
        this.dwellAlertTypeErrorMsg = dwellNotification.get('dwellDaysCount').valid;
        this.lastFreeDayAlertTypeErrorMsg = lastFreeDayNotification.get('daysBeforeLastFreeDayCount').valid;
      }),
    );
    this.formDirty = false;
    this.formError = false;
    this.notificationSettingsForm.reset();
    this.types.forEach(formType => this.notificationSettingsForm.get(formType).reset());

    this.applyButtonStatusEvent.emit({
      from: ModalTypes.NOTIFICATION,
      status: false,
    });
  }

  public onCloseForm(): void {
    this.closeEvent.emit();
    this.store.dispatch(new notificationSettingsActions.ClearAlertsSave());
  }

  public submitChanges(): void {
    const alertConfigList = [];

    const isOwnerBased = this.notificationSettingsForm.get('ownerBased').value;

    Object.keys(AlertTypeEnum).forEach(type => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      alertConfigList.push(this.buildAlertConfig(type, isOwnerBased));
    });

    this.store.dispatch(new notificationSettingsActions.SetAlerts({ body: alertConfigList }));
    this.closeEvent.emit();
  }

  public onEmailChange(emailCheckbox: MatCheckbox, subscribeCheckbox: MatCheckbox, type: string) {
    this.formDirty = true;
    if (emailCheckbox.checked && !subscribeCheckbox.checked) {
      subscribeCheckbox.checked = true;
      this.notificationSettingsForm.get(type).get('subscribe').patchValue(true, { emitEvent: true });
      if ((isDwell(type) || isLastFreeDay(type))) {
        this.setRequiredValidators(subscribeCheckbox.checked, type);
      }
    }
    this.formError = this.hasFormError();
    this.applyButtonStatusEvent.next({
      from: ModalTypes.NOTIFICATION,
      status: !this.formError,
    });
  }

  public onInAppChange(emailCheckbox: MatCheckbox, subscribeCheckbox: MatCheckbox, type: string) {
    this.formDirty = true;
    if ((isDwell(type) || isLastFreeDay(type))) {
      this.setRequiredValidators(subscribeCheckbox.checked, type);
    }
    if (!subscribeCheckbox.checked && emailCheckbox.checked) {
      emailCheckbox.checked = false;
      this.notificationSettingsForm.get(type).get('email').patchValue(false, { emitEvent: true });
    }
    this.formError = this.hasFormError();
    this.applyButtonStatusEvent.next({
      from: ModalTypes.NOTIFICATION,
      status: !this.formError,
    });
  }

  private hasFormError = () => this.formError = !this.types
    .map(formType => this.notificationSettingsForm.get(formType).status === 'VALID')
    .reduce((res: boolean, fstate: boolean) => res = res ? fstate : false, true)

  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  public onOwnerToggle = (_: any) => {
    this.formDirty = true;

    this.formError = this.hasFormError();

    this.applyButtonStatusEvent.emit({
      from: ModalTypes.NOTIFICATION,
      status: !this.formError,
    });
  }

  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  public onDayChanged = (elem: any) => {
    this.formError = this.hasFormError();
    this.formDirty = true;
    this.applyButtonStatusEvent.next({
      from: ModalTypes.NOTIFICATION,
      status: !this.formError,
    });
  }

  private setRequiredValidators(addValidators, type) {
    const validators = [
      Validators.required,
      Validators.pattern('^([1-9]|[0-9][1-9]|[1-9][0-9])$'),
    ];
    const typeFormGroup = this.notificationSettingsForm.get(type);

    if (addValidators) {
      typeFormGroup.get(AlertTypeNotificationDaysCountEnum[type]).setValidators(validators);
      typeFormGroup.get(AlertTypeNotificationDaysCountEnum[type]).enable();
    } else {
      typeFormGroup.get(AlertTypeNotificationDaysCountEnum[type]).clearValidators();
      typeFormGroup.get(AlertTypeNotificationDaysCountEnum[type]).setValue('');
      typeFormGroup.get(AlertTypeNotificationDaysCountEnum[type]).disable();
    }
    typeFormGroup.get(AlertTypeNotificationDaysCountEnum[type]).updateValueAndValidity();
    typeFormGroup.get(AlertTypeNotificationDaysCountEnum[type]).markAsDirty();
  }

  public buildAlertConfig(type: string, isOwnerBased: boolean = false): any {
    const typeFormGroup = this.notificationSettingsForm.get(type);

    const alertConfig = {
      active: true,
      configuration: {
        pinned: '',
        subscribe: typeFormGroup.get('subscribe').value,
        notificationType: typeFormGroup.get('email').value ? ['EMAIL'] : [],
        onlyOwner: isOwnerBased,
      },
      alertTypeId: this.typeIds[type],
    };

    if (isDwell(type)) {
      alertConfig['configuration']['dwellDaysCount'] = typeFormGroup.get('dwellDaysCount').value;
    }
    if (isLastFreeDay(type)) {
      alertConfig['configuration']['daysBeforeLastFreeDayCount'] = typeFormGroup.get('daysBeforeLastFreeDayCount').value;
    }
    return alertConfig;
  }

  public fillFormGroup(alert: Alert): void {
    const type = alert.alertTypeEnum;
    const config = JSON.parse(alert.configuration);
    let inputFieldType = '';

    this.notificationSettingsForm.get('ownerBased').setValue(config.onlyOwner);

    const typeFormGroup = this.notificationSettingsForm.get(type.toString());
    if (typeFormGroup) {
      typeFormGroup.get('subscribe').patchValue(config['subscribe'], { emitEvent: false });
      typeFormGroup.get('email').patchValue(config['notificationType'].indexOf('EMAIL') > -1, { emitEvent: false });

      if (isDwell(type)) {
        inputFieldType = 'dwellDaysCount';
        typeFormGroup.get('dwellDaysCount').patchValue(config['dwellDaysCount'], { emitEvent: false });
      }

      if (isLastFreeDay(type)) {
        inputFieldType = 'daysBeforeLastFreeDayCount';
        typeFormGroup.get('daysBeforeLastFreeDayCount').patchValue(config['daysBeforeLastFreeDayCount'], { emitEvent: false });
      }

      if (inputFieldType !== '') {
        if (typeFormGroup.get('subscribe').value) {
          this.setRequiredValidators(true, type);
        } else {
          typeFormGroup.get(inputFieldType).disable();
        }
      }
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
