import { Action } from '@ngrx/store';

export const CONTAINER_AVAILABILITY_COUNT = '[Container] CONTAINER_AVAILABILITY_COUNT';
export const CONTAINER_AVAILABILITY_COUNT_SUCCESS = '[Container] CONTAINER_AVAILABILITY_COUNT_SUCCESS';
export const CONTAINER_AVAILABILITY_COUNT_ERROR = '[Container] CONTAINER_AVAILABILITY_COUNT_ERROR';

export const CONTAINER_HOLDS_COUNT = '[Container] CONTAINER_HOLDS_COUNT';
export const CONTAINER_HOLDS_COUNT_SUCCESS = '[Container] CONTAINER_HOLDS_COUNT_SUCCESS';
export const CONTAINER_HOLDS_COUNT_ERROR = '[Container] CONTAINER_HOLDS_COUNT_ERROR';

export class ContainerAvailabilityCount implements Action {
  public readonly type = CONTAINER_AVAILABILITY_COUNT;
  constructor(public payload: any = null) {}
}

export class ContainerAvailabilityCountSuccess implements Action {
  public readonly type = CONTAINER_AVAILABILITY_COUNT_SUCCESS;
  constructor(public payload: any = null) {}
}

export class ContainerAvailabilityCountError implements Action {
  public readonly type = CONTAINER_AVAILABILITY_COUNT_ERROR;
  constructor(public payload: any = null) {}
}

export class ContainerHoldsCount implements Action {
  public readonly type = CONTAINER_HOLDS_COUNT;
  constructor(public payload: any = null) {}
}

export class ContainerHoldsCountSuccess implements Action {
  public readonly type = CONTAINER_HOLDS_COUNT_SUCCESS;
  constructor(public payload: any = null) {}
}

export class ContainerHoldsCountError implements Action {
  public readonly type = CONTAINER_HOLDS_COUNT_ERROR;
  constructor(public payload: any = null) {}
}

export type All = ContainerAvailabilityCountSuccess
  | ContainerAvailabilityCountError
  | ContainerAvailabilityCount
  | ContainerHoldsCount
  | ContainerHoldsCountSuccess
  | ContainerHoldsCountError;
