import { ChartTooltipData, createTooltip } from '@core/utility/chart.utility';
import { compose } from '@core/utility/FP.utility';

const parseData = (
  data: any,
): {
  day: string;
  dailySum: any;
  // eslint-disable-next-line @typescript-eslint/ban-types
  statusSums: {};
  available: string;
} => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const date = new Date(data.etaDate);
  const day = new Date(date.valueOf() + date.getTimezoneOffset() * 60000).toLocaleDateString('en-Us', { month: 'short', day: 'numeric' });
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const dailySum = Object.keys(data)
    .filter((key) => key !== 'available')
    .reduce((acc, key) => (acc + data[key].sum ? data[key].sum : 0), 0);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const statusSums = Object.entries(data)
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    .filter(([key, _]: [string, object]) => key !== 'etaDate')
    .reduce((acc, [key, value]: [string, object]) => ({ ...acc, [key]: (<any>value).sum }), {});

  return { day, dailySum, statusSums, available: data.available };
};

const createChartTooltipData = ({ day, dailySum, statusSums, available }): ChartTooltipData => {
  const categories = [
    { label: 'Total Containers', value: dailySum },
    { label: 'On Vessel', value: statusSums.onVessel },
    { label: 'Discharged', value: statusSums.discharged },
    { label: 'Docked', value: statusSums.docked },
    { label: 'Out Gated', value: statusSums.outGated },
    { label: 'Available', value: (available && available.sum) || 0 },
  ];
  return {
    title: day,
    noData: !dailySum,
    categories,
  };
};

// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
const transformDate = (data: any): Date => new Date(data.etaDate + new Date(data.etaDate).getTimezoneOffset() * 60 * 1000);

const assembleDate = (datePST: Date): string => {
  const option = { weekday: 'short', month: 'short', day: 'numeric' };
  return datePST.toLocaleDateString('en-US', { weekday: 'short' }).slice(0, 2) +
    ' ' +
    datePST.toLocaleDateString('en-US', { month: 'short', day: 'numeric' }) +
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    (datePST.toLocaleDateString('en-US', option as any) === new Date().toLocaleDateString('en-US', option as any) ? '\n•' : '');
};

export const defaultToNull = ({ sum }: { sum: number }): number | null => (sum ? sum : null);

export const getTooltip = compose(createTooltip, createChartTooltipData, parseData);

export const parseDay = compose(assembleDate, transformDate);
