import { ForgetPasswordState } from './forget-password.state';
import * as forgetPasswordActions from './forget-password.actions';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export type Action = forgetPasswordActions.All;

const initialState: ForgetPasswordState = {
  success: false,
  loading: false,
  error: false,
  errorData: null,
  updateErrorData: null,
  updateSuccess: false,
};


const getForgetPasswordState = createFeatureSelector<ForgetPasswordState>('forget-password');

export const getForgetPasswordStatus = createSelector(
  getForgetPasswordState,
  state => state.success,
);

export const getSetPasswordStatus = createSelector(
  getForgetPasswordState,
  state => state.updateSuccess,
);

export const getForgetPasswordErrorData = createSelector(
  getForgetPasswordState,
  state => state.errorData,
);

export const getUpdateErrorData = createSelector(
  getForgetPasswordState,
  state => state.updateErrorData,
);

export const isResettingPassword = createSelector(
  getForgetPasswordState,
  state => state && state.loading,
);

// eslint-disable-next-line @typescript-eslint/naming-convention
export function ForgetPasswordReducer(state = initialState, action: Action): ForgetPasswordState {
  switch (action.type) {
    case forgetPasswordActions.FORGET_PASSWORD: {
      return { ...state, loading: true };
    }
    case forgetPasswordActions.FORGET_PASSWORD_SUCCESS: {
      return {
        ...state,
        loading: false,
        success: true,
        error: false,
        errorData: null,
      };
    }
    case forgetPasswordActions.FORGET_PASSWORD_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
        errorData: action.payload,
      };
    }
    case forgetPasswordActions.SET_PASSWORD: {
      return { ...state, loading: true };
    }
    case forgetPasswordActions.SET_PASSWORD_SUCCESS: {
      return {
        ...state,
        loading: false,
        updateSuccess: true,
        error: false,
        updateErrorData: null,
      };
    }
    case forgetPasswordActions.SET_PASSWORD_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
        updateErrorData: action.payload,
        updateSuccess: false,
      };
    }
    case forgetPasswordActions.CLEAR_FORGET_PASSWORD_STATE: {
      return initialState;
    }
    default: return state;
  }
}
