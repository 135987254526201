import { Injectable } from '@angular/core';

import { saveAs } from 'file-saver';
import { Observable, of } from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import * as EmptiesReturnAction from './empties-return.actions';
import { ApiQueryBuilder } from '@services/api-query-builder';
import { ApiCallService } from '@services/api-call.service';
import { EmptyReturnsService } from '@app/modules/dashboard/services/empty-returns.service';
import { fileNameGenerator } from '@core/utility/helpers';

@Injectable()
export class EmptiesReturnEffects {

  constructor(
    private actions$: Actions,
    private api: ApiCallService,
    private emptyReturnsService: EmptyReturnsService,
  ) { }

  public fetchEmptiesReturn$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<EmptiesReturnAction.FetchData>(EmptiesReturnAction.FETCH_DATA),
    mergeMap(({ payload }) =>
      this.api.constructApiCall(
        new ApiQueryBuilder()
          .addName(`emptiesReturn`)
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          .addBody(this.emptyReturnsService.parsePayload(payload))
          .build(),
      ).pipe(
        map(response => this.emptyReturnsService.parseResponse(response)),
        map(data => new EmptiesReturnAction.FetchDataSuccess(data)),
        catchError(error => of(new EmptiesReturnAction.FetchDataError(error))),
      ),
    ),
  ),
  );

  public fetchPublicEmptiesReturn$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<EmptiesReturnAction.FetchPublicData>(EmptiesReturnAction.FETCH_PUBLIC_DATA),
    mergeMap(({ payload }) =>
      this.api.constructApiCall(
        new ApiQueryBuilder()
          .addName(`emptiesReturnPublic`)
          .addBody(payload)
          .build(),
      ).pipe(
        map(data => new EmptiesReturnAction.FetchPublicDataSuccess(data)),
        catchError(error => of(new EmptiesReturnAction.FetchPublicDataError(error))),
      ),
    ),
  ),
  );

  public downloadExcel$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<EmptiesReturnAction.DownloadExcel>(EmptiesReturnAction.DOWNLOAD_EXCEL),
    mergeMap(({ payload }) =>
      this.api.constructApiCall(
        new ApiQueryBuilder()
          .addName(`emptiesExcelDownload`)
          .setIsFile(false)
          .addEntry('responseType', 'blob')
          .addEntry('observe', 'response')
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          .addBody(this.emptyReturnsService.parsePayload(payload))
          .build(),
      ).pipe(
        map(data => {
          const fname = fileNameGenerator('empties-return', 'xlsx');
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          saveAs(data['body'], fname);
          return new EmptiesReturnAction.DownloadExcelSuccess(data);
        }),
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        catchError(error => of(new EmptiesReturnAction.DownloadExcelError(error))),
      ),
    ),
  ),
  );
}
