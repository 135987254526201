import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AddContainersModalComponent } from '@shared/components/add-containers-modal/add-containers-modal.component';
import { PromptModalComponent } from '@shared/components/prompt-modal/prompt-modal.component';
import { UserPreferencesComponent } from '@shared/components/user-preferences-modal/user-preferences-modal.component';
import { NewWindowConfirmModalComponent } from '@shared/components/new-window-confirm-modal/new-window-confirm-modal.component';
import { TermsOfUseModalComponent } from '@shared/components/terms-of-use-modal/terms-of-use-modal.component';
import { PasswordChangeModalComponent } from '@shared/components/password-change-modal/password-change-modal.component';
import { UnclaimContainerModalComponent } from '@shared/components/unclaim-container-modal/unclaim-container-modal.component';
import { AcknowledgeContainersModalComponent } from '@shared/components/acknowledge-containers-modal/acknowledge-containers-modal.component';
import { AppointmentsModalComponent } from '@shared/components/appointments-modal/appointments-modal.component';
import { HoldsModalComponent } from '@shared/components/holds-modal/holds-modal.component';
import { SessionTimeoutModalComponent } from '@shared/components/session-timeout-modal/session-timeout-modal.component';
import { IntermodalClaimModalComponent } from '@shared/components/intermodal-claim-modal/intermodal-claim-modal.component';
import { EditContainersModalComponent } from '@shared/components/edit-containers-modal/edit-containers-modal.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ModalContainerComponent } from '@shared/components/modal-container/modal-container.component';
import { RequestSpecialHandlingModalComponent} from '@shared/components/request-specialhandling-modal/request-specialhandling-modal.component';
import { RequestCancelModalComponent } from '@app/shared/components/request-cancel-modal/request-cancel-modal.component';
import { ScheduleAppointmentSignupModalComponent } from '@app/shared/components/schedule-appointment-signup-modal/schedule-appointment-signup-modal.component';

const MODAL_COMPONENTS = {
  AddContainersModalComponent,
  TermsOfUseModalComponent,
  AppointmentsModalComponent,
  NewWindowConfirmModalComponent,
  EditContainersModalComponent,
  HoldsModalComponent,
  UnclaimContainerModalComponent,
  PromptModalComponent,
  SessionTimeoutModalComponent,
  UserPreferencesComponent,
  AcknowledgeContainersModalComponent,
  IntermodalClaimModalComponent,
  PasswordChangeModalComponent,
  RequestSpecialHandlingModalComponent,
  RequestCancelModalComponent,
  ScheduleAppointmentSignupModalComponent,
};

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  public ref: MatDialogRef<any>;

  constructor(
    private store: Store,
    private dialog: MatDialog,
  ) {

  }

  public openDialog(payload: { component: string, data: { panelClass?: string | string[], [key: string]: any } }): MatDialogRef<any> {
    this.closeDialog();
    this.ref = this.dialog.open(ModalContainerComponent, {
      autoFocus: false,
      disableClose: true,
      panelClass: payload.data?.panelClass ?? null,
      data: {
        component: MODAL_COMPONENTS[payload.component],
        props: payload.data || null,
      },
    });
    return this.ref;
  }

  public closeDialog(): void {
    this.ref?.close();
    this.ref = null;
  }
}
