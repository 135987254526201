import { UniversalSearchState } from './universal-search.state';
import * as UniversalSearchActions from './universal-search.actions';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export type Action = UniversalSearchActions.All;

export const initialState: UniversalSearchState = {
  results: {
    data: {},
    sumOfResults: 0,
  },
  loading: false,
  search_dialog: false,
  searchText: '',
  error: false,
  isRevert: false,
};

// selectors

export const getSearchResultsState = createFeatureSelector<UniversalSearchState>(
  'universal-search',
);

export const getSearchResults = createSelector(
  getSearchResultsState,
  state => state.results,
);

export const getSearchResultDialog = createSelector(
  getSearchResultsState,
  state => state.search_dialog,
);

export const getSearchParam = createSelector(
  getSearchResultsState,
  state => state.searchText,
);

export const getLoading = createSelector(
  getSearchResultsState,
  state => state.loading,
);

export const getRevert = createSelector(
  getSearchResultsState,
  state => state.isRevert,
);

// eslint-disable-next-line @typescript-eslint/naming-convention
export function UniversalSearchReducer(
  state = initialState,
  action: Action,
): UniversalSearchState {
  switch (action.type) {
    case UniversalSearchActions.GET_UNIVERSAL_SEARCH_RESULTS: {
      return {
        ...state,
        search_dialog: true,
        isRevert: false,
        results:  {
          data: {},
          sumOfResults: 0,
        },
        loading: true,
      };
    }

    case UniversalSearchActions.GET_UNIVERSAL_SEARCH_RESULTS_SUBMIT: {
      return {
        ...state,
        loading: true,
        isRevert: false,
        search_dialog: true,
      };
    }

    case UniversalSearchActions.GET_UNIVERSAL_SEARCH_RESULTS_SUCCESS: {
      return {
        ...state,
        results: {
          data: {...action.payload.results},
          sumOfResults: action.payload.sum,
        },
        loading: false,
      };
    }

    case UniversalSearchActions.GET_UNIVERSAL_SEARCH_RESULTS_ERROR: {
      return {
        ...state,
        search_dialog: false,
        loading: false,
      };
    }

    case UniversalSearchActions.SHOW_UNIVERSAL_SEARCH_RESULTS: {
      return {
        ...state,
        search_dialog: true,
      };
    }

    case UniversalSearchActions.HIDE_UNIVERSAL_SEARCH_RESULTS: {
      return {
        ...state,
        search_dialog: false,
      };
    }

    case UniversalSearchActions.DELETE_UNIVERSAL_SEARCH_PARAM: {
      return {
        ...state,
        searchText: '',
      };
    }

    case UniversalSearchActions.REVERT_SEARCH_PARAM: {
      return {
        ...state,
        isRevert: true,
      };
    }

    case UniversalSearchActions.SET_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }

    case UniversalSearchActions.SET_SEARCH_TEXT: {
      return {
        ...state,
        searchText: action.payload,
      };
    }

    default:
      return state;
  }
}
