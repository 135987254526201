import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { exhaustMap, map, catchError } from 'rxjs/operators';

import { ApiCallService } from '@app/core/services/api-call.service';
import { ApiQueryBuilder } from '@app/core/services/api-query-builder';

import {
  intermodalClaim,
  intermodalClaimSuccess,
  intermodalClaimError,
} from './intermodal-claim.actions';
import { IntermodalClaimService } from '@app/modules/dashboard/services/intermodal-claim.service';
import { IntermodalClaimResponse } from '@app/shared/models/dashboard/intermodal-claim.model';

@Injectable()
export class IntermodalClaimEffects {
  constructor(
    private actions$: Actions,
    private intermodalClaimService: IntermodalClaimService,
    private api: ApiCallService,
  ) {}

  public intermodalClaim$ = createEffect(() =>
    this.actions$.pipe(
      ofType(intermodalClaim),
      map((action) => this.intermodalClaimService.parsePayload(action)),
      exhaustMap((action) =>
        this.api
          .constructApiCall(
            new ApiQueryBuilder()
              .addName('intermodalClaim')
              .addBody(action)
              .build(),
          )
          .pipe(
            map((r) => r as IntermodalClaimResponse),
            map((response) =>
              this.intermodalClaimService.parseResponse(response),
            ),
            map((data) => intermodalClaimSuccess({ data })),
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            catchError((error) => of(intermodalClaimError(error))),
          ),
      ),
    ),
  );
}
