import { Pipe, PipeTransform } from "@angular/core";
import { format } from "date-fns";

@Pipe({
    name: 'requestHeader',
  })
  export class RequestHeaderPipe implements PipeTransform {
  
  public transform(value: any): any {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const formatted = new Date(value).toLocaleString('en-US', {
      timeZone: 'America/Los_Angeles', 
    });
    return value? format( new Date(formatted), 'MMM dd, yyyy, HH:mm a') : '';
  }
}