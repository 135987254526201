import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-loader-overlay',
  templateUrl: './loader-overlay.component.html',
  styleUrls: ['./loader-overlay.component.css'],
})
export class LoaderOverlayComponent {
  @Input() public loading: boolean = false;
  @Input() public fillPage: boolean = false;
}
