<div class="error-modal">
    <div mat-dialog-title class="title-text" *ngIf="errorStatus === 401">
        <p>We couldn’t find your account in our system</p>
        <button mat-dialog-close (click)="onClose()">×</button>
    </div>
    
    <mat-dialog-content class="content" *ngIf="errorStatus === 401">

        <p class="description">To create an account, please go to the Sign Up page.
            It will take us up to 3 days to review and approve your application.
        </p>

        <p class="description">In case of any issues, please contact your manager
            or email customer support at <a class="url"
                href="mailto:PortOptimizer.Support@wabtec.com">PortOptimizer.Support&#64;wabtec.com</a>.</p>
    </mat-dialog-content>

    <mat-dialog-content class="content" *ngIf="errorStatus === 403">

        <p class="description">Thank you for your interest in Port Optimizer™.  
            We are validating your email address and company information and will provide additional Port Optimizer™ log in information upon completion.
        </p>

        <p class="description">In case of any issues, please contact your manager
            or email customer support at <a class="url"
                href="mailto:PortOptimizer.Support@wabtec.com">PortOptimizer.Support&#64;wabtec.com</a>.</p>
    </mat-dialog-content>
    <app-api-error *ngIf="(errorStatus !== 401) && (errorStatus !== 403)"></app-api-error>
    <mat-dialog-actions>
        <button mat-raised-button class="button" color="primary" (click)="onClose()">Ok, Got It</button>
    </mat-dialog-actions>
</div>

