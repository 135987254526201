import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { mergeMap, map, catchError} from 'rxjs/operators';

import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import * as ForgetPasswordActions from './forget-password.actions';
import { ApiCallService } from '@services/api-call.service';
import { CognitoParams } from '@shared/models/shared/cognito-params.model';

@Injectable()
export class ForgetPasswordEffects {

  constructor(
    private actions$: Actions,
    private api: ApiCallService,
  ) { }

  public sendForgetPassword$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType<ForgetPasswordActions.ForgetPassword>(ForgetPasswordActions.FORGET_PASSWORD),
      mergeMap(action => {
        const cognitoParams: CognitoParams = {
          name: 'forgetPassword',
          body: {
            email: action.payload.email,
          },
        };

        return this.api.cognitoCall(cognitoParams)
        .pipe(
          map(data => {
            return new ForgetPasswordActions.ForgetPasswordSuccess(data);
          }),
          catchError(error => {
            return of(new ForgetPasswordActions.ForgetPasswordError(error));
          }),
        );
      }),
    ),
    );

  public setPassword$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType<ForgetPasswordActions.SetPassword>(ForgetPasswordActions.SET_PASSWORD),
      mergeMap(action => {
        const cognitoParams: CognitoParams = {
          name: 'setPassword',
          body: {
            email: action.payload.email,
            code: action.payload.code,
            password: action.payload.password,
          },
        };

        return this.api.cognitoCall(cognitoParams)
        .pipe(
          map(data => {
            return new ForgetPasswordActions.SetPasswordSuccess(data);
          }),
          catchError(error => {
            return of(new ForgetPasswordActions.SetPasswordError(error));
          }),
        );
      }),
    ),
    );
}
