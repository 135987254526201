import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { mergeMap, map, catchError, tap } from 'rxjs/operators';
import { Action, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { UniversalSearchState } from './universal-search.state';
import * as UniversalSearchActions from './universal-search.actions';

import { ApiQueryBuilder } from '@services/api-query-builder';
import { ApiCallService } from '@services/api-call.service';
import { ContainerStatusListService } from '@services/container-status-list.service';

@Injectable()
export class UniversalSearchEffects {
  constructor(
    private actions$: Actions,
    private api: ApiCallService,
    private store: Store<UniversalSearchState>,
    private containerStatusListService: ContainerStatusListService,
  ) { }

  public GetSearchResults$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<UniversalSearchActions.GetSearchResults>(UniversalSearchActions.GET_UNIVERSAL_SEARCH_RESULTS),
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    tap(_ => this.store.dispatch(new UniversalSearchActions.ShowSearchResult())),
    mergeMap(action =>
      this.api.constructApiCall(
        new ApiQueryBuilder()
          .addName(this.containerStatusListService.getUniversalSearchEndpoint(action.parent))
          .addBody(this.containerStatusListService.getUniversalSearchRequestObject({
            bucketSize: 10,
            searchText: action.payload.searchText,
            filters: action.payload.filter || [],
          }, action.parent))
          .build(),
      ).pipe(
        map(results => {
          const parsedResult = this.containerStatusListService.getParsedUniversalSearchResponse(results, action.parent);
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          const sum = countNumberOfResults(parsedResult);

          if (sum) {
            this.store.dispatch(new UniversalSearchActions.SetSearchText(action.payload.searchText));
          }

          return new UniversalSearchActions.GetSearchResultsSuccess({ results: parsedResult, sum });
        }),
        // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
        catchError(_ => of(new UniversalSearchActions.GetSearchResultsError())),
      ),
    ),
  ),
  );
}


function countNumberOfResults(response: object): number {
  return Object.values(response).reduce((acc: number, resType: string[]) => acc += resType.length, 0);
}
