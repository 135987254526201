import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { ApiQueryBuilder } from '@services/api-query-builder';

import * as RegisterActions from './register.actions';
import { ApiCallService } from '@services/api-call.service';

@Injectable()
export class RegisterEffects {

  constructor(
    private actions$: Actions,
    private api: ApiCallService,
  ) { }

  public Authenticateregister$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType<RegisterActions.Register>(RegisterActions.REGISTER),
      mergeMap(action => {
        return this.api.constructApiCall(
          new ApiQueryBuilder()
            .addName('signup')
            .addBody(action.payload)
            .build(),
        )
        .pipe(
          map(data => {
            return new RegisterActions.RegisterSuccess(data);
          }),
          catchError(error => {
            return of(new RegisterActions.RegisterError(error));
          }),
        );
      }),
    ),
    );
}
