import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { mergeMap, map, catchError} from 'rxjs/operators';
import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { ApiQueryBuilder } from '@services/api-query-builder';

import * as TerminalMetaAction from './terminal-meta.actions';
import { ApiCallService } from '@services/api-call.service';

@Injectable()
export class TerminalMetaEffects {

  constructor(
    private actions$: Actions,
    private api: ApiCallService,
  ) { }

  public GetTerminalMeta$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType<TerminalMetaAction.TerminalMeta>(TerminalMetaAction.GET_TERMINAL_META),
      mergeMap(() => {
        return this.api.constructApiCall(
          new ApiQueryBuilder()
            .addName('terminalList')
            .build(),
        )
        .pipe(
          map(data => {
            return new TerminalMetaAction.TerminalMetaSuccess(data);
          }),
          catchError(error => {
            return of(new TerminalMetaAction.TerminalMetaError(error));
          }),
        );
      }),
    ),
    );
}
