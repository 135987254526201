import { ContainerFilterState } from './container-filter.state';
import * as ContainerFilterActions from './container-filter.actions';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export type Action = ContainerFilterActions.All;

const initialState: ContainerFilterState = {
  availabilityCount: 0,
  holdsCountResponse: {},
  loadingAvailabilityCount: false,
  loadingholdsCount: false,
};

const getContainerFilterState = createFeatureSelector<ContainerFilterState>('containerFilter');

export const getContainerAvailabilityCount = createSelector(
  getContainerFilterState,
  state => state.availabilityCount,
);

export const isLoadingContainerAvailabilityCount = createSelector(
  getContainerFilterState,
  state => state.loadingAvailabilityCount,
);

export const getContainerHoldsCount = createSelector(
  getContainerFilterState,
  state => state.holdsCountResponse,
);

export const isLoadingContainerHoldsCount = createSelector(
  getContainerFilterState,
  state => state.loadingholdsCount,
);

// eslint-disable-next-line @typescript-eslint/naming-convention
export function ContainerFilterReducer(state = initialState, action: Action): ContainerFilterState {
  switch (action.type) {
    case ContainerFilterActions.CONTAINER_AVAILABILITY_COUNT: {
      return {
        ...state,
        loadingAvailabilityCount: true,
      };
    }

    case ContainerFilterActions.CONTAINER_AVAILABILITY_COUNT_SUCCESS: {
      return {
        ...state,
        availabilityCount: action.payload,
        loadingAvailabilityCount: false,
      };
    }

    case ContainerFilterActions.CONTAINER_AVAILABILITY_COUNT_ERROR: {
      return {
        ...state,
        loadingAvailabilityCount: false,
      };
    }

    case ContainerFilterActions.CONTAINER_HOLDS_COUNT: {
      return {
        ...state,
        loadingholdsCount: true,
      };
    }

    case ContainerFilterActions.CONTAINER_HOLDS_COUNT_SUCCESS: {
      return {
        ...state,
        holdsCountResponse: action.payload,
        loadingholdsCount: false,
      };
    }

    case ContainerFilterActions.CONTAINER_HOLDS_COUNT_ERROR: {
      return {
        ...state,
        loadingholdsCount: false,
      };
    }
    default:
      return state;
  }
}
