<ng-container *ngFor="let loading of [loading$ | async]">
  <div block="edit-containers" class="spinner-overlay-parent">
    <app-loader-overlay [loading]="loading"></app-loader-overlay>
    <div elem="header">
      <div>
        <h2 mat-dialog-title elem="header-text">Edit</h2>
        <div elem="cancel-icon">
          <img
            src="assets/svgs/close-grey.svg"
            width="20"
            height="20"
            alt="cancel"
            (click)="closeEditing()"
          />
        </div>
      </div>

      <div>
        <h6 elem="container-number">{{currentContainer?.containerNumber}}</h6>
        <h4 elem="text">{{displayIndex}} of {{selected?.length}}</h4>
      </div>

      <div
        elem="container-status-wrapper"
        mod="pending"
        *ngIf="currentContainer?.isPendingContainer"
      >
        <div elem="contaner-status-label">
          PENDING
        </div>
      </div>
    </div>

    <div elem="content" mat-dialog-content>
      <form [formGroup]="form" (ngSubmit)="submitForm()">
        <div elem="form-labels">
          <label for="owner">Owner</label>
          <label for="customField1">{{(customFieldsData$ | async)?.customOne}}</label>
          <label for="customField2">{{(customFieldsData$ | async)?.customTwo}}</label>
        </div>

        <div elem="form">
          <mat-form-field>
            <mat-select
              disableOptionCentering
              formControlName="owner"
              [placeholder]="currentContainer?.owner && currentContainer?.owner.fullName || '' "
              (selectionChange)="checkValue($event.value)"
            >
              <mat-option
                [value]="owner"
                *ngFor="let owner of owners$ | async"
              >{{owner.fullName | uppercase}}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field floatLabel="never">
            <input class="custom-filed-input"
              matInput
              elem="input"
              maxlength="255"
              id="customField1-input"
              placeholder="Type Here"
              formControlName="customField1"
              (keyup)="checkValue($event.target.value)"
            />
          </mat-form-field>

          <mat-form-field floatLabel="never">
            <input class="custom-filed-input"
              matInput
              elem="input"
              maxlength="255"
              id="customField2-input"
              placeholder="Type Here"
              formControlName="customField2"
              (keyup)="checkValue($event.target.value)"
            />
          </mat-form-field>
        </div>

        <div>
          <textarea
            id="note"
            elem="text-area-notes"
            formControlName="note"
            placeholder="Add notes here."
            maxlength="255"
            (keyup)="checkValue($event.target.value)"
          ></textarea>
        </div>

        <div elem="footer">
          <div elem="apply-all">
            <button
              elem="button"
              type="button"
              mat-flat-button
              ngDefaultControl
              color="secondary"
              id="apply-all-button"
              *ngIf="selected?.length > 1"
              (click)= "saveAll()"
              [disabled]="!form.dirty"
              formControlName="applyAll"
              [class.disabled]="!form.dirty"
            >
            <img
                *ngIf="applyAll.value"
                src="/assets/svgs/check-blue.svg"
                width="15"
                height="15"
                elem="applied-image"
                alt="Apply all"
            />
              {{ applyAll.value ? 'Applied' : 'Apply to all' }}
            </button>
          </div>
          <div mat-dialog-actions elem="buttons">
            <button
              elem="button"
              type="button"
              color="primary"
              mat-flat-button
              (click)="closeEditing()"
              id="cancel-editing-button"
            >Cancel</button>

            <button
              elem="button"
              type="button"
              color="secondary"
              mat-flat-button
              (click)="back()"
              id="back-container-button"
              [disabled]="displayIndex === 1"
              [class.disabled]="displayIndex === 1"
            >Back</button>

            <button
              elem="button"
              type="button"
              color="secondary"
              mat-flat-button
              (click)="next()"
              id="next-container-button"
              [disabled]="selected?.length === displayIndex"
              [class.disabled]="selected?.length === displayIndex"
            >Next</button>

            <button
              elem="button"
              type="submit"
              color="accent"
              mat-flat-button
              [disabled]="!changeOccured"
              [class.disabled]="!changeOccured"
              id="apply-edit-button"
            >Submit</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-container>
