import { ChangeDetectionStrategy, Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NavigationExtras, Router } from '@angular/router';
import { UniversalAppointmentSystemService } from '@app/core/services/universal-appointment-system.service';
import { ISelectedItems } from '@app/shared/models/dashboard/filters.model';
import { getSelectedList } from '@app/store/dashboard/dashboard.selectors';
import { getUserDetails } from '@app/store/user/user.reducer';
import { select, Store } from '@ngrx/store';
import { last, Observable, Subscription, take, tap } from 'rxjs';

@Component({
  selector: 'app-schedule-appointment-signup-modal',
  templateUrl: './schedule-appointment-signup-modal.component.html',
  styleUrls: ['./schedule-appointment-signup-modal.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScheduleAppointmentSignupModalComponent implements OnInit {
  @Output() public closeForm = new EventEmitter<void>();
  @Output() public submitData = new EventEmitter<object>();
  public signupForm: FormGroup;
  public apiError: string = '';
  public readonly parent: string = 'container';
  public selectedList: ISelectedItems = {
    selected: [],
    isAll: true,
    selectedPendingContainers: [],
    selectedContainerData: [],
    requestSpecialList: [],
  };
  private subscriptions: Subscription = new Subscription();
  public appointmentScheduleContainerList: string[] = [];
  public selectionsChanged: boolean = false;
  userDetails$: Observable<{ firstName: string; lastName: string; email: string }>;


  constructor(
    private fb: FormBuilder,
    private store: Store<{}>,
    private universalAppointmentService: UniversalAppointmentSystemService,
    public dialogRef: MatDialogRef<ScheduleAppointmentSignupModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
  ) {
    this.signupForm = this.fb.group({
      firstName: ['', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(64),
        Validators.pattern(/^[^0-9\s-][a-zA-Z\s#$%^&*`'_-]*[^0-9\s]$/),
      ]],
      lastName: ['', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(64),
        Validators.pattern(/^[^0-9\s-][a-zA-Z\s#$%^&*`'_-]*[^0-9\s]$/),
      ]],
      email: [{ value: '', disabled: true }, [Validators.required, Validators.email]],
      companyName: ['', Validators.required],
      scacCode: [
        '',
        [Validators.required, Validators.minLength(4), Validators.maxLength(4), Validators.pattern(/^[A-Z]{4}$/)],
      ],
      apiError: [''], // New form control for API error
    });
  }
  

  public ngOnInit() {
    this.store.pipe(select(getUserDetails), take(1)).subscribe(userDetails => {
      if (userDetails) {
        this.signupForm.patchValue({
          firstName: userDetails.firstName,
          lastName: userDetails.lastName,
          email: userDetails.email
        });
        this.signupForm.get('email')?.disable();
      }
    });
    this.selectionsChanged = (this.dialogRef?.componentInstance as any).data?.props?.isFromScheduleAppointment;
    this.signupForm.get('scacCode')?.valueChanges.subscribe((value) => {
      if (value) {
        const sanitizedValue = value
          .replace(/[^A-Za-z]/g, '')
          .toUpperCase()
          .slice(0, 4);
        if (sanitizedValue !== value) {
          this.signupForm.get('scacCode')?.setValue(sanitizedValue, { emitEvent: false });
        }
      }
      // Clear API error when user starts typing again
      if (this.signupForm.get('apiError')?.value) {
        this.signupForm.get('apiError')?.setValue('');
      }
    });

    this.subscriptions.add(
      this.store.pipe(
        select(getSelectedList, { parent: this.parent }),
        tap(selected => {
          this.appointmentScheduleContainerList = selected.selectedContainerData
          .filter(container => container.shipmentStatusCode !== 'Outgated')
          .map(container => container.containerNumber);
        }),
      ).subscribe(),
    );
  }

  public closeModal(): void {
    this.dialogRef.close(false);
    this.closeForm.emit();
  }

  public onSubmit(): void {
    if (this.signupForm.valid) {
      const formData = this.signupForm.value;
      const payload = {
        firstName: formData.firstName,
        lastName: formData.lastName,
        scac: formData.scacCode,
        companyName: formData.companyName,
      };

      this.universalAppointmentService.universalAppointmentSystemValidateScac(payload).subscribe({
        next: (response) => {
          if (response.allowUser) {
            const navigationExtras: NavigationExtras = {
              state: {
                data: this.appointmentScheduleContainerList,
              },
            };
            if (this.selectionsChanged) {
              this.router.navigateByUrl('/appointments/overview', navigationExtras);
            } else {
              this.router.navigate(['/appointments/overview']);
            }
            this.closeForm.emit();
            this.dialogRef.close(true);
          } else {
            this.signupForm.get('apiError')?.setValue('Invalid SCAC code. Please enter a valid SCAC code.');
            this.signupForm.get('scacCode')?.setErrors({ apiError: true });
          }
        },
        error: (error) => {
          this.signupForm.get('apiError')?.setValue('Invalid SCAC code. Please enter a valid SCAC code.');
          this.signupForm.get('scacCode')?.setErrors({ apiError: true });
        },
      });
    }
  }

  public get isFormValid(): boolean {
    return this.signupForm.valid;
  }
}
