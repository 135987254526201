import { compose } from './FP.utility';

const GRID_LINES: number = 5;
const ROUND_UP_PADDING: number = 1;
const ROUND_UP_TARGET: number = 10;
const NO_TOOLTIP: string = '<div class="__no-data__"></div>';

export interface ChartTooltipData {
  title: string;
  categories: { label: string; value }[];
  noData?: boolean;
}

export interface ColoredTooltipData {
  title: string;
  categories: { label: string; value: any; color?: string }[];
}

const accumulateContainersByDay = (chartData: any[][]): number[] =>
  chartData.map((day: any[]) =>
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    day.reduce((sum: number, entry: any) => sum + (entry && !isNaN(entry) ? entry : 0), 0),
  );

const roundValue = (padding: number = ROUND_UP_PADDING) =>
  (target: number = ROUND_UP_TARGET) =>
    (maxValue: number): number =>
      Math.ceil((maxValue + padding) / target) * target;


const getHighestTick = (containersByDay: number[]): number => Math.max(...containersByDay);


const generateTicks = (grids: number = GRID_LINES): Function =>
  (userAgent: string = window.navigator.userAgent): Function =>
    (maxValue: number): number[] => userAgent.includes('MSIE') || userAgent.includes('rv:11')
      ? Array.from(
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, prefer-spread
          Array.apply(null, { length: maxValue + 1 }).map(Number.call, Number),
          (_, index) => index % Math.round(maxValue / grids) ? 0 : index)
      : Array(maxValue + 1)
        .fill(null)
        .reduce((ticks, _, i) => i % Math.round(maxValue / grids) ? ticks : [...ticks, i], []);

// eslint-disable-next-line prefer-spread
export const flatArrays = (arrays: any): any => [].concat.apply([], arrays);

export const createChartTicks = compose(
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  generateTicks()(),
  roundValue()(),
  getHighestTick,
  accumulateContainersByDay,
);

export const createTooltip = ({ title, noData, categories }: ChartTooltipData): string =>
  noData
    ? NO_TOOLTIP
    : `<div class="chart-tooltip-wrapper">
            <h5 class="chart-tooltip-title">${title}</h5><br>
            ${categories
              .map(
                ({ label, value }) =>
                  `<div class="chart-tooltip-subtitle">${label} <span class="chart-tooltip-value">${value}</span></div>`,
              )
              .join('\n')}
        </div>`;

export const createColoredTooltip = ({ title, categories }: ColoredTooltipData): string => {
  return `<div class="colored-tooltip">
            <div class="colored-tooltip-title">${title}</div>
            ${categories
              .map(
                ({ label, value, color }) =>
                  `<div class="colored-tooltip-category">
                      <div class="colored-tooltip-category-label">
                        ${
                          color
                            ? `<div class="colored-tooltip-category-color" style="background-color: ${color}"> </div>`
                            : ''
                        }
                        ${label}
                      </div>
                      <div class="colored-tooltip-category-value">${value}</div>
                    </div>`,
              )
              .join('\n')}
        </div>`;
};
