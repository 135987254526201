import { UserState } from './user.state';
import * as UserActions from './user.actions';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export type Action = UserActions.All;

const initialState: UserState = {
  user: null,
  token: null,
  authenticated: false,
  loading: false,
  error: false,
  errorData: null,
  isSessionExpired: false,
};


const getUserState = createFeatureSelector<UserState>('user');

export const getAuthStatus = createSelector(
  getUserState,
  state => state && state.authenticated,
);

export const getAuthToken = createSelector(
  getUserState,
  state => state.token,
);

export const getUser = createSelector(
  getUserState,
  state => state.user,
);

export const getUserId = createSelector(
  getUserState,
  state => state.user && state.user.userDetails ? `${state.user.userDetails.idUser}`: '',
);

export const getPersona = createSelector(
  getUserState,
  state => state.user ? state.user.Persona : '',
);

export const getErrorData = createSelector(
  getUserState,
  state => state.errorData,
);

export const getInitials = createSelector(
  getUserState,
  state => {
    let initials = 'UN';
    if (state.user) {
      initials = state.user.userDetails.firstName
        .split(/ /)
        .reduce((acc: string, item: string) => acc[0] + item[0])
        .substr(0, 2)
        .toUpperCase();
    }
    return initials;
  },
);

export const getFullName = createSelector(
  getUserState,
  state => state.user && state.user.userDetails ? `${state.user.userDetails.firstName}` : '',
);

export const getUserDetails = createSelector(
  getUserState,
  state => {
    if (state.user && state.user.userDetails) {
      const { firstName, lastName, email } = state.user.userDetails;
      return { firstName, lastName, email };
    }
    return { firstName: '', lastName: '', email: '' };
  }
);

export const isSessionExpired = createSelector(
  getUserState,
  state => state.isSessionExpired,
);

export const isAuthenticating = createSelector(
  getUserState,
  state => state && state.loading,
);


// eslint-disable-next-line @typescript-eslint/naming-convention
export function UserReducer(state = initialState, action: Action): UserState {
  switch (action.type) {
    case UserActions.USER_AUTHENTICATE: {
      return {
        ...state,
        loading: true,
      };
    }

    case UserActions.USER_AUTHENTICATE_SUCCESS: {
      return {
        ...state,
        loading: false,
        authenticated: true,
        user: state.user,
        token: state.token,
        isSessionExpired: false,
      };
    }

    case UserActions.USER_AUTHENTICATE_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
        isSessionExpired: false,
        errorData: { ...action.payload },
      };
    }

    case UserActions.SET_TOKEN: {
      return {
        ...state,
        loading: false,
        authenticated: true,
        token: action.payload.token,
      };
    }

    case UserActions.SET_USER: {
      return {
        ...state,
        user: { ...action.payload.user },
      };
    }

    case UserActions.USER_DEAUTHENTICATE: {
      const isSessionExpired = !!action.payload;
      return { ...initialState, isSessionExpired, authenticated: false };
    }

    case UserActions.CLEAR_AUTHENTICATION_ERROR: {
      return {
        ...state,
        error: false,
        errorData: null,
      };
    }

    case UserActions.SET_SESSION_EXPIRED: {
      return {
        ...state,
        isSessionExpired: true,
      };
    }

    case UserActions.CLEAR_SESSION_EXPIRATION: {
      return {
        ...state,
        isSessionExpired: false,
      };
    }

    default: return state;
  }
}
