import { Injectable } from '@angular/core';
import { ApiCallService } from '@app/core/services/api-call.service';
import { ApiQueryBuilder } from '@app/core/services/api-query-builder';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import * as GlobalErrorActions from '@store/global-error/global-error.actions';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import * as UnclaimActions from './unclaim.actions';
import { UnclaimState } from './unclaim.state';
import { DashboardActions } from '@store/dashboard/dashboard.actions';


@Injectable()
export class UnclaimEffect {
  constructor(
    private actions$: Actions,
    private api: ApiCallService,
    private store: Store<UnclaimState>,
  ) {
  }

  public UnclaimContainers$: Observable<Action> = createEffect(() => this.actions$
  .pipe(
    ofType<UnclaimActions.UnclaimContainers>(UnclaimActions.UNCLAIM_CONTAINERS),
    mergeMap(action => {
      const payload = typeof action.payload === 'string' ? [action.payload] : { 'containerNumbers': action.payload };
      const parent = action.parent;
      return this.api
      .constructApiCall(
        new ApiQueryBuilder()
        .addName('removeContainer')
        .addBody(payload)
        .build(),
      )
      .pipe(
        map(data => {
          this.store.dispatch(DashboardActions.updateSelectedList({
            payload: {
              selected: [],
              isAll: true,
              selectedPendingContainers: [],
            },
            parent,
          }));
          this.store.dispatch(DashboardActions.setPageCounter({
            payload: 0,
            parent,
          }));
          return new UnclaimActions.UnclaimResult({
            failed: data['failureResults'],
            success: data['successResults'],
          });
        }),
        tap(() => {
          if (parent !== 'intermodal') {
            this.store.dispatch(DashboardActions.updateListAndChart({ parent }));
          }
        }),
        catchError(error => of(new GlobalErrorActions.SetGlobalError({ payload: error }))),
      );
    }),
  ),
  );
}
