import { Action } from '@ngrx/store';

export const SET_GLOBAL_ERROR = '[Error] SET_GLOBAL_ERROR';
export const CLEAR_GLOBAL_ERROR = '[Error] CLEAR_GLOBAL_ERROR';

export class SetGlobalError implements Action {
  public readonly type = SET_GLOBAL_ERROR;
  constructor(public payload: any = null) { }
}

export class ClearGlobalError implements Action {
  public readonly type = CLEAR_GLOBAL_ERROR;
  constructor(public payload: any = null) { }
}

export type All =
  SetGlobalError
  | ClearGlobalError
  ;
