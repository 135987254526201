import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';

import { select, Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, map, take, tap } from 'rxjs/operators';
import * as InterestedPartiesActions from '@store/interested-parties/interested-parties.actions';
import { getAllUser } from '@store/interested-parties/interested-parties.reducer';

import { IContainerStatus, IRequestList } from '@models/dashboard/container-status.model';
import { IParty } from '@models/dashboard/interested-parties.model';
import { ICustomFields } from '@models/shared/user-preferences.model';

import { UserState } from '@app/store/user/user.state';
import { getPartFiltersAndOrders, getSelectedList } from '@app/store/dashboard/dashboard.selectors';
import { RequestContainerService } from '../request-container-list/request-container.service';
import { ApiCallService } from '@app/core/services/api-call.service';
import { ApiQueryBuilder } from '@app/core/services/api-query-builder';
import { FilterService } from '@app/core/services/filter.service';
import { ContainerStatusListService } from '@app/core/services/container-status-list.service';
import { DashboardActions } from '@app/store/dashboard/dashboard.actions';
import { IOrderProp, IPartFilter, ITypeFilter } from '@app/shared/models/dashboard/filters.model';

@Component({
  selector: 'app-request-specialhandling-modal',
  templateUrl: './request-specialhandling-modal.component.html',
  styleUrls: ['./request-specialhandling-modal.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RequestSpecialHandlingModalComponent implements OnInit, OnDestroy {
  @Output() public closeForm = new EventEmitter<void>();
  @Output() public submitData = new EventEmitter<object>();

  private subscriptions: Subscription = new Subscription();
  private parent: string = '';
  public owners$: Observable<IParty[]>;
  public loading$: Observable<boolean>;
  public customFieldsData$: Observable<ICustomFields>;
  public userDetails$: Observable<object>;
  public selected: string[];
  public displayIndex: number = 1;
  public containersList: IContainerStatus[];
  public currentContainer: IContainerStatus;
  public readonly _injectedData: string;
  public isValidRequest: boolean = false;
  public containerCount: number = 0;
  public selectedListData: IContainerStatus[] = [];
  public terminalDetails$: BehaviorSubject<IRequestList> = new BehaviorSubject<IRequestList>({} as IRequestList);
  public orderField: string = 'etaAta';
  public orderDir: string = 'asc';
  public pageNumber: number;
  public pageFilterCache: IPartFilter[];  
  public usedFilters: string[] = ['universal', 'container'];
  public totalCount: number = 0;
  public queryPayload: {
    paging: {
      orders: IOrderProp[];
      filters: ITypeFilter[];
      page: number;
      showMore: boolean;
    };
    containerCount?: number;
    pendingApplied?: boolean;
    customFieldApplied?: boolean;
  };

  constructor(
    private router: Router,
    // eslint-disable-next-line @typescript-eslint/ban-types
    private store: Store<{}>,
    private userStore: Store<UserState>,
    public requestService: RequestContainerService,
    private filterService: FilterService,
    private api: ApiCallService,
  ) {}

  public ngOnInit(): void {
    //this.loading$ = this.userStore.pipe(select('user'));
    this.parent = this.router.url.split('/')[1];
    this.userStore.pipe(select('user'), take(1)).subscribe((data) => (this.userDetails$ = data['user']?.userDetails));
    this.store.dispatch(new InterestedPartiesActions.GetAllUser());
    this.owners$ = this.store.pipe(
      select(getAllUser),
      map((owners) => owners.slice().sort((a, b) => a.fullName.localeCompare(b.fullName))),
    );
    
    this.subscriptions.add(
      this.store
        .pipe(
          select(getSelectedList, { parent: this.parent }),
          tap((selected) => {
          
              const payload = {
                'terminalCode': selected.selectedContainerData[0]?.terminalName,
              }
              this.api.constructApiCall(new ApiQueryBuilder().addName('terminalDetails').addBody(payload).build()).subscribe(data =>{ 
                this.terminalDetails$.next(data);
              }),
            
            this.selectedListData = selected.selectedContainerData;

            // Check if all containers have same terminal
            const firstTerminal = this.selectedListData[0].terminalName;
            if (
              this.selectedListData.some((container) => {
                return container.terminalName !== firstTerminal || 
                !['APMT', 'ETS', 'WBCT', 'YTI'].includes(container.terminalName) || 
                container.isSpecialHandlingEnabled;
              })
            ) {
              this.isValidRequest = false;
              return;
            }
           
            // Check if ETA is at least 48 hours away
            if (
              this.selectedListData.every((container) => {             
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                const etaTimestamp = new Date(container.etaAta).getTime();
                const formatted = new Date().toLocaleString('en-US', {
                  timeZone: 'America/Los_Angeles',
                });
                const date = new Date(formatted);
                const nowTimestamp = date.getTime();
                return nowTimestamp + 172800000 < etaTimestamp;
              })
            ) {
              this.isValidRequest = true;
            } else {
              this.isValidRequest = false;
            }
          }),
          
        )
        .subscribe(),
    );

    this.subscriptions.add(
      this.requestService.tableDataSource$.subscribe((containers) => {
        this.containerCount = containers.length;
      }),
    );

    this.subscriptions.add(
      this.store.pipe(
        select(getPartFiltersAndOrders, { parent: this.parent }),
        distinctUntilChanged((x, y) => {
          const orderChanged = JSON.stringify(x.orders) === JSON.stringify(y.orders);
          const filterPartsChanged = JSON.stringify(x.partFilters) === JSON.stringify(y.partFilters);
          return orderChanged && filterPartsChanged;
        }),
      ).subscribe(({ orders, partFilters }) => {
        if (orders.length) {
          this.orderField = orders[0].property;
          this.orderDir = orders[0].direction.toLowerCase();
        }

        this.pageFilterCache = partFilters;
        const appliedFilters = partFilters?.filter(partFilter =>
          this.usedFilters.includes(partFilter.name),
        );
        const filters: ITypeFilter[] = this.filterService.composeFilters(appliedFilters);

        const isFilteredForPending = ContainerStatusListService.isFilteredFor(filters, 'pending_containers');

        this.queryPayload = {
          paging: { orders, filters, page: 0, showMore: false },
          pendingApplied: isFilteredForPending,
          customFieldApplied: false,   // will be set in dashboard.effect from the request
        };
      }),
    );
  }

  public fireQueryAction(): void {
    this.queryPayload.paging.showMore = false;
    this.queryPayload.containerCount = this.totalCount;

    this.store.dispatch(DashboardActions.getContainerStatusListResults({
      payload: JSON.parse(JSON.stringify(this.queryPayload)),
      parent: this.parent,
    }));
  }

  public closeSpecialHandling(): void {
    this.requestService.cleanContainerData();
    this.closeForm.emit();
  }

  public checkValue(text: string) {
    this.requestService.notes = text;
  }

  public submitForm(): void {
    this.requestService.createSubmitRequest();
    this.closeForm.emit();
    this.fireQueryAction();
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
