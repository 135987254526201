import { createAction, props } from '@ngrx/store';
import {
  IntermodalClaimParams,
  IntermodalClaimData,
} from '@app/shared/models/dashboard/intermodal-claim.model';

export const initIntermodalClaim = createAction(
  '[Intermodal Claim] Initialize',
);

export const intermodalClaim = createAction(
  '[Intermodal Claim] Add',
  props<IntermodalClaimParams>(),
);

export const intermodalClaimSuccess = createAction(
  '[Intermodal Claim] Success',
  props<{ data: IntermodalClaimData }>(),
);

export const intermodalClaimError = createAction(
  '[Intermodal Claim] Error',
  props<{ error: string }>(),
);
