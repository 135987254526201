import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { catchError, filter, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { Action, select, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import * as VesselActions from './vessel.actions';
import { getAllVesselList } from './vessel.reducer';

import { ApiQueryBuilder } from '@services/api-query-builder';
import { ApiCallService } from '@services/api-call.service';
import { VesselService } from '@services/vessel.service';

const ALLVESSELQUERYPAYLOAD = {
  paging: {
    orders: [{ direction: 'ASC', property: 'vesselName' }],
    page: 0,
    pageSize: 10000,
  },
  statusValues: [],
  vesselMyContainer: false,
};
@Injectable()
export class VesselEffects {
  constructor(
    private actions$: Actions,
    private api: ApiCallService,
    private vesselService: VesselService,
    // eslint-disable-next-line @typescript-eslint/ban-types
    private store: Store<{}>,
  ) {}

  public GetVesselList$: Observable<Action> = createEffect(() =>this.actions$.pipe(
    ofType<VesselActions.GetVesselList>(VesselActions.GET_VESSEL_LIST),
    mergeMap(action =>
      this.api.constructApiCall(
        new ApiQueryBuilder()
          .addName('newVesselList')
          .addBody(this.vesselService.getVesselRequestParamsToSend(action))
          .build(),
      ).pipe(
        map(data => new VesselActions.GetVesselListSuccess(this.vesselService.getParsedVesselResponse(action, data))),
        catchError(error => of(new VesselActions.GetVesselListError(error))),
      ),
    ),
  ),
  );

  public GetAllVesselList$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<VesselActions.GetAllVesselList>(VesselActions.GET_ALL_VESSEL_LIST),
    withLatestFrom(this.store.pipe(select(getAllVesselList))),
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    tap(([_, list]) => {
      if (list.length) {
        this.store.dispatch(new VesselActions.SetVesselLoading(false));
      }
    }),
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    filter(([_, list]) => !list.length),
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    mergeMap(_ =>
      this.api.constructApiCall(
        new ApiQueryBuilder()
          .addName('newVesselList')
          .addBody(this.vesselService.getVesselRequestParamsToSend({payload: ALLVESSELQUERYPAYLOAD}))
          .build(),
      ).pipe(
        map(data => new VesselActions.GetAllVesselListSuccess(
            this.vesselService.getParsedVesselResponse({payload: ALLVESSELQUERYPAYLOAD}, data)),
        catchError(error => of(new VesselActions.GetVesselListError(error)))),
      ),
    ),
  ),
  );
}
