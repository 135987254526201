import { SearchState } from './search.state';
import * as SearchActions from './search.actions';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export type Action = SearchActions.All;

const initialState: SearchState = {
  results: [],
  loading: false,
  error: [],
};

// selectors

const getSearchResultsState = createFeatureSelector<SearchState>('search');

export const getSearchResults = createSelector(
  getSearchResultsState,
  state => state?.results,
);

export const isSearchInProgress = createSelector(
    getSearchResultsState,
    state => state && state.loading,
);

export const getSearchError = createSelector(
    getSearchResultsState,
    state => state?.error,
);

// eslint-disable-next-line @typescript-eslint/naming-convention
export function SearchReducer(state = initialState, action: Action): SearchState {
  switch (action.type) {
    case SearchActions.GET_SEARCH_RESULTS: {
      return { ...state, error: [], loading: true };
    }

    case SearchActions.GET_SEARCH_RESULTS_SUCCESS: {
      return {
        ...state,
        results: [
          ...action.payload,
        ],
        loading: false,
        error: [],
      };
    }

    case SearchActions.GET_SEARCH_RESULTS_ERROR: {
      return {
        ...state,
        error: [action.error],
        loading: false,
      };
    }

    case SearchActions.CLEAR_SEARCH_RESULTS: {
      return {
        ...state,
        results: [],
        loading: false,
        error: [],
      };
    }
    default:
      return state;
  }
}
