import { createFeatureSelector, createSelector } from '@ngrx/store';
import { NotificationState } from './notification.state';
import * as NotificationActions from './notification.actions';

export type Action = NotificationActions.All;

const initialState: NotificationState = {
  alertTypes: [],
  categories: [],
  notificationList: [],
  notificationType: undefined,
  loading: false,
  error: false,
  open: false,
  isLastPage: false,
};

const getNotificationsState = createFeatureSelector<NotificationState>('notifications');

export const isLoading = createSelector(
  getNotificationsState,
  state => state.loading,
);

export const isOpen = createSelector(
  getNotificationsState,
  state => state.open,
);

export const isLastPage = createSelector(
  getNotificationsState,
  state => state.isLastPage,
);

export const notificationList = createSelector(
  getNotificationsState,
  state => state.notificationList,
);

export const notificationCategories = createSelector(
  getNotificationsState,
  state => state.categories,
);

export const getAlertTypes = createSelector(
  getNotificationsState,
  state => state.alertTypes,
);


// eslint-disable-next-line @typescript-eslint/naming-convention
export function NotificationReducer(state = initialState, action: Action): NotificationState {
  switch (action.type) {
    case NotificationActions.FETCH_NOTIFICATIONS: {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }

    case NotificationActions.FETCH_NOTIFICATIONS_SUCCESS: {
      return {
        ...state,
        loading: false,
        isLastPage: action.payload.last,
        notificationType: action.payload.type,
        notificationList: [...action.payload.data],
      };
    }

    case NotificationActions.FETCH_NOTIFICATIONS_FAIL: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }

    case NotificationActions.OPEN_NOTIFICATIONS: {
      return {
        ...state,
        open: true,
      };
    }

    case NotificationActions.CLOSE_NOTIFICATIONS: {
      return {
        ...state,
        open: false,
      };
    }

    case NotificationActions.FETCH_NOTIFICATION_CATEGORIES: {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }

    case NotificationActions.FETCH_NOTIFICATION_CATEGORIES_SUCCESS: {
      return {
        ...state,
        loading: false,
        categories: [...action.payload],
      };
    }

    case NotificationActions.FETCH_NOTIFICATION_CATEGORIES_FAIL: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }

    case NotificationActions.FETCH_ALERT_TYPES: {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }

    case NotificationActions.FETCH_ALERT_TYPES_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        alertTypes: [...action.payload],
      };
    }

    case NotificationActions.FETCH_ALERT_TYPES_FAIL: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }

    default:
      return state;
  }
}
