import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';

import * as AcknowledgeContainersActions from '@store/acknowledge-containers/acknowledge-containers.actions';
import * as fromAcknowledgeContainers from '@store/acknowledge-containers/acknowledge-containers.reducer';
import * as ModalActions from '@store/modal/modal.actions';
import * as FeedbackActions from '@store/feedback/feedback.actions';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-acknowledge-containers-modal',
  templateUrl: './acknowledge-containers-modal.component.html',
  styleUrls: ['./acknowledge-containers-modal.component.css'],
})

export class AcknowledgeContainersModalComponent implements OnInit {
  private containersToAcknowledge: { containers: string[] };

  public loading$: Observable<boolean>;
  public rejectedContainers$: Observable<string>;

  // eslint-disable-next-line @typescript-eslint/ban-types
  constructor(private store: Store<{}>) { }

  public ngOnInit() {
    this.rejectedContainers$ = this.store.pipe(
      select(fromAcknowledgeContainers.getRejectedContainers),
      tap((containers: string[]) => this.containersToAcknowledge = { containers }),
      map((containers: string[]) => containers.join('\n')),
    );
    this.loading$ = this.store.pipe(select(fromAcknowledgeContainers.getLoadingStatus));
  }

  public acknowledgeRejectedContainers(): void {
    this.store.dispatch(new AcknowledgeContainersActions.AcknowledgeRejectedContainers(this.containersToAcknowledge));
  }

  public contactSupport(): void {
    this.store.dispatch(new FeedbackActions.SetRejectedContainers(this.containersToAcknowledge.containers));
    this.store.dispatch(new FeedbackActions.FeedbackContainerReportDrawerOpen());
    this.store.dispatch(new ModalActions.CloseDialog());
  }
}
