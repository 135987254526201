import { Observable, of } from 'rxjs';
import { ApiCallService } from '@services/api-call.service';
import { ApiQueryBuilder } from '@services/api-query-builder';
import { INotificationType, INotificationCategory } from '@models/dashboard/notification.model';

// eslint-disable-next-line @typescript-eslint/ban-types
export const constructRequest = (apiService: ApiCallService, requestPayload: object): Observable<Object> =>
  apiService.constructApiCall(
    new ApiQueryBuilder()
      .addName('notifications')
      .addBody(requestPayload)
      .build(),
  );

export const transformCache = (cache: object): Observable<object> => of(cache);

export const doPayloadsDiffer = (x: object = {}, cached: object = {}): boolean => {
  const isExpired = cached['expires'] <= Date.now();
  delete cached['expires'];

  return x['page'] < cached['page'] && !isExpired
    ? false
    : JSON.stringify(x) !== JSON.stringify(cached) || isExpired;
};

export const isCached = (cache: any, id: string): object | null =>
  cache[id] ? cache[id] : null;

export const extendCategories = ([categories, types]: [INotificationCategory[], INotificationType[]]) => {
  const newCategory: Partial<INotificationCategory> = {
    description: 'New', idAlertType: 'NEW', name: 'New',
  };

  categories = categories.reduce((cats, cat: INotificationCategory) => {
    const { idAlertType, description } = types.find(({ name }) => name === cat.alertType);

    cat.idAlertType = idAlertType;
    cat.name = description;

    return cat.name === 'All'
      ? [{ ...newCategory, totalCount: cat.unreadCount }, cat, ...cats]
      : [...cats, cat];
  }, []);

  return categories;
};

export const withCustomTypes = (types: INotificationType[]): INotificationType[] =>
  ([
    ...types,
    { description: 'All', idAlertType: 'ALL', name: 'ALL' },
    { description: 'Archived', idAlertType: 'ARCHIVED', name: 'ARCHIVED' },
  ]);
