import { createAction, props } from '@ngrx/store';
import { IFilter, ISelectedItems } from '@models/dashboard/filters.model';
import { Payload } from '@app/core/interfaces/payload';

export const DEFAULT_PARENT = 'container';

export interface Parent {
  parent: string
}

type PayloadWithParent<T> = Payload<T> & Parent;

export class DashboardActions {
  public static getContainerStatusListResults = createAction('[Dashboard] GET_CONTAINER_STATUS_LIST_RESULTS', props<PayloadWithParent<any>>());
  public static getContainerStatusListResultsSuccess = createAction('[Dashboard] GET_CONTAINER_STATUS_LIST_RESULTS_SUCCESS', props<PayloadWithParent<any> & { action?: any }>());
  public static getContainerStatusListResultsFailure = createAction('[Dashboard] GET_CONTAINER_STATUS_LIST_RESULTS_FAILURE', props<PayloadWithParent<any>>());
  public static resetContainerStatusListResults = createAction('[Dashboard] GET_CONTAINER_STATUS_LIST_RESULTS_FAILURE', props<PayloadWithParent<any>>());
  public static getPendingContainersListResults = createAction('[Dashboard] GET_PENDING_CONTAINER_LIST_RESULTS', props<PayloadWithParent<any>>());
  public static getPendingContainerListResultsSuccess = createAction('[Dashboard] GET_PENDING_CONTAINER_LIST_RESULTS_SUCCESS', props<PayloadWithParent<any>>());
  public static updateListAndChart = createAction('[Dashboard] UPDATE_LIST_AND_CHART', props<Parent>());
  public static updateFilter = createAction('[Dashboard] UPDATE_FILTER', props<PayloadWithParent<IFilter>>());
  public static updateOrder = createAction('[Dashboard] UPDATE_ORDER', props<PayloadWithParent<any>>());
  public static updateSelectedList = createAction('[Dashboard] UPDATE_SELECTED_LIST', props<PayloadWithParent<ISelectedItems>>());
  public static clearSelectedList = createAction('[Dashboard] CLEAR_SELECTED_LIST', props<Parent>());
  public static updateSearch = createAction('[Dashboard] UPDATE_SEARCH', props<PayloadWithParent<any>>());
  public static clearSearch = createAction('[Dashboard] CLEAR_SEARCH', props<Parent>());
  public static clearManualFilter = createAction('[Dashboard] CLEAR_MANUAL_FILTER');
  public static setPageCounter = createAction('[Dashboard] SET_PAGE_COUNTER', props<PayloadWithParent<number>>());
  public static setLoading = createAction('[Dashboard] SET_LOADING', props<PayloadWithParent<boolean>>());
  public static setScrollTop = createAction('[Dashboard] SET_SCROLL_TO_TOP', props<Payload<boolean>>());
}
