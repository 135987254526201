import { Injectable } from '@angular/core';
import {
  EmptiesFilterValues,
  emptiesIndMap,
  WorkShifts,
  defaultEmptiesReturnFilterset,
} from '@models/dashboard/empties-return.model';
import { IOptionItem } from '@models/dashboard/tree-option-filter.model';
import { Empties } from '@models/dashboard/filters.model';
import { Observable } from 'rxjs';

export interface EmptyReturnsFilterState {
  [key: string]: string[];
  sizeIntersection?: string[];
}

export interface ShippingLine {
  shippingLineName: string;
  hasNotes: boolean;
  noteContent?: string;
  shifts: { containerTypes: any }[];
}

export interface EmptyReturnsData {
  terminalName: string;
  shippingLines: ShippingLine[];
  hasNotes: boolean;
  noteContent?: string;
}

export const DAY_IN_MS: number = 86_400_000;

@Injectable({ providedIn: 'root' })
export class EmptyReturnsService {
  private selectedDate: number = Date.now();
  private unhandledFilters: {} | null;

  public isSearchVisible$: Observable<boolean>;
  public initialFilter$: Observable<any>;

  public getFormattedISODateFor(partOfDay: 'start' | 'end', timestamp: number) {
    const date = new Date(timestamp);
    const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
    const day = date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`;
    const dateString = `${date.getFullYear()}-${month}-${day}`;
    const dayTime = partOfDay === 'start' ? '00:00:00' : '23:59:59';
    return `${dateString}T${dayTime}`;
  }

  private parseEbaData(data: {}): EmptyReturnsData[] {
    const tree = [];

    Object.keys(data).forEach((terminalName) => {
      let terminalHasNotes = false;
      let terminalNoteContent = '';
      const shippingLines = {};
      const perTerminal = {
        terminalName,
        shippingLines: [],
        hasNotes: terminalHasNotes,
        noteContent: terminalNoteContent,
      };

      data[terminalName].map((ebaPiece) => {
        shippingLines[ebaPiece.shippingLineName] = shippingLines[ebaPiece.shippingLineName] || {};

        shippingLines[ebaPiece.shippingLineName][ebaPiece.workShift] =
          shippingLines[ebaPiece.shippingLineName][ebaPiece.workShift] || {};

        shippingLines[ebaPiece.shippingLineName][ebaPiece.workShift][ebaPiece.ebaContainerSizeType] = {
          receiveEmpty: emptiesIndMap[ebaPiece.emptiesInd] || '*',
          noteContent: ebaPiece.containerNotes,
          hasNotes: !!ebaPiece.containerNotes,
        };

        shippingLines[ebaPiece.shippingLineName].hasNotes = !!ebaPiece.shippingLineNotes;
        shippingLines[ebaPiece.shippingLineName].noteContent = ebaPiece.shippingLineNotes;

        terminalHasNotes = !!ebaPiece.terminalNotes;
        terminalNoteContent = ebaPiece.terminalNotes;
      });

      perTerminal.hasNotes = terminalHasNotes;
      perTerminal.noteContent = terminalNoteContent;

      perTerminal.shippingLines = Object.keys(shippingLines).map((shippingLineName) => ({
        shippingLineName,
        hasNotes: shippingLines[shippingLineName].hasNotes,
        noteContent: shippingLines[shippingLineName].noteContent,
        shifts: [
          {
            containerTypes:
              shippingLines[shippingLineName] && shippingLines[shippingLineName][WorkShifts.TERMINAL_WORK_SHIFT_FIRST]
                ? shippingLines[shippingLineName][WorkShifts.TERMINAL_WORK_SHIFT_FIRST]
                : [],
          },
          {
            containerTypes:
              shippingLines[shippingLineName] && shippingLines[shippingLineName][WorkShifts.TERMINAL_WORK_SHIFT_SECOND]
                ? shippingLines[shippingLineName][WorkShifts.TERMINAL_WORK_SHIFT_SECOND]
                : [],
          },
        ],
      }));

      tree.push(perTerminal);
    });

    return tree;
  }

  private createFilterSet(parentTitle: string, parentValue: string, children: string[]): IOptionItem[] {
    return [
      {
        title: parentTitle,
        value: parentValue,
        children: [
          ...children.map((name: string) => ({
            title: name,
            value: name,
            children: [],
            expandable: false,
            disabled: false,
          })),
        ],
        expandable: true,
        disabled: false,
      },
    ];
  }

  private parseDate(date: number): Date {
    return new Date(date + new Date(date).getTimezoneOffset());
  }

  private doFilterValuesMatch(x: string[], y: string[]): boolean {
    return x.length === y.length && x.every((item) => y.includes(item));
  }

  public parseResponse(response: any): {
    data: EmptyReturnsData[];
    filterSet: IOptionItem[];
  } {
    const sources: any[] = !this.unhandledFilters
      ? response.hits.hits.map((d) => d._source)
      : Object.entries(this.unhandledFilters).reduce(
          (acc, [line, types]) =>
            acc.concat(
              response.hits.hits.reduce(
                (flat, { _source }) =>
                  _source.shippingLineName === line && (types as unknown[]).includes(_source.ebaContainerSizeType)
                    ? [...flat, _source]
                    : flat,
                [],
              ),
            ),
          [],
        );

    const grouped = sources.reduce((acc, c) => ({ ...acc, [c.terminalCode]: [...(acc[c.terminalCode] || []), c] }), {});

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const data = this.parseEbaData(grouped);
    const filterSet = JSON.parse(JSON.stringify(defaultEmptiesReturnFilterset));

    return { data, filterSet };
  }

  public parsePayload({ filters, timestamp }) {
    this.unhandledFilters = null;
    this.selectedDate = timestamp || this.selectedDate;

    const search = [];
    const fromDate = this.getFormattedISODateFor('start', this.selectedDate);
    const toDate = this.getFormattedISODateFor('end', this.selectedDate);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    if (filters && Object.keys(filters).length) {
      if (filters.sizeIntersection) {
        delete filters['sizeIntersection'];
        this.unhandledFilters = { ...filters };
      }

      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      const filterKeys = Object.keys(filters);
      if (
        filters[EmptiesFilterValues.SHIPPING_LINE] &&
        filters[EmptiesFilterValues.SHIPPING_LINE].length > 0 &&
        filterKeys.includes(EmptiesFilterValues.SHIPPING_LINE)
      ) {
        search.push({
          key: EmptiesFilterValues.SHIPPING_LINE,
          values: filters[EmptiesFilterValues.SHIPPING_LINE],
          comparison: 'EQUAL',
        });
      }
      if (
        filters[EmptiesFilterValues.CONTAINER_TYPE] &&
        filters[EmptiesFilterValues.CONTAINER_TYPE].length > 0 &&
        filterKeys.includes(EmptiesFilterValues.CONTAINER_TYPE)
      ) {
        search.push({
          key: EmptiesFilterValues.CONTAINER_TYPE,
          values: filters[EmptiesFilterValues.CONTAINER_TYPE],
          comparison: 'EQUAL',
        });
      }
      if (
        filters[EmptiesFilterValues.TERMINAL] &&
        filters[EmptiesFilterValues.TERMINAL].length > 0 &&
        filterKeys.includes(EmptiesFilterValues.TERMINAL)
      ) {
        search.push({
          key: EmptiesFilterValues.TERMINAL,
          values: filters[EmptiesFilterValues.TERMINAL],
          comparison: 'EQUAL',
        });
      }
    }

    return {
      fromDate,
      toDate,
      search,
      sourceFields: Empties.sourceFields,
      orderBy: 'portCode,terminalCode',
      orderByDirection: 'ASC',
    };
  }
}
