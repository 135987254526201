<div class="schedule-signup">
  <div class="schedule-signup-content" *ngIf="data === 'NOT_FOUND'">
    <div class="schedule-signup-header">
      <span>Sign up</span>
      <div class="cancel-icon">
        <img src="assets/svgs/volt-cross.svg" width="32" height="32" alt="cancel" (click)="closeModal()" />
      </div>
    </div>
    <div class="schedule-signup-info">
      Thank you for your interest in the Port Optimizer Appointment System.
    </div>
    <div class="schedule-signup-info">
      For access to the application, please provide the following information:
    </div>
    <form [formGroup]="signupForm" (ngSubmit)="onSubmit()" class="schedule-signup-form">
      <div class="name-row">
        <div class="name-field">
          <mat-label class="schedule-label">First name </mat-label>
          <mat-form-field appearance="fill" class="signup-field">
            <input matInput formControlName="firstName" required>
            <mat-error *ngIf="signupForm.get('firstName')?.invalid && (signupForm.get('firstName')?.touched || signupForm.get('firstName')?.dirty)" class="signup-error">
              Please enter a valid name
            </mat-error>
          </mat-form-field>
        </div>

        <div class="name-field">
          <mat-label class="schedule-label">Last name </mat-label>
          <mat-form-field appearance="fill" class="signup-field">
            <input matInput formControlName="lastName" required>
            <mat-error *ngIf="signupForm.get('lastName')?.invalid && (signupForm.get('lastName')?.touched || signupForm.get('lastName')?.dirty)" class="signup-error">
              Please enter a valid name
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <mat-label class="schedule-label grey-out">Email </mat-label>
      <mat-form-field appearance="fill" class="grey-out-field">
        <input matInput formControlName="email" required readonly>
      </mat-form-field>

      <mat-label class="schedule-label">Legal company name </mat-label>
      <mat-form-field appearance="fill" class="signup-field">
        <input matInput formControlName="companyName" required>
        <mat-error *ngIf="signupForm.get('companyName')?.hasError('required')" class="signup-error">
          Company name is required
        </mat-error>
      </mat-form-field>

      <mat-label class="schedule-label">SCAC code </mat-label>
      <mat-form-field appearance="fill" class="scac-signup-field">
        <input matInput formControlName="scacCode" required>
        <mat-error *ngIf="signupForm.get('scacCode')?.hasError('required')" class="signup-error">
          SCAC Code is required
        </mat-error>
        <mat-error *ngIf="signupForm.get('scacCode')?.hasError('pattern')" class="signup-error">
          SCAC Code must be 4 uppercase letters
        </mat-error>
        <mat-error *ngIf="signupForm.get('scacCode')?.hasError('apiError')" class="signup-error">
          {{ signupForm.get('apiError')?.value }}
        </mat-error>
      </mat-form-field>
      <div class="schedule-signup-info">
        Once your account is approved, you will receive a confirmation email to the address entered above.
      </div>
      <div class="button-section">
        <button mat-stroked-button color="primary" type="button" class="cancel-signup" (click)="closeModal()">
          Cancel
        </button>
        <button mat-raised-button color="primary" type="submit" class="submit-signup" [disabled]="!isFormValid">
          Submit
        </button>
      </div>
    </form>
  </div>
  <div class="schedule-signup-content" *ngIf="data === 'PENDING'">
    <div class="schedule-signup-info">
      Thank you for your interest in the Port Optimizer Appointment System.
    </div>
    <div class="schedule-signup-info">
      We have received your request and <b>your account is under review.</b> Once approved, you will receive a
      confirmation
      email to the address you provided.
    </div>
    <div class="schedule-signup-info">
      For additional assistance, please feel free to contact Port Optimizer support at
      <a href="mailto:PortOptimizer.Support@wabtec.com">PortOptimizer.Support&#64;wabtec.com</a>
    </div>
    <div class="button-section">
      <button mat-raised-button color="primary" type="submit" class="submit-signup" (click)="closeModal()">
        Close
      </button>
    </div>
  </div>
  <div class="schedule-signup-content" *ngIf="data === 'DENIED'">
    <div class="schedule-signup-info">
      Thank you for your interest in the Port Optimizer Appointment System.
    </div>
    <div class="schedule-signup-info">
      Unfortunately, <b>your access was not approved.</b>
    </div>
    <div class="schedule-signup-info">
      If you feel this denial was in error, please reach out to Port Optimizer support at
      <a href="mailto:PortOptimizer.Support@wabtec.com">PortOptimizer.Support&#64;wabtec.com</a>
    </div>
    <div class="button-section">
      <button mat-raised-button color="primary" type="submit" class="submit-signup" (click)="closeModal()">
        Close
      </button>
    </div>
  </div>