<ng-container *ngIf="(forSearch && searchTerm) || (!forSearch && dataSource)">
  <table mat-table [dataSource]="dataSource" class="request-table">
    <ng-container matColumnDef="containerNumber">
      <th mat-header-cell *matHeaderCellDef [class.bold]="orderField === 'containerNumber'" [style.top]="topOffset">
        CONTAINER
      </th>
      <td mat-cell *matCellDef="let element" class="request-table-data">
        {{ element.containerNumber }}
      </td>
    </ng-container>
  
    <ng-container matColumnDef="equipmentCode">
      <th mat-header-cell *matHeaderCellDef [class.bold]="orderField === 'equipmentCode'" [style.top]="topOffset">
        TYPE CODE
      </th>
      <td mat-cell *matCellDef="let element" class="request-table-data">
        {{ element.typeCode }}
      </td>
    </ng-container>
  
    <ng-container matColumnDef="vesselName">
      <th mat-header-cell *matHeaderCellDef [class.bold]="orderField === 'vesselName'" [style.top]="topOffset">
        VESSEL
      </th>
      <td mat-cell *matCellDef="let element" class="request-table-data" matTooltipPosition="above"
        matTooltipClass="tooltip-transparent" matTooltip="{{ element.vesselName }}">
        {{ element.vesselName }}
      </td>
    </ng-container>
  
    <ng-container matColumnDef="eta">
      <th mat-header-cell *matHeaderCellDef [class.bold]="orderField === 'etaAta'" [style.top]="topOffset">
        ETA / ATA
      </th>
      <td mat-cell *matCellDef="let element" class="request-table-data">
        {{ element.etatimestamp ? (element.etatimestamp | date : 'dd LLL | HH:mm') : (element.etaAta | date : 'dd LLL | HH:mm') }}
      </td>
    </ng-container>
  
    <ng-container matColumnDef="destinationTerminal">
      <th mat-header-cell *matHeaderCellDef [class.bold]="orderField === 'destinationTerminal'"
        [style.top]="topOffset">
        TERMINAL
      </th>
      <td mat-cell *matCellDef="let element" class="request-table-data">
        {{ element.terminalName }}
      </td>
    </ng-container>
  
    <ng-container matColumnDef=" ">
      <th mat-header-cell *matHeaderCellDef [style.top]="topOffset" class="request-table-header">
      </th>
      <td mat-cell *matCellDef="let element" class="request-table-data">
        <ng-container *ngIf="!cancelRequest">
          <a class="remove-link" *ngIf="!forSearch || (forSearch && (inRequestList(element.containerTripId) | async))" (click)="removeRow(element.containerTripId, element.containerNumber)">Remove</a>
          <a class="add-link" *ngIf="forSearch && !(inRequestList(element.containerTripId) | async)" (click)="addContainer(element.containerTripId)"><span class="add-plus">&plus;</span> Add</a>
        </ng-container>
      </td>
    </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</ng-container>
<div class="no-containers-found" *ngIf="forSearch && dataSource.length === 0 && searchTerm">
  <span class="no-container-text">No Containers Found</span>
</div>
