<div block="spinner-overlay" [mod]="{ fillPage: fillPage }" *ngIf="loading">
  <div elem="wrapper">
    <div elem="frame">
      <img src="/assets/images/loading.svg" width="120" height="70" alt="Loading...">
      <div elem="title">
        Loading...
      </div>
    </div>
  </div>
</div>
