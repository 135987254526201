import { Action } from '@ngrx/store';

export const GET_CONTAINER_STATUS_LIST_CHART_RESULTS = '[Chart] GET_CONTAINER_STATUS_LIST_CHART_RESULTS';
export const GET_CONTAINER_STATUS_LIST_CHART_RESULTS_SUCCESS = '[Chart] GET_CONTAINER_STATUS_LIST_CHART_RESULTS_SUCCESS';
export const GET_CONTAINER_STATUS_LIST_CHART_RESULTS_FAILURE = '[Chart] GET_CONTAINER_STATUS_LIST_CHART_RESULTS_FAILURE';
export const SET_UPDATE_CHART = '[Chart] SET_UPDATE_CHART';

export class GetContainerStatusListChartResults implements Action  {
    public readonly type = GET_CONTAINER_STATUS_LIST_CHART_RESULTS;
    constructor(public payload: any = null) { }
}

export class GetContainerStatusListChartResultsSuccess implements Action  {
    public readonly type = GET_CONTAINER_STATUS_LIST_CHART_RESULTS_SUCCESS;
    constructor(public payload: any = null) { }
}

export class GetContainerStatusListChartResultsFailure implements Action  {
    public readonly type = GET_CONTAINER_STATUS_LIST_CHART_RESULTS_FAILURE;
    constructor(public payload: any = null) { }
}

export class SetUpdate implements Action  {
    public readonly type = SET_UPDATE_CHART;
    constructor(public payload: boolean) {}
}

export type All =
    | GetContainerStatusListChartResults
    | GetContainerStatusListChartResultsFailure
    | GetContainerStatusListChartResultsSuccess
    | SetUpdate
    ;
