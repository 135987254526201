import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-add-content',
  templateUrl: './add-content.component.html',
  styleUrls: ['./add-content.component.css'],
})
export class AddContentComponent {
  @Input() public loading: boolean;
  @Output() public addContent = new EventEmitter<string[]>();

  public onAddContent = (): void =>
    this.addContent.emit(['add-containers', 'AddContainersModalComponent'])
}