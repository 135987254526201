<button
  block="table-show-more-button"
  color="accent"
  mat-flat-button
  (click)="onClick()"
  [disabled]="disabled"
  [class.disabled]="disabled"
>
  {{ hasMore ? 'Show more' : 'No more records' }}
</button>
