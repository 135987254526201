import { inject, Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable, BehaviorSubject } from 'rxjs';
import { ApiCallService } from './api-call.service';
import { ApiQueryBuilder } from './api-query-builder';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  private apiCaller = inject(ApiCallService);
  private featureStatusSubject = new BehaviorSubject<any>(null);

  public featureEnabled(featureName: string): boolean {
    if (environment.features) {
      if (environment.features[featureName]) {
        return environment.features[featureName];
      }
    }
    return false;
  }

  public fetchUserFeatureStatus(): Observable<any> {
    if (this.featureStatusSubject.value) {
      return this.featureStatusSubject.asObservable();
    } else {
      const searchRequest = new ApiQueryBuilder().addName('fetchUserFeatureStatus').build();
      return this.apiCaller.constructApiCall(searchRequest).pipe(
        tap(response => this.featureStatusSubject.next(response))
      );
    }
  }
}