export interface IContainerStatus {
  bolNumbers: string[];
  containerNumber: string;
  equipmentCode: string;
  equipmentCategory: string;
  etaAta: number;
  unloadEvent: string;
  terminalName: string;
  vesselName: string;
  vesselCode: number;
  holds: string;
  info: boolean;
  flag?: string;
  customFields?: any;
  owner?: any;
  customsHoldCodes?: any;
  otherHoldCodes?: any;
  terminalHoldCodes?: any;
  customsHoldFlag?: string;
  otherHoldFlag?: string;
  terminalHoldFlag?: string;
  holdsPresent?: boolean;
  isAvailableForPickup: boolean | null;
  availableText?: string;
  note?: any;
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  isPendingContainer: boolean | false;
  containerTripId: any;
  enableStatus: boolean;
  tripId: string;
  isVesselWorking: boolean;
  eta?: number;
  isSpecialHandlingEnabled: boolean;
}
export interface IRequestList {
  terminalId?: number;
  terminalCode?: string;
  terminalName?: string;
  email?: string;
  phone?: string;
}
export interface IContainerStatusDropdownField {
  field: string;
  header: string;
}

export interface Flags {
  value: string;
}

export enum PriorityFlagsEnum {
  NONE = 'NONE',
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
}

export const priorityFlagsList: PriorityFlagsEnum[] = [
  PriorityFlagsEnum.NONE,
  PriorityFlagsEnum.LOW,
  PriorityFlagsEnum.MEDIUM,
  PriorityFlagsEnum.HIGH,
];
