import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'requestHandling',
  })
  export class RequestHandlingPipe implements PipeTransform {
  private readonly SPACES = /.{1,3}/g;

  public transform(value: any): any {
    const pattern = /\D/g;
    const trimmed = value?.replace(pattern, '').match(this.SPACES);
    const phone = '('+(trimmed[0])+') ' +(trimmed[1])+'-'+(trimmed[2])+(trimmed[3]);
    return phone;
  }
}