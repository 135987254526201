import { Component, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { ModalConfirmationService } from '@app/core/services/modal-confirmation.service';

export interface NewWindowConfirmModalData {
  url: string;
  terminalCode: string;
  confirmationType: string;
  confirmationText: string;
  modalTitle: string;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-new-window-confirm-modal',
  templateUrl: './new-window-confirm-modal.component.html',
  styleUrls: ['./new-window-confirm-modal.component.css'],
})
export class NewWindowConfirmModalComponent {
  @Output() public submitData = new EventEmitter<object>();
  @Output() public closeForm = new EventEmitter<void>();

  public _injectedData: NewWindowConfirmModalData;

  public remember: boolean = false;

  constructor(private modalConfirmation: ModalConfirmationService) {}

  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  public onConfirmClick(_: MouseEvent): void {
    if (this.remember) {
      this.modalConfirmation.confirmNewWindow(
        this._injectedData.confirmationType,
        this._injectedData.terminalCode,
      );
    }
    window.open(this._injectedData.url);
    this.closeForm.emit();
  }

  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  public onCloseClick(_: MouseEvent): void {
    this.closeForm.emit();
  }

  get confirmationText() {
    return this._injectedData?.confirmationText ?? '';
  }
}
