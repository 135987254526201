const mappings = {
  'On Vessel': 'On Vessel',
  'Docked': 'Docked',
  'UV': 'Discharged',
};

// eslint-disable-next-line @typescript-eslint/ban-types
const mapStatus = (statuses: object = mappings): Function => (defaultCase: string = ''): Function => (key: string): string =>
  statuses[key] ? statuses[key] : defaultCase;


const parseInnerData = ({ byInboundDockedContainers }): object => {
  const [a, b] = byInboundDockedContainers.buckets.map((curr: any) => ({ [mapStatus()()(curr.key)]: curr.doc_count }));
  return { ...a, ...b };
};

export const parseResponse = ([availables, containers]): any => {
  const sum = containers.response.aggregations.filter.doc_count;
  const availableContainers = availables.response.aggregations.filter.doc_count;
  const availability = availableContainers > 0
    ? ((availableContainers / sum) * 100).toFixed(0)
    : 0;

  const statusesWithSum = containers.response.aggregations.filter.byStatus.buckets
    .reduce((acc: object, curr: any) =>
      curr.key === 'NA'
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        ? { ...acc, ...parseInnerData(curr) }
        : { ...acc, [mapStatus()(curr.key)(curr.key)]: curr.doc_count }
      , {},
    );

  statusesWithSum['Out Gated'] =
    // eslint-disable-next-line no-prototype-builtins
    (statusesWithSum.hasOwnProperty('OA') ? statusesWithSum['OA'] : 0)
    // eslint-disable-next-line no-prototype-builtins
    + (statusesWithSum.hasOwnProperty('AL') ? statusesWithSum['AL'] : 0)
    // eslint-disable-next-line no-prototype-builtins
    + (statusesWithSum.hasOwnProperty('RL') ? statusesWithSum['RL'] : 0);


  return {
    sum,
    availability,
    availableContainers,
    totalContainers: { ...statusesWithSum },
  };
};
