<form
  block="landingPageSettings"
  class="spinner-overlay-parent"
  novalidate
  [formGroup]="landingPageSetting"
>
  <mat-radio-group
    name="radiobuttons"
    aria-labelledby="example-radio-group-label"
    elem="radiogroup"
    formControlName="page"
  >
    <ng-container *ngFor="let page of pages">
      <mat-radio-button
        elem="radiobox"
        [value]="page"
        [checked]="page === selectedPage"
      >
        {{ page }}
        <span elem="default" *ngIf="page === 'Containers'">(Default)</span>
      </mat-radio-button>
    </ng-container>
  </mat-radio-group>
</form>
