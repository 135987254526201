<div class="spinner-overlay-parent">
  <div block="user-preferences">
    <div
      elem="container"
      [mod]="{
        tableActive: userPreferenceTabIndex === 0,
        notificationActive: userPreferenceTabIndex === 1
      }"
    >
      <div elem="header-section">
        <ng-container *ngIf="userPreferenceTabIndex === 0">
          <div>Table Settings</div>
          <div elem="header">
            Please select your display preferences for the datagrids of the
            following settings. A maximum of twelve (12) items can be selected
            at once and once the maximum is met others can not be checked.
          </div>
        </ng-container>
        <ng-container *ngIf="userPreferenceTabIndex === 1">
          <div>Notification Settings</div>
          <div elem="header">
            Below you can customize your notifications. In each section you will
            be given options for how you want to be notified. Some of these
            options will include being able to set timeframes for notifications
            and reminders.
          </div>
        </ng-container>
        <ng-container *ngIf="userPreferenceTabIndex === 2">
          <div>Landing Page Settings</div>
          <div elem="header">
            When opening Port Optimizer Track and Trace please select what
            screen you would like to be taken to first?
          </div>
        </ng-container>
        <div elem="title-row">
          <span elem="spacer"></span>
          <img
            elem="modal-close"
            src="/assets/svgs/close.svg"
            (click)="onCancelClick()"
            alt="Close user preferences"
          />
        </div>
      </div>
      <section>
        <app-loader-overlay [loading]="loading$ | async"></app-loader-overlay>

        <mat-tab-group (selectedTabChange)="onTabChanged($event)">
          <mat-tab label="Tables">
            <app-table-settings-modal #tableSettings
                                      (closeEvent)="onCancelClick()"
                                      (applyButtonStatusEvent)="setApplyButtonStatus($event)"
            ></app-table-settings-modal>
          </mat-tab>

          <mat-tab label="Notifications">
            <app-user-preferences-notification #notificationSettings
                                               (closeEvent)="onCancelClick()"
                                               (applyButtonStatusEvent)="setApplyButtonStatus($event)"
            ></app-user-preferences-notification>
          </mat-tab>

          <mat-tab label="Landing Pages">
            <app-landing-page #landingPageSettings
                              (closeEvent)="onCancelClick()"
                              (applyButtonStatusEvent)="setApplyButtonStatus($event)"
            ></app-landing-page>
          </mat-tab>
        </mat-tab-group>
      </section>
      <div elem="buttons">
        <button
          id="landing-page-cancel-button"
          elem="button"
          mat-flat-button
          color="primary"
          (click)="onCancelClick()"
          type="button"
        >Cancel
        </button>

        <button
          elem="button"
          mat-flat-button
          color="accent"
          type="submit"
          id="submit-button"
          [disabled]="isApplyDisabled"
          [class.disabled]="isApplyDisabled"
          (click)="submitChanges()"
        >Apply
        </button>
      </div>
    </div>
  </div>
</div>
