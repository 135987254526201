import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RoutingState } from './routing.state';
import * as RoutingActions from './routing.actions';

export type Action = RoutingActions.All;

const initialState: RoutingState = {
  routeHistory: [],
};

const getRoutingState = createFeatureSelector<RoutingState>('routing');

export const getRouteHistory = createSelector(
  getRoutingState,
  state => state?.routeHistory,
);

// eslint-disable-next-line @typescript-eslint/naming-convention
export function RoutingReducer(state = initialState, action: Action): RoutingState {
  if(state.routeHistory[0] =='/'){
    localStorage.removeItem('IsLoaded');
    localStorage.setItem('defaultPage', '/container/overview');
  } 
  switch (action.type) {
    case RoutingActions.SAVE_ROUTE: {
      return {
        ...state,
        routeHistory: [...state.routeHistory, action.payload],
      };
    }

    default:
      return state;
  }
}
