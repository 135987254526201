import { IntermodalState } from './intermodal.state';
import * as IntermodalActions from './intermodal.actions';

export type Action = IntermodalActions.All;

const initialState: IntermodalState = {
  results: [],
  loading: false,
  error: false,
  page: 1,
  filter: [],
  containerListFilter: [],
  allIntermodal: [],
  terminalCodes: [],
  selectedTrainTripId: null,
  containersListLoading: false,
  containersListError: false,
  updatingCustomFieldsLoading: false,
  loadingDownload: false,
};

// eslint-disable-next-line max-lines-per-function, @typescript-eslint/naming-convention
export function IntermodalReducer(state = initialState, action: Action): IntermodalState {
  switch (action.type) {
    case IntermodalActions.FETCH_LIST: {
      return {
        ...state,
        loading: true,
        error: false,
        page: action.payload && action.payload.page || 0,
      };
    }

    case IntermodalActions.FETCH_LIST_SUCCESS: {
      const results = action.payload;

      return {
        ...state,
        loading: false,
        results,
      };
    }

    case IntermodalActions.FETCH_LIST_ALL: {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }

    case IntermodalActions.FETCH_LIST_ALL_SUCCESS: {
      const allIntermodal = action.payload;

      return {
        ...state,
        loading: false,
        allIntermodal,
      };
    }

    case IntermodalActions.FETCH_LIST_ERROR: {
      return {
        ...state,
        error: true,
        loading: false,
      };
    }

    case IntermodalActions.FETCH_TERMINAL_CODES_FOR_FILTER_SUCCESS: {
      const terminalCodes = action.payload;

      return {
        ...state,
        terminalCodes,
      };
    }

    case IntermodalActions.FETCH_TERMINAL_CODES_FOR_FILTER_ERROR: {
      return {
        ...state,
        error: true,
      };
    }

    case IntermodalActions.SET_SELECTED_TRAIN_TRIP_ID: {
      return {
        ...state,
        selectedTrainTripId: action.payload,
      };
    }

    case IntermodalActions.FETCH_CONTAINERS_LIST: {
      return {
        ...state,
        containersListLoading: true,
        containersListError: false,
      };
    }

    case IntermodalActions.FETCH_CONTAINERS_LIST_SUCCESS: {
      return {
        ...state,
        containersListLoading: false,
      };
    }

    case IntermodalActions.FETCH_CONTAINERS_LIST_ERROR: {
      return {
        ...state,
        error: true,
        containersListLoading: false,
      };
    }

    case IntermodalActions.UPDATE_FLAG: {
      return {
        ...state,
        updatingCustomFieldsLoading: true,
      };
    }

    case IntermodalActions.UPDATE_FLAG_SUCCESS: {
      return {
        ...state,
        updatingCustomFieldsLoading: false,
      };
    }

    case IntermodalActions.UPDATE_FLAG_ERROR: {
      return {
        ...state,
        updatingCustomFieldsLoading: false,
      };
    }

    case IntermodalActions.UPDATE_CUSTOM_FIELDS: {
      return {
        ...state,
        updatingCustomFieldsLoading: true,
      };
    }

    case IntermodalActions.UPDATE_CUSTOM_FIELDS_SUCCESS: {
      return {
        ...state,
        updatingCustomFieldsLoading: false,
      };
    }

    case IntermodalActions.UPDATE_CUSTOM_FIELDS_ERROR: {
      return {
        ...state,
        updatingCustomFieldsLoading: false,
      };
    }

    case IntermodalActions.SET_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }

    case IntermodalActions.SET_CONTAINER_LIST_FILTER: {
      return {
        ...state,
        containerListFilter: action.payload,
      };
    }

    case IntermodalActions.DOWNLOAD_CSV: {
      return {
        ...state,
        loadingDownload: true,
      };
    }

    case IntermodalActions.DOWNLOAD_CSV_ERROR: {
      return {
        ...state,
        loadingDownload: false,
        error: true,
      };
    }

    case IntermodalActions.DOWNLOAD_CSV_SUCCESS: {
      return {
        ...state,
        loadingDownload: false,
        error: false,
      };
    }

    default:
      return state;
  }
}
