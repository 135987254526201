import { Action } from '@ngrx/store';


export const HIDE_SIDEBAR = '[Details] HIDE_SIDEBAR';
export const GET_CONTAINER_DETAILS = '[Details] GET_CONTAINER_DETAILS';
export const GET_CONTAINER_DETAILS_SUCCESS = '[Details] GET_CONTAINER_DETAILS_SUCCESS';
export const GET_CONTAINER_DETAILS_FAILURE = '[Details] GET_CONTAINER_DETAILS_FAILURE';
export const SAVE_CONTAINER_PDF = '[Details] SAVE_CONTAINER_PDF';
export const SAVE_CONTAINER_PDF_SUCCESS = '[Details] SAVE_CONTAINER_PDF_SUCCESS';
export const SAVE_CONTAINER_PDF_FAILURE = '[Details] SAVE_CONTAINER_PDF_FAILURE';
export const SAVE_NOTES = '[Details] SAVE_NOTES';
export const SAVE_NOTES_SUCCESS = '[Details] SAVE_NOTES_SUCCESS';
export const SAVE_NOTES_FAILURE = '[Details] SAVE_NOTES_FAILURE';
export const SAVE_NOTES_SET_DEFAULT = '[Details] SAVE_NOTES_SET_DEFAULT';


export class GetContainerDetails implements Action  {
    public readonly type = GET_CONTAINER_DETAILS;
    constructor(public payload: any = null) {}
}

export class GetContainerDetailsSuccess implements Action  {
    public readonly type = GET_CONTAINER_DETAILS_SUCCESS;
    constructor(public payload: any = null) { }
}

export class GetContainerDetailsFailure implements Action  {
    public readonly type = GET_CONTAINER_DETAILS_FAILURE;
    constructor(public payload: any = null) { }
}

export class SaveNotes implements Action  {
    public readonly type = SAVE_NOTES;
    constructor(
      public payload: any = null,
      public parent: any = null,
      public isPendingContainer: boolean = false,
    ) { }
}

export class SaveNotesSuccess implements Action  {
    public readonly type = SAVE_NOTES_SUCCESS;
    constructor(public payload: any = null) { }
}

export class SaveNotesSetDefault implements Action  {
  public readonly type = SAVE_NOTES_SET_DEFAULT;
  constructor(public payload: any = null) { }
}

export class HideSidebar implements Action  {
    public readonly type = HIDE_SIDEBAR;
}

export class SaveNotesFailure implements Action  {
    public readonly type = SAVE_NOTES_FAILURE;
    constructor(public payload: any = null) { }
}

export class SaveContainerPdf implements Action  {
    public readonly type = SAVE_CONTAINER_PDF;
    constructor(public payload: any = null) { }
}

export class SaveContainerPdfSuccess implements Action  {
    public readonly type = SAVE_CONTAINER_PDF_SUCCESS;
}

export class SaveContainerPdfFailure implements Action  {
    public readonly type = SAVE_CONTAINER_PDF_FAILURE;
    constructor (public payload: string) {}
}


export type All =
    | HideSidebar
    | GetContainerDetails
    | GetContainerDetailsSuccess
    | GetContainerDetailsFailure
    | SaveNotes
    | SaveNotesSuccess
    | SaveNotesFailure
    | SaveNotesSetDefault
    | SaveContainerPdf
    | SaveContainerPdfSuccess
    | SaveContainerPdfFailure;
