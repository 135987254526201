import { RegisterState } from './register.state';
import * as registerActions from './register.actions';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export type Action = registerActions.All;

const initialState: RegisterState = {
  success: false,
  loading: false,
  error: false,
  errorData: null,
  registrationResponse: null,
};

// selectors

const getRegisterState = createFeatureSelector<RegisterState>('register');

export const getErrorData = createSelector(
  getRegisterState,
  state => (state.error ? state.errorData : null),
);

export const getRegisterStatus = createSelector(
  getRegisterState,
  state => state.success,
);

export const isRegistrationInProgress = createSelector(
  getRegisterState,
  state => state && state.loading,
);

// eslint-disable-next-line @typescript-eslint/naming-convention
export function RegisterReducer(state = initialState, action: Action): RegisterState {
  switch (action.type) {
    case registerActions.REGISTER: {
      return { ...state, loading: true };
    }
    case registerActions.REGISTER_SUCCESS: {
      return { ...state, loading: false, success: true, error: false, registrationResponse: action.payload };
    }
    case registerActions.REGISTER_ERROR: {
      return { ...state, loading: false, error: true, success: false, errorData: action.payload };
    }
    default:
      return state;
  }
}
