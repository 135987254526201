import { Action } from '@ngrx/store';
import { MatDialogRef } from '@angular/material/dialog';

export const OPEN_DIALOG = '[Modal] OPEN_DIALOG';
export const CLOSE_DIALOG = '[Modal] CLOSE_DIALOG';
export const RESULT_DIALOG = '[Modal] RESULT_DIALOG';
export const SET_DIALOG_REF = '[Modal] SET_DIALOG_REF';
export const CLEAR_DIALOG_REF = '[Modal] CLEAR_DIALOG_REF';

export class OpenDialog implements Action {
  public readonly type = OPEN_DIALOG;
  constructor(public payload?: any ) { }
}

export class SetDialogRef implements Action {
  public readonly type = SET_DIALOG_REF;
  constructor(public payload?: MatDialogRef<any> ) { }
}

export class ClearDialogRef implements Action {
  public readonly type = CLEAR_DIALOG_REF;
}

export class CloseDialog implements Action {
  public readonly type = CLOSE_DIALOG;
}

export class ResultDialog implements Action {
  public readonly type = RESULT_DIALOG;
  constructor(public payload: { animal: string }) { }
}

export type All =
  | OpenDialog
  | SetDialogRef
  | ClearDialogRef
  | CloseDialog
  | ResultDialog;
