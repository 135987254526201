import { Action } from '@ngrx/store';
import { IParty } from '@models/dashboard/interested-parties.model';

export const GET_INTERESTED_PARTIES = '[Interested Parties] GET_INTERESTED_PARTIES';
export const SET_INTERESTED_PARTIES = '[Interested Parties] SET_INTERESTED_PARTIES';
export const GET_INTERESTED_PARTIES_SUCCESS = '[Interested Parties] GET_INTERESTED_PARTIES_SUCCESS';
export const GET_INTERESTED_PARTIES_FAILURE = '[Interested Parties] GET_INTERESTED_PARTIES_FAILURE';

export const GET_ALL_USER = '[Interested Parties] GET_ALL_USER';
export const GET_ALL_USER_SUCCESS = '[Interested Parties] GET_ALL_USER_SUCCESS';
export const GET_ALL_USER_FAILURE = '[Interested Parties] GET_ALL_USER_FAILURE';

export class GetInterestedParties implements Action  {
    public readonly type = GET_INTERESTED_PARTIES;
}

export class SetInterestedParties implements Action  {
    public readonly type = SET_INTERESTED_PARTIES;
    constructor (public payload: IParty[]) {}
}

export class GetInterestedPartiesSuccess implements Action  {
    public readonly type = GET_INTERESTED_PARTIES_SUCCESS;
    constructor(public payload: IParty[] = null) {}
}

export class GetInterestedPartiesFailure implements Action  {
    public readonly type = GET_INTERESTED_PARTIES_FAILURE;
}

export class GetAllUser implements Action  {
    public readonly type = GET_ALL_USER;
}

export class GetAllUserSuccess implements Action  {
    public readonly type = GET_ALL_USER_SUCCESS;
    constructor(public payload: IParty[] = null) {}
}

export class GetAllUserFailure implements Action  {
    public readonly type = GET_ALL_USER_FAILURE;
    constructor(public payload: any = null) {}
}

export type All =
    | GetInterestedParties
    | SetInterestedParties
    | GetInterestedPartiesSuccess
    | GetInterestedPartiesFailure
    | GetAllUser
    | GetAllUserSuccess
    | GetAllUserFailure;
