import { Action } from '@ngrx/store';

export const GET_CONTAINER_CHART_AVAILABILITY = '[Chart Availability] GET_CONTAINER_CHART_AVAILABILITY';
export const GET_CONTAINER_CHART_AVAILABILITY_SUCCESS = '[Chart Availability] GET_CONTAINER_CHART_AVAILABILITY_SUCCESS';
export const GET_CONTAINER_CHART_AVAILABILITY_ERROR = '[Chart Availability] GET_CONTAINER_CHART_AVAILABILITY_ERROR';

export class GetContainerChartAvailability implements Action  {
    public readonly type = GET_CONTAINER_CHART_AVAILABILITY;
    constructor(public payload: any = null) {}
}

export class GetContainerChartAvailabilitySuccess implements Action  {
    public readonly type = GET_CONTAINER_CHART_AVAILABILITY_SUCCESS;
    constructor(public payload: any = null) {}
}

export class GetContainerChartAvailabilityError implements Action  {
    public readonly type = GET_CONTAINER_CHART_AVAILABILITY_ERROR;
    constructor(public payload: any = null) {}
}

export type All = GetContainerChartAvailability | GetContainerChartAvailabilitySuccess | GetContainerChartAvailabilityError;
