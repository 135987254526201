import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import * as UserPreferencesActions from './user-preferences.actions';

import { ApiQueryBuilder } from '@services/api-query-builder';
import { ApiCallService } from '@services/api-call.service';

@Injectable()
export class UserPreferencesEffects {
  constructor(
    private actions$: Actions,
    private api: ApiCallService,
  ) {
  }

  public getUserPreferencesColumns$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType<UserPreferencesActions.SaveUserPreferences>(UserPreferencesActions.SAVE_USER_PREFERENCES),
      mergeMap(action =>
        this.api.constructApiCall(
          new ApiQueryBuilder()
            .addName('userPreferences')
            .addBody(action.payload)
            .build(),
        )
          .pipe(
            map(() => new UserPreferencesActions.SaveUserPreferencesSuccess(action.payload)),
            catchError((error) => of(new UserPreferencesActions.SaveUserPreferencesError(error)),
            ),
          ),
      ),
    ),
    );
}
