import { Action } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';

export const FETCH_DATA = '[EmptiesReturn] FETCH_DATA';
export const FETCH_DATA_SUCCESS = '[EmptiesReturn] FETCH_DATA_SUCCESS';
export const FETCH_DATA_ERROR = '[EmptiesReturn] FETCH_DATA_ERROR';

export const FETCH_PUBLIC_DATA = '[EmptiesReturn] FETCH_PUBLIC_DATA';
export const FETCH_PUBLIC_DATA_SUCCESS = '[EmptiesReturn] FETCH_PUBLIC_DATA_SUCCESS';
export const FETCH_PUBLIC_DATA_ERROR = '[EmptiesReturn] FETCH_PUBLIC_DATA_ERROR';

export const UPDATE_FILTER = '[EmptiesReturn] UPDATE_FILTER';

export const DOWNLOAD_EXCEL = '[EmptiesReturn] DOWNLOAD_EXCEL';
export const DOWNLOAD_EXCEL_SUCCESS = '[EmptiesReturn] DOWNLOAD_EXCEL_SUCCESS';
export const DOWNLOAD_EXCEL_ERROR = '[EmptiesReturn] DOWNLOAD_EXCEL_ERROR';


export class FetchData implements Action {
  public readonly type = FETCH_DATA;
  constructor(public payload: any = null) { }
}

export class FetchDataSuccess implements Action {
  public readonly type = FETCH_DATA_SUCCESS;
  constructor(public payload: any = null) { }
}

export class FetchDataError implements Action {
  public readonly type = FETCH_DATA_ERROR;
  constructor(public payload: any = null) { }
}

export class FetchPublicData implements Action {
  public readonly type = FETCH_PUBLIC_DATA;
  constructor(public payload: any = null) { }
}

export class FetchPublicDataSuccess implements Action {
  public readonly type = FETCH_PUBLIC_DATA_SUCCESS;
  constructor(public payload: any = null) { }
}

export class FetchPublicDataError implements Action {
  public readonly type = FETCH_PUBLIC_DATA_ERROR;
  constructor(public payload: any = null) { }
}

export class UpdateFilter implements Action {
  public readonly type = UPDATE_FILTER;
  constructor(public payload: any) { }
}

export class DownloadExcel implements Action {
  public readonly type = DOWNLOAD_EXCEL;
  constructor(public payload: any) { }
}
export class DownloadExcelSuccess implements Action {
  public readonly type = DOWNLOAD_EXCEL_SUCCESS;
  constructor(public payload: any) { }
}
export class DownloadExcelError implements Action {
  public readonly type = DOWNLOAD_EXCEL_ERROR;
  constructor(public payload: HttpErrorResponse) { }
}


export type All =
  | FetchData
  | FetchDataSuccess
  | FetchDataError
  | FetchPublicData
  | FetchPublicDataSuccess
  | FetchPublicDataError
  | UpdateFilter
  | DownloadExcel
  | DownloadExcelSuccess
  | DownloadExcelError;
