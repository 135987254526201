export const chartRequiredParsedObject = {
  'available': {
    'items': {},
    'sum': 0,
  },
  'discharged': {
    'items': {},
    'sum': 0,
  },
  'docked': {
    'items': {},
    'sum': 0,
  },
  'onVessel': {
    'items': {},
    'sum': 0,
  },
  'outGated': {
    'items': {},
    'sum': 0,
  },
  'etaDate': 0,
};

export const shipmentStatusFilter = {
  'filterKey': 'shipmentStatusCd',
  'filterValues': [],
};

export const vesselStatusFilter = {
  'filterKey': 'vesselStatus',
  'filterValues': [],
};

export const importExportFilter = {
  'filterKey': 'importExport',
  'filterValues': ['I'],
};

export const shipmentStatusMappingValues = {
  'On Vessel': ['NA'],
  'Docked': ['NA'],
  'Discharged': ['UV'],
  'Out Gated': ['OA', 'AL', 'RL'],
};

export const holdsSelectedMappingValues = {
  'TERMINAL': 'terminalHoldFlag',
  'CUSTOMS': 'customsHoldFlag',
  'NONE': 'otherHoldFlag',
};

export const isAvailableForPickupFilter = {
  'filterKey': 'isAvailableForPickup',
  'filterValues': ['Available'],
};

export const universalSearchFilterValues = ['VESSEL_NAME', 'BOL_NUMBER', 'TERMINAL_NAME'];

export const TERMINAL_NAME = {
  'filterKey': 'destinationTerminalCode',
  'filterValues': [],
};

export const VESSEL_NAME = {
  'filterKey': 'vesselName',
  'filterValues': [],
};

export const TRIP_ID = {
  'filterKey': 'tripId',
  'filterValues': [],
};

export const BOL_NUMBER = {
  'filterKey': 'bolNumber',
  'filterValues': [],
};

export const holdsFilter = {
  'filterKey': '',
  'filterValues': ['Y'],
};

export const priorityFilter = {
  'filterKey': 'priority',
  'filterValues': [],
};

export const ownerFilter = {
  'filterKey': 'ownerId',
  'filterValues': [],
};

export const containerSizeFilter = {
  'filterKey': 'containerLength',
  'filterValues': [],
};

export const universalSearchFilter = {
  'filterKey': 'containerNumber,destinationTerminalCode,bolNumber,vesseName',
  'filterValues': [],
  'comparison': 'CASE_INSENSITIVE_REGEX',
};

export const chartRequestObject = {
  'fromDate': new Date(new Date(new Date().setDate(new Date().getDate() - 7))).toISOString().substr(0, 10) + 'T00:00:00',
  'toDate': new Date(new Date(new Date().setDate(new Date().getDate() + 7))).toISOString().substr(0, 10) + 'T23:59:59',
  'fetchNonOrRecentlyOutgatedContainers': true,
  'searchParameters': [
    {
      'key': 'byEtaAta',
      'children': [
        {
          'key': 'byStatus',
          'children': [
            {
              'key': 'byInboundDockedContainers',
              'children': [],
            },
          ],
          'fetchSource': true,
        },
        {
          'key': 'byAvailability',
          'children': [],
          'filters': [
            {
              'filterKey': 'shipmentStatusCd',
              'filterValues': ['NA', 'UV'],
            },
          ],
        },
      ],
      'firstSortPreference': {
        'orderBy': 'KEY',
        'orderByDirection': 'ASC',
      },
      'secondSortPreference': {
        'orderBy': 'COUNT',
        'orderByDirection': 'ASC',
      },
      'fetchSource': true,
      'filters': [],
      'fetchLastAddedContainers': false,
      'customFieldsFilter': {
        'filterOnCustomFields': false,
        'filterOnCustomFieldsData': [],
      },
    },
  ],
};

export enum containerStatusEnum {
  ON_VESSEL = 'On Vessel',
  DOCKED = 'Docked',
  OUTGATED = 'Outgated',
  DISCHARGED = 'Discharged'
}
