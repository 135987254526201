/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { ApiQueryBuilder } from '@services/api-query-builder';
import { ApiCallService } from '@services/api-call.service';
import { defaultToNull, getTooltip, parseDay } from './chart.helpers';
import * as ChartActions from './chart.actions';
import { ChartService } from '@services/chart.service';

@Injectable()
export class ChartEffects {
  constructor(
    private actions$: Actions,
    private api: ApiCallService,
    private chartService: ChartService,
  ) { }

  public GetContainerStatusListChartResults$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<ChartActions.GetContainerStatusListChartResults>(ChartActions.GET_CONTAINER_STATUS_LIST_CHART_RESULTS),
    mergeMap(action =>
      this.api.constructApiCall(
        new ApiQueryBuilder()
        .addName(this.chartService.getChartEndpointName())
        .addBody(this.chartService.getRequestParamsToSend(action.payload))
          .build(),
      ).pipe(
        map((response: any) => {
          let parsedResponse = response;
          if (this.chartService.displayNewChart()) {
            parsedResponse = this.chartService.getParsedChartResponse(response);
          }
          return parsedResponse.map((dailyChartData: any) => [
            parseDay(dailyChartData),
            getTooltip(dailyChartData),
            defaultToNull(dailyChartData.outGated),
            defaultToNull(dailyChartData.discharged),
            defaultToNull(dailyChartData.docked),
            defaultToNull(dailyChartData.onVessel),
          ]);
        }),
        map(chartData => new ChartActions.GetContainerStatusListChartResultsSuccess(chartData)),
        catchError(error => of(new ChartActions.GetContainerStatusListChartResultsFailure(error))),
      ),
    ),
  ),
  );
}
