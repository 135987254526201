import { USER_DEAUTHENTICATE } from '@store/user/user.actions';
import { ActionReducer, Action } from '@ngrx/store';

const INIT = 'GET_INITIAL_STATE';

export function logout(reducer: ActionReducer<any>): ActionReducer<any> {
  const initialState = reducer(undefined, { type: INIT });

  return function(state: any, action: Action) {
    if (action.type === USER_DEAUTHENTICATE) {
      return reducer(
        {
          ...initialState,
          user: {
            ...initialState.user,
            isSessionExpired: state.user.isSessionExpired,
          },
        },
        action,
      );
    }

    return reducer(state, action);
  };
}
