import { IOptionItem } from '@models/dashboard/tree-option-filter.model';

export interface IRequestFilterEntry {
  filterKey: string;
  filterValues: any[];
}

export interface ISelectedItems {
  selected: string[];
  isAll: boolean;
  selectedPendingContainers?: any[];
  selectedContainerData?: any[];
  requestSpecialList?: any[];
}

export interface IFilterEntry {
  type: string;
  searchValue: string;
  customFieldName?: string;
  exactMatch?: boolean;
}

export interface ITypeFilter {
  type?: string;
  filters: IFilterEntry[] | ITypeFilter[];
}

export interface IPartFilter {
  name: FilterPartType | string;
  typeFilters: ITypeFilter[];
}

export interface IFilter {
  partFilters: IPartFilter[];
  pendingApplied?: boolean;
}

export interface IOrderProp {
  property: string;
  direction: string;
}

export interface IPartFilterAndOrderState {
  partFilters: IPartFilter[];
  orders: IOrderProp[];
}

export interface UserInfo {
  email: string;
  familyName: string;
  fullName?: string;
  givenName: string;
  id: string;
}

export interface IFilterChips {
  displayName: string;
  value: string;
}

export enum FilterPartType {
  CONTAINER = 'container',
  UNIVERSAL = 'universal',
  DOWNLOAD = 'download',
  DETAILS = 'details',
  EMPTIES = 'empties',
  TERMINAL_NAME = 'terminalName',
  VESSEL_CODE = 'vesselCode',
  TRIP_ID = 'tripId',
}

export enum FilterTypeNames {
  UNLOAD_EVENT = 'Status',
  HOLDS = 'Holds',
  BOL_NUMBER = 'BoL Number',
  CONTAINER_NUMBER = 'Container Number',
  TERMINAL_NAME = 'Terminal',
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const FieldTypeMap: Map<string, string> = new Map([
  ['containerResults', 'CONTAINER_NUMBER'],
  ['bolResults', 'BOL_NUMBER'],
  ['vesselResults', 'VESSEL_NAME'],
  ['terminalResults', 'TERMINAL_NAME'],
  ['customField1Results', 'CUSTOM_FIELD_1'],
  ['customField2Results', 'CUSTOM_FIELD_2'],
]);

export const OWNER = 'OWNER';
export const HOLDS = 'HOLDS';
export const MY_CONTAINERS = 'My Containers';
export const USER_ID_LENGTH = 36;
export const AVAILABLE = 'Available';
export const IS_AVAILABLE_FOR_PICKUP = 'IS_AVAILABLE_FOR_PICKUP';
export const FLAG = 'FLAG';
export const UNLOAD_EVENT = 'UNLOAD_EVENT';
export const CONTAINER_LENGTH = 'CONTAINER_LENGTH';
export const LAST_ADDED = 'Last Added';
export const LAST_CLAIMED = 'LAST_CLAIMED';
export const CUSTOM = 'Custom';
export const OTHER = 'Others';
export const TERMINAL = 'Terminal';
export const PENDING_CONTAINERS = 'Pending Containers';
export const VESSEL_MY_CONTAINER = 'vesselMyContainer';
export const VESSEL_MY_CONTAINERS = 'My Container(s)';
export const VESSEL_STATUS = 'statusValues';
export const TERMINALS = 'Terminals';
export const BOL_NUMBER = 'BOL_NUMBER';
export const TERMINAL_NAME = 'TERMINAL_NAME';
export const VESSEL_NAME = 'VESSEL_NAME';
export const CONTAINER_NUMBER = 'CONTAINER_NUMBER';
export const TRIP_ID = 'TRIP_ID';
export const WORKING_VESSELS = 'Working Vessels';
export const VESSEL = 'Vessel';
export const VESSEL_FILTER = 'vesselName';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const ContainersFilterSet: IOptionItem[] = [
  {
    title: 'Holds',
    value: HOLDS,
    children: [
      {
        title: 'Custom',
        value: 'CUSTOMS',
        children: [],
        expandable: false,
        disabled: false,
      },
      {
        title: 'Terminal',
        value: 'TERMINAL',
        children: [],
        expandable: false,
        disabled: false,
      },
      {
        title: 'Others',
        value: 'NONE',
        children: [],
        expandable: false,
      },
    ],
    expandable: false,
  },
  {
    title: 'Priority',
    value: FLAG,
    children: [
      {
        title: 'Low',
        value: 'LOW',
        children: [],
        expandable: false,
        disabled: false,
      },
      {
        title: 'Medium',
        value: 'MEDIUM',
        children: [],
        expandable: false,
        disabled: false,
      },
      {
        title: 'High',
        value: 'HIGH',
        children: [],
        expandable: false,
      },
    ],
    expandable: false,
    disabled: false,
  },
  {
    title: 'Status',
    value: UNLOAD_EVENT,
    children: [
      {
        title: 'On Vessel',
        value: 'On Vessel',
        children: [],
        expandable: false,
        disabled: false,
      },
      {
        title: 'Docked',
        value: 'Docked',
        children: [],
        expandable: false,
        disabled: false,
      },
      {
        title: 'Discharged',
        value: 'Discharged',
        children: [],
        expandable: false,
        disabled: false,
      },
      {
        title: 'Out Gated',
        value: 'Out Gated',
        children: [],
        expandable: false,
        disabled: false,
      },
    ],
    expandable: false,
    disabled: false,
  },
  {
    title: 'Container Size',
    value: CONTAINER_LENGTH,
    children: [
      {
        title: '20',
        value: '20',
        children: [],
        expandable: false,
        disabled: false,
      },
      {
        title: '40',
        value: '40',
        children: [],
        expandable: false,
        disabled: false,
      },
      {
        title: '45',
        value: '45',
        children: [],
        expandable: false,
      },
      {
        title: 'Others',
        value: 'Others',
        children: [],
        expandable: false,
        disabled: false,
      },
    ],
    expandable: false,
    disabled: false,
  },
];

// eslint-disable-next-line @typescript-eslint/naming-convention
export const VesselFilterSet: IOptionItem[] = [
  {
    title: 'My containers',
    value: 'vesselMyContainer',
    children: [
      {
        title: 'My containers',
        value: 'vesselMyContainer',
        children: [],
        expandable: false,
        disabled: false,
      },
    ],
    expandable: false,
    disabled: false,
  },
  {
    title: 'Status',
    value: 'statusValues',
    children: [
      {
        title: 'Inbound',
        value: 'NA',
        children: [],
        expandable: false,
        disabled: false,
      },
      {
        title: 'Docked',
        value: 'docked',
        children: [],
        expandable: false,
        disabled: false,
      },
      {
        title: 'Working',
        value: 'unloading',
        children: [],
        expandable: false,
        disabled: false,
      },
      {
        title: 'Outbound',
        value: 'outbound',
        children: [],
        expandable: false,
        disabled: false,
      },
    ],
    expandable: true,
    disabled: false,
  },
];

export namespace Empties {
  export const sourceFields: string[] = [
    'containerNotes',
    'shippingLineNotes',
    'terminalNotes',
    'portCode',
    'workShift',
    'notes',
    'shippingLineName',
    'emptiesInd',
    'terminalCode',
    'dualTrasFlag',
    'ebaContainerSizeType',
  ];
  export const searchSourceFields: string[] = [
    'containerNumber',
    'bolNumber',
    'vesselName',
    'destinationTerminalCode',
    'eta',
    'etaTimeStamp',
    'ata',
    'ataTimeStamp',
    'dischargeDateTime',
    'outgateDateTime',
    'shipmentStatusCd',
    'isContainerDocked',
    'customsHoldFlag',
    'customsHoldCodes',
    'terminalHoldFlag',
    'terminalHoldCodes',
    'otherHoldFlag',
    'otherHoldCodes',
    'pendingCustHoldFlag',
    'pendingCustHoldCodes',
    'currentPosition',
    'equipmentCode',
    'equipmentCategory',
    'isAvailableForPickup',
    'lastFreeDay',
    'shipperName',
    'shippingLineName',
    'voyageNumber',
    'isHazardous',
    'hazMatClassCode',
    'berthCode',
    'destinationMode',
    'chassisNumber',
    'shippingLineSCAC',
    'truckerSCAC',
    'railRoadSCAC',
    'vesselOwnerSCAC',
    'vesselCode',
    'containerTripId',
    'containerStatus',
    'ataOrAtaTimeStamp',
    'etaOrEtaTimeStamp',
    'outgate',
    'tripId',
    'appointmentStatus',
    'appointmentStatusMapper',
    'appointmentDate',
    'appointmentSlotEndDateTime',
    'avgBerthTime',
    'avgDischargeTime',
    'avgLoadTime',
    'ingateTerminalCd',
    'ingateDateTime',
    'outgateDateTimeByTruck',
    'totalTime',
    'carrierName',
    'outgateTerminalCd',
  ];
}

export namespace Terminal {
  const duplicatedProps: Partial<IOptionItem> = {
    children: [],
    expandable: false,
    disabled: false,
  };

  const nodes: Partial<IOptionItem>[] = [
    { title: MY_CONTAINERS, value: MY_CONTAINERS },
    { title: AVAILABLE, value: AVAILABLE },
    { title: TERMINALS, value: TERMINALS },
  ];

  export const containerFilterSet: IOptionItem[] = nodes.map(({ title, value }) => ({
    title,
    value,
    children: title === TERMINALS ? [] : [{ title, value, ...duplicatedProps } as IOptionItem],
    expandable: false,
    disabled: false,
  }));
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const AvailableForPickupCountRequest = {
  fromDate: new Date(new Date(new Date().setDate(new Date().getDate() - 90))).toISOString().substr(0, 10) + 'T00:00:00',
  toDate: new Date(new Date(new Date().setDate(new Date().getDate() + 90))).toISOString().substr(0, 10) + 'T23:59:59',
  fetchNonOrRecentlyOutgatedContainers: true,
  searchParameters: [
    {
      key: 'byAvailableForPickup',
      children: [],
      filters: [
        { filterKey: 'importExport', filterValues: ['I'] },
        { filterKey: 'shipmentStatusCd', filterValues: ['NA', 'UV'] },
      ],
      fetchSource: true,
    },
  ],
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export const HoldsCountRequest = {
  searchRequests: [
    {
      fromDate:
        new Date(new Date(new Date().setDate(new Date().getDate() - 90))).toISOString().substr(0, 10) + 'T00:00:00',
      toDate:
        new Date(new Date(new Date().setDate(new Date().getDate() + 90))).toISOString().substr(0, 10) + 'T23:59:59',
      fetchNonOrRecentlyOutgatedContainers: true,
      searchParameters: [
        {
          key: 'byCustomsHolds',
          children: [],
          filters: [
            { filterKey: 'customsHoldFlag', filterValues: ['Y'] },
            { filterKey: 'importExport', filterValues: ['I'] },
            { filterKey: 'shipmentStatusCd', filterValues: ['NA', 'UV'] },
          ],
        },
      ],
    },
    {
      fromDate:
        new Date(new Date(new Date().setDate(new Date().getDate() - 90))).toISOString().substr(0, 10) + 'T00:00:00',
      toDate:
        new Date(new Date(new Date().setDate(new Date().getDate() + 90))).toISOString().substr(0, 10) + 'T23:59:59',
      fetchNonOrRecentlyOutgatedContainers: true,
      searchParameters: [
        {
          key: 'byTerminalHolds',
          children: [],
          filters: [
            { filterKey: 'terminalHoldFlag', filterValues: ['Y'] },
            { filterKey: 'importExport', filterValues: ['I'] },
            { filterKey: 'shipmentStatusCd', filterValues: ['NA', 'UV'] },
          ],
        },
      ],
    },
    {
      fromDate:
        new Date(new Date(new Date().setDate(new Date().getDate() - 90))).toISOString().substr(0, 10) + 'T00:00:00',
      toDate:
        new Date(new Date(new Date().setDate(new Date().getDate() + 90))).toISOString().substr(0, 10) + 'T23:59:59',
      fetchNonOrRecentlyOutgatedContainers: true,
      searchParameters: [
        {
          key: 'byOtherHolds',
          children: [],
          filters: [
            { filterKey: 'otherHoldFlag', filterValues: ['Y'] },
            { filterKey: 'importExport', filterValues: ['I'] },
            { filterKey: 'shipmentStatusCd', filterValues: ['NA', 'UV'] },
          ],
        },
      ],
    },
  ],
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export const IntermodalFilterSet: IOptionItem[] = [];
