import { InjectionToken } from '@angular/core';

export interface ToastConfig {
  position?: {
      top: number;
      right: number;
  };
  animation?: {
      fadeOut: number;
      fadeIn: number;
  };
}

export const defaultToastConfig: ToastConfig = {
  position: {
      top: 70,
      right: 20,
  },
  animation: {
      fadeOut: 300,
      fadeIn: 300,
  },
};

export const TOAST_CONFIG_TOKEN = new InjectionToken('toast-config');
