import { Action } from '@ngrx/store';

export const UNCLAIM_CONTAINERS = '[Unclaim] UNCLAIM_CONTAINERS';
export const UNCLAIM_CONTAINERS_RESULT = '[Unclaim] UNCLAIM_CONTAINERS_RESULT';
export const UNCLAIM_CONTAINERS_FAILED = '[Unclaim] UNCLAIM_CONTAINERS_FAILED';
export const UNCLAIM_CLEAR = '[Unclaim] UNCLAIM_CLEAR';

export class UnclaimContainers implements Action {
  public readonly type = UNCLAIM_CONTAINERS;
  constructor(
    public payload: any,
    public parent: string,
  ) { }
}

export class UnclaimResult implements Action {
  public readonly type = UNCLAIM_CONTAINERS_RESULT;
  constructor(public payload: any) { }
}

export class UnclaimFailed implements Action {
  public readonly type = UNCLAIM_CONTAINERS_FAILED;
  constructor(public payload: string[]) { }
}

export class Clear implements Action {
  public readonly type = UNCLAIM_CLEAR;
}

export type All =
  | UnclaimContainers
  | UnclaimResult
  | Clear
  | UnclaimFailed;
