import { Injectable } from '@angular/core';

import {
  FieldTypeMap,
  FilterPartType,
  IFilter,
  IFilterEntry,
  IPartFilter,
  ITypeFilter,
} from '@shared/models/dashboard/filters.model';

@Injectable({
  providedIn: 'root',
})
export class FilterService {
  public filterValue = undefined;
  public filterValueVessel: any;
  public filterValueTerminal: any;

  public composeFilters = (filterParts: IPartFilter[]): ITypeFilter[] => {
    if (filterParts && filterParts.length) {
      if (filterParts.length === 1) {
        // No need for composition
        return [{
          type: filterParts[0].typeFilters[0].type,
          filters: filterParts[0].typeFilters[0].filters,
        }];
      } else {
        // Compose filters
        const filters = filterParts.map(fp => fp.typeFilters[0]);
        return [{
          type: 'AND',
          filters,
        }];
      }
    } else {
      return [];
    }
  }

  public singleResults = (data: object) =>
    Object.keys(data).map(type =>
      ({
        type,
        size: data[type].length || 0,
        items: data[type],
      }),
    )

  public generateFilter = (filterResults: any[], param: string): IFilterEntry[] =>
    filterResults.map(byType => {
      switch (byType.type) {
        case 'customField1Results': {
          return {
            type: 'CUSTOM_FIELD',
            customFieldName: 'customField1',
            searchValue: param,
          };
        }

        case 'customField2Results': {
          return {
            type: 'CUSTOM_FIELD',
            customFieldName: 'customField2',
            searchValue: param,
          };
        }

        default: {
          return {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            type: FieldTypeMap.get(byType.type),
            searchValue: param,
          };
        }
      }
    })

  public getFilterListFromValueArray = (items: string[] = [], type: string): IFilterEntry[] => items.map(item => {
    const result: IFilterEntry = <IFilterEntry>{
      type,
      searchValue: item,
    };
    if (type === 'OWNER') {
      result.exactMatch = true;
    }
    return result;
  })

  // My container filter needs boolean value, so modified it to any
  public getTypeFilter = (type: string, items: any[], op: string = 'OR'): ITypeFilter =>
    (<ITypeFilter>{
      type: op,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      filters: this.getFilterListFromValueArray(items, type),
    })

  public getFilterPayloadFromPartfilter = (
    filterPartsValue: IPartFilter[],
    type: FilterPartType | string,
    filterPart: IPartFilter,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    partLogicType: string = null,
  ): IFilter => {
    let base: IPartFilter[] = [];

    if (filterPartsValue.length) {
      base = filterPartsValue.filter((part: IPartFilter) => part.name !== type);
    }
    base.push(filterPart);

    const filter: IPartFilter[] = base.length ? base : [];

    return <IFilter>{
      filters: this.composeFilters(filter),
      partFilters: base,
    };
  }

  public getFilterPayload = (
    filterPartsValue: IPartFilter[],
    type: FilterPartType,
    filterList: IFilterEntry[],
    partLogicType: string = null,
  ): IFilter => {
    let base: IPartFilter[] = [];

    if (filterPartsValue.length) {
      base = filterPartsValue.filter(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
        (part: IPartFilter) => part.name !== type,
      );
    }

    const entry: ITypeFilter[] = partLogicType
      ? [{ type: partLogicType, filters: filterList }]
      : [{ filters: filterList }];


    if (filterList.length) {
      base.push({
        name: type,
        typeFilters: entry,
      });
    }

    const filter: IPartFilter[] = base.length ? base : [];

    return <IFilter>{
      filters: this.composeFilters(filter),
      partFilters: base,
    };
  }

  public buildFilter = (filterType: string, value: string): IFilterEntry[] =>
    value ? [{ type: filterType, searchValue: value }] : []
}
