<button
  block="download-button"
  (click)="onClick()"
  [class.disabled]="downloading || disabled"
>
  {{ downloading ? "Downloading..." : "Download" }}
  <img
    alt="Download"
    elem="icon"
    src="/assets/svgs/{{
      downloading ? 'clock' : disabled ? 'download-gray' : 'download'
    }}.svg"
  />
</button>
