import { Component, Output, EventEmitter, OnInit, ChangeDetectionStrategy } from '@angular/core';

import { State } from '@app/store/app.state';
import { Store, select } from '@ngrx/store';
import * as fromHoldCode from '@store/hold-codes/hold-codes.reducer';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-holds-modal',
  templateUrl: './holds-modal.component.html',
  styleUrls: ['./holds-modal.component.css'],
})
export class HoldsModalComponent implements OnInit {
  @Output() public closeForm = new EventEmitter<void>();

  public readonly _injectedData: any;

  public error$: Observable<boolean>;
  public loading$: Observable<boolean>;
  public holdCodesData$: Observable<any>;

  constructor(private store: Store<State>) {}

  public ngOnInit(): void {
    this.holdCodesData$ = this.store.pipe(
      select(fromHoldCode.getHoldCodeData),
      map((data: any) => data.hits),
    );
    this.error$ = this.store.pipe(select(fromHoldCode.getHoldCodeError));
    this.loading$ = this.store.pipe(select(fromHoldCode.isLoading));
  }

  public closeModal = (): void => this.closeForm.emit();
}
