import { Injectable } from '@angular/core';
import { ApiQueryBuilder } from '@services/api-query-builder';
import { ApiCallService } from '@services/api-call.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WhatsnewPopupService {
  
  constructor(private apiCaller: ApiCallService) { }

  public whatsNewArchiveList(): Observable<any> {
    const searchRequest = new ApiQueryBuilder()
      .addName('whatsNewArchieve')
      .build();
      return this.apiCaller.constructApiCall(searchRequest);
  }

  public whatsNewpopupClose(): void {
    this.apiCaller.constructApiCall(new ApiQueryBuilder().addName('whatsNewPopupClose').build())
      .subscribe((response) => {
        response;
      });
  }

  public getWhatsNewLatest(): Observable<any> {
    const searchRequest = new ApiQueryBuilder()
      .addName('whatsNewLatest')
      .build();
    return this.apiCaller.constructApiCall(searchRequest);
  }
}