import { Component, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';

import { Store } from '@ngrx/store';

import { State } from '@store/app.state';
import { TermsOfUseDeny, TermsOfUseAccept } from '@app/store/terms-of-use/terms-of-use.actions';
import { TERMS_OF_USE_MODAL } from '@shared/models/shared/modal.constants';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-terms-of-use-modal',
  templateUrl: './terms-of-use-modal.component.html',
  styleUrls: ['./terms-of-use-modal.component.css'],
})
export class TermsOfUseModalComponent {
  @Output() public submitData = new EventEmitter<object>();
  @Output() public closeForm = new EventEmitter<void>();

  public canBeAccepted: boolean = false;

  constructor(private store: Store<State>) {}

  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  public onDeclineClick(_: MouseEvent): void {
    this.store.dispatch(new TermsOfUseDeny());
    this.closeForm.emit();
  }

  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  public onAcceptClick(_: MouseEvent): void {
    this.store.dispatch(new TermsOfUseAccept());
    this.submitData.emit({ data: { accepted: true, modalType: TERMS_OF_USE_MODAL } });
  }

  public onScroll(event: any): void {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    if (event.target.offsetHeight + Math.ceil(event.target.scrollTop) >= event.target.scrollHeight) {
      this.canBeAccepted = true;
    }
  }
}
