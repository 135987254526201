import { createAction, props } from '@ngrx/store';
import { IOptionItem } from '@app/shared/models/dashboard/tree-option-filter.model';
import {
  EmptiesHistoricalFetchParams,
  EmptiesHistoricalData,
} from '@app/modules/dashboard/services/empties-historical.service';

export const initHistorical = createAction(
  '[Empties Historical] Initialize',
);

export const fetchTerminalFilterSet = createAction(
  '[Empties Historical] Fetch Terminal Filter Set',
);

export const fetchTerminalFilterSetSuccess = createAction(
  '[Empties Historical] Fetch Terminal Filter Set Success',
  props<{ terminalFilterSet: IOptionItem[] }>(),
);

export const fetchTerminalFilterSetError = createAction(
  '[Empties Historical] Fetch Terminal Filter Set Error',
  props<{ error: string }>(),
);

export const fetchHistorical = createAction(
  '[Empties Historical] Fetch',
  props<EmptiesHistoricalFetchParams>(),
);

export const fetchHistoricalSuccess = createAction(
  '[Empties Historical] Fetch Success',
  props<{ data: EmptiesHistoricalData[] }>(),
);

export const fetchHistoricalError = createAction(
  '[Empties Historical] Fetch Error',
  props<{ error: string }>(),
);

export const downloadHistorical = createAction(
  '[Empties Historical] Download',
  props<EmptiesHistoricalFetchParams>(),
);

export const downloadHistoricalSuccess = createAction(
  '[Empties Historical] Download Success',
);

export const downloadHistoricalError = createAction(
  '[Empties Historical] Download Error',
  props<{ downloadError: string }>(),
);
