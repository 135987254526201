import {
  AfterViewInit,
  Component,
  ComponentFactoryResolver,
  Inject,
  OnDestroy,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { State } from '@store/app.state';
import * as ModalActions from '@store/modal/modal.actions';
import * as UserActions from '@store/user/user.actions';
import * as AddContainersModalActions from '@store/add-containers/add-containers.actions';
import * as NotificationSettingsActions from '@store/notification-settings/notification-settings.actions';
import * as UnclaimActions from '@store/unclaim/unclaim.actions';
import * as CustomFieldsActions from '@store/custom-fields/custom-fields.actions';
import {
  UnclaimContainers as UnclaimIntermodalContainers,
  UpdateCustomFields as UpdateIntermodalCustomFields,
} from '@store/intermodal/intermodal.actions';

import * as ModalConstants from '@models/shared/modal.constants';
import { FeatureFlagService } from '@services/feature-flag.service';
import { PriorityFlagsEnum } from '@app/shared/models/dashboard/container-status.model';
import { DashboardActions } from '@store/dashboard/dashboard.actions';

@Component({
  selector: 'app-dialog-component',
  templateUrl: 'modal-container.component.html',
})
export class ModalContainerComponent implements AfterViewInit, OnDestroy {
  public readonly features = {
    updatePendingContainers: this.featureFlags.featureEnabled('updatePendingContainers'),
    isNewVesselListEnabled: this.featureFlags.featureEnabled('newVesselList'),
    isNewContainerStatusListEnabled: this.featureFlags.featureEnabled('newContainerStatusList'),
  };
  @ViewChild('vc', { read: ViewContainerRef }) private _container: ViewContainerRef;
  private subscriptions: Subscription = new Subscription();

  constructor(
    public dialogRef: MatDialogRef<ModalContainerComponent>,
    private _resolver: ComponentFactoryResolver,
    private store: Store<State>,
    private router: Router,
    private featureFlags: FeatureFlagService,
    @Inject(MAT_DIALOG_DATA) public data?: any,
  ) { }


  public ngAfterViewInit() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const cmpRef = this._container.createComponent(this.data.component);

    if (cmpRef.instance['submitData']) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      this.subscriptions.add(cmpRef.instance['submitData'].subscribe(data => this.modalContainerClick(data)));
    }
    if (cmpRef.instance['closeForm']) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      this.subscriptions.add(cmpRef.instance['closeForm'].subscribe(() => this.onNoClick()));
    }
    cmpRef.instance['_injectedData'] = this.data.props ? this.data.props : undefined;
  }

  private getParent = (): string => {
    // eslint-disable-next-line no-var
    var parent = this.router.url.split('/')[1];
    if (parent === 'terminal' && (this.router.url.split('/').at(-1) === 'outgated' || this.router.url.split('/').at(-1) === 'returned')) {
      parent = 'container';
    }
    return parent;
  }

  // eslint-disable-next-line max-lines-per-function
  private modalContainerClick(value) {
    if (value.data.modalType === ModalConstants.ADD_CONTAINERS_MODAL) {
      if (value.data.containerAction === 'addContainers') {
        if (value.data.dataList) {
          const claimItems = value.data.dataList.map(item => item);

          const payload = {
            claimingType: value.data.claimingType,
            claimData: claimItems,
          };

          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          this.store.dispatch(new AddContainersModalActions.AddContainers(payload, value.data.cancelModal));
        } else {
          this.store.dispatch(new AddContainersModalActions.UploadFile({
            claimingType: 'container',
            file: value.data.file,
          },
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          value.data.cancelModal,
          ));
        }
      } else if (value.data.containerAction === 'pendingContainers') {
        const payload = {
          containers: value.data.pendingContainersDataList,
        };

        this.store.dispatch(new AddContainersModalActions.AddPendingContainers(payload));
      }

    } else if (value.data.modalType === ModalConstants.NOTIFICATION_SETTINGS_MODAL) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      const keys = Object.keys(value.data.settings);
      const newAlerts = keys.map(key => ({
        active: value.data.settings[key].subscribe ? true : false,
        configuration: value.data.settings[key], alertTypeId: key,
      }));
      this.store.dispatch(new NotificationSettingsActions.SetAlerts({ body: newAlerts }));
      this.store.dispatch(new ModalActions.CloseDialog());
    } else if (value.data.modalType === ModalConstants.EDIT_CONTAINER_MODAL) {
      const pendingContainers = value.data.containers.filter(container => container.isPendingContainer);
      let claimedContainers = value.data.containers.filter(container => !container.isPendingContainer);

      if (claimedContainers.length > 0) {
        claimedContainers = claimedContainers.map(container => {

          if (
            (this.getParent() === 'intermodal') ||
            (this.getParent() === 'terminal') ||
            (this.getParent() === 'vessel' && this.features.isNewVesselListEnabled) ||
            (this.getParent() === 'container' && this.features.isNewContainerStatusListEnabled)
          ) {
            const toReturnClaimedObject = {
              'containerNumber': container.containerNumber,
              'firstCustomField': container.customFields[0].value,
              'secondCustomField': container.customFields[1].value,
              'tripId': container.containerTripId,
              'notes': container.noteText,
              'priority': container.priority === '' ? PriorityFlagsEnum.NONE : container.priority,
            };
            if (container.customFields[2].value.id !== '') {
              toReturnClaimedObject['owner'] = container.customFields[2].value.fullName;
              toReturnClaimedObject['ownerId'] = container.customFields[2].value.id;
            }
            return toReturnClaimedObject;
          } else {
            delete container.isPendingContainer;
            return container;
          }
        });

        if (this.getParent() === 'intermodal') {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          this.store.dispatch(new UpdateIntermodalCustomFields(claimedContainers, this.getParent()));
        } else {
          this.store.dispatch(new CustomFieldsActions.UpdateCustomFields(claimedContainers, this.getParent()));
        }
      }
      if (this.features.updatePendingContainers && pendingContainers.length > 0) {
        const payloadData = pendingContainers.map(container => {
          const toReturnPendingObject = {
            'container': container.containerNumber,
            'notes': container.noteText,
            'custom1': container.customFields[0].value,
            'custom2': container.customFields[1].value,
            'priority': container.priority === '' ? PriorityFlagsEnum.NONE : container.priority,
          };
          if (container.customFields[2].value.id !== '') {
            toReturnPendingObject['owner'] = container.customFields[2].value.fullName;
            toReturnPendingObject['ownerId'] = container.customFields[2].value.id;
          }
          return toReturnPendingObject;
        });
        this.store.dispatch(new CustomFieldsActions.UpdatePendingContainerCustomFields(payloadData, this.getParent()));
      }
      this.store.dispatch(new ModalActions.CloseDialog());
      this.store.dispatch(DashboardActions.clearSelectedList({
        parent: this.getParent(),
      }) );
    } else if (value.data.modalType === ModalConstants.UNCLAIM_CONTAINER_MODAL) {
      if (this.getParent() === 'intermodal') {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        this.store.dispatch(new UnclaimIntermodalContainers(value.data.containers, this.getParent()));
      } else {
        this.store.dispatch(new UnclaimActions.UnclaimContainers(value.data.containers, this.getParent()));
      }
      this.store.dispatch(new ModalActions.CloseDialog());
    } else if (value.data.modalType === ModalConstants.SESSION_MODAL) {
      this.store.dispatch(new UserActions.ClearSessionExpired());
      this.store.dispatch(new ModalActions.CloseDialog());
    } else {
      this.store.dispatch(new ModalActions.CloseDialog());
    }
  }

  private onNoClick = (): void => this.store.dispatch(new ModalActions.CloseDialog());

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}
