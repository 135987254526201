<div block="nwc-modal">
  <div elem="header">
    <div elem="title">Attention</div>
    <div elem="close" (click)="onCloseClick($event)"><img src="/assets/svgs/close.svg" alt="Close modal" width="20" height="20"></div>
  </div>
  <div elem="content">
    {{ confirmationText }}
  </div>
  <div mat-dialog-actions elem="buttons">
    <div elem="remember">
      <input elem="checkbox" type="checkbox" id="remember" [(ngModel)]="remember">
      <label elem="remember-label" for="remember">Do not show this message again.</label>
    </div>
    <button
      elem="button"
      mat-flat-button
      color="accent"
      (click)="onConfirmClick($event)"
      type="button"
    >Continue</button>
  </div>
</div>
