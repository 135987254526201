export const defaultRoutes = {
    'Containers': '/container/overview',
    'Container Details': '/container/details',
    'Vessels': '/vessel/overview',
    'Terminals': '/terminal/overview',
    'Empty Returns': '/terminal/empties',
    'Appointments': '/appointments/overview',
    'Historical Search': '/terminal/historical-search',
    'Access Requests': '/accessrequests/overview',
    'Intermodal': '/intermodal/overview',
    'default': '/container/overview',
};
