import { Action } from '@ngrx/store';

export const GET_LANDING_PAGE_SETTINGS_DATA = '[LandingPage] GET_LANDING_PAGE_SETTINGS_DATA';
export const GET_LANDING_PAGE_SETTINGS_DATA_SUCCESS = '[LandingPage] GET_LANDING_PAGE_SETTINGS_DATA_SUCCESS';
export const GET_LANDING_PAGE_SETTINGS_DATA_ERROR = '[LandingPage] GET_LANDING_PAGE_SETTINGS_DATA_ERROR';
export const GET_LANDING_PAGE_SETTINGS_DATA_RESET = '[LandingPage] GET_LANDING_PAGE_SETTINGS_DATA_RESET';
export const GET_LANDING_PAGE_SETTINGS_SELECTED_PAGE = '[LandingPage] GET_LANDING_PAGE_SETTINGS_SELECTED_PAGE';

export class GetLandingPageSettings implements Action {
  public readonly type = GET_LANDING_PAGE_SETTINGS_DATA;

  constructor(public payload: any = null) {
  }
}

export class GetLandingPageSettingsSuccess implements Action {
  public readonly type = GET_LANDING_PAGE_SETTINGS_DATA_SUCCESS;

  constructor(public payload: any = null) {
  }
}

export class GetLandingPageSettingsError implements Action {
  public readonly type = GET_LANDING_PAGE_SETTINGS_DATA_ERROR;

  constructor(public payload: any = null) {
  }
}

export class GetLandingPageSettingsReset implements Action {
  public readonly type = GET_LANDING_PAGE_SETTINGS_DATA_RESET;
}

export class GetLandingPageSettingsSelectedPage implements Action {
  public readonly type = GET_LANDING_PAGE_SETTINGS_SELECTED_PAGE;

  constructor(public payload: any = null) {
  }
}

export type All = GetLandingPageSettings
  | GetLandingPageSettingsSuccess
  | GetLandingPageSettingsError
  | GetLandingPageSettingsReset
  | GetLandingPageSettingsSelectedPage;
