import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-table-summary',
  templateUrl: './table-summary.component.html',
  styleUrls: ['./table-summary.component.css'],
})
export class TableSummaryComponent {
  @Input() public currentCount: number;
  @Input() public totalCount: number;
}
