import { Action } from '@ngrx/store';
import { INotificationCategoryType } from './notification-types.state';

export const FETCH_ALERT_TYPES = '[Notifications] FETCH_ALERT_TYPES';
export const FETCH_ALERT_TYPES_SUCCESS = '[Notifications] FETCH_ALERT_TYPES_SUCCESS';
export const FETCH_ALERT_TYPES_FAIL = '[Notifications] FETCH_ALERT_TYPES_FAIL';

export class FetchAlertTypes implements Action  {
    public readonly type = FETCH_ALERT_TYPES;
}

export class FetchAlertTypesSuccess implements Action  {
    public readonly type = FETCH_ALERT_TYPES_SUCCESS;
    constructor(public payload: INotificationCategoryType[]) {}
}

export class FetchAlertTypesFail implements Action  {
    public readonly type = FETCH_ALERT_TYPES_FAIL;
  constructor(public payload: any) {}
}

export type All = FetchAlertTypes
  | FetchAlertTypesSuccess
  | FetchAlertTypesFail;
