<app-loader-fullscreen [loading]="loading"></app-loader-fullscreen>
<div block="add-content">
  <h2 elem="title">
    PLEASE ADD CONTAINERS TO START
  </h2>

  <button
    type="button"
    color="accent"
    mat-flat-button
    elem="addButton"
    id="add-container-button"
    (click)="onAddContent()"
  >Add Container</button>
</div>