import { Action } from '@ngrx/store';

export const FETCH_NOTIFICATION_SETTINGS = '[Notification settings] FETCH_NOTIFICATION_SETTINGS';
export const FETCH_NOTIFICATION_SETTINGS_SUCCESS = '[Notification settings] FETCH_NOTIFICATION_SETTINGS_SUCCESS';
export const FETCH_NOTIFICATION_SETTINGS_FAIL = '[Notification settings] FETCH_NOTIFICATION_SETTINGS_FAIL';

export const FETCH_ALERTS = '[Notification settings] FETCH_ALERTS';
export const FETCH_ALERTS_SUCCESS = '[Notification settings] FETCH_ALERTS_SUCCESS';
export const FETCH_ALERTS_FAIL = '[Notification settings] FETCH_ALERTS_FAIL';

export const FETCH_TYPES = '[Notification settings] FETCH_TYPES';
export const FETCH_TYPES_SUCCESS = '[Notification settings] FETCH_TYPES_SUCCESS';
export const FETCH_TYPES_FAIL = '[Notification settings] FETCH_TYPES_FAIL';

export const SET_ALERTS = '[Notification settings] SET_ALERTS';
export const SET_ALERTS_SUCCESS = '[Notification settings] SET_ALERTS_SUCCESS';
export const SET_ALERTS_FAIL = '[Notification settings] SET_ALERTS_FAIL';
export const SET_ALERTS_CLEAR = '[Notification settings] SET_ALERTS_CLEAR';

export class FetchNotificationSettings implements Action {
  public readonly type = FETCH_NOTIFICATION_SETTINGS;

  constructor(public payload?: any) {
  }
}

export class FetchNotificationSettingsSuccess implements Action {
  public readonly type = FETCH_NOTIFICATION_SETTINGS_SUCCESS;

  constructor(public payload?: any) {
  }
}

export class FetchNotificationSettingsFail implements Action {
  public readonly type = FETCH_NOTIFICATION_SETTINGS_FAIL;

  constructor(public payload?: any) {
  }
}

export class FetchAlerts implements Action {
  public readonly type = FETCH_ALERTS;
}

export class FetchAlertsSuccess implements Action {
  public readonly type = FETCH_ALERTS_SUCCESS;

  constructor(public payload?: any) {
  }
}

export class FetchAlertsFail implements Action {
  public readonly type = FETCH_ALERTS_FAIL;

  constructor(public payload?: any) {
  }
}

export class FetchTypes implements Action {
  public readonly type = FETCH_TYPES;
}

export class FetchTypesSuccess implements Action {
  public readonly type = FETCH_TYPES_SUCCESS;

  constructor(public payload?: any) {
  }
}

export class FetchTypesFail implements Action {
  public readonly type = FETCH_TYPES_FAIL;

  constructor(public payload?: any) {
  }
}

export class SetAlerts implements Action {
  public readonly type = SET_ALERTS;

  constructor(public payload?: any) {
  }
}

export class SetAlertsSuccess implements Action {
  public readonly type = SET_ALERTS_SUCCESS;

  constructor(public payload?: any) {
  }
}


export class SetAlertsFail implements Action {
  public readonly type = SET_ALERTS_FAIL;

  constructor(public payload?: any) {
  }
}

export class ClearAlertsSave implements Action {
  public readonly type = SET_ALERTS_CLEAR;
}

export type All = FetchNotificationSettings
  | FetchNotificationSettingsSuccess
  | FetchNotificationSettingsFail
  | FetchAlerts
  | FetchAlertsSuccess
  | FetchAlertsFail
  | FetchTypes
  | FetchTypesSuccess
  | FetchTypesFail
  | SetAlerts
  | SetAlertsSuccess
  | SetAlertsFail
  | ClearAlertsSave;
