<div class="content">
  <div block="header">
    <h1 elem="title" mat-dialog-title>Add Intermodal Containers</h1>
    <div elem="close-icon" (click)="onClose()">
      <img src="/assets/svgs/close.svg" alt="Close" />
    </div>
  </div>
  <div block="error-banner" *ngIf="(failureReceived$ | async)">
    <img src="/assets/svgs/failure-white.svg" alt="Possible error" />
    <div elem="message">There was an error adding your container(s).</div>
  </div>
  <p>
    Track specific containers by entering their container number.
  </p>
  <form [formGroup]="claimForm" (ngSubmit)="onSubmit()">
    <div block="claim-form">
      <div
        elem="counter"
        [mod]="{
          error: idList.errors?.invalidIDs || (failureReceived$ | async)
        }"
      >
        Container Count: {{ counter$ | async }}
      </div>
      <textarea
        elem="id-list"
        formControlName="idList"
        [mod]="{
          error: idList.errors?.invalidIDs || (failureReceived$ | async),
          disabled: loading$ | async
        }"
        placeholder="Add Containers here"
        [readonly]="(failureReceived$ | async)"
      ></textarea>
      <div elem="error-slot">
        <div elem="error-message" *ngIf="idList.errors?.invalidIDs">
          <div>The following inputs are not recognised as valid:</div>
          <div elem="invalid-ids">
            <span
              elem="invalid-id"
              *ngFor="let id of idList.errors?.invalidIDs; last as isLast"
            >
              {{ id }}<ng-container *ngIf="!isLast">,</ng-container>
            </span>
          </div>
        </div>
        <div elem="error-message" *ngIf="(numOfFailuresInResponse$ | async) as numOfFailuresInResponse">
          <div>
            {{ numOfFailuresInResponse }} out of {{ totalCount }}
            <span [ngPlural]="numOfFailuresInResponse">
              <ng-template ngPluralCase="=1">container was</ng-template>
              <ng-template ngPluralCase="other">containers were</ng-template>
            </span>
            unable to be added.
          </div>
          <div>
            You may copy the input which caused the error and try again.
          </div>
        </div>
      </div>
    </div>
    <div
      mat-dialog-actions
      block="buttons"
      *ngFor="let responseReceived of [responseReceived$ | async]"
    >
      <button
        elem="button"
        mat-flat-button
        color="primary"
        (click)="onClose()"
        type="button"
      >
        {{ responseReceived ? "Close" : "Cancel" }}
      </button>
      <ng-container *ngFor="let loading of [loading$ | async]">
        <button
          elem="button"
          mat-flat-button
          color="accent"
          type="submit"
          [class.disabled]="loading || idList.invalid"
          [disabled]="loading || idList.invalid"
          *ngIf="!responseReceived"
        >
          {{ loading ? "Submitting" : "Submit" }}
        </button>
      </ng-container>
    </div>
  </form>
</div>
