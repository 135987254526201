import { Injectable } from '@angular/core';

import { Action, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, Observable } from 'rxjs';
import { mergeMap, map, catchError, tap } from 'rxjs/operators';

import { ApiQueryBuilder } from '@services/api-query-builder';
import { ApiCallService } from '@services/api-call.service';
import * as AcknowledgeContainersActions from '@store/acknowledge-containers/acknowledge-containers.actions';
import * as ModalActions from '@store/modal/modal.actions';

@Injectable()
export class AcknowledgeContainersEffect {
  constructor(
    // eslint-disable-next-line @typescript-eslint/ban-types
    private store: Store<{}>,
    private actions$: Actions,
    private api: ApiCallService,
  ) { }

  public AcknowledgeRejectedContainers$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<AcknowledgeContainersActions.AcknowledgeRejectedContainers>(AcknowledgeContainersActions.ACKNOWLEDGE_REJECTED_CONTAINERS),
    mergeMap(action =>
      this.api.constructApiCall(
        new ApiQueryBuilder()
          .addName('acknowledgeRejectedContainers')
          .addBody(action.payload)
          .build(),
      ).pipe(
        // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
        tap(_ => {
          this.store.dispatch(new ModalActions.CloseDialog());
          localStorage.setItem('rejectedContainers', JSON.stringify([]));
        }),
        map(data => new AcknowledgeContainersActions.AcknowledgeRejectedContainersSuccess(data)),
        catchError(error => of(new AcknowledgeContainersActions.AcknowledgeRejectedContainersError(error))),
      ),
    ),
  ),
  { dispatch: false },
  );
}


