import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateDuration',
})
export class DateDurationPipe implements PipeTransform {
  public transform(epochValueString: string): string {
    const milliseconds = parseInt(epochValueString, 10);
    // Convert epoch value to milliseconds
    const seconds = ~~(milliseconds / 1000);
    // eslint-disable-next-line no-var
    var minutes = ~~(seconds / 60);
    // eslint-disable-next-line no-var
    var hours = ~~(minutes / 60);
    // eslint-disable-next-line no-var
    var days = ~~(hours / 24);

    // eslint-disable-next-line no-var, @typescript-eslint/naming-convention
    var actual_hours = hours % 24;
    // eslint-disable-next-line no-var, @typescript-eslint/naming-convention
    var actual_minutes = minutes % 60;

    if(actual_minutes > 30) {
      actual_hours = actual_hours + 1;
      if( actual_hours == 24) {
        actual_hours = 0;
        days = days + 1;
      }
    }

    const formatDays = days.toString().length < 2 ? '  ' + days.toString() : days.toString();
    // const formatDays = days.toString().padStart(2, " ");
    const formatHours = actual_hours.toString().padStart(2, '0');

    // Calculate the duration
    // eslint-disable-next-line no-var
    var duration = formatDays + "d " + formatHours + "h";
    duration = duration.trim() === "0d 00h" ? "-" : duration;
    return duration;
  }
}