<div class="whats-new-overview">
  <div class="header-section">
    <div class="whats-heading">What’s New</div>
    <div class="cancel-icon">
      <img src="assets/svgs/close-big.svg" width="45" height="45" alt="cancel" mat-dialog-close />
    </div>
  </div>
  <div class="whats-feature-heading">{{ currentFeature.heading }}</div>
  <div class="whats-feature-info">{{ currentFeature.description }}</div>
  <div class="whats-content">
    <div class="whats-new-carosal">
      <div class="prev-button" (click)="showPreviousFeature()" *ngIf="!hasSingleSlide"><img class="direction-img"
          src="/assets/svgs/chevron-blue.svg" alt=""></div>
      <div class="whats-new-images">
        <div class="carosal-img-container">
          <img class="carosal-img" [src]="currentFeature.img_src" alt="">
        </div>
        <div class="slide-indicators">
          <span class="slide-indicator" *ngFor="let feature of features; let i = index"
            [class.active]="i === currentFeatureIndex"></span>
        </div>
      </div>
      <div class="next-button" (click)="showNextFeature()" *ngIf="!hasSingleSlide"><img class="direction-img"
          src="/assets/svgs/chevron-blue.svg" alt=""></div>
    </div>
  </div>
</div>