import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { WhatsnewPopupService } from '@app/core/services/whatsnew-popup.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-whats-new-modal',
  templateUrl: './whats-new-modal.component.html',
  styleUrls: ['./whats-new-modal.component.css'],
})
export class WhatsNewModalComponent implements OnInit {
  public features: any[] = [];
  public currentFeatureIndex: number = 0;
  public currentFeature: any;
  public hasSingleSlide: boolean;

  constructor(private whatsNewService: WhatsnewPopupService) {}

  public ngOnInit(): void {
    this.whatsNewService.getWhatsNewLatest().subscribe((data: any[]) => {
      this.features = data.flatMap((item) =>
        item.details.map((detail) => ({
          heading: detail.screenHeader,
          description: detail.description,
          img_src: detail.screenId,
        })),
      );
      this.currentFeature = this.features[this.currentFeatureIndex];
      this.hasSingleSlide = this.features.length === 1;
    });
  }

  public showPreviousFeature(): void {
    this.currentFeatureIndex = (this.currentFeatureIndex - 1 + this.features.length) % this.features.length;
    this.currentFeature = this.features[this.currentFeatureIndex];
  }

  public showNextFeature(): void {
    this.currentFeatureIndex = (this.currentFeatureIndex + 1) % this.features.length;
    this.currentFeature = this.features[this.currentFeatureIndex];
  }
}
