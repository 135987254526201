import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Inject, Component } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorModalComponent {

  //public userRoutes = UserRoutes;
  public errorStatus: number = 0;

  constructor(public dialogRef: MatDialogRef<ErrorModalComponent>,
    private router: Router, @Inject(MAT_DIALOG_DATA) public data: {errorData:HttpErrorResponse}) {
    this.errorStatus = data.errorData.status;
  }

  public registrationPage() {
    this.router.navigate(['/register']);
  }
  
  public onClose(): void {
    localStorage.removeItem('errorData')
    this.dialogRef.close();
  }
}
