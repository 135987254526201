import { createFeatureSelector, createSelector } from '@ngrx/store';

import { GateHoursState } from './gate-hours.state';
import * as GateHoursAction from './gate-hours.actions';

export type Action = GateHoursAction.All;

const initialState: GateHoursState = {
  loading: false,
  error: false,
  data: [],
  errorData: null,
};

const getGateHoursState = createFeatureSelector<GateHoursState>('gateHours');

export const getErrorData = createSelector(
  getGateHoursState,
  state => (state.error ? state.errorData : null),
);

export const getGateHoursResponse = createSelector(
  getGateHoursState,
  state => state.data,
);

export const getGateHoursResponseForTerminal = (terminalCode: string) => {
  return createSelector(
    getGateHoursState,
    state => state.data[terminalCode] || [],
  );
};

export const getLoadingState = createSelector(
  getGateHoursState,
  state => state.loading,
);

// eslint-disable-next-line @typescript-eslint/naming-convention
export function GateHoursReducer(state = initialState, action: Action): GateHoursState {
  switch (action.type) {
    case GateHoursAction.GET_GATE_HOURS: {
      return { ...state, loading: true, error: false };
    }

    case GateHoursAction.GET_GATE_HOURS_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload,
      };
    }

    case GateHoursAction.GET_GATE_HOURS_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
        errorData: { ...action.payload },
      };
    }

    default:
      return state;
  }
}
