import { Action } from '@ngrx/store';

export const START_LOGGING = '[Routing] START_LOGGING';
export const SAVE_ROUTE = '[Routing] SAVE_ROUTE';

export class StartLogging implements Action {
  public readonly type = START_LOGGING;
}

export class SaveRoute implements Action {
  public readonly type = SAVE_ROUTE;
  constructor(public payload: any = null) {}
}

export type All = StartLogging | SaveRoute;
