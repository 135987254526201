import { Action } from '@ngrx/store';

export const SET_REJECTED_CONTAINERS = '[Acknowledge] SET_REJECTED_CONTAINERS';
export const ACKNOWLEDGE_REJECTED_CONTAINERS = '[Acknowledge] ACKNOWLEDGE_REJECTED_CONTAINERS';
export const ACKNOWLEDGE_REJECTED_CONTAINERS_ERROR = '[Acknowledge] ACKNOWLEDGE_REJECTED_CONTAINERS_ERROR';
export const ACKNOWLEDGE_REJECTED_CONTAINERS_SUCCESS = '[Acknowledge] ACKNOWLEDGE_REJECTED_CONTAINERS_SUCCESS';

export class SetRejectedContainers implements Action {
  public readonly type = SET_REJECTED_CONTAINERS;
  constructor(public payload: any = null) {}
}

export class AcknowledgeRejectedContainers implements Action {
  public readonly type = ACKNOWLEDGE_REJECTED_CONTAINERS;
  constructor(public payload: any = null) {}
}

export class AcknowledgeRejectedContainersError implements Action {
  public readonly type = ACKNOWLEDGE_REJECTED_CONTAINERS_ERROR;
  constructor(public payload: any = null) {}
}

export class AcknowledgeRejectedContainersSuccess implements Action {
  public readonly type = ACKNOWLEDGE_REJECTED_CONTAINERS_SUCCESS;
  constructor(public payload: any = null) {}
}

export type All = SetRejectedContainers
  | AcknowledgeRejectedContainers
  | AcknowledgeRejectedContainersError
  | AcknowledgeRejectedContainersSuccess;
