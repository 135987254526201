<div block="prompt">
  <div elem="text">
    <div elem="cancel">
      <a href="#" (click)="onCancel()"><img src="/assets/svgs/close.svg" alt="Cancel" width="20" height="20" alt="Close dialog"/></a>
    </div>
    <span>
      <h2>Have many containers to track?</h2>
    </span>
    <div elem="doc">
      <div elem="subtitle">Sign up and log in for FREE</div>
      <p>Continuously track multiple containers, receive status updates, and much more!</p>
    </div>
  </div>
  <div mat-dialog-actions elem="buttons">
    <button elem="button" mat-flat-button color="primary" type="button" (click)="onRegisterAcceptClick($event)">Sign Up</button>
    <button elem="button" mat-flat-button color="accent" (click)="onLoginAcceptClick($event)" type="button">Log In</button>
  </div>
</div>
