import { HoldCodesState } from './hold-codes.state';
import * as HoldCodesActions from './hold-codes.actions';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export type Action = HoldCodesActions.All;

const initialState: HoldCodesState = {
  data: {},
  loading: false,
  error: false,
};

// selectors

const getHoldCodesState = createFeatureSelector<HoldCodesState>('hold-codes');

export const getHoldCodeData = createSelector(
  getHoldCodesState,
  state => state.data,
);

export const getHoldCodeError = createSelector(
  getHoldCodesState,
  state => state.error,
);

export const isLoading = createSelector(
  getHoldCodesState,
  state => state.loading,
);


// eslint-disable-next-line @typescript-eslint/naming-convention
export function HoldCodesReducer(state = initialState, action: Action): HoldCodesState {
  switch (action.type) {
    case HoldCodesActions.FETCH_HOLD_CODES: {
      return {
            ...state,
            loading: true,
            error: false,
        };
    }
    case HoldCodesActions.FETCH_HOLD_CODES_SUCCESS: {
      return {
            ...state,
            loading: false,
            data: {...action.payload},
        };
    }
    case HoldCodesActions.FETCH_HOLD_CODES_ERROR: {
      return {
            ...state,
            loading: false,
            error: true,
        };
    }
    default:
        return state;
  }
}
