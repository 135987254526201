import { Injectable } from '@angular/core';
import { FeatureFlagService } from '@services/feature-flag.service';

import * as chartConstants from './chart.constants';

@Injectable({
  providedIn: 'root',
})

export class ChartService {
  private features = {
    isNewChartEnabled: this.featureFlags.featureEnabled('newChart'),
  };
  private daysToAddSubtract = 7;
  private statusFilterList = [];

  constructor(private featureFlags: FeatureFlagService) {}

  public displayNewChart(): boolean {
    return this.features.isNewChartEnabled;
  }

  public getChartEndpointName() {
    return this.features.isNewChartEnabled
      ?  'newChart'
      :  'chart';
  }

  // eslint-disable-next-line max-lines-per-function
  public getRequestParamsToSend(payloadData) {
    if (this.displayNewChart()) {
      const chartRequestObjectToSend = JSON.parse(JSON.stringify(chartConstants.chartRequestObject));
      const vesselStatusValues = [];
      const shipmentStatusSelectedValues = [];
      const holdsSelectedValues = [];
      const prioritySelectedValues = [];
      const ownerSelectedValues = [];
      const containerSizeSelectedValues = [];
      let universalSearchFilterApplied = false;
      let universalSearchValue = '';
      chartRequestObjectToSend.searchParameters[0].filters = [];
      chartRequestObjectToSend.searchParameters[0].customFieldsFilter.filterOnCustomFieldsData = [];
      chartRequestObjectToSend.searchParameters[0].customFieldsFilter.filterOnCustomFields = false;
      chartRequestObjectToSend.searchParameters[0].filters.push(chartConstants.importExportFilter);
      this.statusFilterList = [];
      if (payloadData.filters.length > 0) {
      if (payloadData.filters[0].filters.length === 6) {
        universalSearchValue = payloadData.filters[0].filters[0].searchValue;
        universalSearchFilterApplied = true;
      }
        payloadData.filters[0].filters.map(subFilter => {
          if (subFilter.type === 'OR' && subFilter.filters.length === 6) {
            universalSearchValue = subFilter.filters[0].searchValue;
            universalSearchFilterApplied = true;
          }
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          if (['AND', 'OR'].includes(subFilter.type) && subFilter.filters.length > 0) {
            subFilter.filters.map(subFilter1 => {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
              const filtersToIterate = ['AND', 'OR'].includes(subFilter1.type)
              ? subFilter1.filters
              : [subFilter1];
              filtersToIterate.map(filtersToApply => {
                if (filtersToApply.type === 'UNLOAD_EVENT') {
                  // eslint-disable-next-line prefer-spread
                  shipmentStatusSelectedValues.push.apply(shipmentStatusSelectedValues,
                    chartConstants.shipmentStatusMappingValues[filtersToApply.searchValue]);
                  vesselStatusValues.push(filtersToApply.searchValue);
                } else if (filtersToApply.type === 'IS_AVAILABLE_FOR_PICKUP') {
                  const availableFilter = chartConstants.isAvailableForPickupFilter;
                  const eliminateOutgated = JSON.parse(JSON.stringify(chartConstants.shipmentStatusFilter));
                  eliminateOutgated.filterValues = ['NA', 'UV'];
                  chartRequestObjectToSend.searchParameters[0].filters.push(availableFilter);
                  chartRequestObjectToSend.searchParameters[0].filters.push(eliminateOutgated);
                } else if (filtersToApply.type === 'HOLDS') {
                  holdsSelectedValues.push(chartConstants.holdsSelectedMappingValues[filtersToApply.searchValue]);
                } else if (filtersToApply.type === 'OWNER') {
                  ownerSelectedValues.push(filtersToApply.searchValue);
                } else if (filtersToApply.type === 'FLAG') {
                  prioritySelectedValues.push(filtersToApply.searchValue);
                } else if (filtersToApply.type === 'LAST_CLAIMED') {
                  chartRequestObjectToSend.searchParameters[0].fetchLastAddedContainers = true;
                } else if (filtersToApply.type === 'CONTAINER_LENGTH') {
                  containerSizeSelectedValues.push(filtersToApply.searchValue);
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                } else if (chartConstants.universalSearchFilterValues.includes(filtersToApply.type) && !universalSearchFilterApplied) {
                  const universalSearchFilter = JSON.parse(JSON.stringify(chartConstants[filtersToApply.type]));
                  universalSearchFilter.filterValues.push(filtersToApply.searchValue);
                  chartRequestObjectToSend.searchParameters[0].filters.push(universalSearchFilter);
                }
              });
            });
          } else {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            if (chartConstants.universalSearchFilterValues.includes(subFilter.type) && !universalSearchFilterApplied) {
              const universalSearchFilter = JSON.parse(JSON.stringify(chartConstants[subFilter.type]));
              universalSearchFilter.filterValues.push(subFilter.searchValue);
              chartRequestObjectToSend.searchParameters[0].filters.push(universalSearchFilter);
            }
          }
        });
        if (vesselStatusValues.length > 0) {
          const shipmentStatusFilter = JSON.parse(JSON.stringify(chartConstants.shipmentStatusFilter));
          this.statusFilterList = vesselStatusValues;
          shipmentStatusFilter.filterValues = shipmentStatusSelectedValues;
          chartRequestObjectToSend.searchParameters[0].filters.push(shipmentStatusFilter);
        }
        if (holdsSelectedValues.length > 0) {
          const holdsFilter = JSON.parse(JSON.stringify(chartConstants.holdsFilter));
          holdsFilter.filterKey = holdsSelectedValues.join(',');
          if (holdsSelectedValues.length  > 1) {
            holdsFilter['comparison'] = 'SHOULD';
          }
          chartRequestObjectToSend.searchParameters[0].filters.push(holdsFilter);
        }
        if (containerSizeSelectedValues.length > 0) {
          const containerSizeFilter = JSON.parse(JSON.stringify(chartConstants.containerSizeFilter));
          containerSizeFilter.filterValues = containerSizeSelectedValues;
          chartRequestObjectToSend.searchParameters[0].filters.push(containerSizeFilter);
        }
        if (ownerSelectedValues.length || prioritySelectedValues.length) {
          chartRequestObjectToSend.searchParameters[0].customFieldsFilter.filterOnCustomFields = true;
        }
        if (ownerSelectedValues.length > 0) {
          const ownerFilter = JSON.parse(JSON.stringify(chartConstants.ownerFilter));
          ownerFilter.filterValues = ownerSelectedValues;
          if (ownerSelectedValues.length > 1) {
            ownerFilter['comparison'] = 'SHOULD';
          }
          chartRequestObjectToSend.searchParameters[0].customFieldsFilter.filterOnCustomFieldsData.push(ownerFilter);
        }
        if (prioritySelectedValues.length > 0) {
          const priorityFilter = JSON.parse(JSON.stringify(chartConstants.priorityFilter));
          priorityFilter.filterValues = prioritySelectedValues;
          if (prioritySelectedValues.length > 1) {
            priorityFilter['comparison'] = 'EQUAL';
          }
          chartRequestObjectToSend.searchParameters[0].customFieldsFilter.filterOnCustomFieldsData.push(priorityFilter);
        }
        if (universalSearchFilterApplied) {
          const universalSearchFilter = JSON.parse(JSON.stringify(chartConstants.universalSearchFilter));
          universalSearchFilter.filterValues.push(universalSearchValue);
          chartRequestObjectToSend.searchParameters[0].filters.push(universalSearchFilter);
        }
      }
      return chartRequestObjectToSend;
    } else {
      return payloadData;
    }
  }

  public getParsedChartResponse(data) {
    const chartResponse = data.response.aggregations.filter.byEtaAta.buckets;
    let counter = (-this.daysToAddSubtract);
    const parsedChartList = [];

    while (counter <= this.daysToAddSubtract) {
      const parsedData = {
        ...chartConstants.chartRequiredParsedObject,
        available: {...chartConstants.chartRequiredParsedObject.available},
        discharged: {...chartConstants.chartRequiredParsedObject.discharged},
        docked: {...chartConstants.chartRequiredParsedObject.docked},
        onVessel: {...chartConstants.chartRequiredParsedObject.onVessel},
        outGated: {...chartConstants.chartRequiredParsedObject.outGated},
      };
      const timeStamp = this.getDateTimestamp(counter);

      if (chartResponse.length > 0) {
        const timeStampData = chartResponse.filter(byDateBuckets => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, no-useless-escape
          const bucketTimestamp = new Date(byDateBuckets.key.replace(/([0-9]{4}-[0-9]{2}-[0-9]{2})([\+\-][0-9]{2}:[0-9]{2})/, '$1 00:00:00+0000')).getTime();
          return Number(bucketTimestamp) === timeStamp;
        });

        if (timeStampData.length > 0) {
          const statusBuckets = timeStampData[0].byStatus.buckets;
          const availabilityBuckets = timeStampData[0].filter.byAvailability.buckets;
          let onVesselCount = 0;
          let dockedCount = 0;
          let availableCount = 0;
          let dischargedCount = 0;
          let outgatedCount = 0;

          if (statusBuckets.length > 0) {
            statusBuckets.map(subStatusBuckets => {
              if (subStatusBuckets.key === 'UV') {
                dischargedCount = subStatusBuckets.doc_count;
              } else if (subStatusBuckets.key === 'OA' || subStatusBuckets.key === 'AL' || subStatusBuckets.key === 'RL') {
                outgatedCount = outgatedCount + subStatusBuckets.doc_count;
              }
              subStatusBuckets.byInboundDockedContainers.buckets.map(inboundDockedBuckets => {
                if (inboundDockedBuckets.key === 'On Vessel') {
                  onVesselCount = (this.statusFilterList.length > 0
                      && (this.statusFilterList.includes('Docked') && !this.statusFilterList.includes('On Vessel')))
                    ? 0
                    : inboundDockedBuckets.doc_count;
                } else if (inboundDockedBuckets.key === 'Docked') {
                  dockedCount = (this.statusFilterList.length > 0
                      && (this.statusFilterList.includes('On Vessel') && !this.statusFilterList.includes('Docked')))
                    ? 0
                    : inboundDockedBuckets.doc_count;
                }
              });
            });
          }

          if (availabilityBuckets.length > 0) {
            availabilityBuckets.map(availableBuckets => {
              if (availableBuckets.key === 'Available') {
                availableCount = availableBuckets.doc_count;
              }
            });
          }

          parsedData.available.sum = availableCount;
          parsedData.onVessel.sum = onVesselCount;
          parsedData.docked.sum = dockedCount;
          parsedData.discharged.sum = dischargedCount;
          parsedData.outGated.sum = outgatedCount;
        }
      }
      parsedData.etaDate = timeStamp;
      parsedChartList.push(parsedData);
      counter++;
    }
    return parsedChartList;
  }

  public getParsedChartAvailabilitySearchFilters(action) {
    const chartAvailabilityRequesToSend = [];
    if (action.payload !== null && action.payload.filters.length > 0) {
      action.payload.filters[0].filters.map(subFilter => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        if (['AND', 'OR'].includes(subFilter.type) && subFilter.filters.length > 0) {
          subFilter.filters.map(subFilter1 => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            if (chartConstants.universalSearchFilterValues.includes(subFilter1.type)) {
              const universalSearchFilter = JSON.parse(JSON.stringify(chartConstants[subFilter1.type]));
              universalSearchFilter.filterValues.push(subFilter1.searchValue);
              chartAvailabilityRequesToSend.push(universalSearchFilter);
            }
          });
        } else {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          if (chartConstants.universalSearchFilterValues.includes(subFilter.type)) {
            const universalSearchFilter = JSON.parse(JSON.stringify(chartConstants[subFilter.type]));
            universalSearchFilter.filterValues.push(subFilter.searchValue);
            chartAvailabilityRequesToSend.push(universalSearchFilter);
          }
        }
      });
    }
    return chartAvailabilityRequesToSend;
  }

  private getParsedResponseObject() {
    return chartConstants.chartRequiredParsedObject;
  }

  private getDateTimestamp(days) {
    const currentDate = days > 0
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ? new Date(new Date().setDate(new Date().getDate() + Math.abs(days)))
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      : new Date(new Date().setDate(new Date().getDate() - Math.abs(days)));

    return Date.UTC(currentDate.getUTCFullYear(), currentDate.getUTCMonth(), currentDate.getUTCDate(), 0, 0, 0, 0);
  }
}
