<div block="notification">
  <form (keydown.enter)="$event.preventDefault()" id="notificationSettingsForm" [formGroup]="notificationSettingsForm">

    <section elem="accept">
      <mat-checkbox formControlName="ownerBased" (click)="onOwnerToggle($event)" #ownerBased>
        Only show notifications on containers where I am the "Owner"
      </mat-checkbox>
    </section>

    <mat-accordion>
      <mat-expansion-panel
        #panel
        hideToggle
        *ngFor="let type of types"
      >
        <mat-expansion-panel-header
          expandedHeight="20px"
          collapsedHeight="20px"
        >
          <span *ngIf="!panel.expanded">
            <img src="/assets/svgs/chevron-right.svg" alt="Expand" />
          </span>
          <span *ngIf="panel.expanded">
            <img src="/assets/svgs/chevron.svg" alt="Collapse" />
          </span>
          <span elem="title" *ngIf="type === 'VESSEL_ARRIVAL'">Vessel Arrival</span>
          <span elem="title" *ngIf="type === 'CONTAINER_DISCHARGE'">Container Discharge</span>
          <span elem="title" *ngIf="type === 'CONTAINER_AVAILABLE'">Container Available</span>
          <span elem="title" *ngIf="type === 'DWELL_NOTIFICATION'">Dwells - Days After Discharge</span>
          <span elem="title" *ngIf="type === 'CONTAINER_OUTGATE'">Container - Out Gated</span>
          <span elem="title" *ngIf="type === 'LAST_FREE_DAY_NOTIFICATION'">Container - Last Free Day</span>
          <span elem="title" *ngIf="type === 'CONTAINER_AUTO_UNCLAIM'">Container - Auto Unclaimed</span>
          <span elem="title" *ngIf="type === 'EMPTY_RETURNS'">Empty Returns Availability</span>

        </mat-expansion-panel-header>

        <div elem="panel" [formGroupName]="type">
          <div elem="form-line">
            Check the boxes and types of notifications you wish to receive below.
          </div>

          <mat-checkbox elem="checkbox" formControlName="subscribe" #subscribeCheckbox (change)="onInAppChange(emailCheckbox, subscribeCheckbox, type)">In App Notifications</mat-checkbox>

          <mat-checkbox elem="checkbox" formControlName="email" #emailCheckbox (change)="onEmailChange(emailCheckbox, subscribeCheckbox, type)">Email</mat-checkbox>

          <div *ngIf="type === 'DWELL_NOTIFICATION'" elem="form-line">
            <span>Notify me</span>
            <div elem="days">
              <input
                type="text"
                placeholder="X"
                elem="days-input"
                maxlength="2"
                formControlName="dwellDaysCount"
                (change)="onDayChanged($event)"/>
            </div>
            <span>day(s) after discharge</span>
          </div>

          <div *ngIf="type === 'LAST_FREE_DAY_NOTIFICATION'" elem="form-line">
            <span>Notify me</span>
            <div elem="days">
              <input
                type="text"
                placeholder="X"
                elem="days-input"
                maxlength="2"
                formControlName="daysBeforeLastFreeDayCount"
                (change)="onDayChanged($event)"/>
            </div>
            <span>day(s) before last free day</span>
          </div>
          <div elem="errorMsg" *ngIf="type === 'DWELL_NOTIFICATION' && dwellInAppSelected && !dwellAlertTypeErrorMsg">
            <span>Please enter valid day(s) with range from 1-99 to notify for 'Dwells - Days After Discharge'</span>
          </div>
          <div elem="errorMsg" *ngIf="type === 'LAST_FREE_DAY_NOTIFICATION' && lastFreeDayInAppSelected && !lastFreeDayAlertTypeErrorMsg">
            <span>Please enter valid day(s) with range from 1-99 to notify for 'Container - Last Free Day'</span>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>

  </form>
</div>
