<ng-container *ngFor="let loading of [loading$ | async]">
  <div class="spinner-overlay-parent" block="request-containers">
    <app-loader-overlay [loading]="loading"></app-loader-overlay>
    <div elem="header">
      <div>
        <h2 mat-dialog-title elem="header-text">Request Special Handling | {{requestedCancelList.requestDate | requestHeader}}</h2>
        <div elem="cancel-icon">
          <img
            src="assets/svgs/close-grey.svg"
            width="20"
            height="20"
            alt="cancel"
            (click)="closeSpecialHandling()"
          />
        </div>
      </div>
    </div>

    <div elem="content" mat-dialog-content>    
        <div class="request-user-content">        
          <div class="cardRequest">
            <div [style.grid-area]="'header'" class="request-type">Requested by</div>
            <div [style.grid-area]="'label'" class="field-label">
              Name:
            </div>
            <div [style.grid-area]="'value'" class="field-value">
              {{requestedCancelList?.userDetailsModel?.firstName}} {{requestedCancelList?.userDetailsModel?.lastName}}
            </div>
            <div [style.grid-area]="'company'" class="field-label">
              Company:
            </div>
            <div [style.grid-area]="'companyName'" class="field-value">
              {{requestedCancelList?.userDetailsModel?.companyName}}
            </div>
            <div [style.grid-area]="'email'" class="field-label">
              Email:
            </div>
            <div [style.grid-area]="'emailValue'" class="field-value">
              {{requestedCancelList?.userDetailsModel?.email}}
            </div>
            <div [style.grid-area]="'phone'" class="field-label">
              Phone:
            </div>
            <div [style.grid-area]="'phonevalue'" class="field-value">
              {{requestedCancelList?.userDetailsModel?.phone | requestHandling}}
            </div>
          </div>
          <div class="cardTerminal">
            <div [style.grid-area]="'header'" class="request-type">Terminal</div>
            <div [style.grid-area]="'terminallabel'" class="field-label">
            Terminal name:
            </div>
            <div [style.grid-area]="'terminalvalue'" class="field-value">
             {{requestedCancelList?.terminalDetails?.terminalCode}}
            </div>
            <div [style.grid-area]="'contactNamelabel'" class="field-label">
              Contact:
            </div>
            <div [style.grid-area]="'contactNamevalue'" class="field-value">
              {{requestedCancelList?.terminalDetails?.contactName}}
            </div>
            <div [style.grid-area]="'emaillabel'" class="field-label">
              Email:
              </div>
            <div [style.grid-area]="'emailvalue'" class="field-value">
              {{requestedCancelList?.terminalDetails?.email}}
            </div>
          </div>
        </div>
        <div class="divider">
          <mat-divider></mat-divider>
        </div>
        <div class="div-gap">
          <div [style.grid-area]="'header'" class="request-type">Select services</div>
          <div class="request-type"><mat-checkbox [checked]="true" [disabled]="true">Peel pile</mat-checkbox></div>
        </div>
        <div class="divider">
          <mat-divider></mat-divider>
        </div>
        <div class="containers-list-card">
          <span class="request-type-header">Containers</span>
          <span class="count-containers">{{ requestedCancelList?.containerDetails?.length }} containers</span>
          <span class="container-note">Only containers that are at the same terminal, at least 48 hours out from arrival, and not
            part of an existing special request are searchable.</span>
            <div class="containers-table-box">
              <div class="has-containers">
                <app-request-specialhandling-table
                  [cancelRequest]= "true" [dataSource]="(requestedCancelList.containerDetails)"></app-request-specialhandling-table>
                  </div>
            </div>
        </div>
        <span class="request-textarea">
          <textarea id="note" [(ngModel)]="notes" (ngModelChange)="onValueChange()" class="text-area-notes" maxlength="255" placeholder="Enter your comment here..." cols="50"></textarea>
        </span>
        <div elem="footer">
          <div mat-dialog-actions elem="buttons">
            <button elem="button" type="submit" color="accent" mat-flat-button
               id="apply-edit-button" (click)="cancelRequest()">Cancel Request</button>
          </div>
        </div>
      </div>
  </div>
</ng-container>
