<div block="table-settings">
  <div elem="content">
    <mat-accordion>
      <mat-expansion-panel
        #panel
        hideToggle
        [expanded]="!i"
        *ngFor="let keys of preferencesKeys; let i = index"
      >
	     <mat-expansion-panel-header expandedHeight="20px" collapsedHeight="20px">
          <span *ngIf="!panel.expanded">
            <img src="/assets/svgs/chevron-right.svg" alt="Expand" />
          </span>
          <span *ngIf="panel.expanded">
            <img src="/assets/svgs/chevron.svg" alt="Collapse" />
          </span>
	       <h2> {{keys.header}} </h2>
	     </mat-expansion-panel-header>
          <div elem="panel">
            <div *ngFor="let obj of userPreferences[keys.field]">
              <form [formGroup]="customFieldsFormGroup" elem="customFieldsForm">
                <div elem="customFields">
                  <mat-checkbox
                    elem="checkbox"
                    [checked]="obj.checked"
                    (change)="onChange(keys.field, obj.field, $event)"
                    [disabled]="obj.disabled"
                  ></mat-checkbox>
                  <div elem="header"
                    *ngIf="!(obj.field === 'customField1' || obj.field === 'customField2')"
                  >{{obj.header}}</div>
                </div>
                <div
                  elem="customlabel1"
                  (click)="(obj.disabled || !obj.checked) ? null : onEditCustomFields(keys.field, obj.field, $event)"
                  *ngIf="obj.field === 'customField1'">
                  <input
                    placeholder="Custom Field 1"
                    formControlName="customField1"
                    (blur)="(obj.disabled || !obj.checked) ? null : onEditCustomFields(keys.field, obj.field, $event)"
                    #customFieldOne
                    *ngIf="customFieldsStatus[keys.field]['customField1']"
                    maxlength="20"
                  />
                  <div
                    elem="customLabelText"
                    *ngIf="!customFieldsStatus[keys.field]['customField1']"
                  >
                    {{
                      customFieldsPayload[keys.field] === null ||
                      customFieldsPayload[keys.field]['customOne'] === ''
                        ? 'Custom Field 1'
                        : customFieldsPayload[keys.field]['customOne'] | titlecase
                    }}
                  </div>
                </div>

                <div
                  elem="customlabel2"
                  (click)="(obj.disabled || !obj.checked) ? null : onEditCustomFields(keys.field, obj.field, $event)"
                  *ngIf="obj.field === 'customField2'">
                  <input
                    elem="customfield2"
                    type="text"
                    placeholder="Custom Field 2"
                    formControlName="customField2"
                    (blur)="(obj.disabled || !obj.checked) ? null : onEditCustomFields(keys.field, obj.field, $event)"
                    #customFieldTwo
                    *ngIf="customFieldsStatus[keys.field]['customField2']"
                    maxlength="20"
                  />
                  <div
                    elem="customLabelText"
                    *ngIf="!customFieldsStatus[keys.field]['customField2']">
                    {{
                      customFieldsPayload[keys.field] === null ||
                      customFieldsPayload[keys.field]['customTwo'] === ''
                        ? 'Custom Field 2'
                        : customFieldsPayload[keys.field]['customTwo'] | titlecase
                    }}
                  </div>
                </div>
              </form>
            </div>
          </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
