import { IntermodalCustomFields, UpdateFlagPayload } from '@app/shared/models/dashboard/intermodal.model';
import { FetchContainersListPayload } from '@app/modules/dashboard/components/intermodal-containers-list/intermodal-containers-list.component';
import { Action } from '@ngrx/store';

export const FETCH_LIST = '[Intermodal] FETCH_LIST';
export const FETCH_LIST_SUCCESS = '[Intermodal] FETCH_LIST_SUCCESS';
export const FETCH_LIST_ALL = '[Intermodal] FETCH_LIST_ALL';
export const FETCH_LIST_ALL_SUCCESS = '[Intermodal] FETCH_LIST_ALL_SUCCESS';
export const FETCH_LIST_ERROR = '[Intermodal] FETCH_LIST_ERROR';

export const FETCH_TERMINAL_CODES_FOR_FILTER = '[Intermodal] FETCH_TERMINAL_CODES_FOR_FILTER';
export const FETCH_TERMINAL_CODES_FOR_FILTER_SUCCESS = '[Intermodal] FETCH_TERMINAL_CODES_FOR_FILTER_SUCCESS';
export const FETCH_TERMINAL_CODES_FOR_FILTER_ERROR = '[Intermodal] FETCH_TERMINAL_CODES_FOR_FILTER_ERROR';

export const SET_SELECTED_TRAIN_TRIP_ID = '[Intermodal] SET_SELECTED_TRAIN_TRIP_ID';

export const FETCH_CONTAINERS_LIST = '[Intermodal] FETCH_CONTAINERS_LIST';
export const FETCH_CONTAINERS_LIST_SUCCESS = '[Intermodal] FETCH_CONTAINERS_LIST_SUCCESS';
export const FETCH_CONTAINERS_LIST_ERROR = '[Intermodal] FETCH_CONTAINERS_LIST_ERROR';

export const UNCLAIM_CONTAINERS = '[Intermodal] UNCLAIM_CONTAINERS';
export const UNCLAIM_CONTAINERS_SUCCESS = '[Intermodal] UNCLAIM_CONTAINERS_SUCCESS';
export const UNCLAIM_CONTAINERS_ERROR = '[Intermodal] UNCLAIM_CONTAINERS_ERROR';

export const UPDATE_FLAG = '[Intermodal] UPDATE_FLAG';
export const UPDATE_FLAG_SUCCESS = '[Intermodal] UPDATE_FLAG_SUCCESS';
export const UPDATE_FLAG_ERROR = '[Intermodal] UPDATE_FLAG_ERROR';

export const UPDATE_CUSTOM_FIELDS = '[Intermodal] UPDATE_CUSTOM_FIELDS';
export const UPDATE_CUSTOM_FIELDS_SUCCESS = '[Intermodal] UPDATE_CUSTOM_FIELDS_SUCCESS';
export const UPDATE_CUSTOM_FIELDS_ERROR = '[Intermodal] UPDATE_CUSTOM_FIELDS_ERROR';
export const SET_FILTER = '[Intermodal] SET_FILTER';
export const SET_CONTAINER_LIST_FILTER = '[Intermodal] SET_CONTAINER_LIST_FILTER';

export const DOWNLOAD_CSV = '[Intermodal] DOWNLOAD_CSV';
export const DOWNLOAD_CSV_SUCCESS = '[Intermodal] DOWNLOAD_CSV_SUCCESS';
export const DOWNLOAD_CSV_ERROR = '[Intermodal] DOWNLOAD_CSV_ERROR';

export class FetchList implements Action {
  public readonly type = FETCH_LIST;

  constructor(public payload: any = null) {
  }
}

export class FetchListSuccess implements Action {
  public readonly type = FETCH_LIST_SUCCESS;

  constructor(public payload: any = null) {
  }
}

export class FetchListAll implements Action {
  public readonly type = FETCH_LIST_ALL;

  constructor(public payload: any = null) {
  }
}

export class FetchListAllSuccess implements Action {
  public readonly type = FETCH_LIST_ALL_SUCCESS;

  constructor(public payload: any = null) {
  }
}

export class FetchListError implements Action {
  public readonly type = FETCH_LIST_ERROR;

  constructor(public payload: any = null) {
  }
}

export class FetchTerminalCodesForFilter implements Action {
  public readonly type = FETCH_TERMINAL_CODES_FOR_FILTER;

  constructor(public payload: any = null) {
  }
}

export class FetchTerminalCodesForFilterSuccess implements Action {
  public readonly type = FETCH_TERMINAL_CODES_FOR_FILTER_SUCCESS;

  constructor(public payload: any = null) {
  }
}

export class FetchTerminalCodesForFilterError implements Action {
  public readonly type = FETCH_TERMINAL_CODES_FOR_FILTER_ERROR;

  constructor(public payload: any = null) {
  }
}

export class SetSelectedTrainTripId implements Action {
  public readonly type = SET_SELECTED_TRAIN_TRIP_ID;

  constructor(public payload: string = null) {
  }
}

export class FetchContainersList implements Action {
  public readonly type = FETCH_CONTAINERS_LIST;

  constructor(
    public payload: FetchContainersListPayload = null,
    public parent: string,
  ) {
  }
}

export class FetchContainersListSuccess implements Action {
  public readonly type = FETCH_CONTAINERS_LIST_SUCCESS;

  constructor(public payload: any = null) {
  }
}

export class FetchContainersListError implements Action {
  public readonly type = FETCH_CONTAINERS_LIST_ERROR;

  constructor(public payload: any = null) {
  }
}

export class UnclaimContainers implements Action {
  public readonly type = UNCLAIM_CONTAINERS;

  constructor(
    public payload: string[] = null,
    public parent: string,
  ) {
  }
}

export class UnclaimContainersSuccess implements Action {
  public readonly type = UNCLAIM_CONTAINERS_SUCCESS;

  constructor(public payload: any = null) {
  }
}

export class UnclaimContainersError implements Action {
  public readonly type = UNCLAIM_CONTAINERS_ERROR;

  constructor(public payload: any = null) {
  }
}

export class UpdateFlag implements Action {
  public readonly type = UPDATE_FLAG;

  constructor(public payload: UpdateFlagPayload = null) {
  }
}

export class UpdateFlagSuccess implements Action {
  public readonly type = UPDATE_FLAG_SUCCESS;

  constructor(public payload: any = null) {
  }
}

export class UpdateFlagError implements Action {
  public readonly type = UPDATE_FLAG_ERROR;

  constructor(public payload: any = null) {
  }
}

export class UpdateCustomFields implements Action {
  public readonly type = UPDATE_CUSTOM_FIELDS;

  constructor(
    public payload: IntermodalCustomFields[] = null,
    public parent: string,
  ) {
  }
}

export class UpdateCustomFieldsSuccess implements Action {
  public readonly type = UPDATE_CUSTOM_FIELDS_SUCCESS;

  constructor(public payload: any = null) {
  }
}

export class UpdateCustomFieldsError implements Action {
  public readonly type = UPDATE_CUSTOM_FIELDS_ERROR;

  constructor(public payload: any = null) {
  }
}

export class SetFilter implements Action {
  public readonly type = SET_FILTER;

  constructor(public payload: string[] = null) {
  }
}

export class SetContainerListFilter implements Action {
  public readonly type = SET_CONTAINER_LIST_FILTER;

  constructor(public payload: string[] = null) {
  }
}

export class DownloadCsv implements Action  {
  public readonly type = DOWNLOAD_CSV;
  constructor (public payload: any) {}
}

export class DownloadCsvSuccess implements Action  {
  public readonly type = DOWNLOAD_CSV_SUCCESS;
}

export class DownloadCsvError implements Action  {
  public readonly type = DOWNLOAD_CSV_ERROR;
}

export type All =
  | FetchList
  | FetchListSuccess
  | FetchListAll
  | FetchListAllSuccess
  | FetchListError
  | FetchTerminalCodesForFilter
  | FetchTerminalCodesForFilterSuccess
  | FetchTerminalCodesForFilterError
  | SetSelectedTrainTripId
  | FetchContainersList
  | FetchContainersListSuccess
  | FetchContainersListError
  | UnclaimContainers
  | UnclaimContainersSuccess
  | UnclaimContainersError
  | UpdateFlag
  | UpdateFlagSuccess
  | UpdateFlagError
  | UpdateCustomFields
  | UpdateCustomFieldsSuccess
  | UpdateCustomFieldsError
  | SetFilter
  | SetContainerListFilter
  | DownloadCsv
  | DownloadCsvSuccess
  | DownloadCsvError;
