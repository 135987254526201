/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import * as TerminalMetricsAction from './terminal-metrics.actions';
import { ApiQueryBuilder } from '@services/api-query-builder';
import { ApiCallService } from '@services/api-call.service';
import { TerminalMetricsService } from '@dashboard/services/terminal-metrics.service';

@Injectable()
export class TerminalMetricsEffects {

  constructor(
    private actions$: Actions,
    private api: ApiCallService,
    private terminalMetricsService: TerminalMetricsService,
  ) { }

  public GetTerminalMetrics$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<TerminalMetricsAction.FetchMetrics>(TerminalMetricsAction.FETCH_METRICS),
    mergeMap(({ payload }) =>
      this.api.constructApiCall(
        new ApiQueryBuilder()
          .addName('terminalListStatus')
          .addBody(this.terminalMetricsService.createRequestPayload(payload))
          .build(),
      ).pipe(
        map(this.terminalMetricsService.parseTerminalMetricsResponse(payload)),
        map(data => new TerminalMetricsAction.FetchMetricsSuccess(data)),
        catchError(error => of(new TerminalMetricsAction.FetchMetricsError(error))),
      ),
    ),
  ),
  );
}
