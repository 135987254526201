import { Action } from '@ngrx/store';

export const GET_TERMINAL_META = '[TerminalMeta] GET_TERMINAL_META';
export const GET_TERMINAL_META_SUCCESS = '[TerminalMeta] GET_TERMINAL_META_SUCCESS';
export const GET_TERMINAL_META_ERROR = '[TerminalMeta] GET_TERMINAL_META_ERROR';

export class TerminalMeta implements Action {
  public readonly type = GET_TERMINAL_META;
  constructor(public payload: any = null) { }
}

export class TerminalMetaSuccess implements Action {
  public readonly type = GET_TERMINAL_META_SUCCESS;
  constructor(public payload: any = null) { }
}

export class TerminalMetaError implements Action {
  public readonly type = GET_TERMINAL_META_ERROR;
  constructor(public payload: any = null) { }
}

export type All = TerminalMeta | TerminalMetaSuccess | TerminalMetaError;
