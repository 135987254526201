import { Injectable } from '@angular/core';
import { addDays, subDays } from 'date-fns';

import {
  IntermodalCustomFields,
  FetchListPayload,
  FILTER_KEYS_ENUM,
  IntermodalContainerStatusEnum,
  IntermodalItem,
  IntermodalVesselStatusEnum,
  INTERMODAL_LIST_PAGE_SIZE,
  UpdateFlagPayload,
} from '@app/shared/models/dashboard/intermodal.model';
import { DEFAULT_PAGE_SIZE } from '@app/core/services/api-query-builder';
import { PriorityFlagsEnum, priorityFlagsList } from '@app/shared/models/dashboard/container-status.model';
import { monthNames } from '@models/shared/landing-page.constants';
import { OWNER } from '@models/dashboard/filters.model';
import { Store, select } from '@ngrx/store';
import { UserState } from '@app/store/user/user.state';
import { take } from 'rxjs/operators';

const DAYS_INTERVAL = 14;

@Injectable({ providedIn: 'root' })
export class IntermodalService {
  private userStore: Store<UserState>;
  public createFetchListRequestPayload(payload: FetchListPayload) {
    return this.createListRequestPayload(payload);
  }

  public createFetchListAllRequestPayload(payload: FetchListPayload) {
    const requestPayload = this.createListRequestPayload(payload);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { size, ...searchParameters } = requestPayload.searchParameters[0];

    return {
      ...requestPayload,
      searchParameters: [searchParameters],
    };
  }

  public createFetchTerminalFiltersRequestPayload() {
    const requestPayload = this.createListRequestPayload();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { size, filters, ...searchParameters } = requestPayload.searchParameters[0];

    return {
      ...requestPayload,
      searchParameters: [searchParameters],
    };
  }

  private createListRequestPayload(payload?: FetchListPayload) {
    const page = payload && payload.page || 1;
    const filters = this.createPayloadFilters(payload && payload.filters || [], payload && payload.userId || undefined);

    return {
      fromDate: subDays(new Date(), DAYS_INTERVAL),
      toDate: addDays(new Date(), DAYS_INTERVAL),
      searchParameters: [
        {
          key: 'byTrainTripId',
          children: [
            {
              key: 'byConstant',
              children: [],
            },
          ],
          'firstSortPreference': {
            'orderBy': 'KEY',
            'orderByDirection': 'ASC',
          },
          'secondSortPreference': {
            'orderBy': 'COUNT',
            'orderByDirection': 'ASC',
          },
          filters,
          fetchSource: true,
          size: page * INTERMODAL_LIST_PAGE_SIZE,
        },
      ],
    };
  }

  private createPayloadFilters(payloadFilters: string[], userId: string) {
    const filters = [
      {
        filterKey: 'railRoad',
        filterValues: [
          'Union Pacific',
        ],
        comparison: 'EQUAL',
      },
    ];
    if (payloadFilters.includes(FILTER_KEYS_ENUM.MY_CONTAINERS) && !!userId) {
      filters.push({
        filterKey: FILTER_KEYS_ENUM.MY_CONTAINERS,
        filterValues: [`${ userId }`],
        comparison: 'EQUAL',
      });
    }

    const terminalFilters = payloadFilters
      // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
      .filter(f => f !== FILTER_KEYS_ENUM.MY_CONTAINERS)
      // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
      .filter(f => f !== IntermodalVesselStatusEnum.INBOUND_TO_PORT)
      // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
      .filter(f => f !== IntermodalVesselStatusEnum.REACHED_PORT);
    if (terminalFilters.length) {
      filters.push({
        filterKey: FILTER_KEYS_ENUM.TERMINAL,
        filterValues: terminalFilters,
        comparison: 'EQUAL',
      });
    }

    const statusFilterKey = [];
    const statusFilterValues = [];
    let statusComparison = 'EXIST';
    if (
      payloadFilters.includes(IntermodalVesselStatusEnum.INBOUND_TO_PORT) &&
      !payloadFilters.includes(IntermodalVesselStatusEnum.REACHED_PORT)
    ) {
      statusFilterKey.push(FILTER_KEYS_ENUM.INTERCHANGE_DELIVERED);
      statusFilterValues.push(IntermodalVesselStatusEnum.REACHED_PORT);
      statusComparison = 'NOT_EXIST';
    }
    if (
      payloadFilters.includes(IntermodalVesselStatusEnum.REACHED_PORT) &&
      !payloadFilters.includes(IntermodalVesselStatusEnum.INBOUND_TO_PORT)
    ) {
      statusFilterKey.push(FILTER_KEYS_ENUM.INTERCHANGE_DELIVERED);
      statusFilterValues.push(IntermodalVesselStatusEnum.REACHED_PORT);
    }
    if (
      payloadFilters.includes(IntermodalVesselStatusEnum.REACHED_PORT) &&
      payloadFilters.includes(IntermodalVesselStatusEnum.INBOUND_TO_PORT)
    ) {
      statusFilterKey.push(FILTER_KEYS_ENUM.ESTIMATED_TIME_OF_ARRIVAL);
      statusFilterKey.push(FILTER_KEYS_ENUM.INTERCHANGE_DELIVERED);
      statusFilterValues.push(IntermodalVesselStatusEnum.INBOUND_TO_PORT);
      statusFilterValues.push(IntermodalVesselStatusEnum.REACHED_PORT);
    }
    if (statusFilterKey.length && statusFilterValues.length) {
      filters.push({
        filterKey: statusFilterKey.join(','),
        filterValues: statusFilterValues,
        comparison: statusComparison,
      });
    }

    return filters;
  }

  public parseFetchTerminalFiltersResponse({ response }: any) {
    if (
      response &&
      response.aggregations &&
      response.aggregations.byTrainTripId &&
      response.aggregations.byTrainTripId.buckets &&
      response.aggregations.byTrainTripId.buckets.length
    ) {
      const terminalCodes = response.aggregations.byTrainTripId.buckets.reduce((result: string[], bucket) => {
        const terminalCode =
          bucket.data &&
          bucket.data.hits &&
          bucket.data.hits.hits[0] &&
          bucket.data.hits.hits[0]._source &&
          bucket.data.hits.hits[0]._source.destinationTerminalCode;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        result.push(terminalCode);

        return result;
      }, []);

      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      return [...new Set(terminalCodes.filter(t => !!t))];
    }
    return [];
  }

  public parseFetchListResponse({ response }: any): IntermodalItem[] {
    if (
      response &&
      response.aggregations &&
      response.aggregations.filter &&
      response.aggregations.filter.byTrainTripId &&
      response.aggregations.filter.byTrainTripId.buckets &&
      response.aggregations.filter.byTrainTripId.buckets.length
    ) {
      return response.aggregations.filter.byTrainTripId.buckets.map(bucket => {
        const hits = bucket.data && bucket.data.hits && bucket.data.hits.hits[0] || {};
        const source: Omit<IntermodalItem, 'vesselStatus'> = hits && hits._source || {};
        const vesselStatus = hits && hits.fields && hits.fields.vesselStatus[0];
        const containerCount = bucket.doc_count || 0;
        const date = source.estimatedTimeOfArrival || source.interchangeDelivered;
        const dropDownIntermodalName = `${ source.trainId }
          ${ date
          ? '- ' + new Date(date).getDate() + ' ' + monthNames[new Date(date).getMonth()].substr(0, 3)
          : '' }`;

        return {
          ...source,
          id: hits._id,
          containerCount,
          dropDownIntermodalName,
          vesselStatus,
        };
      });
    }

    return [];
  }

  public createFetchContainersListRequestPayload({ trainTripId, paging, filter }) {
    const filters = this.parseContainersListRequestPayload(filter);
    const additionalConditions = this.getAdditionalConditions(filter);
    const filterParams = [];
    const defaultFilter = {
      key: 'trainTripId',
      values: [trainTripId],
      comparison: 'EQUAL',
    };
    filterParams.push(defaultFilter);
    if (filters) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      filterParams.push(...filters);
    }
    const { page, sort } = paging;

    return {
      fromDate: subDays(new Date(), DAYS_INTERVAL),
      toDate: addDays(new Date(), DAYS_INTERVAL),
      sourceFields: ['*'],
      search: filterParams,
      from: DEFAULT_PAGE_SIZE * page,
      size: DEFAULT_PAGE_SIZE,
      orderBy: sort.active,
      orderByDirection: sort.direction.toUpperCase(),
      ...additionalConditions,
    };
  }

  public parseContainersListRequestPayload(filter) {
    let userId;
    this.userStore.pipe(select('user'), take(1)).subscribe(
      data =>  userId = data['user'].userDetails.idUser,
   );
    
    const filterPayload = [];

    if (filter.MY_CONTAINERS && !!userId) {
      filterPayload.push({
        key: FILTER_KEYS_ENUM.MY_CONTAINERS,
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        values: [`${ userId }`],
        comparison: 'EQUAL',
      });
    }

    if (filter.OWNER) {
      filterPayload.push({
        key: OWNER,
        values: [...filter.OWNER],
        comparison: 'EQUAL',
      });
    }

    if (filter.FLAG) {
      const priorityFilters = filter.FLAG.map(flag => {
        if (flag === 'LOW') {
          return '1';
        } else if (flag === 'MEDIUM') {
          return '2';
        } else if (flag === 'HIGH') {
          return '3';
        }
      });
      filterPayload.push({
        key: 'custom.priority',
        values: priorityFilters,
        comparison: 'EQUAL',
      });
    }

    if (filter.UNLOAD_EVENT) {
      filterPayload.push({
        key: 'containerStatus',
        values: filter.UNLOAD_EVENT,
        comparison: 'EQUAL',
      });
    }

    if (filter.CONTAINER_LENGTH) {
      filterPayload.push({
        key: 'derivedContainerLength',
        values: filter.CONTAINER_LENGTH,
        comparison: 'EQUAL',
      });
    }

    filterPayload.forEach((res) => {
      if (!res.values.length) {
        const index = filterPayload.indexOf(res);
        if (index !== -1) {
          filterPayload.splice(index, 1);
        }
      }
    });

    return filterPayload;
  }

  private getAdditionalConditions(filter) {
    return filter?.LAST_CLAIMED
      ? {
          pottAdditionalConditions: {
            fetchLastAddedContainers: true,
          },
        }
      : {};
  }

  public parseFetchContainersListResponse(response, page) {
    const hits = response && response.hits || undefined;
    const contentList = hits && hits.hits.length && hits.hits.map(item => item._source) || [];

    const content = contentList.map(i => ({
      ...i,
      containerNumber: i.containerNumber,
      containerTripId: i.containerTripId,
      type: i.derivedContainerLength,
      status: i.containerStatus,
      eventDate: i.eventTimeStamp,
      wellCarId: i.flatcarEquipmentId || '-',
      vessel: i.containerStatus === IntermodalContainerStatusEnum.LOADED_ON_TO_VESSEL && !!i.vesselName ? i.vesselName : '-',
      ownerName: i.custom[0] && i.custom[0].ownerName,
      customField1: i.custom[0] && i.custom[0].customField1 || '',
      customField2: i.custom[0] && i.custom[0].customField2 || '',
      customFields: {
        customField1: i.custom[0] && i.custom[0].customField1 || '',
        customField2: i.custom[0] && i.custom[0].customField2 || '',
      },
      owner: {
        email: '',
        familyName: '',
        fullName: i.custom[0] && i.custom[0].ownerName,
        givenName: i.custom[0] && i.custom[0].ownerName,
        id: i.custom[0] && i.custom[0].ownerId,
      },
      note: i.custom[0] && i.custom[0].notes || '',
      flag: i.custom[0] && i.custom[0].priority && priorityFlagsList[i.custom[0].priority] || PriorityFlagsEnum.NONE,
    }));

    return {
      content,
      totalContainerCount: hits && hits.total && hits.total.value || 0,
      totalElements: hits && hits.total && hits.total.value || 0,
      size: content.length,
      number: page,
      numberOfElements: content.length,
    };
  }

  public createUnclaimContainersRequestPayload(containerNumbers: string[]) {
    return {
      containerNumbers,
    };
  }

  public createUpdateFlagRequestPayload(payload: UpdateFlagPayload) {
    return this.createUpdateCustomFieldsRequestPayload([payload]);
  }

  public createUpdateCustomFieldsRequestPayload(customFields: IntermodalCustomFields[]) {
    return customFields.map((c: IntermodalCustomFields) => ({
      containerNumber: c.containerNumber,
      tripId: c.tripId,
      firstCustomField: c.firstCustomField !== undefined ? c.firstCustomField : null,
      secondCustomField: c.secondCustomField !== undefined ? c.secondCustomField : null,
      notes: c.notes !== undefined ? c.notes : null,
      owner: c.owner !== undefined ? c.owner : null,
      ownerId: c.ownerId !== undefined ? c.ownerId : null,
      priority: c.priority !== undefined ? c.priority : null,
    }));
  }
}
