import { Action } from '@ngrx/store';
import { INotificationType } from '@models/dashboard/notification.model';

export const FETCH_NOTIFICATIONS = '[Notifications] FETCH_NOTIFICATIONS';
export const FETCH_NOTIFICATIONS_SUCCESS = '[Notifications] FETCH_NOTIFICATIONS_SUCCESS';
export const FETCH_NOTIFICATIONS_FAIL = '[Notifications] FETCH_NOTIFICATIONS_FAIL';
export const CLOSE_NOTIFICATIONS = '[Notifications] CLOSE_NOTIFICATIONS';
export const OPEN_NOTIFICATIONS = '[Notifications] OPEN_NOTIFICATIONS';
export const MARK_AS_READ = '[Notifications] MARK_AS_READ';
export const FETCH_NOTIFICATION_CATEGORIES = '[Notifications] FETCH_NOTIFICATION_CATEGORY';
export const FETCH_NOTIFICATION_CATEGORIES_SUCCESS = '[Notifications] FETCH_NOTIFICATION_CATEGORY_SUCCESS';
export const FETCH_NOTIFICATION_CATEGORIES_FAIL = '[Notifications] FETCH_NOTIFICATION_CATEGORY_FAIL';
export const FETCH_ALERT_TYPES = '[Notifications] FETCH_ALERT_TYPES';
export const FETCH_ALERT_TYPES_SUCCESS = '[Notifications] FETCH_ALERT_TYPES_SUCCESS';
export const FETCH_ALERT_TYPES_FAIL = '[Notifications] FETCH_ALERT_TYPES_FAIL';
export const ARCHIVE_NOTIFICATIONS = '[Notifications] ARCHIVE_NOTIFICATIONS';

export class FetchNotifications implements Action {
  public readonly type = FETCH_NOTIFICATIONS;
  constructor(public payload?: any) { }
}

export class FetchNotificationsSuccess implements Action {
  public readonly type = FETCH_NOTIFICATIONS_SUCCESS;
  constructor(public payload: any) { }
}
export class FetchNotificationsFail implements Action {
  public readonly type = FETCH_NOTIFICATIONS_FAIL;
  constructor(public payload?: any) { }
}

export class CloseNotifications implements Action {
  public readonly type = CLOSE_NOTIFICATIONS;
}

export class OpenNotifications implements Action {
  public readonly type = OPEN_NOTIFICATIONS;
}

export class MarkAsRead implements Action {
  public readonly type = MARK_AS_READ;
  constructor(public payload: any) { }
}
export class FetchNotificationCategories implements Action {
  public readonly type = FETCH_NOTIFICATION_CATEGORIES;
}

export class FetchNotificationCategoriesSuccess implements Action {
  public readonly type = FETCH_NOTIFICATION_CATEGORIES_SUCCESS;
  constructor(public payload: any) { }
}

export class FetchNotificationCategoriesFail implements Action {
  public readonly type = FETCH_NOTIFICATION_CATEGORIES_FAIL;
  constructor(public payload: any) { }
}

export class FetchAlertTypes implements Action {
  public readonly type = FETCH_ALERT_TYPES;
}

export class FetchAlertTypesSuccess implements Action {
  public readonly type = FETCH_ALERT_TYPES_SUCCESS;
  constructor(public payload: INotificationType[]) { }
}

export class FetchAlertTypesFail implements Action {
  public readonly type = FETCH_ALERT_TYPES_FAIL;
}

export class ArchiveNotificatons implements Action {
  public readonly type = ARCHIVE_NOTIFICATIONS;
  constructor(public payload: {ids: string[], type: string}) {}
}

export type All = FetchNotifications
  | FetchNotificationsSuccess
  | FetchNotificationsFail
  | CloseNotifications
  | OpenNotifications
  | MarkAsRead
  | FetchNotificationCategories
  | FetchNotificationCategoriesSuccess
  | FetchNotificationCategoriesFail
  | FetchAlertTypes
  | FetchAlertTypesSuccess
  | FetchAlertTypesFail
  | ArchiveNotificatons;
