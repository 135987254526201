import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as UnclaimActions from './unclaim.actions';
import { UnclaimState } from './unclaim.state';

export type Action = UnclaimActions.All;

export const initialState: UnclaimState = {
  success: false,
  loading: false,
  error: false,
  failedContainers: [],
  successContainers: [],
};

const getUnclaimState = createFeatureSelector<UnclaimState>('unclaim');

export const unclaimResult = createSelector(
  getUnclaimState,
  state => {
    return {
      success: !!state.successContainers.length,
      error: !!state.failedContainers.length,
      failedContainers: state.failedContainers,
      successContainers: state.successContainers,
    };
  },
);

export const successOperationFlag = createSelector(
  getUnclaimState,
  state => state.success,
);

export const unclaimLoading = createSelector(
  getUnclaimState,
  state => state.loading,
);

export const errorOperationFlag = createSelector(
  getUnclaimState,
  state => state.error,
);

// eslint-disable-next-line @typescript-eslint/naming-convention
export function UnclaimReducer(state = initialState, action: Action): UnclaimState {
  switch (action.type) {
    case UnclaimActions.UNCLAIM_CONTAINERS: {
      return {
        ...state,
        success: false,
        loading: true,
        error: false,
        successContainers: [],
        failedContainers: [],
      };
    }

    case UnclaimActions.UNCLAIM_CLEAR: {
      return {
        ...state,
        success: false,
        loading: false,
        error: false,
        successContainers: [],
        failedContainers: [],
      };
    }

    case UnclaimActions.UNCLAIM_CONTAINERS_RESULT: {
      return {
        ...state,
        loading: false,
        success: !!action.payload.success.length,
        error: !!action.payload.failed.length,
        successContainers: action.payload.success,
        failedContainers: action.payload.failed,
      };
    }

    case UnclaimActions.UNCLAIM_CONTAINERS_FAILED: {
      return {
        ...state,
        loading: false,
        success: false,
        successContainers: [],
        error: true,
      };
    }

    default:
      return state;
  }
}
