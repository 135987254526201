<div block="tos">
  <div elem="text">
    <h2>Terms of Use</h2>
    <div (scroll)="onScroll($event)" block="tos-doc">
      <p>
        <strong>Thank you</strong> for visiting the Wabtec website (“Site”). The Site is operated by
        Wabtec Corporation [and its subsidiaries] (“Wabtec,”) for the benefit of you
        and, if applicable, the entity for which you are using the Site (“you”).
        Your use of the Site is governed at all times by the following Terms of Use as
        they may be amended from time to time. [Certain sections of the Site may contain
        additional restrictions as to use or access. ]Each time you access the Site you
        are indicating that you have read and agree to the Terms of Use and that you have
        authority to enter into this agreement between Wabtec and you. If you do not agree
        with the Terms of Use, you must discontinue use of the Site.
      </p>
      <p>
        <strong> 1.&nbsp;Permission to Use the Site; Restrictions.</strong>
      </p>
      <p>
        Wabtec hereby grants you permission to use the Site and its Content only for your
        personal use and in accordance with these Terms of Use. “Content” means any data
        and information accessible on the Site, including without limitation text, images,
        video, graphics, audio files, software, and any combination or derivative work of
        them. Wabtec reserves the right to suspend or revoke your use permission in its
        sole discretion without notice. You may download and print Content, including these
        Terms of Use, to your personal computing device solely for your own personal
        non-commercial use. You may not otherwise use, reproduce, alter, prepare derivative
        works or modify the Site or its Content in any way, nor may you transmit, distribute,
        or display Content to third parties without the express written permission of the
        Wabtec Legal Department.

      </p>

      <p>
        <strong> 2.&nbsp;Disclaimer -- No Warranties; As Is.&nbsp;</strong>
      </p>
      <p>
        Content on the Site may contain technical inaccuracies or typographical errors.
        Content may be changed, updated or deleted without notice. Wabtec may also make
        changes in products and the programs described in the Site at any time without
        notice. Products and programs are subject to availability, credit approval and
        Wabtec’s sales terms and conditions. Wabtec may publish information on the Site
        regarding products, programs or services not available in all geographical
        locations. Contact a Wabtec Sales Representative for information on the products,
        programs and services that may be available to you.
      </p>
      <p>
        THE SITE, AND EVERYTHING PROVIDED ON THE SITE, ARE PROVIDED TO YOU “AS IS” AND
        “WITH ALL FAULTS”, AND YOU ASSUME THE ENTIRE RISK AS TO THE RESULTS AND THE
        PERFORMANCE OF THE SITE. WABTEC HEREBY DISCLAIMS AND YOU AGREE TO WAIVE ALL
        WARRANTIES OF ANY KIND WITH REGARD TO THE SITE OR ANY CONTENT OR SERVICES
        PROVIDED ON THE SITE, EITHER EXPRESSED OR IMPLIED, INCLUDING BUT NOT LIMITED
        TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
        NEGLIGENCE, INTERFERENCE WITH YOUR ENJOYMENT OF THE SITE OR OTHERS, OR AGAINST
        INTELLECTUAL PROPERTY INFRINGEMENT. WABTEC DOES NOT GIVE ANY WARRANTIES OR
        UNDERTAKE ANY DUTIES WITH REGARD TO: THE AVAILABILITY OF THE SITE, FUNCTIONALITY,
        TIMELINESS OF SERVICES, ACCURACY OR CURRENCY OF CONTENT, LACK OF VIRUSES OR OTHERWISE.
        <strong>Use of the Site is at your sole risk.</strong> Some jurisdictions do not allow
        the exclusion of implied warranties, so the above exclusion may not apply to you.
        <strong>IN NO EVENT SHALL WABTEC BE LIABLE TO YOU FOR ANY DIRECT, INDIRECT, SPECIAL
          OR OTHER INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES RESULTING FROM ANY USE OF
          THE SITE OR ANY HYPERLINKED WEBSITE, INCLUDING, WITHOUT LIMITATION, ANY LOST PROFITS,
          BUSINESS INTERRUPTION, LOSS OF PROGRAMS OR DATA ON YOUR SYSTEM OR OTHERWISE, EVEN
          IF WABTEC HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES AND EVEN IF THE REMEDIES
          OTHERWISE PROVIDED UNDER THESE TERMS OF USE , AT LAW, OR IN EQUITY, FAIL OF THEIR
          ESSENTIAL PURPOSE.</strong>
      </p>
      <p>
        <strong> 3.&nbsp;Intellectual Property Notices. </strong>
      </p>
      <p>
        Wabtec and its licensors hold the copyright in all Content on the Site. The Content
        includes trademarks (registered and unregistered), text, graphics, logos and service
        marks that are the property of Wabtec and other parties. You are permitted to
        display, copy, distribute and download the Content for personal, noncommercial use
        only, provided you do not modify the Content and that you retain all copyright and
        other proprietary notices contained in the Content. Nothing in these Terms of Use
        confers any rights in the Content to you that are not specifically mentioned in
        these Terms of Use.
      </p>

      <p>
        <strong> 4.&nbsp;Links to Third-Party Web Sites.</strong>
      </p>
      <p>
        As a convenience to you, the Site may provide hyperlinks to other websites
        controlled by third parties (“Third-Party Websites”). The products and
        information on Third-Party Websites are promoted and sold by third parties
        and not by Wabtec. <strong>Your visit to these Third-Party Websites, and
          any transactions or information gathering you engage in at those websites,
          are subject to the terms and conditions of the Third-Party Websites,
          not the terms and conditions of the Site. Any links on the Site to
          Third-Party Websites do not imply a business or other relationship, or
          an endorsement of the websites by Wabtec, unless specifically stated in
          the Site, and no such Third-Party Website is authorized to make any
          representation or warranty on Wabtec’s behalf. In no event shall Wabtec
          be held liable in any manner for providing a link or reference to a
          Third-Party Website.</strong>
      </p>

      <p>
        <strong> 5.&nbsp;Prohibited Uses.</strong>
      </p>
      <p>
        You shall not engage in unacceptable use of the Site or its Content, which includes,
        without limitation, use of the Site or its Content to: (a) disseminate, store or
        transmit unsolicited messages, chain letters or unsolicited commercial e-mail; (b)
        disseminate or transmit material that, to a reasonable person may be abusive, obscene,
        pornographic, defamatory, harassing, grossly offensive, vulgar, threatening or
        malicious; (c) disseminate, store or transmit files, graphics, software or other
        materials that actually or potentially infringes the copyright, trademark, patent,
        trade secret or other intellectual property right of any person; (d) create a false
        identity or to otherwise attempt to mislead any person or Wabtec as to the identity
        or origin of any communication; (e) interfere, disrupt or attempt to gain unauthorized
        access to or any other computer network; (f) disseminate, store or transmit viruses,
        Trojan horses or any other malicious code or program; or (g) engage in any other
        activity specifically prohibited by these Terms of Use or deemed by Wabtec to be in
        conflict with the spirit or intent of these Terms of Use.
      </p>
      <p>
        <strong> 6.&nbsp;Indemnification.</strong>
      </p>
      <p>
        You agree to indemnify, hold harmless and defend Wabtec, and its affiliates, agents,
        employees, and licensors from and against any and all claims and expenses, including
        reasonable attorneys’ fees, brought by a third party arising out of or related in any
        way to your use of the Site, or your violation of these Terms of Use, violation of any
        law or regulation, or violation of any proprietary or privacy right.
      </p>

      <p>
        <strong> 7.&nbsp;Governing Law; Venue.</strong>
      </p>
      <p>
        These Terms of Use and any dispute arising hereunder will be governed and construed
        in accordance with the laws of the Commonwealth of Pennsylvania, U.S.A., without
        giving effect to the principles of its conflict of law provisions. You agree and
        consent to the exclusive jurisdiction of and venue in the state or federal courts
        in Allegheny County, Pennsylvania, U.S.A. in all disputes arising out of or relating
        to these Terms of Use or to access and use of the Site. If you choose to access the
        Site from outside the U.S. you are responsible for compliance with local laws, if
        and to the extent local laws are applicable.
      </p>
      <p>
        <strong> 8.&nbsp; Privacy.</strong>
      </p>
      <p>
        The Wabtec <a href="https://www.wabteccorp.com/privacy-policy" rel="noopener noreferrer nofollow" target="_blank"
        class="highlight">Privacy Policy</a> is a part of these Terms of Use and its terms are incorporated
        by this reference. Please read it now (by clicking on the “Privacy Policy” link). The policy explains
        how certain information about you is collected and may be used. Data within this application is
        stored within the United States.
      </p>
      <p>
        <strong> 9.&nbsp;No Payment for Submission of Comments, Ideas, and Other Information.</strong>
      </p>
      <p>
        From time to time, the Site may afford you the opportunity to interact
        with Wabtec. You grant Wabtec a royalty-free, irrevocable, unrestricted,
        nonexclusive worldwide license for all possible rights and all possible
        media now known or later developed, to use, modify, copy, sublicense, or
        display any submissions that you provide to us including, but not limited
        to, feedback or ideas regarding the Site, Content, comments, suggestions,
        ideas, concepts, or other information, including personal contact
        information (collectively, “Submission”) for any purpose, commercial or
        otherwise, without compensation to you. Except as expressly provided in
        the Privacy Policy, Wabtec has no obligations regarding any Submission,
        including any obligation of confidentiality or remuneration, and Wabtec
        shall not be liable for any use or disclosure (including publication in
        any medium) of any Submission.
      </p>
      <p>
        <strong> 10.&nbsp;Changes to Terms and Conditions.</strong>
      </p>
      <p>
        These Terms of Use may change from time to time. When you visit the
        Site, you are bound by the version of these Terms of Use that is then
        in effect and you should, therefore, periodically visit this page
        to determine the then-current Terms of Use to which you are bound.
      </p>
    </div>
  </div>
  <div mat-dialog-actions elem="buttons">
    <button
      elem="button"
      mat-flat-button
      color="primary"
      (click)="onDeclineClick($event)"
      type="button"
    >
      Decline
    </button>
    <button
      elem="button"
      mat-flat-button
      color="accent"
      type="button"
      (click)="onAcceptClick($event)"
      [disabled]="!canBeAccepted"
      [class.disabled]="!canBeAccepted"
    >
      Accept
    </button>
  </div>
</div>
