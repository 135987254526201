import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { Action, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { ApiQueryBuilder } from '@services/api-query-builder';
import { ApiCallService } from '@services/api-call.service';

import * as AddContainersActions from './add-containers.actions';
import * as GlobalErrorActions from '@store/global-error/global-error.actions';
import { State as AddContainersState } from '@store/add-containers/add-containers.state';
import { FeatureFlagService } from '@services/feature-flag.service';
import { DashboardActions } from '@store/dashboard/dashboard.actions';


@Injectable()
export class AddContainersEffect {
  public readonly features = {
    updatePendingContainers: this.featureFlags.featureEnabled('updatePendingContainers'),
  };

  constructor(
    private actions$: Actions,
    private api: ApiCallService,
    private store: Store<AddContainersState>,
    private featureFlags: FeatureFlagService,
  ) { }

  public addContainers$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType<AddContainersActions.AddContainers>
      (AddContainersActions.ADD_CONTAINERS),
      switchMap(action => {
        return this.api
          .constructApiCall(
            new ApiQueryBuilder()
              .addName('addContainer')
              .addBody(action.payload)
              .build(),
          )
          .pipe(
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            map(((data: any) => new AddContainersActions.AddContainersResult(
              {
                success: data.successResults,
                failed: data.failureResults,
                invalidContainers: data.invalidContainers,
                claimConditionsNotSatisfiedInES: data.claimConditionsNotSatisfiedInES,
                containersNotInES: data.containersNotInES,
                claimingType: action.payload.claimingType,
                cancelModal: action.cancelModal,
              },
            ))),
            catchError(error => {
              const payload = {
                errorType: 'network',
                error,
              };
              this.store.dispatch(
                new AddContainersActions.AddContainersError(error),
              );
              return of(new GlobalErrorActions.SetGlobalError(payload));
            }),
          );
        }),
      ),
    );

  public addPendingContainers$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType<AddContainersActions.AddPendingContainers>
      (AddContainersActions.ADD_PENDING_CONTAINERS),
      switchMap(action => {
        return this.api
          .constructApiCall(
            new ApiQueryBuilder()
              .addName('pendingContainers')
              .addBody(action.payload)
              .build(),
          )
          .pipe(
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            map(((data: any) => new AddContainersActions.AddPendingContainersResult(
              {
                successPendingContainers: data.successResult,
              },
            ))),
            catchError(error => {
              const payload = {
                errorType: 'network',
                error,
              };
              this.store.dispatch(
                new AddContainersActions.AddPendingContainersError(error),
              );
              return of(new GlobalErrorActions.SetGlobalError(payload));
            }),
          );
        }),
      ),
    );

  public UploadFile$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType<AddContainersActions.UploadFile>
      (AddContainersActions.UPLOAD_FILE),
      switchMap(action => {
        const formData: FormData = new FormData();
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        formData.append('file', action.payload.file, action.payload.file.name);

        return this.api
          .constructApiCall(
            new ApiQueryBuilder()
              .addName('fileUpload')
              .addBody({ data: formData })
              .setIsFile(true)
              .build(),
          )
          .pipe(
            switchMap(data => [
              DashboardActions.updateListAndChart({ parent: 'container' }),
              new AddContainersActions.AddContainersResult(
                {
                  success: data['successfulContainerNumbers'],
                  failed: data['failedContainerNumbers'],
                  invalidContainers: data['invalidContainers'],
                  claimConditionsNotSatisfiedInES: data['claimConditionsNotSatisfiedInES'],
                  containersNotInES: data['containersNotInES'],
                  claimingType: action.payload.claimingType,
                  cancelModal: action.cancelModal,
                },
              ),
            ]),
            catchError(error => {
              const payload = {
                errorType: 'network',
                error,
              };
              this.store.dispatch(
                new AddContainersActions.AddContainersError(error),
              );
              return of(new GlobalErrorActions.SetGlobalError(payload));
            }),
          );
        }),
      ),
    );
}
