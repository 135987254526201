import { Component, EventEmitter, Input, Output, ChangeDetectionStrategy } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-table-show-more-button',
  templateUrl: './table-show-more-button.component.html',
  styleUrls: ['./table-show-more-button.component.css'],
})
export class TableShowMoreButtonComponent {
  @Input() public disabled: boolean;
  @Input() public hasMore: boolean;
  @Output() public clickAction = new EventEmitter();

  public onClick() {
    this.clickAction.emit();
  }
}
