import { Component, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-prompt-modal',
  templateUrl: './prompt-modal.component.html',
  styleUrls: ['./prompt-modal.component.css'],
})
export class PromptModalComponent {
  public closeForm = new EventEmitter<void>();

  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  public onRegisterAcceptClick = (_: any): void => {
    this.closeForm.emit();
    this.router.navigateByUrl('/register');
  }

  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  public onLoginAcceptClick = (_: any): void  => {
    this.closeForm.emit();
    this.router.navigateByUrl('/container/overview');
  }

  public onCancel = (): void => this.closeForm.emit();

  constructor(private router: Router) {}
}
