import { Action } from '@ngrx/store';

export const REGISTER = '[Register] REGISTER';
export const REGISTER_SUCCESS = '[Register] REGISTER_SUCCESS';
export const REGISTER_ERROR = '[Register] REGISTER_ERROR';

export class Register implements Action {
  public readonly type = REGISTER;
  constructor(public payload: any = null) { }
}

export class RegisterSuccess implements Action {
  public readonly type = REGISTER_SUCCESS;
  constructor(public payload: any = null) { }
}

export class RegisterError implements Action {
  public readonly type = REGISTER_ERROR;
  constructor(public payload: any = null) { }
}

export type All = Register | RegisterSuccess | RegisterError;
