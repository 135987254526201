import { ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';
import * as InterestedPartiesActions from '@store/interested-parties/interested-parties.actions';
import { getAllUser, isInterestedPartiesLoading } from '@store/interested-parties/interested-parties.reducer';
import * as fromCustomFields from '@store/custom-fields/custom-fields.reducer';

import { EDIT_CONTAINER_MODAL as modalType } from '@models/shared/modal.constants';
import { IContainerStatus } from '@models/dashboard/container-status.model';
import { IParty } from '@models/dashboard/interested-parties.model';
import { BODY_FIELD, CUSTOM_FIELDS, EditedCustomFields, FIRST_CUSTOM_FIELD, NOTE, OWNER, SECOND_CUSTOM_FIELD } from '@models/shared/edit-containers-models';
import { ICustomFields } from '@models/shared/user-preferences.model';
import { FeatureFlagService } from '@services/feature-flag.service';
import { UserPreferencesService } from '@services/user-preferences.service';
import { getSelectedList } from '@store/dashboard/dashboard.selectors';
import { ContainerStatusListService } from '@services/container-status-list.service';

@Component({
  selector: 'app-edit-containers-modal',
  templateUrl: './edit-containers-modal.component.html',
  styleUrls: ['./edit-containers-modal.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditContainersModalComponent implements OnInit, OnDestroy {
  @Output() public closeForm = new EventEmitter<void>();
  @Output() public submitData = new EventEmitter<object>();

  public subscriptions: Subscription = new Subscription();
  private parent: string = '';

  public owners$: Observable<IParty[]>;
  public loading$: Observable<boolean>;
  public customFieldsData$: Observable<ICustomFields>;

  public form: FormGroup;
  public selected: string[];
  public changeOccured: boolean = false;
  public displayIndex: number = 1;
  public containersList: IContainerStatus[];
  public currentContainer: IContainerStatus;
  public readonly _injectedData: string;

  public readonly features = {
    updatePendingContainers: this.featureFlags.featureEnabled('updatePendingContainers'),
    userPreferences: this.featureFlags.featureEnabled('userPreferences'),
    isNewVesselListEnabled: this.featureFlags.featureEnabled('newVesselList'),
    isNewContainerStatusListEnabled: this.featureFlags.featureEnabled('newContainerStatusList'),
  };

  get note(): string {
    return this.form.get(NOTE).value !== null ? this.form.get(NOTE).value.trim() : this.form.get(NOTE).value;
  }
  get owner(): AbstractControl {
    return this.form.get(OWNER);
  }
  get applyAll(): AbstractControl {
    return this.form.get('applyAll');
  }
  get customField1(): string {
    return this.form.get(FIRST_CUSTOM_FIELD).value !== null ? this.form.get(FIRST_CUSTOM_FIELD).value.trim() : this.form.get(FIRST_CUSTOM_FIELD).value;
  }
  get customField2(): string {
    return this.form.get(SECOND_CUSTOM_FIELD).value !== null ? this.form.get(SECOND_CUSTOM_FIELD).value.trim() : this.form.get(SECOND_CUSTOM_FIELD).value;
  }

  constructor(
    private router: Router,
    // eslint-disable-next-line @typescript-eslint/ban-types
    private store: Store<{}>,
    private formBuilder: FormBuilder,
    private featureFlags: FeatureFlagService,
    private userPreferencesService: UserPreferencesService,
    private containerStatusListService: ContainerStatusListService,
  ) {}

  public ngOnInit(): void {
    this.parent = this.router.url.split('/')[1];

    this.store.dispatch(new InterestedPartiesActions.GetAllUser());
    this.owners$ = this.store.pipe(
      select(getAllUser),
      map((owners) => owners.slice().sort((a, b) => a.fullName.localeCompare(b.fullName))),
    );
    this.loading$ = this.store.pipe(select(isInterestedPartiesLoading));

    this.subscriptions.add(
      this.store
        .pipe(
          select(getSelectedList, { parent: this.parent }),
          tap((list) => {
            if (this._injectedData) {
              this.selected = [this._injectedData];
            } else {
              this.selected = list.selectedPendingContainers
                ? list.selected.concat(list.selectedPendingContainers)
                : list.selected;
            }
          }),
        )
        .subscribe(),
    );

    this.subscriptions.add(
      this.containerStatusListService
        .getContainerStatusListResults(this.parent)
        .pipe(
          map((containers: IContainerStatus[]) => containers.filter((c) => this.selected.includes(c.containerNumber))),
          take(1),
          tap((list) => ((this.containersList = list), (this.currentContainer = list[0]))),
        )
        .subscribe(),
    );

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const note = this.parent === 'intermodal' ? this.currentContainer?.note : this.fromNullable(this.currentContainer?.note, BODY_FIELD);
    this.form = this.formBuilder.group({
      owner: ['', []],
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      customField1: [this.fromNullable(this.currentContainer?.customFields, FIRST_CUSTOM_FIELD), []],
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      customField2: [this.fromNullable(this.currentContainer?.customFields, SECOND_CUSTOM_FIELD), []],
      note: [note, []],
      applyAll: [false, []],
    });

    this.subscriptions.add(
      this.form.valueChanges
        .pipe(
          map((changes) => !!changes),
          filter((change) => change),
          take(1),
        )
        .subscribe(),
    );

    if (this.features.userPreferences) {
      const parentFields = 'containerFields';
      this.customFieldsData$ = this.store.pipe(
        select(fromCustomFields.getCustomFieldsResponse),
        map((data) => (data ? data[parentFields] : this.userPreferencesService.getCustomFieldData(parentFields))),
      );
    }
  }

  private fromNullable = (context: object | null, prop: string): object | string => (context && context[prop] ? context[prop] : '');

  private overwriteContainer = (): IContainerStatus => ({
    ...this.currentContainer,
    owner: this.owner.value ? this.owner.value : this.currentContainer.owner ? this.currentContainer.owner : '',
    customFields: {
      customField1: this.customField1 || '',
      customField2: this.customField2 || '',
    },
    note: { body: this.note || '' },
  });

  private parsePayload = (container?: IContainerStatus): EditedCustomFields => ({
    containerNumber: container ? container.containerNumber : this.currentContainer.containerNumber,
    customFields: [
      {
        name: FIRST_CUSTOM_FIELD,
        value: container && container.customFields ? container.customFields.customField1 : this.customField1 ? this.customField1 : '',
      },
      {
        name: SECOND_CUSTOM_FIELD,
        value: container && container.customFields ? container.customFields.customField2 : this.customField2 ? this.customField2 : '',
      },
      {
        name: OWNER,
        value:
          this.parent === 'intermodal' ||
          this.parent === 'terminal' ||
          (this.features.updatePendingContainers && container?.isPendingContainer) ||
          (this.parent === 'vessel' && this.features.isNewVesselListEnabled) ||
          (this.parent === 'container' && this.features.isNewContainerStatusListEnabled)
            ? container?.owner
            : container && container?.owner
            ? container?.owner.id
            : this.owner.value
            ? this.owner.value.id
            : '',
      },
    ],
    noteText: container ? container?.note.body : this.note ? this.note : '',
    isPendingContainer: container ? container?.isPendingContainer : this.currentContainer?.isPendingContainer,
    containerTripId: container ? container?.containerTripId : this.currentContainer.containerTripId,
    priority: container ? container?.flag : this.currentContainer.flag,
  });

  private increment = (value: number): number => value + 1;
  private decrement = (value: number): number => value - 1;

  private getCurrentContainerIndex = (): number => this.containersList.findIndex((container) => container.containerNumber === this.currentContainer.containerNumber);

  private updateControls(
    controls = [
      { outerObject: CUSTOM_FIELDS, innerObject: FIRST_CUSTOM_FIELD },
      { outerObject: CUSTOM_FIELDS, innerObject: SECOND_CUSTOM_FIELD },
      { outerObject: NOTE, innerObject: BODY_FIELD },
    ],
  ): void {
    controls.forEach((control) =>
      this.form
        .get(control.outerObject === NOTE ? control.outerObject : control.innerObject)
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        .setValue(this.fromNullable(this.currentContainer[control.outerObject], control.innerObject)),
    );

    const changeStatus = this.currentContainer.enableStatus ? this.currentContainer.enableStatus : false;
    this.changeOccured = changeStatus;
  }

  private emitData(): void {
    const containers = this.containersList.map(this.parsePayload);
    this.submitData.emit({ data: { modalType, containers } });
  }

  public saveEdit(): void {
    if (this.applyAll.value) {
      const updates = Object.keys(this.form.controls)
        .map((key) => ({ control: this.form.get(key), key }))
        .filter(({ control }) => control.dirty)
        .reduce(
          (acc: object, { key, control }) =>
            key.match(/customField/gm)
              ? { ...acc, customFields: { ...acc[CUSTOM_FIELDS], [key]: control.value } }
              : key.match(new RegExp(NOTE, 'gm'))
              ? { ...acc, [key]: { body: control.value } }
              : { ...acc, [key]: { ...control.value } },
          {},
        );

      this.containersList = this.containersList.map((container) => ({
        ...container,
        customFields: updates[CUSTOM_FIELDS] ? { ...container[CUSTOM_FIELDS], ...updates[CUSTOM_FIELDS] } : container[CUSTOM_FIELDS],
        note: updates[NOTE] || container[NOTE],
        owner: updates[OWNER] || container[OWNER],
      }));
    } else {
      this.containersList[this.getCurrentContainerIndex()] = this.overwriteContainer();
    }
  }

  private toggleApplyAll(): void {
    this.applyAll.setValue(!this.applyAll.value);
  }

  public closeEditing(): void {
    this.closeForm.emit();
  }

  public saveAll(): void {
    this.toggleApplyAll();
    this.saveEdit();
  }

  public submitForm(): void {
    this.saveEdit();
    this.emitData();
  }

  public back(): void {
    const currentIndex = this.getCurrentContainerIndex();

    this.displayIndex = this.decrement(this.displayIndex);
    this.containersList[currentIndex] = this.overwriteContainer();
    this.currentContainer = this.containersList[currentIndex - 1];
    this.form.reset();
    this.updateControls();
  }

  public next(): void {
    const currentIndex = this.getCurrentContainerIndex();

    if (!this.applyAll.value) {
      this.saveEdit();
    }

    this.displayIndex = this.increment(this.displayIndex);
    this.containersList[currentIndex] = this.overwriteContainer();
    this.currentContainer = this.containersList[currentIndex + 1];
    this.form.reset();
    this.updateControls();
  }

  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  public checkValue(value: any): void {
    this.changeOccured = true;
    this.currentContainer['enableStatus'] = true;
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
