import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { select, Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { filter, first, map, mergeMap, tap } from 'rxjs/operators';

import * as UserActions from '@store/user/user.actions';
import * as fromUsers from '@store/user/user.reducer';
import { isRegistrationInProgress } from '@store/register/register.reducer';
import { isSearchInProgress } from '@store/search/search.reducer';
import { isResettingPassword } from '@store/forget-password/forget-password.reducer';
import { isEmptiesLoading } from '@app/store/empties-return/empties-return.reducer';

import { HasNetworkAccessOnLanding } from '@models/shared/interceptor.model';

@Injectable()
export class RequestTokenInterceptor implements HttpInterceptor {
  constructor(private store: Store<{}>) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return combineLatest([
      this.store.pipe(select(fromUsers.getAuthToken)),
      this.store.pipe(select(fromUsers.isSessionExpired)),
      this.store.pipe(select(fromUsers.getAuthStatus)),
      this.store.pipe(select(fromUsers.isAuthenticating)),
      this.store.pipe(select(isRegistrationInProgress)),
      this.store.pipe(select(isEmptiesLoading)),
      this.store.pipe(select(isSearchInProgress)),
      this.store.pipe(select(isResettingPassword)),
    ]).pipe(
      first(),
      filter<HasNetworkAccessOnLanding>(
        ([, , authStatus, authenticating, isRegistering, isEmpties, isSearching, isResetting]) =>
          authStatus || authenticating || isRegistering || isEmpties || isSearching || isResetting,
      ),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      map(([token, sessionState, ..._]) => [token, sessionState]),
      mergeMap(([token, isSessExpired]) => {
        const persistedToken = token || localStorage.getItem('token');

        const authRequest = persistedToken
          ? request.clone({ setHeaders: { Authorization: `Bearer ${persistedToken}` } })
          : request;

        return next.handle(authRequest).pipe(
          tap(
            () => ({}),
            (err: any) =>
              err instanceof HttpErrorResponse && err.status === 401 && !isSessExpired
                ? this.store.dispatch(new UserActions.SetSessionExpired())
                : null,
          ),
        );
      }),
    );
  }
}
