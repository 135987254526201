<div block="session-timeout">
  <div elem="header">
    <h2 mat-dialog-title elem="header-text">Uh oh!</h2>
  </div>
  <div elem="content" mat-dialog-content>
    <p>Your session has timed out. <br> Please log back in to continue tracking containers.</p>
  </div>
  <div mat-dialog-actions elem="buttons">
    <button 
      elem="button"
      type="button"
      class="mat-flat-button mat-button-base mat-primary"
      (click)="setSessionValid()"
    >Close</button>
  </div>
</div>