/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { Action, select, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import saveAs from 'file-saver';

import * as UnclaimActions from '@store/unclaim/unclaim.actions';
import * as IntermodalActions from './intermodal.actions';

import * as fromIntermodal from './intermodal.selectors';

import { ApiQueryBuilder } from '@services/api-query-builder';
import { ApiCallService } from '@services/api-call.service';
import { IntermodalService } from '@dashboard/services/intermodal.service';
import { IntermodalState } from './intermodal.state';
import { getUserId } from '../user/user.reducer';
import { SortDirection } from '@angular/material/sort';
import { getOrders } from '@store/dashboard/dashboard.selectors';
import { DashboardActions } from '@store/dashboard/dashboard.actions';
import { fileNameGenerator } from '@app/core/utility/helpers';

@Injectable()
export class IntermodalEffects {
  constructor(private actions$: Actions, private api: ApiCallService, private intermodalService: IntermodalService, private store: Store<IntermodalState>) {}

  public FetchIntermodalList$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<IntermodalActions.FetchList>(IntermodalActions.FETCH_LIST),
    withLatestFrom(this.store.pipe(select(fromIntermodal.getFilter)), this.store.pipe(select(getUserId))),
    mergeMap(([action, filters, userId]) =>
      this.api
        .constructApiCall(
          new ApiQueryBuilder()
            .addName('intermodalList')
            .addBody(
              this.intermodalService.createFetchListRequestPayload({
                page: action.payload.page || 1,
                filters,
                userId,
              }),
            )
            .build(),
        )
        .pipe(
          map((response) => this.intermodalService.parseFetchListResponse(response)),
          map((data) => new IntermodalActions.FetchListSuccess(data)),
          catchError((error) => of(new IntermodalActions.FetchListError(error))),
        ),
    ),
  ),
  );

  public FetchIntermodalListAll$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<IntermodalActions.FetchListAll>(IntermodalActions.FETCH_LIST_ALL),
    withLatestFrom(this.store.pipe(select(fromIntermodal.getFilter)), this.store.pipe(select(getUserId))),
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    mergeMap(([_, filters, userId]) =>
      this.api.constructApiCall(new ApiQueryBuilder().addName('intermodalList').addBody(this.intermodalService.createFetchListAllRequestPayload({ filters, userId })).build()).pipe(
        map((response) => this.intermodalService.parseFetchListResponse(response)),
        map((data) => new IntermodalActions.FetchListAllSuccess(data)),
        catchError((error) => of(new IntermodalActions.FetchListError(error))),
      ),
    ),
  ),
  );

  public FetchIntermodalTerminalCodesForFilter$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<IntermodalActions.FetchTerminalCodesForFilter>(IntermodalActions.FETCH_TERMINAL_CODES_FOR_FILTER),
    mergeMap(() =>
      this.api.constructApiCall(new ApiQueryBuilder().addName('intermodalList').addBody(this.intermodalService.createFetchTerminalFiltersRequestPayload()).build()).pipe(
        map((response) => this.intermodalService.parseFetchTerminalFiltersResponse(response)),
        map((data) => new IntermodalActions.FetchTerminalCodesForFilterSuccess(data)),
        catchError((error) => of(new IntermodalActions.FetchTerminalCodesForFilterError(error))),
      ),
    ),
  ),
  );

  public FetchIntermodalContainersList$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<IntermodalActions.FetchContainersList>(IntermodalActions.FETCH_CONTAINERS_LIST),
    mergeMap(({ payload, parent }) =>
      this.api
        .constructApiCall(new ApiQueryBuilder().addName('intermodalContainerList').addBody(this.intermodalService.createFetchContainersListRequestPayload(payload)).build())
        .pipe(
          map((response) => this.intermodalService.parseFetchContainersListResponse(response, payload.paging.page)),
          switchMap((data) => [
            new IntermodalActions.FetchContainersListSuccess(),
            DashboardActions.getContainerStatusListResultsSuccess({
              payload: data,
              parent,
            }),
          ]),
          catchError((error) => {
            this.store.dispatch(
              DashboardActions.setLoading({
                payload: false,
                parent,
              }),
            );
            return of(new IntermodalActions.FetchContainersListError(error));
          }),
        ),
    ),
  ),
  );

  public UnclaimContainers$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<IntermodalActions.UnclaimContainers>(IntermodalActions.UNCLAIM_CONTAINERS),
    mergeMap(({ payload, parent }) =>
      this.api
        .constructApiCall(new ApiQueryBuilder().addName('intermodalContainersUnclaim').addBody(this.intermodalService.createUnclaimContainersRequestPayload(payload)).build())
        .pipe(
          switchMap((data) => {
            const failed = data['failureResults'];
            const success = data['successResults'];

            return [new UnclaimActions.UnclaimResult({ failed, success }), new IntermodalActions.UnclaimContainersSuccess()];
          }),
          tap((data: any) => {
            if (data && data.type && data.type === UnclaimActions.UNCLAIM_CONTAINERS_RESULT && data.payload && data.payload.success && data.payload.success.length) {
              this.store.dispatch(
                DashboardActions.updateSelectedList({
                  payload: {
                    selected: [],
                    isAll: false,
                  },
                  parent,
                }),
              );
            }
          }),
          catchError((error) => of(new IntermodalActions.UnclaimContainersError(error))),
        ),
    ),
  ),
  );

  public UpdateFlag$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<IntermodalActions.UpdateFlag>(IntermodalActions.UPDATE_FLAG),
    mergeMap((action) =>
      this.api
        .constructApiCall(new ApiQueryBuilder().addName('intermodalCustomFieldsUpdate').addBody(this.intermodalService.createUpdateFlagRequestPayload(action.payload)).build())
        .pipe(
          map(() => new IntermodalActions.UpdateFlagSuccess()),
          catchError((error) => of(new IntermodalActions.UpdateFlagError(error))),
        ),
    ),
  ),
  );

  public UpdateCustomFields$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<IntermodalActions.UpdateCustomFields>(IntermodalActions.UPDATE_CUSTOM_FIELDS),
    withLatestFrom(
      this.store.pipe(select(fromIntermodal.getSelectedTrainTripId)),
      this.store.pipe(
        select(getOrders, { parent: 'intermodal' }),
        map((orders) => (orders && orders[0]) || null),
      ),
    ),
    mergeMap(([action, trainTripId, sort]) =>
      this.api
        .constructApiCall(
          new ApiQueryBuilder().addName('intermodalCustomFieldsUpdate').addBody(this.intermodalService.createUpdateCustomFieldsRequestPayload(action.payload)).build(),
        )
        .pipe(
          switchMap(() => {
            const payload = {
              paging: {
                sort: {
                  active: sort.property,
                  direction: <SortDirection>sort.direction,
                },
                page: 0,
              },
              filter: [],
              trainTripId,
            };

            return [
              DashboardActions.setLoading({
                payload: true,
                parent: action.parent,
              }),
              DashboardActions.setPageCounter({
                payload: 0,
                parent: action.parent,
              }),
              new IntermodalActions.FetchContainersList(payload, action.parent),
              new IntermodalActions.UpdateCustomFieldsSuccess(),
            ];
          }),
          catchError((error) => of(new IntermodalActions.UpdateCustomFieldsError(error))),
        ),
    ),
  ),
  );

  public DownloadCsv$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<IntermodalActions.DownloadCsv>(IntermodalActions.DOWNLOAD_CSV),
    mergeMap(({ payload }) =>
      this.api
        .constructApiCall(
          new ApiQueryBuilder()
            .addName('intermodalContainerListDownload')
            .addBody(this.intermodalService.createFetchContainersListRequestPayload(payload))
            .setIsFile(false)
            .addEntry('responseType', 'blob')
            .addEntry('observe', 'response')
            .build(),
        )
        .pipe(
          map((data: any) => {
            const fname = fileNameGenerator('intermodal_containers', 'xlsx');
            saveAs(data.body, fname);
            return new IntermodalActions.DownloadCsvSuccess();
          }),
          catchError(() => {
            return of(new IntermodalActions.DownloadCsvError());
          }),
        ),
    ),
  ),
  );
}
