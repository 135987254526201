import { Injectable } from '@angular/core';
import { ApiQueryBuilder } from '@services/api-query-builder';
import { ApiCallService } from '@services/api-call.service';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UniversalAppointmentSystemService {
  constructor(private apiCaller: ApiCallService) {}

  public universalAppointmentSystemScacPopUp(): Observable<any> {
    const searchRequest = new ApiQueryBuilder().addName('universalAppointmentSystemScacPopup').build();
    return this.apiCaller.constructApiCall(searchRequest);
  }

  public universalAppointmentSystemValidateScac(userScacDetails: any): Observable<any> {
    return this.apiCaller
      .constructApiCall(new ApiQueryBuilder().addName('universalAppointmentSystemValidateScac').addBody(userScacDetails).build())
      .pipe(
        map((response: any) => {
          return response;
        }),
      );
  }
}