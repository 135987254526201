import { containerCategoryMapping } from '@dashboard/models/container-category.mapping';
import { IOptionItem } from './tree-option-filter.model';

export interface EmptiesTypeModel {
  key: string;
  value: string;
}

export enum WorkShifts {
  TERMINAL_WORK_SHIFT_FIRST = '1ST',
  TERMINAL_WORK_SHIFT_SECOND = '2ND',
}

export const emptiesIndMap = {
  YES: 'Yes',
  NO: 'No',
  DUAL: 'Dual',
};

export const emptiesSizeTypeMappings = containerCategoryMapping.map((d) => ({ value: d.key, title: d.value }));

export enum EmptiesFilterNames {
  SHIPPING_LINE = 'Shipping Line',
  CONTAINER_TYPE = 'Container Type',
  TERMINAL = 'Terminal',
}

export enum EmptiesFilterValues {
  SHIPPING_LINE = 'shippingLineName',
  CONTAINER_TYPE = 'ebaContainerSizeType',
  TERMINAL = 'terminalCode',
}

export const emptiesFilterMap = {
  [EmptiesFilterValues.SHIPPING_LINE]: EmptiesFilterNames.SHIPPING_LINE,
  [EmptiesFilterValues.CONTAINER_TYPE]: EmptiesFilterNames.CONTAINER_TYPE,
  [EmptiesFilterValues.TERMINAL]: EmptiesFilterNames.TERMINAL,
};

export const defaultEmptiesReturnFilterset: IOptionItem[] = [
  {
    title: 'Shipping Line',
    value: 'shippingLineName',
    children: [
      {
        title: 'APL',
        value: 'APL',
        children: [],
        expandable: false,
        disabled: false,
      },
      {
        title: 'BAL',
        value: 'BAL',
        children: [],
        expandable: false,
        disabled: false,
      },
      {
        title: 'CMA-CGM/APL/ANL',
        value: 'CMA-CGM/APL/ANL',
        children: [],
        expandable: false,
        disabled: false,
      },
      {
        title: 'CHV',
        value: 'CHV',
        children: [],
        expandable: false,
        disabled: false,
      },
      {
        title: 'Cool Carriers (TGR/CCS/DEL)',
        value: 'Cool Carriers (TGR/CCS/DEL)',
        children: [],
        expandable: false,
        disabled: false,
      },
      {
        title: 'Cosco',
        value: 'Cosco',
        children: [],
        expandable: false,
        disabled: false,
      },
      {
        title: 'CU Lines',
        value: 'CU Lines',
        children: [],
        expandable: false,
        disabled: false,
      },
      {
        title: 'Evergreen',
        value: 'Evergreen',
        children: [],
        expandable: false,
        disabled: false,
      },
      {
        title: 'Hamburg-Sud',
        value: 'Hamburg-Sud',
        children: [],
        expandable: false,
        disabled: false,
      },
      {
        title: 'Hapag-Lloyd',
        value: 'Hapag-Lloyd',
        children: [],
        expandable: false,
        disabled: false,
      },
      {
        title: 'Hyundai',
        value: 'Hyundai',
        children: [],
        expandable: false,
        disabled: false,
      },
      {
        title: 'IRIS',
        value: 'IRIS',
        children: [],
        expandable: false,
        disabled: false,
      },
      {
        title: 'JJ Lines',
        value: 'JJ Lines',
        children: [],
        expandable: false,
        disabled: false,
      },
      {
        title: 'Jin Jiang Shipping',
        value: 'Jin Jiang Shipping',
        children: [],
        expandable: false,
        disabled: false,
      },
      {
        title: 'Lihua Lines',
        value: 'Lihua Lines',
        children: [],
        expandable: false,
        disabled: false,
      },
      {
        title: 'Maersk',
        value: 'Maersk',
        children: [],
        expandable: false,
        disabled: false,
      },
      {
        title: 'Matson',
        value: 'Matson',
        children: [],
        expandable: false,
        disabled: false,
      },
      {
        title: 'MSC',
        value: 'MSC',
        children: [],
        expandable: false,
        disabled: false,
      },
      {
        title: 'NPD',
        value: 'NPD',
        children: [],
        expandable: false,
        disabled: false,
      },
      {
        title: 'ONE',
        value: 'ONE',
        children: [],
        expandable: false,
        disabled: false,
      },
      {
        title: 'OOCL',
        value: 'OOCL',
        children: [],
        expandable: false,
        disabled: false,
      },
      {
        title: 'Pasha',
        value: 'Pasha',
        children: [],
        expandable: false,
        disabled: false,
      },
      {
        title: 'PIL',
        value: 'PIL',
        children: [],
        expandable: false,
        disabled: false,
      },
      {
        title: 'PLL',
        value: 'PLL',
        children: [],
        expandable: false,
        disabled: false,
      },
      {
        title: 'Sea Lead',
        value: 'Sea Lead',
        children: [],
        expandable: false,
        disabled: false,
      },
      {
        title: 'SM Lines',
        value: 'SM Lines',
        children: [],
        expandable: false,
        disabled: false,
      },
      {
        title: 'Sparx',
        value: 'Sparx',
        children: [],
        expandable: false,
        disabled: false,
      },
      {
        title: 'SJJ Lines',
        value: 'SJJ Lines',
        children: [],
        expandable: false,
        disabled: false,
      },
      {
        title: 'Transfar',
        value: 'Transfar',
        children: [],
        expandable: false,
        disabled: false,
      },
      {
        title: 'Wan Hai',
        value: 'Wan Hai',
        children: [],
        expandable: false,
        disabled: false,
      },
      {
        title: 'X-Press Feeders',
        value: 'X-Press Feeders',
        children: [],
        expandable: false,
        disabled: false,
      },
      {
        title: 'Yang Ming',
        value: 'Yang Ming',
        children: [],
        expandable: false,
        disabled: false,
      },
      {
        title: 'ZIM',
        value: 'ZIM',
        children: [],
        expandable: false,
        disabled: false,
      },
    ],
    expandable: true,
    disabled: false,
  },
  {
    title: 'Container Type',
    value: 'ebaContainerSizeType',
    children: [
      {
        title: '20ST',
        value: '20ST',
        children: [],
        expandable: false,
        disabled: false,
      },
      {
        title: '40ST',
        value: '40ST',
        children: [],
        expandable: false,
        disabled: false,
      },
      {
        title: '40HC',
        value: '40HC',
        children: [],
        expandable: false,
        disabled: false,
      },
      {
        title: '45',
        value: '45',
        children: [],
        expandable: false,
        disabled: false,
      },
      {
        title: '20RF',
        value: '20RF',
        children: [],
        expandable: false,
        disabled: false,
      },
      {
        title: '40RF',
        value: '40RF',
        children: [],
        expandable: false,
        disabled: false,
      },
      {
        title: 'Flat',
        value: 'Flat',
        children: [],
        expandable: false,
        disabled: false,
      },
      {
        title: 'Special',
        value: 'Special',
        children: [],
        expandable: false,
        disabled: false,
      },
    ],
    expandable: true,
    disabled: false,
  },
  {
    title: 'Terminal',
    value: 'terminalCode',
    children: [
      {
        title: 'APMT',
        value: 'APMT',
        children: [],
        expandable: false,
        disabled: false,
      },
      {
        title: 'ETS',
        value: 'ETS',
        children: [],
        expandable: false,
        disabled: false,
      },
      {
        title: 'FMS',
        value: 'FMS',
        children: [],
        expandable: false,
        disabled: false,
      },
      {
        title: 'ITS',
        value: 'ITS',
        children: [],
        expandable: false,
        disabled: false,
      },
      {
        title: 'LBCT',
        value: 'LBCT',
        children: [],
        expandable: false,
        disabled: false,
      },
      {
        title: 'PCT',
        value: 'PCT',
        children: [],
        expandable: false,
        disabled: false,
      },
      {
        title: 'SSA-A',
        value: 'SSA-A',
        children: [],
        expandable: false,
        disabled: false,
      },
      {
        title: 'TRPC',
        value: 'TRPC',
        children: [],
        expandable: false,
        disabled: false,
      },
      {
        title: 'TTI',
        value: 'TTI',
        children: [],
        expandable: false,
        disabled: false,
      },
      {
        title: 'WBCT',
        value: 'WBCT',
        children: [],
        expandable: false,
        disabled: false,
      },
      {
        title: 'YTI',
        value: 'YTI',
        children: [],
        expandable: false,
        disabled: false,
      },
    ],
    expandable: true,
    disabled: false,
  },
];

export const shippingLineSCACMapping: Record<string, string[]> = {
  'BANR': ['BAL'],
  'CHVU': ['CHV'],
  'CMDU': ['CMA-CGM/APL/ANL', 'APL', 'APL/CMA', 'CMA/ANL/APL', 'CMA-CGM'],
  'CCQD': ['Cool Carriers (TGR/CCS/DEL)', 'TGR', 'TGR/CCS'],
  'CCSG': ['Cool Carriers (TGR/CCS/DEL)', 'TGR', 'TGR/CCS'],
  'COSU': ['Cosco'],
  'CULV': ['CU Lines', 'CUL'],
  'EGLV': ['Evergreen'],
  'HLCU': ['Hapag-Lloyd', 'HAP', 'HPL'],
  'UASU': ['Hapag-Lloyd', 'HAP', 'HPL'],
  'ITAU': ['Hapag-Lloyd', 'HAP', 'HPL'],
  'HDMU': ['Hyundai', 'Hyundai Merchant Marine'],
  '11WJ': ['JJ Lines'],
  'LLHP': ['Lihua Lines', 'LHL', 'LIHUA', 'LLH'],
  'MAEU': ['Maersk'],
  'MAEI': ['Maersk'],
  'SAFM': ['Maersk'],
  'MATS': ['Matson', 'MAT'],
  'MSCU': ['MSC'],
  'ONEY': ['ONE'],
  'NYKS': ['ONE'],
  'MOLU': ['ONE'],
  'OOLU': ['OOCL'],
  'PSHI': ['Pasha'],
  'PLLU': ['PLL'],
  'SMLM': ['SM Lines', 'SML'],
  'SMC': ['SM Lines', 'SML'],
  'WHLC': ['Wan Hai', 'WHL'],
  'YMLU': ['Yang Ming'],
  'YMJA': ['Yang Ming'],
  'YMPR': ['Yang Ming'],
  'MEDU': ['MSC'],
  'KKLU': ['ONE'],
  'APLU': ['CMA-CGM/APL/ANL'],
  'ANLC': ['CMA-CGM/APL/ANL'],
  'SUDU': ['Hamburg-Sud'],
  'PCIU': ['PIL', 'PLL'],
  'PABV': ['PIL', 'PLL'],
  'TJFH': ['Transfar'],
  'SEAU': ['Sea Lead'],
  'XCLS': ['X-Press Feeders'],
  'SJBV': ['SJJ Lines', 'SJJ'],
  'ZIMU': ['ZIM'],
  'TSTU': ['TSL']
};
