import { InterestedPartiesState } from './interested-parties.state';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as InterestedPartiesActions from './interested-parties.actions';

export type Action = InterestedPartiesActions.All;

const initialState: InterestedPartiesState = {
  allUser: [],
  partiesList: [],
  listLoading: false,
  error: false,
};

const getInterestedPartiesState = createFeatureSelector<InterestedPartiesState>(
  'interestedParties',
);

export const getInterestedParties = createSelector(
  getInterestedPartiesState,
  state => state.partiesList,
);

export const getAllUser = createSelector(
  getInterestedPartiesState,
  state => state.allUser,
);

export const isInterestedPartiesLoading = createSelector(
  getInterestedPartiesState,
  state => state.listLoading,
);

// eslint-disable-next-line @typescript-eslint/naming-convention
export function InterestedPartiesReducer(
  state = initialState,
  action: Action,
): InterestedPartiesState {
  switch (action.type) {
    case InterestedPartiesActions.SET_INTERESTED_PARTIES: {
      return {
        ...state,
        listLoading: false,
        partiesList: action.payload,
      };
    }

    case InterestedPartiesActions.GET_INTERESTED_PARTIES: {
      return {
        ...state,
        listLoading: true,
      };
    }

    case InterestedPartiesActions.GET_INTERESTED_PARTIES_SUCCESS: {
      return {
        ...state,
        listLoading: false,
        partiesList: action.payload,
      };
    }

    case InterestedPartiesActions.GET_INTERESTED_PARTIES_FAILURE: {
      return {
        ...state,
        listLoading: false,
        partiesList: [],
      };
    }

    case InterestedPartiesActions.GET_ALL_USER: {
      return {
        ...state,
        listLoading: true,
      };
    }

    case InterestedPartiesActions.GET_ALL_USER_SUCCESS: {
      return {
        ...state,
        listLoading: false,
        error: false,
        allUser: [...action.payload],
      };
    }

    case InterestedPartiesActions.GET_ALL_USER_FAILURE: {
      return {
        ...state,
        listLoading: false,
        error: true,
      };
    }

    default: {
      return { ...state };
    }
  }
}
