import { Action } from '@ngrx/store';

export const GET_WAIT_TIME = '[WaitTime] GET_WAIT_TIME';
export const GET_WAIT_TIME_SUCCESS = '[WaitTime] GET_WAIT_TIME_SUCCESS';
export const GET_WAIT_TIME_ERROR = '[WaitTime] GET_WAIT_TIME_ERROR';

export class WaitTime implements Action {
  public readonly type = GET_WAIT_TIME;
  constructor(public payload: any = null) { }
}

export class WaitTimeSuccess implements Action {
  public readonly type = GET_WAIT_TIME_SUCCESS;
  constructor(public payload: any = null) { }
}

export class WaitTimeError implements Action {
  public readonly type = GET_WAIT_TIME_ERROR;
  constructor(public payload: any = null) { }
}

export type All = WaitTime | WaitTimeSuccess | WaitTimeError;
