import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { exhaustMap, map, catchError, tap } from 'rxjs/operators';
import { saveAs } from 'file-saver';

import { fileNameGenerator } from '@app/core/utility/helpers';
import { ApiCallService } from '@app/core/services/api-call.service';
import { ApiQueryBuilder } from '@app/core/services/api-query-builder';
import {
  EmptiesHistoricalService,
  EmptiesHistoricalFetchResponse,
  EmptiesHistoricalTerminalFilterSetResponse,
} from '@app/modules/dashboard/services/empties-historical.service';

import {
  initHistorical,
  fetchTerminalFilterSet,
  fetchTerminalFilterSetSuccess,
  fetchTerminalFilterSetError,
  fetchHistorical,
  fetchHistoricalSuccess,
  fetchHistoricalError,
  downloadHistorical,
  downloadHistoricalSuccess,
  downloadHistoricalError,
} from './empties-historical.actions';

@Injectable()
export class EmptiesHistoricalEffects {
  constructor(
    private actions$: Actions,
    private emptiesHistoricalService: EmptiesHistoricalService,
    private api: ApiCallService,
  ) {}

  public initHistorical$ = createEffect(() =>
    this.actions$.pipe(
      ofType(initHistorical),
      map(() => fetchTerminalFilterSet()),
    ),
  );

  public fetchTerminalFilterSet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchTerminalFilterSet),
      map(() => this.emptiesHistoricalService.createTerminalFilterSetPayload()),
      exhaustMap((payload) =>
        this.api
          .constructApiCall(
            new ApiQueryBuilder()
              .addName('emptiesHistoricalTerminalList')
              .addBody(payload)
              .build(),
          )
          .pipe(
            map((r) => r as EmptiesHistoricalTerminalFilterSetResponse),
            map((response) =>
              this.emptiesHistoricalService.parseTerminalFilterSetResponse(
                response,
              ),
            ),
            map((terminalFilterSet) =>
              fetchTerminalFilterSetSuccess({ terminalFilterSet }),
            ),
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            catchError((error) => of(fetchTerminalFilterSetError(error))),
          ),
      ),
    ),
  );

  public fetchHistorical$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchHistorical),
      map((action) => this.emptiesHistoricalService.parsePayload(action)),
      exhaustMap((action) =>
        this.api
          .constructApiCall(
            new ApiQueryBuilder()
              .addName('emptiesHistorical')
              .addBody(action)
              .build(),
          )
          .pipe(
            map((r) => r as EmptiesHistoricalFetchResponse),
            map((response) =>
              this.emptiesHistoricalService.parseResponse(response),
            ),
            map((data) => fetchHistoricalSuccess({ data })),
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            catchError((error) => of(fetchHistoricalError(error))),
          ),
      ),
    ),
  );

  public downloadHistorical$ = createEffect(() =>
    this.actions$.pipe(
      ofType(downloadHistorical),
      map((action) => this.emptiesHistoricalService.parsePayload(action)),
      exhaustMap((action) =>
        this.api
          .constructApiCall(
            new ApiQueryBuilder()
              .addName('emptiesHistoricalDownload')
              .addBody(action)
              .setIsFile(false)
              .addEntry('responseType', 'blob')
              .addEntry('observe', 'response')
              .build(),
          )
          .pipe(
            tap((data) => {
              const fname = fileNameGenerator('empties-historical', 'zip');
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
              saveAs(data['body'], fname);
            }),
            map(() => downloadHistoricalSuccess()),
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            catchError((error) => of(downloadHistoricalError(error))),
          ),
      ),
    ),
  );
}
