import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-download-button',
  templateUrl: './download-button.component.html',
  styleUrls: ['./download-button.component.css'],
})
export class DownloadButtonComponent {
  @Input() public downloading: boolean;
  @Input() public disabled: boolean;
  @Output() public download = new EventEmitter<void>();

  public onClick(): void {
    const { downloading, download, disabled } = this;
    if (!downloading && !disabled) {
      download.emit();
    }
  }
}
