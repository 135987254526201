import { Action } from '@ngrx/store';

import { AddBannerPayload, RemoveBannerPayload } from '@app/shared/modules/banner/models/banner.models';

export const ADD_BANNER = '[Banner] ADD';
export const REMOVE_BANNER = '[Banner] REMOVE';

export class AddBanner implements Action {
  public readonly type = ADD_BANNER;
  constructor(public payload: AddBannerPayload = null) { }
}

export class RemoveBanner implements Action {
  public readonly type = REMOVE_BANNER;
  constructor(public payload: RemoveBannerPayload = null) { }
}

export type All =
  | AddBanner
  | RemoveBanner;
