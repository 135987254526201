import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { map, catchError, switchMap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import * as GlobalErrorActions from '@store/global-error/global-error.actions';
import * as NotificationSettingsActions from '@store/notification-settings/notification-settings.actions';

import { ApiQueryBuilder } from '@services/api-query-builder';
import { ApiCallService } from '@services/api-call.service';


@Injectable()
export class NotificationSettingsEffect {

  public getNotificationSettings$ = createEffect(() => this.actions$.pipe(
    ofType<NotificationSettingsActions.FetchNotificationSettings>(NotificationSettingsActions.FETCH_NOTIFICATION_SETTINGS),
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    switchMap(_ =>
      this.api.constructApiCall(
        new ApiQueryBuilder()
          .addName('notificationSettings')
          .build(),
      ).pipe(
        map(data => new NotificationSettingsActions.FetchNotificationSettingsSuccess(data)),
        catchError(error => of(new GlobalErrorActions.SetGlobalError({ errorType: 'network', error }))),
      ),
    ),
  ),
  );

  public getAlerts$ = createEffect(() => this.actions$.pipe(
    ofType<NotificationSettingsActions.FetchAlerts>(NotificationSettingsActions.FETCH_ALERTS),
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    switchMap(_ =>
      this.api.constructApiCall(
        new ApiQueryBuilder()
          .addName('notificationAlerts')
          .build(),
      ).pipe(
        map((data) => {
          return new NotificationSettingsActions.FetchAlertsSuccess(data);
        }),
        catchError((error) => {
          return of(new NotificationSettingsActions.FetchAlertsFail(error));
        }),
      ),
    ),
  ),
  );

  public fetchPinnedTypes$ = createEffect(() => this.actions$.pipe(
    ofType<NotificationSettingsActions.FetchTypes>(NotificationSettingsActions.FETCH_TYPES),
    switchMap(() =>
      this.api.constructApiCall(
        new ApiQueryBuilder()
          .addName('pinnedNotificationTypes')
          .build(),
      ).pipe(
        map(data => new NotificationSettingsActions.FetchTypesSuccess(data)),
        catchError(error => of(new NotificationSettingsActions.FetchTypesFail(error))),
      ),
    ),
  ),
  );

  public setAlerts$ = createEffect(() => this.actions$.pipe(
    ofType<NotificationSettingsActions.SetAlerts>(NotificationSettingsActions.SET_ALERTS),
    switchMap(action =>
      this.api.constructApiCall(
        new ApiQueryBuilder()
          .addName('setNotificationAlerts')
          .addBody(action.payload.body)
          .build(),
      ).pipe(
        map((data) => {
          return new NotificationSettingsActions.SetAlertsSuccess(data);
        }),
        catchError((error) => {
          return of(new GlobalErrorActions.SetGlobalError(error) && new NotificationSettingsActions.SetAlertsFail(error));
        }),
      ),
    ),
  ),
  );


  constructor(
    private actions$: Actions,
    private api: ApiCallService,
  ) {
  }
}
