import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ChassisCountState } from './chassis-count.state';
import * as ChassisCountAction from './chassis-count.actions';

export type Action = ChassisCountAction.All;

const initialState: ChassisCountState = {
  loading: false,
  error: false,
  data: [],
  errorData: null,
};

const getChassisCountState = createFeatureSelector<ChassisCountState>('chassisCount');

export const getErrorData = createSelector(
  getChassisCountState,
  state => (state.error ? state.errorData : null),
);

export const getChassisCountResponse = createSelector(
  getChassisCountState,
  state => state.data,
);

export const getChassisCountResponseForTerminal = (terminalCode: string) =>
  createSelector(
    getChassisCountState,
    state => state.data[terminalCode] || [],
  );


export const getLoadingState = createSelector(
  getChassisCountState,
  state => state.loading,
);

// eslint-disable-next-line @typescript-eslint/naming-convention
export function ChassisCountReducer(state = initialState, action: Action): ChassisCountState {
  switch (action.type) {
    case ChassisCountAction.GET_CHASSIS_COUNT: {
      return { ...state, loading: true, error: false };
    }

    case ChassisCountAction.GET_CHASSIS_COUNT_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        data: { ...action.payload },
      };
    }

    case ChassisCountAction.GET_CHASSIS_COUNT_ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
        errorData: { ...action.payload },
      };
    }

    default:
      return state;
  }
}
