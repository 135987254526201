import { Injectable } from '@angular/core';

import { FeatureFlagService } from '@services/feature-flag.service';

@Injectable({
  providedIn: 'root',
})

export class InterestedPartiesService {
  private features = {
    isNewInterestedPartiesEnabled: this.featureFlags.featureEnabled('newInterestedParties'),
  };

  constructor(private featureFlags: FeatureFlagService) {}

  public getEndpointName() {
    return this.features.isNewInterestedPartiesEnabled
      ? 'newInterestedParties'
      : 'interestedParties';
  }

  public getParsedInterestedPartiesResponse(response) {
    if (this.features.isNewInterestedPartiesEnabled) {
      const requiredUsers = response.slice(0, 10);
      return requiredUsers.map(user => {
        return {
          fullName: user.cognitoUser.Attributes[6].Value + ' ' + user.cognitoUser.Attributes[7].Value,
          id: user.cognitoUser.Attributes[0].Value,
          email: user.cognitoUser.Attributes[8].Value,
          familyName: user.cognitoUser.Attributes[7].Value,
          givenName: user.cognitoUser.Attributes[6].Value,
          phoneNumber: user.cognitoUser.Attributes[5].Value,
          customAccessUi: null,
        };
      });
    }
    return response;
  }
}
