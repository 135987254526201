<ng-container *ngFor="let loading of [loading$ | async]">
  <div block="request-containers" class="spinner-overlay-parent" *ngIf="isValidRequest">
    <app-loader-overlay [loading]="loading"></app-loader-overlay>
    <div elem="header">
      <div>
        <h2 mat-dialog-title elem="header-text">Request Special Handling</h2>
        <div elem="cancel-icon">
          <img
            src="assets/svgs/close-grey.svg"
            width="20"
            height="20"
            alt="cancel"
            (click)="closeSpecialHandling()"
          />
        </div>
      </div>
    </div>

    <div elem="content" mat-dialog-content>    
        <div class="request-user-content">        
          <div class="cardRequest">
            <div [style.grid-area]="'header'" class="request-type">Requested by</div>
            <div [style.grid-area]="'label'" class="field-label">
              Name:
            </div>
            <div [style.grid-area]="'value'" class="field-value">
              {{userDetails$.firstName}} {{userDetails$.lastName}}
            </div>
            <div [style.grid-area]="'company'" class="field-label">
              Company:
            </div>
            <div [style.grid-area]="'companyName'" class="field-value">
              {{userDetails$.companyName}}
            </div>
            <div [style.grid-area]="'email'" class="field-label">
              Email:
            </div>
            <div [style.grid-area]="'emailValue'" class="field-value">
              {{userDetails$.email}}
            </div>
            <div [style.grid-area]="'phone'" class="field-label">
              Phone:
            </div>
            <div [style.grid-area]="'phonevalue'" class="field-value">
              {{userDetails$.phone | requestHandling}}
            </div>
          </div>
          <div class="cardTerminal">
           
            <div [style.grid-area]="'header'" class="request-type">Terminal</div>
            <div class="field-label terminal-label">Terminal name:</div>
            <div class="field-value terminal-value">
              {{ (terminalDetails$ | async)?.terminalCode || '-' }}
            </div>
          
            <div class="field-label contact-name-label">Contact:</div>
            <div class="field-value contact-name-value">
              {{ (terminalDetails$ | async)?.contactName || '-' }}
            </div>
          
            <div class="field-label email-label">Email:</div>
            <div class="field-value email-value">
              {{ (terminalDetails$ | async)?.email || '-' }}
            </div>
          </div>
        </div>
        <div class="divider">
          <mat-divider></mat-divider>
        </div>
        <div class="div-gap">
          <div [style.grid-area]="'header'" class="request-type">Select services</div>
          <div class="request-type"><mat-checkbox [checked]="true" [disabled]="true">Peel pile</mat-checkbox></div>
        </div>
        <div class="divider">
          <mat-divider></mat-divider>
        </div>
        <div class="containers-list-card">
          <app-request-container-list></app-request-container-list>
        </div>
        <div class="request-textarea">
          <textarea id="note" class="text-area-notes" placeholder="Please enter any additional comments for the terminal..."
            maxlength="255" (keyup)="checkValue($event.target.value)"></textarea>
        </div>
        <div elem="footer">
          <div mat-dialog-actions elem="buttons">
            <button elem="button" type="button" color="primary" mat-flat-button (click)="closeSpecialHandling()"
              id="cancel-editing-button">Cancel</button>
            <button elem="button" type="submit" color="accent" mat-flat-button (click)="submitForm()"
            [disabled]="!containerCount" [class.disabled]="!containerCount" id="apply-edit-button">Submit</button>
          </div>
        </div>
      </div>
  </div>
  <div block="attention-required" *ngIf="!isValidRequest">
    <div elem="header">
      <div>
        <h2 mat-dialog-title elem="header-text">Attention required</h2>
        <div elem="cancel-icon">
          <img src="assets/svgs/close-grey.svg" width="20" height="20" alt="cancel" (click)="closeSpecialHandling()" />
        </div>
      </div>
    </div>
    <div elem="content" mat-dialog-content>
      <p elem="text">
        A <b>Special Handling Request</b> cannot be created for the selected containers because of the following issue(s):
      </p>
      <ul elem="points">
        <li>Containers must be located at the same Port of LA terminal</li>
        <li>Containers must be at least 48 hours out from arrival at the port</li>
        <li>Containers cannot be part of an existing special request</li>
      </ul>
      <div elem="footer">
        <div mat-dialog-actions elem="buttons">
          <button elem="button" type="submit" color="accent" mat-flat-button (click)="closeSpecialHandling()"
            id="cancel-editing-button">Ok, Got It</button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
