import { createFeatureSelector, createSelector } from '@ngrx/store';
import { NotificationSettingsState } from './notification-settings.state';
import * as NotificationSettingsActions from './notification-settings.actions';

export type Action = NotificationSettingsActions.All;

const initialState: NotificationSettingsState = {
  subscribedTypes: [],
  settings: [],
  loading: false,
  error: false,
  alerts: [],
  alertsLoading: false,
  alertsError: false,
  sendSuccess: false,
  sendLoading: false,
  typesError: false,
  typesLoading: false,
};

const getNotificationsState = createFeatureSelector<NotificationSettingsState>('notificationSettings');

export const isLoading = createSelector(
  getNotificationsState,
  state => state.loading,
);

export const getSettings = createSelector(
  getNotificationsState,
  state => state.settings,
);

export const isAlertsLoading = createSelector(
  getNotificationsState,
  state => state.alertsLoading,
);

export const getAlerts = createSelector(
  getNotificationsState,
  state => state.alerts,
);

export const getTypes = createSelector(
  getNotificationsState,
  state => state.subscribedTypes,
);

export const getTypesError = createSelector(
  getNotificationsState,
  state => state.typesError,
);

export const isTypesLoading = createSelector(
  getNotificationsState,
  state => state.typesLoading,
);

export const isAlertSaveSuccess = createSelector(
  getNotificationsState,
  state => state.sendSuccess,
);

export const isAlertSaveInProgress = createSelector(
  getNotificationsState,
  state => state.sendLoading,
);

export const isNotificationCallFinished = createSelector(
  getNotificationsState,
  (state) => {
    return { success: state.sendSuccess, error: state.error };
  },
);


// eslint-disable-next-line @typescript-eslint/naming-convention
export function NotificationSettingsReducer(state = initialState, action: Action): NotificationSettingsState {
  switch (action.type) {
    case NotificationSettingsActions.FETCH_NOTIFICATION_SETTINGS: {
      return { ...state, loading: true };
    }

    case NotificationSettingsActions.FETCH_NOTIFICATION_SETTINGS_SUCCESS: {
      return { ...state, loading: false, settings: action.payload };
    }

    case NotificationSettingsActions.FETCH_NOTIFICATION_SETTINGS_FAIL: {
      return { ...state, loading: false, error: true };
    }

    case NotificationSettingsActions.FETCH_ALERTS: {
      return { ...state, alertsLoading: true, alertsError: false };
    }

    case NotificationSettingsActions.FETCH_ALERTS_SUCCESS: {
      return { ...state, alertsLoading: false, alerts: action.payload };
    }

    case NotificationSettingsActions.FETCH_ALERTS_FAIL: {
      return { ...state, alertsLoading: false, alertsError: true };
    }

    case NotificationSettingsActions.FETCH_TYPES: {
      return { ...state, typesLoading: true, typesError: false };
    }

    case NotificationSettingsActions.FETCH_TYPES_SUCCESS: {
      return { ...state, typesLoading: false, subscribedTypes: [...action.payload] };
    }

    case NotificationSettingsActions.FETCH_TYPES_FAIL: {
      return { ...state, typesLoading: false, typesError: true };
    }

    case NotificationSettingsActions.SET_ALERTS: {
      return { ...state, sendLoading: true, sendSuccess: false };
    }

    case NotificationSettingsActions.SET_ALERTS_SUCCESS: {
      return { ...state, sendLoading: false, sendSuccess: true };
    }

    case NotificationSettingsActions.SET_ALERTS_FAIL: {
      return { ...state, sendLoading: false, sendSuccess: false, error: true };
    }

    case NotificationSettingsActions.SET_ALERTS_CLEAR: {
      return { ...initialState };
    }

    default:
      return state;
  }
}
