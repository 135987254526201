/* eslint-disable max-lines-per-function */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Store, select } from '@ngrx/store';
import { Angulartics2 } from 'angulartics2';
import { Observable, Subscription, combineLatest } from 'rxjs';

import * as AddContainerActions from '@store/add-containers/add-containers.actions';
import {
  isAddContainerLoading,
  addContainersResult,
  addPendingContainersResult,
  getErrorMessage,
} from '@store/add-containers/add-containers.reducer';
import { State } from '@store/app.state';
import { ADD_CONTAINERS_MODAL } from '@models/shared/modal.constants';
import { AddContainerPayload } from '@models/dashboard/add-containers.model';
import { map } from 'rxjs/operators';
import { FeatureFlagService } from '@services/feature-flag.service';
import { noWhitespaceValidator } from '@app/core/validators/no-whitespace.validator';

@Component({
  selector: 'app-add-containers-modal',
  templateUrl: './add-containers-modal.component.html',
  styleUrls: ['./add-containers-modal.component.css'],
})
export class AddContainersModalComponent implements OnInit, OnDestroy {
  @Output() submitData = new EventEmitter<object>();
  @Output() closeForm = new EventEmitter<void>();

  public features = {
    isBOLClaimEnabled: this.featureFlags.featureEnabled('enableClaimBOL'),
  };

  private subscriptions: Subscription = new Subscription();

  public containersLoading$: Observable<boolean>;
  public addContainerNumbers: FormGroup;
  public fileToUpload: File = null;
  public containerCounter: number = 0;
  public failedContainerNumbers: number = 0;
  public pendingContainerNumbers: number = 0;
  public invalidContainerNumbers: number = 0;
  public containersError: boolean = false;
  public containersErrorMessage: string;
  public errorStream: Observable<{ error: boolean; failedContainers: string[] }>;
  public successStream: Observable<boolean>;
  public totalContainerNumbers: number = 0;
  public invalidContainersList: string[] = [];
  public enableSubmit: boolean = true;
  private pendingContainers: string[] = [];
  public successContainers: string[] = [];
  public disableChooseFile: boolean = false;
  public enableAddContainers: boolean = false;
  private invalidContainers: string[] = [];
  public hasFailedBOLNumbers: boolean = false;
  public fileUploadBOL: boolean = false;
  public failedBOLNumbersList: string[] = [];
  public csvContent: any;
  public finalDataBOL: any;

  constructor(
    private fb: FormBuilder,
    private store: Store<State>,
    private analytics: Angulartics2,
    private featureFlags: FeatureFlagService,
  ) {}

  public calculateResult = ([containerResult, pendingContainerResult, errorResult]) => {
    const fileMode = !!this.addContainerNumbers.get('fileName').value.length;
    const containerType = this.addContainerNumbers.get('type').value;

    if (containerType === 'container') {
      if (containerResult.hasFailedContainers) {
        if (fileMode) {
          containerResult.invalidContainers = containerResult.invalidContainers.filter((values) =>
            this.removeUnwantedText(values),
          );
          containerResult.failedContainers = containerResult.failedContainers.filter((values) =>
            this.removeUnwantedText(values),
          );
        }

        this.containersError = true;

        this.pendingContainers = [
          ...containerResult.claimConditionsNotSatisfiedInES,
          ...containerResult.containersNotInES,
          ...this.pendingContainers,
        ];
        this.pendingContainers = [...new Set(this.pendingContainers)];

        this.invalidContainers = [...containerResult.invalidContainers, ...this.invalidContainers];
        this.invalidContainers = [...new Set(this.invalidContainers)];

        this.addContainerNumbers.get('pendingContainerNumbers').setValue(this.pendingContainers.join('\n'));

        this.setSuccessContainers(containerResult);

        if (containerResult.success) {
          this.addContainerNumbers.get('containerNumbers').setValue(containerResult.invalidContainers.join('\n'));
        }

        this.pendingContainerNumbers = this.pendingContainers.length;
        this.failedContainerNumbers = containerResult.failedContainers.length;
        this.invalidContainerNumbers = containerResult.invalidContainers.length;
        this.totalContainerNumbers =
          this.successContainers.length + this.pendingContainers.length + this.invalidContainerNumbers;

        // this is to enable addcontainers textarea after click on submit button, if invalid containers are exists.
        if (this.invalidContainerNumbers) {
          this.enableAddContainers = false;
        }
      } else if (containerResult.success) {
        this.setSuccessContainers(containerResult);
        this.totalContainerNumbers =
          this.successContainers.length + this.pendingContainers.length + this.invalidContainerNumbers;

        this.addContainerNumbers.get('containerNumbers').setValue('');
        this.invalidContainerNumbers = 0;
      }

      this.hasFailedBOLNumbers = false;
      this.failedBOLNumbersList = [];
    } else {
      if (containerResult.success) {
        if (fileMode) {
          containerResult.failedContainers = containerResult.failedContainers.filter((values) =>
            this.removeUnwantedText(values),
          );
        }

        this.failedBOLNumbersList = [...containerResult.failedContainers, ...this.failedBOLNumbersList];
        this.failedBOLNumbersList = [...new Set(this.failedBOLNumbersList)];

        this.failedContainerNumbers = this.failedBOLNumbersList.length;
        this.pendingContainers = [];
        this.pendingContainerNumbers = 0;
        this.successContainers = [];

        if (containerResult.failedContainers.length) {
          this.hasFailedBOLNumbers = true;
          this.enableAddContainers = false;
        }

        this.addContainerNumbers.get('containerNumbers').setValue('');
        this.addContainerNumbers.get('pendingContainerNumbers').setValue(this.failedBOLNumbersList.join('\n'));
      }
    }

    if (
      (!containerResult.invalidContainers.length &&
        !this.hasFailedBOLNumbers &&
        (this.pendingContainerNumbers > 0
          ? pendingContainerResult.hasSuccessPendingContainers
          : containerResult.success)) ||
      (containerResult.success &&
        !containerResult.hasFailedContainers &&
        containerResult.successContainers.length > 0 &&
        this.addContainerNumbers.get('type').value === 'bol')
    ) {
      this.closeForm.emit();
    }

    if (errorResult.error) {
      this.containersError = true;
    }
  };

  ngOnInit(): void {
    this.addContainerNumbers = this.fb.group({
      type: ['container', [Validators.required]],
      containerNumbers: ['', [Validators.required, noWhitespaceValidator]],
      pendingContainerNumbers: [''],
      fileName: ['', []],
    });

    this.containersLoading$ = this.store.pipe(select(isAddContainerLoading));

    combineLatest([
      this.store.pipe(select(addContainersResult)),
      this.store.pipe(select(addPendingContainersResult)),
      this.store.pipe(select(getErrorMessage)),
    ])
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      .pipe(map(this.calculateResult))
      .subscribe();

    this.onChanges();
  }

  public setSuccessContainers = (containerResult) => {
    this.successContainers = [...containerResult.successContainers, ...this.successContainers];
    this.successContainers = [...new Set(this.successContainers)];
  };

  public removeUnwantedText = (values) => values.substr(3, values.length) !== 'CONTAINER/BOL NUMBER';

  private onChanges(): void {
    this.subscriptions.add(
      this.addContainerNumbers.get('containerNumbers').valueChanges.subscribe((value: string) => {
        const containerNumberArray = this.formatValue(value);
        const length = containerNumberArray.length;
        let lastItemLength: number;

        const fileMode = !!this.addContainerNumbers.get('fileName').value.length;

        this.enableSubmit =
          !!containerNumberArray.length ||
          (!this.successContainers.length && !this.pendingContainerNumbers) ||
          fileMode;

        this.invalidContainerNumbers =
          !this.enableSubmit || !containerNumberArray.length ? 0 : this.invalidContainerNumbers;

        if (containerNumberArray[length - 1]) {
          lastItemLength = containerNumberArray[length - 1].length;
          this.disableChooseFile = true;
          this.addContainerNumbers.get('fileName').setValue('');
        } else {
          this.containerCounter = 0;
          this.disableChooseFile = !!this.pendingContainerNumbers || false;
          return;
        }

        if (lastItemLength > 0) {
          this.containerCounter = length;
        } else if (lastItemLength === 0) {
          this.containerCounter = length - 1;
        }
      }),
    );

    this.subscriptions.add(
      this.addContainerNumbers.get('type').valueChanges.subscribe(() => {
        this.addContainerNumbers.get('containerNumbers').setValue('');
        this.addContainerNumbers.get('pendingContainerNumbers').setValue('');
        this.addContainerNumbers.get('containerNumbers').setValidators([Validators.required]);
        this.addContainerNumbers.get('pendingContainerNumbers').setValidators([]);
        this.addContainerNumbers.get('containerNumbers').updateValueAndValidity();
        this.addContainerNumbers.get('pendingContainerNumbers').updateValueAndValidity();

        this.pendingContainers = [];
        this.successContainers = [];
        this.invalidContainerNumbers = 0;
        this.failedContainerNumbers = 0;
        this.totalContainerNumbers = 0;
        this.pendingContainerNumbers = 0;
        this.fileToUpload = null;
        this.enableSubmit = true;
        this.enableAddContainers = false;
        this.fileUploadBOL = false;
        this.store.dispatch(new AddContainerActions.AddContainersClear());
      }),
    );
  }

  private formatValue = (value: string): string[] =>
    value
      .split(/[,\n]/)
      .map((item) => item.trim())
      .filter((item) => item);

  private setValidations = () => {
    const fileMode = !!this.addContainerNumbers.get('fileName').value.length;
    this.analytics.eventTrack.next({
      action: 'change-mode',
      properties: {
        category: 'add-containers',
        label: fileMode ? 'csv' : 'text',
      },
    });

    if (fileMode) {
      this.addContainerNumbers.get('containerNumbers').setValidators([]);
    } else if (this.pendingContainerNumbers > 0) {
      this.addContainerNumbers.get('containerNumbers').setValidators([]);
      this.addContainerNumbers.get('pendingContainerNumbers').setValidators([Validators.required]);
      this.addContainerNumbers.get('type').setValidators([]);
    } else {
      this.addContainerNumbers.patchValue({ fileName: null });
      this.fileToUpload = null;
      this.addContainerNumbers.get('containerNumbers').setValidators([Validators.required]);
      this.addContainerNumbers.get('type').setValidators([Validators.required]);
      this.addContainerNumbers.get('fileName').setValidators([]);
      this.addContainerNumbers.get('fileName').setErrors(null);
    }
    this.addContainerNumbers.updateValueAndValidity();
  };

  public handleFileClear(event): void {
    event.target.value = '';
  }

  public handleFileChange(files: FileList): void {
    this.fileToUpload = files[0];
    this.fileUploadBOL = false;
    this.addContainerNumbers.patchValue({
      fileName: this.fileToUpload.name,
      containerNumbers: '',
    });
    this.enableAddContainers = true;

    this.setValidations();
    this.addContainerNumbers.get('containerNumbers').updateValueAndValidity();
    this.addContainerNumbers.get('fileName').updateValueAndValidity();
    if (this.addContainerNumbers.get('type').value === 'bol') {
      if (files && files.length) {
        this.fileUploadBOL = true;
        const fileReader = new FileReader();
        fileReader.onload = this.onFileLoad.bind(this);
        fileReader.readAsText(this.fileToUpload, 'UTF-8');
      }
    }
  }

  private onFileLoad(fileLoadedEvent: any) {
    // extracted csv value
    this.csvContent = fileLoadedEvent.target.result;
    const rows = this.csvContent.split('\n');

    rows.map((row) => {
      return row.split(',');
    });

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    this.finalDataBOL = rows.map((row) => row.trim()).filter((row) => !['', 'Container/BOL Number'].includes(row));
  }

  public onCancelClick(): void {
    this.analytics.eventTrack.next({
      action: 'cancel',
      properties: {
        category: 'add-containers',
      },
    });

    if (this.addContainerNumbers.get('type').value === 'container') {
      const payload = {
        modalType: ADD_CONTAINERS_MODAL,
        containerAction: 'addContainers',
        cancelModal: true,
      };
      payload['dataList'] = this.formatValue(this.successContainers.toString());
      payload['claimingType'] = this.addContainerNumbers.get('type').value;

      if (payload['dataList'].length > 0) {
        this.submitData.emit({ data: payload });
      }
    }

    this.store.dispatch(new AddContainerActions.Clear());
    this.closeForm.emit();
  }

  public submitStatus = () => {
    return (
      (this.addContainerNumbers.get('type').value === 'bol' &&
        this.failedContainerNumbers > 0 &&
        !this.disableChooseFile &&
        !this.fileUploadBOL) ||
      this.addContainerNumbers.status !== 'VALID'
    );
  };

  public clearError(): void {
    this.containersError = false;
    this.containersErrorMessage = '';
  }

  public onSendData({ value, valid }: { value: AddContainerPayload; valid: boolean }) {
    this.analytics.eventTrack.next({
      action: 'submit',
      properties: {
        category: this.pendingContainerNumbers > 0 && !this.enableSubmit ? 'add-pending-containers' : 'add-containers',
      },
    });

    if (this.pendingContainerNumbers > 0) {
      this.addContainerNumbers.get('containerNumbers').setValidators([]);
      this.addContainerNumbers.get('pendingContainerNumbers').setValidators([Validators.required]);
      this.addContainerNumbers.get('fileName').setValidators([]);
      this.addContainerNumbers.get('containerNumbers').updateValueAndValidity();
      this.addContainerNumbers.get('pendingContainerNumbers').updateValueAndValidity();
      this.addContainerNumbers.get('fileName').updateValueAndValidity();
    }

    valid = this.addContainerNumbers.valid;
    const fileMode = !!this.addContainerNumbers.get('fileName').value.length;
    let payload = {};
    const bolPayload = {};

    if (valid) {
      payload = {
        modalType: ADD_CONTAINERS_MODAL,
        containerAction: this.pendingContainerNumbers > 0 && !this.enableSubmit ? 'pendingContainers' : 'addContainers',
        cancelModal: false,
      };

      if (fileMode) {
        payload['file'] = this.fileToUpload;
        this.addContainerNumbers.get('fileName').setValue('');
        this.fileToUpload = null;
        if (this.addContainerNumbers.get('type').value === 'bol') {
          bolPayload['claimData'] = this.finalDataBOL;
          bolPayload['claimingType'] = value.type;
        }
      } else {
        if (this.pendingContainerNumbers > 0 && !this.enableSubmit) {
          payload['pendingContainersDataList'] = this.formatValue(value.pendingContainerNumbers);
        } else {
          payload['dataList'] = this.formatValue(value.containerNumbers);
          payload['claimingType'] = value.type;
        }
      }
    }

    // this code is to handle if there is success and invalid containers
    if (!valid && !this.enableSubmit) {
      payload = {
        modalType: ADD_CONTAINERS_MODAL,
        containerAction: 'addContainers',
        cancelModal: false,
      };
      payload['dataList'] = this.formatValue(this.successContainers.toString());
      payload['claimingType'] = value.type;
    }

    if (this.fileUploadBOL) {
      if (this.addContainerNumbers.get('type').value === 'bol') {
        this.store.dispatch(new AddContainerActions.AddContainers(bolPayload));
        this.fileUploadBOL = false;
      }
    } else {
      this.submitData.emit({ data: payload });
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
