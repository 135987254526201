import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { BannerState } from '@app/store/banner/banner.state';
import { AddBanner, RemoveBanner } from '@app/store/banner/banner.actions';
import { getBanners } from '@app/store/banner/banner.reducer';
import { BannerItem, AddBannerPayload, RemoveBannerPayload } from '../models/banner.models';

@Injectable({
  providedIn: 'root',
})
export class BannerService {
  private banners$: Observable<BannerItem[]>;

  constructor(
    private store: Store<BannerState>,
  ) {
    this.banners$ = this.store.pipe(select(getBanners));
  }

  public add(payload: AddBannerPayload) {
    this.store.dispatch(new AddBanner(payload));
  }

  public remove(id: RemoveBannerPayload) {
    this.store.dispatch(new RemoveBanner(id));
  }

  public getBanners() {
    return this.banners$;
  }
}
