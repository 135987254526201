import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class TimezoneService {

  public getDateInRequiredTimeZone(timestamp: number) {
    return timestamp > 0 ? new Date(new Date(timestamp).toLocaleString('en-US', { timeZone: environment.timeZone })) : null;
  }

  public getDateInUTC(timestamp: number) {
    return timestamp > 0 ? new Date(new Date(timestamp).toLocaleString('en-US', { timeZone: 'UTC' })) : null;
  }
}
