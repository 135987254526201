/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import * as ChartAvailabilityActions from './chart-availability.actions';
import { parseResponse } from './chart-availability.helpers';

import { ApiQueryBuilder } from '@services/api-query-builder';
import { ApiCallService } from '@services/api-call.service';
import { ChartService } from '@services/chart.service';

@Injectable()
export class ChartAvailabilityEffects {
  constructor(
    private actions$: Actions,
    private api: ApiCallService,
    private chartService: ChartService,
  ) { }


  public GetContainerStatusListChartResults$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<ChartAvailabilityActions.GetContainerChartAvailability>(ChartAvailabilityActions.GET_CONTAINER_CHART_AVAILABILITY),
    mergeMap(action => {
      const universalSearchFiltersToApply = this.chartService.getParsedChartAvailabilitySearchFilters(action);
      const fromDate = new Date(new Date(new Date().setDate(new Date().getDate() - 90))).toISOString().substr(0, 10) + 'T00:00:00';
      const toDate = new Date(new Date(new Date().setDate(new Date().getDate() + 90))).toISOString().substr(0, 10) + 'T23:59:59';
      const payload = {
        searchRequests: [
          {
            fromDate: fromDate,
            toDate: toDate,
            fetchNonOrRecentlyOutgatedContainers: true,
            searchParameters: [{
              key: 'byAvailableForPickup',
              children: [],
              filters: [
                { filterKey: 'importExport', filterValues: ['I'] },
                { filterKey: 'shipmentStatusCd', filterValues: ['NA', 'UV'] },
                { filterKey: 'isAvailableForPickup', filterValues: ['Available'] },
              ],
              fetchSource: true,
            }],
          },
          {
            fromDate: fromDate,
            toDate: toDate,
            fetchNonOrRecentlyOutgatedContainers: true,
            searchParameters: [{
              key: 'byStatus',
              children: [{ key: 'byInboundDockedContainers', children: [] }],
              filters: [{ filterKey: 'importExport', filterValues: ['I'] }],
              fetchSource: true,
            }],
          },
        ],
      };
      universalSearchFiltersToApply.forEach(requestObject => {
        payload.searchRequests.forEach(filters => filters.searchParameters[0].filters.push(requestObject));
      });
      return this.api.constructApiCall(
        new ApiQueryBuilder()
          .addName('chartAvailability')
          .addBody(payload)
          .build(),
      )
        .pipe(
          map((data: any) => new ChartAvailabilityActions.GetContainerChartAvailabilitySuccess(parseResponse(data))),
          catchError(error => of(new ChartAvailabilityActions.GetContainerChartAvailabilityError(error))),
        );
    }),
  ),
  );
}
