<form block="form" novalidate [formGroup]="resetPassword" autocomplete="off">
  <h1 elem="title">Password Change Required</h1>
  <p elem="desc">
    As part of a security update for Port Optimizer, it is required that your password must be updated.
    Complete this process below by entering the code that was emailed to you and by following the prompts below.
    After clicking apply you will be prompted to log in with your new password.
  </p>
  <div elem="field" mod="code">
    <label elem="label" for="code">Verification Code</label>
    <input
      elem="text-input"
      type="text"
      name="code"
      id="code"
      placeholder="Verification Code"
      formControlName="code"
      autocomplete="off"
      [mod]="{ error: code.invalid && (code.dirty || code.touched) }"
      [class.error]="code.invalid && (code.dirty || code.touched)"
    />
    <div
      class="alert-message"
      *ngIf="code.invalid && (code.dirty || code.touched)"
    >
      Please enter a valid verification code.
    </div>
  </div>
  <div elem="field">
    <label elem="label" for="password">Enter new password</label>
    <input
      elem="text-input"
      type="password"
      name="password"
      id="password"
      placeholder="Password"
      formControlName="password"
      autocomplete="off"
      [mod]="{
        error: password.invalid && (password.dirty || password.touched)
      }"
      [class.error]="password.invalid && (password.dirty || password.touched)"
    />
    <div
      class="alert-message"
      *ngIf="password.invalid && (password.dirty || password.touched)"
    >
      Please enter a password: min. 8 characters, lowercase and uppercase
      letters, number and symbol.
    </div>
  </div>
  <div elem="field">
    <label elem="label" for="retype-password">Re-enter password</label>
    <input
      elem="text-input"
      type="password"
      name="confirmPassword"
      id="retype-password"
      placeholder="Re-Enter Password"
      formControlName="confirmPassword"
      autocomplete="off"
      [mod]="{
        error:
          confirmPassword.invalid &&
          (confirmPassword.dirty || confirmPassword.touched)
      }"
      [class.error]="
        confirmPassword.invalid &&
        (confirmPassword.dirty || confirmPassword.touched)
      "
    />
    <div
      class="alert-message"
      *ngIf="
        confirmPassword.invalid &&
        (confirmPassword.dirty || confirmPassword.touched)
      "
    >
      Please enter the password again.
    </div>
    <div
      class="alert-message"
      *ngIf="
        resetPassword.errors && resetPassword.errors['nomatch']
      "
    >
      Passwords should match!
    </div>
    <div class="alert-message" *ngIf="updateErrorData$ | async as updateErrorData">
      {{ updateErrorData.error.errorMessage }}
    </div>
  </div>
  <div elem="controls">
    <div elem="cancel">
      <button
        id="cancel-form-button"
        mat-flat-button
        color="primary"
        (click)="closeModal()"
      >
        Cancel
      </button>
    </div>
    <div elem="submit">
      <button
        id="submit-form-button"
        type="submit"
        role="submit"
        mat-flat-button
        color="accent"
        (click)="resetPasswordClick(resetPassword.value, resetPassword.valid)"
        [disabled]="!resetPassword.valid"
        [class.disabled]="!resetPassword.valid"
      >
        Apply
      </button>
    </div>
  </div>
</form>
