import { Action } from '@ngrx/store';

export const ADD_CONTAINERS = '[Add Containers] ADD_CONTAINERS';
export const ADD_CONTAINERS_RESULT = '[Add Containers] ADD_CONTAINERS_RESULT';
export const ADD_CONTAINERS_ERROR = '[Add Containers] ADD_CONTAINERS_ERROR';
export const ADD_CONTAINERS_INIT = '[Add Containers] ADD_CONTAINERS_INIT';

export const ADD_PENDING_CONTAINERS = '[Add Pending Containers] ADD_PENDING_CONTAINERS';
export const ADD_PENDING_CONTAINERS_RESULT = '[Add Pending Containers] ADD_PENDING_CONTAINERS_RESULT';
export const ADD_PENDING_CONTAINERS_ERROR = '[Add Pending Containers] ADD_PENDING_CONTAINERS_ERROR';

export const UPLOAD_FILE = '[Add Containers] UPLOAD_FILE';

export const CLEAR = '[Dashboard] CLEAR';
export const ADD_CONTAINERS_CLEAR = '[Add Containers] ADD_CONTAINERS_CLEAR';

export class AddContainers implements Action {
  public readonly type = ADD_CONTAINERS;
  constructor(public payload?: any, public cancelModal?: boolean) { }
}
export class UploadFile implements Action {
  public readonly type = UPLOAD_FILE;
  constructor(public payload?: any, public cancelModal?: boolean) { }
}

export class AddContainersResult implements Action {
  public readonly type = ADD_CONTAINERS_RESULT;
  constructor(public payload: any) { }
}

export class AddContainersError implements Action {
  public readonly type = ADD_CONTAINERS_ERROR;
  constructor(public payload?: any) { }
}

export class AddContainersInit implements Action {
  public readonly type = ADD_CONTAINERS_INIT;
}

export class AddPendingContainers implements Action {
  public readonly type = ADD_PENDING_CONTAINERS;
  constructor(public payload?: any) { }
}

export class AddPendingContainersResult implements Action {
  public readonly type = ADD_PENDING_CONTAINERS_RESULT;
  constructor(public payload: any) { }
}

export class AddPendingContainersError implements Action {
  public readonly type = ADD_PENDING_CONTAINERS_ERROR;
  constructor(public payload?: any) { }
}

export class AddContainersClear implements Action {
  public readonly type = ADD_CONTAINERS_CLEAR;
}

export class Clear implements Action {
  public readonly type = CLEAR;
}

export type All = AddContainers
  | UploadFile
  | AddContainersResult
  | AddContainersError
  | AddContainersInit
  | AddPendingContainers
  | AddPendingContainersResult
  | AddPendingContainersError
  | AddContainersClear
  | Clear;
