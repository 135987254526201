export enum BANNER_LEVEL {
  SUCCESS = 'success',
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error',
}

export interface BannerItem {
  id: string | number;
  text: string;
  level: BANNER_LEVEL;
  autoClose?: boolean;
  hideCloseButton?: boolean;
}

export type AddBannerPayload = Omit<BannerItem, 'id'>;

export type RemoveBannerPayload = string | number;

export const BANNER_TIMEOUT_AUTOCLOSE_IN_MS = 5000;
