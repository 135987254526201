import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';

import * as ModalActions from './modal.actions';
import { ModalState } from './modal.state';

import { ModalService } from '@services/modal.service';

@Injectable()
export class ModalEffects {
  constructor(
    private actions: Actions,
    private store: Store<ModalState>,
    private modalService: ModalService,
  ) { }

  public openDialog$ = createEffect(() => this.actions.pipe(
    ofType<ModalActions.OpenDialog>(ModalActions.OPEN_DIALOG),
    tap(action => this.modalService.openDialog({
      component: action.payload.dialogType,
      data: action.payload.data,
    })),
  ),
  { dispatch: false },
  );

  public closeDialog$ = createEffect(() => this.actions.pipe(
    ofType<ModalActions.CloseDialog>(ModalActions.CLOSE_DIALOG),
    tap(() => this.modalService.closeDialog()),
  ),
  { dispatch: false },
  );
}
