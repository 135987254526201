import { Injectable } from '@angular/core';
import { ApiCallService } from '@app/core/services/api-call.service';
import { ApiQueryBuilder } from '@app/core/services/api-query-builder';
import { IContainerStatus } from '@app/shared/models/dashboard/container-status.model';
import { BehaviorSubject, Subscription } from 'rxjs';
import { RequestType } from './request-container.constants';
import { BannerService } from '@app/shared/modules/banner/service/banner.service';
import { BANNER_LEVEL } from '@app/shared/modules/banner/models/banner.models';
import * as DialogActions from '@store/modal/modal.actions';
import { Store } from '@ngrx/store';
import { State } from '@store/app.state';
import { format } from 'date-fns';
import { TimezoneService } from '@app/core/services/timezone.service';
import { ContainerStatusListService } from '@app/core/services/container-status-list.service';
import { FeatureFlagService } from '@app/core/services/feature-flag.service';

@Injectable({
  providedIn: 'root',
})
export class RequestContainerService {
  public tableDataSource$: BehaviorSubject<any[]> = new BehaviorSubject([]);
  public searchDataSource$: BehaviorSubject<any[]> = new BehaviorSubject([]);
  public requestList$: BehaviorSubject<any[]> = new BehaviorSubject([]);
  public currentTerminal: string;
  public searchTerm: string = '';
  private subscriptions: Subscription = new Subscription();
  public notes: string = '';
  public isAllSelectedValue: boolean = false;
  private readonly parent: string = 'container';
  private fromDate = new Date(new Date(new Date().setDate(new Date().getDate() + 2))).toISOString().substr(0, 19);
  private toDate =
    new Date(new Date(new Date().setDate(new Date().getDate() + 90))).toISOString().substr(0, 10) + 'T23:59:59';

  public payload = {
    from: 0,
    size: 100,
    sourceFields: [
      'containerNumber',
      'etaAta',
      'etaOrEtaTimeStamp',
      'vesselName',
      'destinationTerminalCode',
      'dischargeDateTime',
      'terminalName',
      'outgateDateTime',
      'equipmentCategory',
      'containerTripId',
      'etaTimeStamp',
    ],
    orderBy: 'containerNumber',
    orderByDirection: 'ASC',
    pottAdditionalConditions: { fetchPOTTAdditionalData:true, fetchCustomFields:true, sortOnScriptField:'etaAta', sortByDirection:"ASC" },
    search: [{ key: 'importExport', values: ['I'], comparison: 'EQUAL' }],
    specialHandlingRequestFilter: {
      fetchContainersWithoutSpecialHandling: true,
    },
    dateFilterAttribute: 'etaTimeStamp',
    fromDate: this.fromDate,
    toDate: this.toDate,
  };

  constructor(
    private api: ApiCallService,
    private bannerService: BannerService,
    private timezoneService: TimezoneService,
    private containerService: ContainerStatusListService,
    private featureFlags: FeatureFlagService,
    private store: Store<State>) {}

  public removeRow(id: string) {
    let currentList = this.tableDataSource$.getValue();
    currentList = currentList.filter((container) => container.containerTripId !== id);
    this.tableDataSource$.next(currentList);
  }

  public addContainers(containers: IContainerStatus[]) {
    const containerCopy = [...containers];
    containerCopy.sort((a, b) => {
      return a.containerNumber.localeCompare(b.containerNumber);
    });
    this.currentTerminal = containerCopy[0].terminalName;
    this.tableDataSource$.next(containerCopy);
  }

  public addSearchContainers(searchContainers: IContainerStatus[]) {
    this.searchDataSource$.next(searchContainers);
  }

  public addSearchContainerToRequestContainers(id: string) {
    const searchList = this.searchDataSource$.getValue();
    const tableList = this.tableDataSource$.getValue();
    const addedContainer = searchList.filter((container) => container.containerTripId === id)[0];
    const updatedContainerList = [...tableList, addedContainer];

    updatedContainerList.sort((a, b) => {
      return a.containerNumber.localeCompare(b.containerNumber);
    });

    this.searchDataSource$.next(searchList);
    this.tableDataSource$.next(updatedContainerList);
  }

  public requestCancel(payload): void {
    this.api
    .constructApiCall(new ApiQueryBuilder().addName('cancelRequest').addBody(payload).build())
    .subscribe(
      (response: any) => {
        if (response) {
          this.bannerService.add({
            text: `Your request has been cancelled`,
            level: BANNER_LEVEL.SUCCESS,
            autoClose: true,
          });
          this.store.dispatch(
            new DialogActions.CloseDialog(),
          );
          this.cleanContainerData();
          this.fetchRequestList()
        }
      },
      () => {
        this.bannerService.add({
          text: `Your request has been cancel has failed.`,
          level: BANNER_LEVEL.ERROR,
          autoClose: true,
        });
        this.store.dispatch(
          new DialogActions.CloseDialog(),
        );
      },
    );
  }

  public fetchContainerData(searchParam): void {
    const searchFilter = {
      key: 'containerNumber',
      values: [searchParam],
      comparison: 'CASE_INSENSITIVE_REGEX',
    };
    const terminalFilter = {
      key: 'destinationTerminalCode',
      values: [this.currentTerminal],
      comparison: 'EQUAL',
    }
    this.payload.search = this.payload.search.filter((filter) => {
      return filter.key !== 'containerNumber' && filter.key !== 'destinationTerminalCode';
    });

    this.payload.search.push(searchFilter);
    this.payload.search.push(terminalFilter);

    this.subscriptions.add(
      this.api
        .constructApiCall(new ApiQueryBuilder().addName('containerStatusList').addBody(this.payload).build())
        .subscribe(
          (response: any) => {
            const searchValues = [];
            if (response && response.hits) {
              response.hits.hits.forEach((response) => {
                const data = this.createResponseObject(response);
                searchValues.push(data);
              });
            }
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            this.addSearchContainers(searchValues);
          },
          (error) => {
            console.error(error);
          },
        ),
    );
  }

  private createResponseObject(response: any) {
    return {
      containerNumber: response._source.containerNumber,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      etaAta: this.timezoneService.getDateInRequiredTimeZone(this.containerService.getEtaAta(response)),
      etaTimeStamp: response._source.etaOrEtaTimeStamp,
      containerTripId: response._source.containerTripId,
      terminalName: response._source.destinationTerminalCode,
      typeCode: response._source.equipmentCategory,
      vesselName: response._source.vesselName,
    }
  }

  public cleanContainerData() {
    this.tableDataSource$.next([]);
    this.searchDataSource$.next([]);
    this.notes = '';
  }

  public createSubmitRequest(): any {
    const currentList = this.tableDataSource$.getValue();
    const currentRequest = {
      containerDetails: [],
      serviceName: RequestType.PEEL_PILE,
      terminalCode: this.currentTerminal,
      notes: this.notes,
    };

    currentList.forEach((container) => {
      const currentContainer = {
        containerNumber: container.containerNumber,
        containerTripId: container.containerTripId,
        etaAta: container.etaTimeStamp,
        terminalCode: container.terminalName,
        typeCode: container.typeCode,
        vesselName: container.vesselName,
      };
      currentRequest.containerDetails.push(currentContainer);
    });

    this.api
      .constructApiCall(new ApiQueryBuilder().addName('requestSpecialHandling').addBody(currentRequest).build())
      .subscribe(
        (response: any) => {
          if (response) {
            this.bannerService.add({
              text: `Your request has been sent`,
              level: BANNER_LEVEL.SUCCESS,
              autoClose: true,
            });
            this.cleanContainerData();
            this.fetchRequestList()
          }
        },
        () => {
          this.bannerService.add({
            text: `Your request creation has failed.`,
            level: BANNER_LEVEL.ERROR,
            autoClose: true,
          });
          this.cleanContainerData();
        },
      );
  }

  public fetchRequestList(): any {
    this.api.constructApiCall(new ApiQueryBuilder().addName('specialHandling').build())
    .subscribe(
      (response: any) => {
        const requestList = response.map(request => ({
          containerDetails: request.containerDetails.map(container => ({
            ...container,
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            etatimestamp: this.timezoneService.getDateInRequiredTimeZone(container.etaAta),
            terminalName: container.terminalCode,
        })),    
          terminalDetails: request.terminalDetails,
          userDetailsModel: request.userDetailsModel,
          requestDate: request.requestDate,
          requestId: request.requestId,
          requestStatus: request.requestStatus,
          serviceName: request.serviceName,
          terminalRequest: request.serviceName+' Request | '+request.terminalName+' | '+ this.dateFormat(request.requestDate) ,
          notes: request.notes,
        }));
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        this.requestList$.next(requestList);      
    })
  }

  public dateFormat(timestamp:any){
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const formatted = new Date(timestamp).toLocaleString('en-US', {
      timeZone: 'America/Los_Angeles', 
    });
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    return timestamp? format( new Date(formatted), 'MMM dd, HH:mm a') : ''
  }
}
