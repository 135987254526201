import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { map, catchError, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { ApiQueryBuilder } from '@services/api-query-builder';
import { ApiCallService } from '@services/api-call.service';

import * as GlobalErrorActions from '@store/global-error/global-error.actions';
import * as NotificationTypesActions from '@store/notification-types/notification-types.actions';
import { INotificationCategoryType } from './notification-types.state';

@Injectable()
export class NotificationTypesEffect {
  constructor(
    // eslint-disable-next-line @typescript-eslint/ban-types
    private store: Store<{}>,
    private actions$: Actions,
    private api: ApiCallService,
  ) { }

  public fetchAlertTypes$ = createEffect(() => this.actions$.pipe(
    ofType<NotificationTypesActions.FetchAlertTypes>(NotificationTypesActions.FETCH_ALERT_TYPES),
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    switchMap(_ => {
        return this.api.constructApiCall(
          new ApiQueryBuilder()
            .addName('alertTypes')
            .build(),
        ).pipe(
          map((data: INotificationCategoryType[]) => new NotificationTypesActions.FetchAlertTypesSuccess(data)),
          catchError(error => {
            const payload = { errorType: 'network', error };

            this.store.dispatch(new GlobalErrorActions.SetGlobalError(payload));
            return of(new NotificationTypesActions.FetchAlertTypesFail(payload));
          }),
        );
    },
    ),
  ),
  );
}

