import { createFeatureSelector, createSelector } from '@ngrx/store';

import { EmptiesReturnState } from './empties-return.state';
import * as EmptiesReturnAction from './empties-return.actions';

export type Action = EmptiesReturnAction.All;

const initialState: EmptiesReturnState = {
  loading: false,
  error: false,
  data: [],
  publicData: {},
  filter: {},
  filterSet: [],
  errorData: null,
  downloadingExcelSheet: false,
  excelDownloadError: null,
};

const getEmptiesReturnState = createFeatureSelector<EmptiesReturnState>('empties-return');

export const getErrorData = createSelector(
  getEmptiesReturnState,
  state => state.errorData || state.error,
);

export const getEmptiesReturnResponse = createSelector(
  getEmptiesReturnState,
  state => state.data,
);

export const getPublicEmptiesReturnResponse = createSelector(
  getEmptiesReturnState,
  state => state.publicData,
);

export const getEmptiesReturnResponseForTerminal = (terminalCode: string) =>
  createSelector(
    getEmptiesReturnState,
    state => state.data.filter(({terminalName}) => terminalName === terminalCode),
  );

export const isEmptiesLoading = createSelector(
  getEmptiesReturnState,
  state => state.loading,
);

export const getFilterState = createSelector(
  getEmptiesReturnState,
  state => state.filter,
);

export const getFilterSet = createSelector(
  getEmptiesReturnState,
  state => state.filterSet,
);

export const isExcelInDownload = createSelector(
  getEmptiesReturnState,
  state => state.downloadingExcelSheet,
);

// eslint-disable-next-line @typescript-eslint/naming-convention
export function EmptiesReturnReducer(state = initialState, action: Action): EmptiesReturnState {
  switch (action.type) {
    case EmptiesReturnAction.FETCH_PUBLIC_DATA:
    case EmptiesReturnAction.FETCH_DATA: {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }

    case EmptiesReturnAction.FETCH_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        filterSet: JSON.parse(JSON.stringify(action.payload.filterSet)),
        data: [...action.payload.data],
      };
    }

    case EmptiesReturnAction.FETCH_PUBLIC_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        publicData: action.payload,
      };
    }

    case EmptiesReturnAction.FETCH_PUBLIC_DATA_ERROR:
    case EmptiesReturnAction.FETCH_DATA_ERROR: {
      return {
        ...state,
        error: true,
        loading: false,
        errorData: { ...action.payload },
      };
    }

    case EmptiesReturnAction.UPDATE_FILTER: {
      return {
        ...state,
        filter: { ...action.payload },
      };
    }

    case EmptiesReturnAction.DOWNLOAD_EXCEL: {
      return {
        ...state,
        downloadingExcelSheet: true,
        excelDownloadError: null,
      };
    }
    case EmptiesReturnAction.DOWNLOAD_EXCEL_SUCCESS: {
      return {
        ...state,
        downloadingExcelSheet: false,
      };
    }
    case EmptiesReturnAction.DOWNLOAD_EXCEL_ERROR: {
      return {
        ...state,
        downloadingExcelSheet: false,
        excelDownloadError: { ...action.payload },
      };
    }

    default:
      return state;
  }
}
