import { Component, EventEmitter, OnInit, Output, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-appointments-modal',
  templateUrl: './appointments-modal.component.html',
  styleUrls: ['./appointments-modal.component.css'],
})
export class AppointmentsModalComponent implements OnInit {
  @Output() public submitData = new EventEmitter<object>();
  @Output() public closeForm = new EventEmitter<void>();

  public _injectedData: any;
  public url: SafeResourceUrl;

  constructor(private sanitizer: DomSanitizer) {
  }

  public ngOnInit() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument 
    this.url = this.sanitizer.sanitize(SecurityContext.URL, this._injectedData?.url);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public onCloseClick(_: MouseEvent): void {
    this.closeForm.emit();
  }

  get modalTitle() {
    return this._injectedData?.modalTitle || '';
  }
}
