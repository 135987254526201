import {
  createReducer,
  on,
  createFeatureSelector,
  createSelector,
} from '@ngrx/store';
import {
  fetchHistorical,
  fetchHistoricalSuccess,
  fetchHistoricalError,
  initHistorical,
  fetchTerminalFilterSet,
  fetchTerminalFilterSetSuccess,
  fetchTerminalFilterSetError,
  downloadHistorical,
  downloadHistoricalSuccess,
  downloadHistoricalError,
} from './empties-historical.actions';
import { EmptiesHistoricalState } from './empties-historical.state';

export const initialState: EmptiesHistoricalState = {
  loading: false,
  data: [],
  error: '',
  terminalFilterSet: [],
  downloading: false,
  downloadError: '',
};

const getEmptiesHistoricalState = createFeatureSelector<EmptiesHistoricalState>(
  'emptiesHistorical',
);

export const getFetchData = createSelector(
  getEmptiesHistoricalState,
  (state) => state.data,
);

export const getFetchLoading = createSelector(
  getEmptiesHistoricalState,
  (state) => state.loading,
);

export const getDownloading = createSelector(
  getEmptiesHistoricalState,
  (state) => state.downloading,
);

export const getTerminalFilterSet = createSelector(
  getEmptiesHistoricalState,
  (state) => state.terminalFilterSet,
);

const _emptiesHistoricalReducer = createReducer(
  initialState,
  on(
    initHistorical,
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    (_: EmptiesHistoricalState): EmptiesHistoricalState => ({
      ...initialState,
    }),
  ),
  on(
    fetchTerminalFilterSet,
    (state: EmptiesHistoricalState): EmptiesHistoricalState => ({
      ...state,
      loading: true,
      terminalFilterSet: initialState.terminalFilterSet,
      error: '',
    }),
  ),
  on(
    fetchTerminalFilterSetSuccess,
    (
      state: EmptiesHistoricalState,
      { terminalFilterSet },
    ): EmptiesHistoricalState => ({
      ...state,
      loading: false,
      terminalFilterSet,
      error: '',
    }),
  ),
  on(
    fetchTerminalFilterSetError,
    (state: EmptiesHistoricalState, { error }): EmptiesHistoricalState => ({
      ...state,
      loading: false,
      terminalFilterSet: initialState.terminalFilterSet,
      error,
    }),
  ),
  on(
    fetchHistorical,
    (state: EmptiesHistoricalState): EmptiesHistoricalState => ({
      ...state,
      loading: true,
      data: initialState.data,
      error: '',
    }),
  ),
  on(
    fetchHistoricalSuccess,
    (state: EmptiesHistoricalState, { data }): EmptiesHistoricalState => ({
      ...state,
      loading: false,
      data,
      error: '',
    }),
  ),
  on(
    fetchHistoricalError,
    (state: EmptiesHistoricalState, { error }): EmptiesHistoricalState => ({
      ...state,
      loading: false,
      data: initialState.data,
      error,
    }),
  ),
  on(
    downloadHistorical,
    (state: EmptiesHistoricalState): EmptiesHistoricalState => ({
      ...state,
      downloading: true,
      downloadError: '',
    }),
  ),
  on(
    downloadHistoricalSuccess,
    (state: EmptiesHistoricalState): EmptiesHistoricalState => ({
      ...state,
      downloading: false,
      downloadError: '',
    }),
  ),
  on(
    downloadHistoricalError,
    (state: EmptiesHistoricalState, { downloadError }): EmptiesHistoricalState => ({
      ...state,
      downloading: false,
      downloadError,
    }),
  ),
);

// eslint-disable-next-line @typescript-eslint/naming-convention
export function EmptiesHistoricalReducer(state, action) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  return _emptiesHistoricalReducer(state, action);
}
