import { Component, Inject, ChangeDetectionStrategy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-request-container-remove-model',
  templateUrl: './request-container-remove-model.component.html',
  styleUrls: ['./request-container-remove-model.component.css'],
})
export class RequestContainerRemoveModelComponent {

  constructor(
    public dialogRef: MatDialogRef<RequestContainerRemoveModelComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { container: string },
    public dialog: MatDialog,
  ) {}

  public onCancelClick(): void {
    this.dialogRef.close();
  }

  public onConfirmClick(): void {
    this.dialogRef.close(true);
  }

}
