import { Action } from '@ngrx/store';

export const GET_SEARCH_RESULTS = '[Search] GET_SEARCH_RESULTS';
export const GET_SEARCH_RESULTS_SUCCESS = '[Search] GET_SEARCH_RESULTS_SUCCESS';
export const GET_SEARCH_RESULTS_ERROR = '[Search] GET_SEARCH_RESULTS_ERROR';
export const CLEAR_SEARCH_RESULTS = '[Search] CLEAR_SEARCH_RESULTS';

export class GetSearchResults implements Action {
  public readonly type = GET_SEARCH_RESULTS;
  constructor(public payload: any = null) { }
}

export class GetSearchResultsSuccess implements Action {
  public readonly type = GET_SEARCH_RESULTS_SUCCESS;
  constructor(public payload: any) { }
}

export class GetSearchResultsError implements Action {
  public readonly type = GET_SEARCH_RESULTS_ERROR;
  constructor(public error: any) { }
}

export class ClearSearchResults implements Action {
  public readonly type = CLEAR_SEARCH_RESULTS;
}

export type All = GetSearchResults | GetSearchResultsSuccess | GetSearchResultsError | ClearSearchResults ;
