import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { IScheduleAppointmentData } from '@app/shared/models/dashboard/terminal.model';
import { OpenDialog } from '@app/store/modal/modal.actions';
import { NewWindowConfirmModalData } from '@app/shared/components/new-window-confirm-modal/new-window-confirm-modal.component';

const APPOINTMENT_NEW_WINDOW_IGNORE_LIST = 'appointmentNewWindowIgnoreList';
const GATEHOURS_NEW_WINDOW_IGNORE_LIST = 'gatehoursNewWindowIgnoreList';

@Injectable({
  providedIn: 'root',
})
export class ModalConfirmationService {
  constructor(private store: Store<{}>) {}

  public checkGateHours({
    terminalCode,
    url,
    openInNewWindow = false,
  }: IScheduleAppointmentData) {
    const data: NewWindowConfirmModalData = {
      url: url,
      confirmationType: GATEHOURS_NEW_WINDOW_IGNORE_LIST,
      terminalCode: terminalCode,
      confirmationText: `To check updated gate hours at this terminal will open a new tab with in your browser.
      Click on that tab to continue to the process.`,
      modalTitle: 'Gate hours',
    };
    return this.openURLInWindowOrModal(data, openInNewWindow);
  }

  public scheduleAppointment({
    terminalCode,
    url,
    openInNewWindow = false,
  }: IScheduleAppointmentData) {
    const data: NewWindowConfirmModalData = {
      url: url,
      confirmationType: APPOINTMENT_NEW_WINDOW_IGNORE_LIST,
      terminalCode: terminalCode,
      confirmationText: `To schedule at this terminal will open a new tab with in your browser.
      Click on that tab to continue to the process.`,
      modalTitle: 'Scheduled appointments',
    };
    return this.openURLInWindowOrModal(data, openInNewWindow);
  }

  public confirmNewWindow(storageKey: string, terminalCode: string) {
    const settings = JSON.parse(
      window.localStorage.getItem(storageKey) || '{}',
    );
    settings[terminalCode] = true;
    window.localStorage.setItem(storageKey, JSON.stringify(settings));
  }

  private openURLInWindowOrModal(data, openInNewWindow) {
    const alreadyConfirmed = this.isNewWindowConfirmed(
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      data.confirmationType,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      data.terminalCode,
    );
    const dialogType = openInNewWindow
      ? 'NewWindowConfirmModalComponent'
      : 'AppointmentsModalComponent';

    return openInNewWindow && alreadyConfirmed
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ? window.open(data.url)
      : this.store.dispatch(new OpenDialog({ dialogType, data }));
  }

  private isNewWindowConfirmed(storageKey: string, terminalCode: string) {
    return !!JSON.parse(window.localStorage.getItem(storageKey) || '{}')[
      terminalCode
    ];
  }
}
